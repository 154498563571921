import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { StudentsComponent } from './students/students.component';
import { CalidadComponent, /*ButtonViewLoadChartt */ } from './calidad/calidad.component';
import { DelegateComponent } from './delegate/delegate.component';
import { SelfAppraisalComponent } from './self_appraisal/self_appraisal.component';
import { InstitutionalEvaluationComponent } from './institutional-evaluation/institutional-evaluation.component';
import { Appraiselect } from './appraiselect.component';
import { RadarStudentBoxComponent } from './delegate/radar-student-box/radar-student-box.component';
import { RadarSelfApprBoxComponent } from './delegate/radar-selfappr-box/radar-selfappr-box.component'
import { RadarSchoolApprBoxComponent } from './delegate/radar-schoolappr-box/radar-schoolappr-box.component';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { GraduatesComponent } from './graduates/graduates.component';
import { RadarGraduatedapprBoxComponent } from './delegate/radar-graduatedappr-box/radar-graduatedappr-box.component';
import { TeacherViewComponent } from './teacher-view/teacher-view.component';
import { OrderModule } from 'ngx-order-pipe';
import { SchoolViewComponent } from './school-view/school-view.component';
import { CarouselModule } from 'ngx-owl-carousel-o';


export const routes: Routes = [
  { path: 'evaluation/students', component: StudentsComponent },
  { path: 'evaluation/delegate', component: DelegateComponent },
  { path: 'evaluation/self_appraisal', component: SelfAppraisalComponent },
  { path: 'evaluation/institutional_evaluation', component: InstitutionalEvaluationComponent },
  { path: 'evaluation/calidad', component: CalidadComponent },
  { path: 'evaluation/graduates', component: GraduatesComponent },
  { path: 'evaluation/teacher_view', component: TeacherViewComponent },
  { path: 'evaluation/school_view', component: SchoolViewComponent }



];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    CarouselModule,
    FormsModule,
    HttpModule,
    Ng2SmartTableModule,
    OrderModule


  ],
  declarations: [
    StudentsComponent,
    DelegateComponent,
    SelfAppraisalComponent,
    InstitutionalEvaluationComponent,
    GraduatesComponent,
    Appraiselect,
    CalidadComponent,
    RadarStudentBoxComponent,  //ARAÑA DE CALIFICACION ESTUDIANTES A DOCENTE EN DELEGATE
    RadarSelfApprBoxComponent, //ARAÑA DE AUTOEVALUACION DEL DOCENTE EN DELEGATE
    RadarSchoolApprBoxComponent, RadarGraduatedapprBoxComponent,  //ARAÑA DE AUTOEVALUACION DE la escuela EN DELEGATE
    //ButtonViewLoadChartt,
    TeacherViewComponent,
    SchoolViewComponent


  ],
  exports: [RouterModule],
  entryComponents: [
    // ButtonViewLoadChartt
    // EvaluationBoxComponent

  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class TeacherEvaluationModule { }