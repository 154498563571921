import { Component, OnInit } from '@angular/core';

import { isNullOrUndefined } from 'util';

import { ActivatedRoute, Router } from "@angular/router";
import { RecordService } from 'app/models/midterm/record.service';
import { AuthService } from 'app/models/Auth/auth.service';
import { ProfileNavComponent } from 'app/components/profile-nav/profile-nav.component';
import { EvaluationsService } from 'app/models/evaluation/evaluations.service';
import { Course } from 'app/models/Coursesinfo/course';
import { UsersService } from 'app/models/usersInfo/users.service';
import { CoursesService } from 'app/models/Coursesinfo/courses.service';

declare var $: any;
declare var Chart: any;

@Component({
  moduleId: module.id,
  selector: 'radar-graduatedappr-box',
  templateUrl: './radar-graduatedappr-box.component.html',
  styleUrls: ['./radar-graduatedappr-box.component.css']
})
export class RadarGraduatedapprBoxComponent implements OnInit {

  public Courses: Course[];
  public Subjects: Course[]
  public IsProfessor: boolean = false;
  public IsStudent: boolean = false;
  public IsStudent2: boolean = false;
  public SubjectsProfesor: Course[];
  public EventCalendarProfessor: any[] = []
  public EventCalendarColorIndexProfessor: any[] = []

  public EventCalendarStudent: any[] = []
  public EventCalendarColorIndexStudent: any[] = []

  TotalCat1: number = 0;
  TotalCat2: number = 0;
  TotalCat3: number = 0;
  TotalCat4: number = 0;
  TotalCat5: number = 0;
  TotalCat6: number = 0;
  TotalCat7: number = 0;
  TotalCat8: number = 0;
  TotalCat9: number = 0;
  dataRadar: any
  ChartRadar: any
  IsInspector = false;
  public ColorCourses = ["#5d80a2", "#680345", "#7C6445", "#E154CE", "#258C64", "#A44015", "#FE9A2E", "#DF0101", "#01DF01", "#0040FF", "#565ACB", "#144A20", "#AAE951", "#6CF132", "#F19663", "#891CBB", "#21EBF3", "#F19663", "#61265F", "#6916E4", "#464208", "#393D78", "#E2337D", "#A1924C", "#52B743", "#176548", "#A0B03D", "#7C188A", "#77BB3E"]

  SchoolID: number = 0;
  subjectgroupsprofessor: any = []

  public idSchool: any;

  constructor(
    private recordService: RecordService,
    private authService: AuthService,
    private usersService: UsersService,
    private coursesService: CoursesService,
    private route: ActivatedRoute,
    private router: Router,
    public profileNavComponent: ProfileNavComponent,
    public evaluationsservice: EvaluationsService,

  ) {

  }

  //INDICO QUE LOS DATOS NO ESTAN DISPONIBLES AL CARGAR EL HTML PARA NO MOSTRAR ERRORES
  ngOnInit() {
    console.log("Chart graduatesappr");
    console.log(this.idSchool);

    // RADAR CHART
    //var radarCtx = document.getElementById('chart-radar').getContext('2d');
    var radarCtx = $("#chart-radar-graduatesappr")[0].getContext('2d');

    this.dataRadar = {


      labels: ["Aspectos Institucionales", "Recursos académicos", "Recursos administrativos"],
      datasets: [
        {
          label: "Egresados",
          backgroundColor: "rgba(47, 121, 238,0.2)",
          borderColor: "rgba(47, 121, 238,1)",
          pointBackgroundColor: "rgba(47, 121, 238,1)",
          pointBorderColor: "#fff",
          pointHoverBackgroundColor: "#fff",
          pointHoverBorderColor: "rgba(47, 121, 238,1)",
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0]
        }
      ]
    };

    this.ChartRadar = new Chart(radarCtx, {
      type: 'radar',
      data: this.dataRadar,
      options: {
        responsive: true,

        scale: {

          ticks: {
            max: 5,
            stepSize: 1,
            min: 0,
            beginAtZero: true,
          }
        },

      }
    });

    if (isNullOrUndefined(this.idSchool)) {
      //NO HAGO NADA
    }
    else {


      var filterarray = []

      filterarray.push({ "SchoolID": this.idSchool })

      var filterdb = `{"where": { "or": ` + JSON.stringify(filterarray) + `}}`;
      //consulto todas las notas de esa escuela
      this.evaluationsservice.getGraduatesApprBySubjectGroup(filterdb).subscribe(responseGraduates => {

        console.log(responseGraduates, 'getGraduatesAppr')
        this.TotalCat1 = 0
        this.TotalCat2 = 0
        this.TotalCat3 = 0
        //CALCULAR EL PROMEDIO DE TODAS LAS NOTAS
        for (var i = 0; i < responseGraduates.length; i++) {
          console.log('length', responseGraduates.length)
          this.TotalCat1 += responseGraduates[i].TotalCat1
          this.TotalCat2 += responseGraduates[i].TotalCat2
          this.TotalCat3 += responseGraduates[i].TotalCat3

        }

        this.TotalCat1 = Number(((this.TotalCat1 / responseGraduates.length)/10).toFixed(1));
        this.TotalCat2 = Number(((this.TotalCat2 / responseGraduates.length)/10).toFixed(1));
        this.TotalCat3 = Number(((this.TotalCat3 / responseGraduates.length)/10).toFixed(1));
        this.dataRadar.datasets[0].data = [this.TotalCat1, this.TotalCat2, this.TotalCat3]
        //this.ChartRadar.removeData();
        // this.ChartRadar.addData(this.dataRadar.datasets[0].data);
        console.log("Egresados Escuelas")
        console.log(this.idSchool)
        console.log(this.dataRadar.datasets[0].data)
        this.ChartRadar.update();
      })

    }
  }

  ngAfterViewInit() {
  }
}






