import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NewScheduleModule } from './new-schedule/new-schedule.module';
import { SmartScheduleModule } from './smart-schedule/smart-schedule.module';
import { RecesosModule } from './recesos/recesos.module';



@NgModule({
  imports: [
    CommonModule,
    NewScheduleModule,
    SmartScheduleModule,
    RecesosModule,
  ],
  declarations: [

  ]
})
export class modHorarios { }