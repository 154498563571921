import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalDataSource } from 'ng2-smart-table';
import { AuthService } from '../../../../models/Auth/auth.service';
import { ProfileNavComponent } from '../../../../components/profile-nav/profile-nav.component';
import { CoursesService } from '../../../../models/Coursesinfo/courses.service';
import { DomSanitizer } from '@angular/platform-browser';
import { UsersService } from '../../../../models/usersInfo/users.service';
import { UsersField } from '../../../../models/usersInfo/users-fields';
import { School } from '../../../../models/Coursesinfo/school';
import { EvaluationsService } from '../../../../models/evaluation/evaluations.service';
import { ChartCalidadSchoolComponent } from '../../../modDirector/report/ChartCalidad/chart-calidad-school.component';
import { ChartCalidadTeacherComponent } from '../../../modDirector/report/ChartCalidad/chart-calidad-teacher.component';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { environment } from 'environments/environment';



/*// INICIO COMPONENTE PARA EL NG SMART TABLE GRUPOS
@Component({
  selector: 'button-view-groups',
  template:`
 <a (click)="nombrefuncion(renderValue)" href="javascript:void(0)" >hola hola  {{renderValue}} </a>
`,

})
export class ButtonViewLoadChartt implements ViewCell, OnInit, AfterViewChecked {
  constructor(
    public chartcalidad:ChartCalidadTeacherComponent
  ) { }
  renderValue: string;
  @Input() value: string;
  @Input() rowData: any;
  @Output() save: EventEmitter<any> = new EventEmitter()
  ngOnInit() {
    // console.log("aca esta el render despues")

    this.renderValue = this.value;
   
  }
  ngAfterViewChecked() {
    this.renderValue = this.value;
  }



  nombrefuncion(id){

    this.chartcalidad.idProfileCV = id
     this.chartcalidad.ngOnInit()
  
    
  }
  



}
// FIN COMPONENTE PARA EL NG SMART TABLE GRUPOS*/



@Component({
  selector: 'Calidad',
  templateUrl: './calidad.component.html',
  styleUrls: ['calidad.component.css'],
  providers: [ChartCalidadSchoolComponent, ChartCalidadTeacherComponent]
})
export class CalidadComponent {

  customOptions: OwlOptions = {
    loop: true,
    navSpeed: 700,
    nav: false,
    dots: false,
    margin: 10,
    responsive: {
      0: {
        items: 3
      },
      400: {
        items: 6
      },
      740: {
        items: 10
      },
      940: {
        items: 13
      }
    }
  }


  NameDir: string;
  EmailDir: string;
  NameIns: string;
  EmailIns: string;
  Role2: string;
  Acepted: boolean = false;

  user: string;
  SchoolID: number;
  Url: any;
  IsUrl: boolean = false;
  schoolID: number;
  User_info: UsersField;

  ejex1 = []
  ejey1 = []
  ejex2 = []
  ejey2 = []
  ejex3 = []
  ejey3 = []
  ejex4 = []
  ejey4 = []

  year: number

  dataRadar: any
  ChartRadar: any

  inforteacher = []

  coursesActive: number

  DependSchoolID =4;
  schools : School[];
  isDataReady = false;
  isSchoolReady = false;

  numberStudents: number = 0;

  //Tabla Lista de Docentes - Inicio
  settingsReportTeacher = {
    noDataMessage: "No hay datos disponibles",

    actions: {
      edit: false,
      columnTitle: "Acciones"
    },
    add: {
      addButtonContent: '',
      createButtonContent: '',
      cancelButtonContent: '',
      confirmCreate: false
    },
    edit: {
      editButtonContent: 'Ver Tabla',
      saveButtonContent: '',
      cancelButtonContent: '',
      confirmSave: false
    },
    delete: {
      deleteButtonContent: "Ver Resultados",
      confirmDelete: true,
    },
    pager: {
      perPage: 5,
    },
    columns: {

      NameRange: {
        title: 'Rango',
        filter: false,
        editable: false
      },
      Names: {
        title: 'Docente',
        filter: false,
        editable: false,
        type: 'html'
      },
      NameCourse: {
        title: 'Curso',
        filter: false,
        editable: false
      },
      NameSubject: {
        title: 'Nombre de la Materia',
        filter: false,
        editable: false
      },
      CodeClassRoom: {
        title: 'Codigo ClassRoom',
        filter: false,
        editable: false
      },
      number: {
        title: '',
        filter: false,
        editable: false,
        type: 'html'
      }
    }
  };

  sourceReportTeacher: LocalDataSource;


  public SCHOOL_LOGO_DYNAMIC_IMG:any = (id:number) => environment.SCHOOL_LOGO_DYNAMIC_IMG(id);
  public SCHOOL_DYNAMIC_PHOTO:any = (id:number) => environment.SCHOOL_DYNAMIC_PHOTO(id);

  //Tabla Lista de Docentes - Fin

  constructor(private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    public profileNavComponent: ProfileNavComponent,
    private coursesService: CoursesService,
    public sanitizer: DomSanitizer,
    private servicios: UsersService,
    public chartcalidadschool: ChartCalidadSchoolComponent,
    public chartcalidadteacher: ChartCalidadTeacherComponent,
    public evaluationsservice: EvaluationsService,


  ) {
    //CARGA EL ID DE LA ESCUELA
    this.schoolID = this.profileNavComponent.roles[0].SchoolID;
    //Cargo datos Profesores
    this.sourceReportTeacher = new LocalDataSource();
    //SI NO HAY USUARIOS EN LOCAL STORAGE, LLEVAR A LOGIN
    if (this.authService.getCurrentUser() == null) {
      this.router.navigate(['/register']);
    } else {
      //DE LO CONTRARO CARGAR EL ID DEL USUARIO
      this.user = this.authService.getCurrentUser(); //devuelve el id del usuario

      //SI EL ID DEL USUARIO ES UN NUMERO ENTERO
      if (Number.isInteger(parseInt(this.user))) {


        //CONSULTO EL PERFIL DEL USUARIO
        var validar_usuario = [false];

        for (let role of this.profileNavComponent.roles) {
          if (role.name == "Calidad") {
            validar_usuario = validar_usuario.concat([true])
            this.SchoolID = role.SchoolID;
            break
          }
          if (role.name == "Calidad") {
            validar_usuario = validar_usuario.concat([true])
            this.SchoolID = role.SchoolID;
            break
          }
          if (role.name == "Inspector Escuela") {
            validar_usuario = validar_usuario.concat([true])
            this.SchoolID = role.SchoolID;
            break
          }
        }
        if (!validar_usuario.includes(true)) { this.router.navigate(['/']); }

        this.authService.swalLoading("Cargando")



        this.coursesService.getSchoolByID(this.SchoolID).subscribe(resp => {
          //console.log(resp)

          this.Url = sanitizer.bypassSecurityTrustResourceUrl(resp.ReportSchool);
          this.authService.swalEndLoad()
          this.IsUrl = true
        })
        this.coursesService.getAllSchoolByDepend(this.SchoolID).subscribe(depend => {
          if(depend.length > 0){
            this.schools = depend
            this.isSchoolReady = true
          }else{
            this.isDataReady = true
            this.LoadData()
          }
        })
      }
    }
  }

  setNewSchool(id){
    this.SchoolID=id
    this.isDataReady = true
    this.LoadData() 
  }

  LoadData(){

    var filterarray = []

    filterarray.push({ "SchoolID": this.SchoolID })

    var filterdb = `{"where": { "or": ` + JSON.stringify(filterarray) + `}}`;
    //consulto todas las notas de esa escuela
    this.evaluationsservice.getSchoolApprBySubjectGroup(filterdb).subscribe(responseSchool => {

      this.numberStudents = responseSchool.length

    })
    //this.chartcalidadschool.idSchool = this.SchoolID
    //console.log('Escuela idSchool', this.chartcalidadschool.idSchool)
    //this.chartcalidadschool.ngOnInit()
    //SI EL ID DEL USUARIO ES UN NUMERO ENTERO
    if (Number.isInteger(parseInt(this.user))) {

      //LLAMO EL SERVICIO QUE CONTIENE LOS DATOS DEL USUARIO
      this.servicios.get(parseInt(this.user)).subscribe(response => {
        this.User_info = response;
      });
    }

    //Carga tabla de profesores
    this.coursesService.getAllTeacherSubjectBySchool(this.SchoolID).subscribe(p => {
      this.sourceReportTeacher.load(p)
      /*for (let index = 0; index < p.length; index++) {

          var lugar = {
            rango:  p[index].NameRange,
            names: p[index].Names,
            materia: p[index].NameSubject,
            codecla: p[index].CodeClassRoom,
            id: p[index].id
            
          }
  
          this.inforteacher.push(lugar);
          //console.log("Informacion profe")
          //console.log(this.inforteacher)  
        }*/

    })

    // Cargar Cursos por fecha y categoria
    this.coursesService.getAllCoursesDatesBySchoolByCategory1(this.SchoolID, 1).subscribe(cc1 => {

      var anyo = new Date().getFullYear();
      this.year = anyo
      cc1.forEach(element => {

        this.ejex1.push(element.Date);
        this.ejey1.push(element.NumberCourse);

      });

      this.coursesService.getAllCoursesDatesBySchoolByCategory1(this.SchoolID, 2).subscribe(cc2 => {

        cc2.forEach(element => {

          this.ejex2.push(element.Date);
          this.ejey2.push(element.NumberCourse);

        });

        this.coursesService.getAllCoursesDatesBySchoolByCategory1(this.SchoolID, 3).subscribe(cc3 => {

          cc3.forEach(element => {

            this.ejex3.push(element.Date);
            this.ejey3.push(element.NumberCourse);

          });

          this.coursesService.getAllCoursesDatesBySchoolByCategory1(this.SchoolID, 4).subscribe(cc4 => {

            cc4.forEach(element => {

              this.ejex4.push(element.Date);
              this.ejey4.push(element.NumberCourse);

            });

            this.plotGraph();

          })

        })

      })

    })


    //Carga NOmbre director y Inspector
    this.coursesService.getAllRoleDirectorInspectorBySchool(this.SchoolID, 4).subscribe(dir => {
      this.NameDir = dir[0].Names
      this.EmailDir = dir[0].CedocEmail
    })
    this.coursesService.getAllRoleDirectorInspectorBySchool(this.SchoolID, 7).subscribe(ins => {
      this.NameIns = ins[0].Names
      this.EmailIns = ins[0].CedocEmail
    })
    //CARGA CANTIDAD DE CURSOS ACTIVOS
    this.coursesService.getAllCourseIsActiveBySchool(this.SchoolID).subscribe(ias => {
      this.coursesActive = ias.count
    })
  }

  plotGraph() {

    var maxlen1 = this.ejex2;
    var maxlen2 = this.ejex4;


    if (this.ejex1.length > this.ejex2.length)
      maxlen1 = this.ejex1

    if (this.ejex3.length > this.ejex4.length)
      maxlen2 = this.ejex3

    var maxlen3 = maxlen2
    if (maxlen1.length > maxlen2.length)
      maxlen3 = maxlen1


      /***
       * TODO: use new chart module
       */
    // SMIL ANIMATIONS
    // var animationsChart = new Chartist.Line(".chart-smil-animations", {
    //   labels: maxlen3,
    //   series: [this.ejey2, this.ejey1, this.ejey3, this.ejey4]
    // }, {
    //     low: 0,
    //     plugins: [
    //       Chartist.plugins.tooltip()
    //     ]
    //   }),
    //   seq = 0,
    //   delays = 80,
    //   durations = 500;

    // animationsChart.on("created", function () {
    //   seq = 0
    // }), animationsChart.on("draw", function (data) {
    //   if (seq++ , "line" === data.type) data.element.animate({
    //     opacity: {
    //       begin: seq * delays + 1e3,
    //       dur: durations,
    //       from: 0,
    //       to: 1
    //     }
    //   });
    //   else if ("label" === data.type && "x" === data.axis) data.element.animate({
    //     y: {
    //       begin: seq * delays,
    //       dur: durations,
    //       from: data.y + 100,
    //       to: data.y,
    //       easing: "easeOutQuart"
    //     }
    //   });
    //   else if ("label" === data.type && "y" === data.axis) data.element.animate({
    //     x: {
    //       begin: seq * delays,
    //       dur: durations,
    //       from: data.x - 100,
    //       to: data.x,
    //       easing: "easeOutQuart"
    //     }
    //   });
    //   else if ("point" === data.type) data.element.animate({
    //     x1: {
    //       begin: seq * delays,
    //       dur: durations,
    //       from: data.x - 10,
    //       to: data.x,
    //       easing: "easeOutQuart"
    //     },
    //     x2: {
    //       begin: seq * delays,
    //       dur: durations,
    //       from: data.x - 10,
    //       to: data.x,
    //       easing: "easeOutQuart"
    //     },
    //     opacity: {
    //       begin: seq * delays,
    //       dur: durations,
    //       from: 0,
    //       to: 1,
    //       easing: "easeOutQuart"
    //     }
    //   });
    //   else if ("grid" === data.type) {
    //     var pos1Animation = {
    //       begin: seq * delays,
    //       dur: durations,
    //       from: data[data.axis.units.pos + "1"] - 30,
    //       to: data[data.axis.units.pos + "1"],
    //       easing: "easeOutQuart"
    //     },
    //       pos2Animation = {
    //         begin: seq * delays,
    //         dur: durations,
    //         from: data[data.axis.units.pos + "2"] - 100,
    //         to: data[data.axis.units.pos + "2"],
    //         easing: "easeOutQuart"
    //       },
    //       ctAnimations = {};
    //     ctAnimations[data.axis.units.pos + "1"] = pos1Animation, ctAnimations[data.axis.units.pos + "2"] = pos2Animation, data.element.animate(ctAnimations)
    //   }
    // }), animationsChart.on("created", function () {
    // });
  }



  ngOnInit() {

  

  }

  onSearchTR(query: string = '') {
    if (query == "") {
      this.sourceReportTeacher.setFilter([])
    } else {
      query = query.replace(/\s/g, '');
      this.sourceReportTeacher.setFilter([
        {
          field: 'Names',
          search: query
        },
        {
          field: 'NameSubject',
          search: query
        }
      ], false);
    }
  }



  onSearchTeacher(event): any {

    console.log("id")
    console.log(event.data.id)



    //this.chartcalidadteacher.idProfileCV = event.data.id
    //this.chartcalidadteacher.ngOnInit()
  }


}