import { isNullOrUndefined } from 'util';
import { Component, OnInit } from '@angular/core';
import { Course } from '../../../../../models/Coursesinfo/course';
import { CoursesService } from "../../../../../models/Coursesinfo/courses.service";
import { UsersService } from "../../../../../models/usersInfo/users.service";
import { ActivatedRoute, Router } from "@angular/router";
import { RecordService } from '../../../../../models/midterm/record.service';
import { AuthService } from '../../../../../models/Auth/auth.service';
import { ProfileNavComponent } from '../../../../../components/profile-nav/profile-nav.component';
import { EvaluationsService } from '../../../../../models/evaluation/evaluations.service';

declare var $: any;
declare var Chart: any;

@Component({
  moduleId: module.id,
  selector: 'radar-selfappr-box',
  templateUrl: './radar-selfappr-box.component.html',
  styleUrls: ['./radar-selfappr-box.component.css']


})


export class RadarSelfApprBoxComponent implements OnInit {

  public Courses: Course[];
  public Subjects: Course[]
  public IsProfessor: boolean = false;
  public IsStudent: boolean = false;
  public IsStudent2: boolean = false;
  public SubjectsProfesor: Course[];
  public EventCalendarProfessor: any[] = []
  public EventCalendarColorIndexProfessor: any[] = []

  public EventCalendarStudent: any[] = []
  public EventCalendarColorIndexStudent: any[] = []

  TotalCat1: number = 0;
  TotalCat2: number = 0;
  TotalCat3: number = 0;
  TotalCat4: number = 0;
  TotalCat5: number = 0;
  TotalCat6: number = 0;
  TotalCat7: number = 0;
  TotalCat8: number = 0;
  TotalCat9: number = 0;
  dataRadar: any
  ChartRadar: any
  IsInspector = false;
  public ColorCourses = ["#5d80a2", "#680345", "#7C6445", "#E154CE", "#258C64", "#A44015", "#FE9A2E", "#DF0101", "#01DF01", "#0040FF", "#565ACB", "#144A20", "#AAE951", "#6CF132", "#F19663", "#891CBB", "#21EBF3", "#F19663", "#61265F", "#6916E4", "#464208", "#393D78", "#E2337D", "#A1924C", "#52B743", "#176548", "#A0B03D", "#7C188A", "#77BB3E"]


  SchoolID: number = 0;
  subjectgroupsprofessor: any = []

  public idSubjectGroup: any;

  constructor(
    private recordService: RecordService,
    private authService: AuthService,
    private usersService: UsersService,
    private coursesService: CoursesService,
    private route: ActivatedRoute,
    private router: Router,
    public profileNavComponent: ProfileNavComponent,
    public evaluationsservice: EvaluationsService

  ) {



  }





  //INDICO QUE LOS DATOS NO ESTAN DISPONIBLES AL CARGAR EL HTML PARA NO MOSTRAR ERRORES
  ngOnInit() {


    // RADAR CHART
    //var radarCtx = document.getElementById('chart-radar').getContext('2d');
    var radarCtx = $("#chart-radar-selfappr")[0].getContext('2d');

    this.dataRadar = {
      labels: ["Planeación y Organización", "Conocimiento", "Pedagogía/Metodología", "Evaluación y Retroalimentación", "Relación y Comunicación", "Compromiso Institucional", "Currículo Propuesto", "Currículo En Uso", "Análisis Curricular"],
      datasets: [
        {
          label: "Autoevaluación",
          backgroundColor: "rgba(238, 47, 47,0.2)",
          borderColor: "rgba(238, 47, 47,1)",
          pointBackgroundColor: "rgba(238, 47, 47,1)",
          pointBorderColor: "#fff",
          pointHoverBackgroundColor: "#fff",
          pointHoverBorderColor: "rgba(238, 47, 47,1)",
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0]
        }
      ]
    };

    this.ChartRadar = new Chart(radarCtx, {
      type: 'radar',
      data: this.dataRadar,
      options: {
        scale: {

          ticks: {
            max: 50,
            stepSize: 10,
            min: 0,
            beginAtZero: true
          }
        }
      }
    });


    this.dataRadar.datasets[0].data = [0, 0, 0, 0, 0, 0, 0, 0, 0]
    this.ChartRadar.update();




    if (isNullOrUndefined(this.idSubjectGroup)) {
      //NO HAGO NADA
    }
    else {
      console.log(this.idSubjectGroup)


      //consulto todas las notas que le dieron al profesor
      this.evaluationsservice.getSelfApprBySubjectGroup(this.idSubjectGroup).subscribe(responseStudentAppr => {
        console.log(responseStudentAppr)
        //CALCULAR EL PROMEDIO DE TODAS LAS NOTAS
        for (var i = 0; i < responseStudentAppr.length; i++) {

          this.TotalCat1 += responseStudentAppr[i].TotalCat1
          this.TotalCat2 += responseStudentAppr[i].TotalCat2
          this.TotalCat3 += responseStudentAppr[i].TotalCat3
          this.TotalCat4 += responseStudentAppr[i].TotalCat4
          this.TotalCat5 += responseStudentAppr[i].TotalCat5
          this.TotalCat6 += responseStudentAppr[i].TotalCat6
          this.TotalCat7 += responseStudentAppr[i].TotalCat7
          this.TotalCat8 += responseStudentAppr[i].TotalCat8
          this.TotalCat9 += responseStudentAppr[i].TotalCat9

        }

        this.TotalCat1 = Number((this.TotalCat1 / responseStudentAppr.length).toFixed(1));
        this.TotalCat2 = Number((this.TotalCat2 / responseStudentAppr.length).toFixed(1));
        this.TotalCat3 = Number((this.TotalCat3 / responseStudentAppr.length).toFixed(1));
        this.TotalCat4 = Number((this.TotalCat4 / responseStudentAppr.length).toFixed(1));
        this.TotalCat5 = Number((this.TotalCat5 / responseStudentAppr.length).toFixed(1));
        this.TotalCat6 = Number((this.TotalCat6 / responseStudentAppr.length).toFixed(1));
        this.TotalCat7 = Number((this.TotalCat7 / responseStudentAppr.length).toFixed(1));
        this.TotalCat8 = Number((this.TotalCat8 / responseStudentAppr.length).toFixed(1));
        this.TotalCat9 = Number((this.TotalCat9 / responseStudentAppr.length).toFixed(1));


        this.dataRadar.datasets[0].data = [this.TotalCat1, this.TotalCat2, this.TotalCat3, this.TotalCat4, this.TotalCat5, this.TotalCat6, this.TotalCat7, this.TotalCat8, this.TotalCat9]
        //this.ChartRadar.removeData();
        // this.ChartRadar.addData(this.dataRadar.datasets[0].data);
        console.log(this.dataRadar.datasets[0].data)
        this.ChartRadar.update();

      })









    }









  }

  ngAfterViewInit() {




  }


}