import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { CustomFormsModule } from 'ng2-validation';
import { ButtonViewScheduleComponent2, ButtonViewScheduleGroupsComponent2 } from './smart-schedule.component';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { PendingChangesGuard } from './pending-changes-guard';
import { SmartScheduleComponent } from './smart-schedule.component';
import { OrderModule } from 'ngx-order-pipe';
import { SmartCalendarComponent } from './smart-calendar/smart-calendar.component';


export const routes: Routes = [
  { path: 'horarios/smart-schedule', component: SmartScheduleComponent, },
  { path: 'horarios/automatic-schedule', component: SmartScheduleComponent,  },
  { path: 'horarios/smart-calendar', component: SmartCalendarComponent }
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FormsModule,
    CustomFormsModule,
    HttpModule,
    Ng2SmartTableModule,
    OrderModule
  ],
  entryComponents: [
    ButtonViewScheduleComponent2,
    ButtonViewScheduleGroupsComponent2,
    
  ],
  declarations: [
    SmartScheduleComponent,
    ButtonViewScheduleComponent2,
    ButtonViewScheduleGroupsComponent2,
    SmartCalendarComponent,
    
  ],
  exports: [RouterModule],
  providers: [PendingChangesGuard],


})
export class SmartScheduleModule { }
