import { Component, OnInit, AfterViewChecked, HostListener } from '@angular/core';
import { Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { isNullOrUndefined } from 'util';
import { LocalDataSource } from 'ng2-smart-table';
import { ViewCell } from 'ng2-smart-table';
import { School } from "../../../models/Coursesinfo/school";
import { Module } from "../../../models/Coursesinfo/module";
import { UsersField } from '../../../models/usersInfo/users-fields';
import { Subject } from '../../../models/Coursesinfo/subject';
import { Course } from '../../../models/Coursesinfo/course';
import { AuthService } from '../../../models/Auth/auth.service';
import { UsersService } from '../../../models/usersInfo/users.service';
import { CoursesService } from '../../../models/Coursesinfo/courses.service';
import { ProfileNavComponent } from '../../../components/profile-nav/profile-nav.component';
import { Observable } from 'rxjs/Observable';
import { ComponentCanDeactivate } from './pending-changes-guard';
//import { MODULE_SUFFIX } from '@angular/compiler/src/util';
import * as moment from 'moment';

declare var $: any;


var async = require("async");



export class IntermediateWeekly {
    public Disponible = 0;
    public contractType: object = {
        0: "Seleccionar Tipo de Contrato",
    }


}



// INICIO COMPONENTE PARA EL NG SMART TABLE ISWEEKLY
@Component({
    selector: 'button-view-isweekly',
    template: `
  <b *ngIf="renderValue=='Si'" >  {{renderValue}}</b>
  <b *ngIf="renderValue=='No'"   style='color: red;'>  {{renderValue}}</b>
      `
})
export class ButtonViewWeeklyBreak implements ViewCell, OnInit, AfterViewChecked {
    constructor(
        public intermediateWeekly: IntermediateWeekly,
    ) { }
    renderValue: string;
    @Input() value: string | number;
    @Input() rowData: any;
    @Output() save: EventEmitter<any> = new EventEmitter()
    ngOnInit() {
        // console.log("aca esta el render despues")
        //  console.log(this.intermediate.Grups)
        if (!isNullOrUndefined(this.value)) {
            if (this.value.toString() == "true" || this.value.toString() == "1")
                this.renderValue = "Si"
            if (this.value.toString() == "false" || this.value.toString() == "0")
                this.renderValue = "No"
        }
    }
    ngAfterViewChecked() {
        this.renderValue = String(this.value);
    }

}
// FIN COMPONENTE PARA EL NG SMART TABLE  ISWEEKLY


// INICIO COMPONENTE PARA EL NG SMART TABLE NOMBRE DEL DIA SEMANA
@Component({
    selector: 'button-view-name-day',
    template: `{{renderValue}}`
})
export class ButtonViewNameDay implements ViewCell, OnInit, AfterViewChecked {
    constructor(
        public intermediateWeekly: IntermediateWeekly,
    ) { }
    renderValue: string;
    @Input() value: string | number;
    @Input() rowData: any;
    @Output() save: EventEmitter<any> = new EventEmitter()
    ngOnInit() {
        var weekDays ={ "Mon":"Lunes",
        "Tue":"Martes",
        "Wed":"Miercoles",
        "Thu":"Jueves",
        "Fri":"Viernes"
         }
        // console.log("aca esta el render despues")
        //  console.log(this.intermediate.Grups)
        if (!isNullOrUndefined(this.value)) {
            
                this.renderValue = weekDays[this.value]
            
        }
    }
    ngAfterViewChecked() {
        this.renderValue = String(this.value);
    }

}
// FIN COMPONENTE PARA EL NG SMART TABLE  ISWEEKLY

@Component({
    selector: 'recesos',
    templateUrl: './recesos.component.html',
    styleUrls: ['./recesos.component.css'],
    providers:
        [UsersService,
            CoursesService,
            IntermediateWeekly,
            ButtonViewWeeklyBreak,
            ButtonViewNameDay]
})
export class RecesosComponent implements ComponentCanDeactivate, OnInit {
    enableSecondarystudentsasociation: boolean = false;
    isPersonal: boolean = false;
    CourseGroupIDSelected: any;
    previousEvents: any[] = [];
    collapseCards: boolean = true;
    //:DAVID : CanDeactivate

    canDeactivate(): Observable<boolean> | boolean {

        // insert logic to check if there are pending changes here;
        // returning true will navigate without confirmation
        // returning false will show a confirm alert before navigating away
        return !this.isloading
    }

    isloading = false;
    serverTime: Date

    public Groups: any = { id: 0 };
    Groups_init: any = { id: 0 };
    AcademicArea_init: any = { id: 0 };
    public AcademicArea: any;
    public AcademicAreaSelected: any;
    public EnableTable: boolean = false;
    EnableTableBreakTimes: boolean = false
    public ListProfessors: UsersField[];
    public SubjectsModifyAddProfessor: Subject[];
    public ModelAcademicAreaID: number;
    public ModelCourseGroupID: number;
    user: string;
    student: UsersField = {
        CourseOferID: 0,

    };
    public CourseID;
    public Subject: Subject[];
    course_by_school: Course[];
    subjects_by_course: Course[];
    Studends_by_Course: any;
    public List_students_by_course: any[];
    public List_students_by_course2: any[];
    public Students: any[];
    public StudentsByGroup: any[];
    public Contador: number;
    public AreStudents: boolean;
    public AreGroups: boolean;
    public school: Course[];
    public NumberGroups =
        [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26]
    public SchoolByID: School;
    public ModelProfessorSmartTable;
    public ModelProfessorSmartTable2;
    abecedario = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"]
    public SubjectGtoupID_Verified = []
    public Disponible: number = 0;
    public EnableAsociations: boolean = false
    public CreateSchedule: boolean = true
    public ShowCalendar: boolean = false
    public TotalEvents = 0;
    public CurrenEvent = 0;
    public courseofferidseleted: number;
    public SchoolIDSelected: number;
    public courseofferidseletedsemestrlize: boolean;
    public SearchSubjects = [];
    public CourseInfo;
    public midtermData: any[];
    public enablestudentsasociation: boolean = false;
    public contador_calendario: number;
    public array_to_asociate: any[] = [];
    public infoarray_to_asociate: any[] = []
    public infop: any[] = []

    isHorarios: boolean = false
    //:DAVID 
    alertLoad: boolean = false;//:DAVID 

    //inicia configuracion smart table
    modules: Module[];
    schools: School[];
    courses: Course[];
    subjects: Course[];
    userid: string;
    areaID: number = 0;
    courseID: number = 0;


    //CONFIGURAR SMART TABLE PARA GESTIONAR RECESOS

    settingsBreaks = {
        noDataMessage: "No hay datos disponibles",

        add: {
            confirmCreate: true,
            createButtonContent: "Crear",
            cancelButtonContent: "Cancelar",
            addButtonContent: "Agregar Nuevo Receso"
        },
        edit: {
            editButtonContent: '<i class="fa fa-pencil-square"></i>',
            saveButtonContent: '<i class="fa fa-floppy-o"></i>',
            cancelButtonContent: '<i class="fa fa-window-close"></i>',
            confirmSave: true,
        },
        /* delete: {
          deleteButtonContent: '<i class="fa fa-window-close"></i>',
          confirmDelete: true
        }, */

        actions: {
            add: true,
            delete: false,
            edit: true,
            columnTitle: "Acciones"
        },
        setPaging: {
            page: 1,
            perPage: 15,
            doEmit: true,
        },

        pager: {
            perPage: 15,
            doEmit: true
        },

        columns: {

            NameBreak: {
                title: 'Nombre Receso',
                filter: true,
                editable: true,
            },

            DaySelected: {
                title: 'Dia del Evento',
                filter: false,
                editable: true,
                editor: {
                    type: 'list',
                    config: {
                        list: [{ value: "Mon", title: 'Lunes' }, { value: "Tue", title: 'Martes' }, { value: "Wed", title: 'Miercoles' }, { value: "Thu", title: 'Jueves' }, { value: "Fri", title: 'Viernes' }],
                    }
                },
                type: 'custom',
                renderComponent: ButtonViewNameDay,
                onComponentInitFunction(instance) {
                    instance.save.subscribe(row => {
                        console.log("algo se edito")
                        // this.saveDocenteDetails(row.id)
                    });
                }
            },


            StarTime: {
                title: 'Hora Inicio (HH:mm)',
                filter: false,
                editable: true,
            },

            EndTime: {
                title: 'Horas Final (HH:mm)',
                filter: false,
                editable: true,
            },

            /*
            IsWeekly: {
                title: 'Evento Semanal',
                filter: false,
                editable: false,
                editor: {
                    type: 'list',
                    config: {
                        list: [{ value: true, title: 'Si' }, { value: false, title: 'No' }],
                    }
                },
                type: 'custom',
                renderComponent: ButtonViewWeeklyBreak,
                onComponentInitFunction(instance) {
                    instance.save.subscribe(row => {
                        console.log("algo se edito")
                        // this.saveDocenteDetails(row.id)
                    });
                }
            },
            */
            isAvailable: {
                title: 'Evento Disponible',
                filter: false,
                editable: true,
                editor: {
                    type: 'list',
                    config: {
                        list: [{ value: true, title: 'Si' }, { value: false, title: 'No' }],
                    }
                },
                type: 'custom',
                renderComponent: ButtonViewWeeklyBreak,
                onComponentInitFunction(instance) {
                    instance.save.subscribe(row => {
                        console.log("algo se edito")
                        // this.saveDocenteDetails(row.id)
                    });
                }
            },




        }
    };

    sourceBreaks: LocalDataSource;


    settingsGlobalBreaks = {
        noDataMessage: "No hay datos disponibles",

        add: {
            confirmCreate: true,
            createButtonContent: "Crear",
            cancelButtonContent: "Cancelar",
            addButtonContent: "Agregar Nuevo Receso"
        },
        edit: {
            editButtonContent: '<i class="fa fa-pencil-square"></i>',
            saveButtonContent: '<i class="fa fa-floppy-o"></i>',
            cancelButtonContent: '<i class="fa fa-window-close"></i>',
            confirmSave: true,
        },
        delete: {
            deleteButtonContent: '<i class="fa fa-trash"></i>',
            confirmDelete: true
        },

        actions: {
            add: true,
            delete: true,
            edit: true,
            columnTitle: "Acciones"
        },
        setPaging: {
            page: 1,
            perPage: 15,
            doEmit: true,
        },

        pager: {
            perPage: 15,
            doEmit: true
        },

        columns: {

            NameBreak: {
                title: 'Nombre Receso',
                filter: true,
                editable: true,
            },



            StarTime: {
                title: 'Hora Inicio (YYYY-MM-DD HH:mm)',
                filter: false,
                editable: true,
            },

            EndTime: {
                title: 'Horas Final (YYYY-MM-DD HH:mm)',
                filter: false,
                editable: true,
            },


            isAvailable: {
                title: 'Evento Disponible',
                filter: false,
                editable: true,
                editor: {
                    type: 'list',
                    config: {
                        list: [{ value: true, title: 'Si' }, { value: false, title: 'No' }],
                    }
                },
                type: 'custom',
                renderComponent: ButtonViewWeeklyBreak,
                onComponentInitFunction(instance) {
                    instance.save.subscribe(row => {
                        console.log("algo se edito")
                        // this.saveDocenteDetails(row.id)
                    });
                }
            },




        }
    };
    sourceGlobalBreaks: LocalDataSource;
    //FIND E CONFIGURACION SMART TABLE RECESOS
    constructor(private authService: AuthService,
        private route: ActivatedRoute,
        private router: Router,
        public servicios: UsersService,
        public coursesService: CoursesService,
        public profileNavComponent: ProfileNavComponent,
        public intermediateWeekly: IntermediateWeekly

    ) {
        this.sourceBreaks = new LocalDataSource(); // create the source
        this.sourceGlobalBreaks = new LocalDataSource(); // create the source




    }
    //INDICO QUE LOS DATOS NO ESTAN DISPONIBLES AL CARGAR EL HTML PARA NO MOSTRAR ERRORES
    DatosDisponibles: boolean = false;
    ngOnInit() {
        this.setJquerry();
        //GUARDO LA HORA DEL SERVIDOR
        this.authService.getTime().then(server2 => this.serverTime = server2.time);

        $("#Group").prop("disabled", true);
        $("#AcademicAreaID").prop("disabled", true);
        //SI NO HAY USUARIOS EN LOCAL STORAGE, LLEVAR A LOGIN
        if (this.authService.getCurrentUser() == null) {
            this.router.navigate(['/register']);
        } else {
            //DE LO CONTRARO CARGAR EL ID DEL USUARIO
            this.user = this.authService.getCurrentUser(); //devuelve el id del usuario
            //SI EL ID DEL USUARIO ES UN NUMERO ENTERO
            if (Number.isInteger(parseInt(this.user))) {
                //console.debug(this.authService.getCurrentUser());
                //CONSULTO EL PERFIL DEL USUARIO
                var validar_usuario = [false];
                var SchoolID = 0;

                for (let role of this.profileNavComponent.roles) {
                    if (role.name == "Horarios") {
                        validar_usuario = validar_usuario.concat([true])
                        SchoolID = role.SchoolID;
                        this.SchoolIDSelected = role.SchoolID
                        this.isHorarios = true

                    }
                    if (role.name == "Personal") {
                        validar_usuario = validar_usuario.concat([true])
                        SchoolID = role.SchoolID;
                        this.SchoolIDSelected = role.SchoolID
                        this.isHorarios = true
                        this.isPersonal = true
                    }

                }
                //SI ALGUN ROL PUEDE VERLO CARGAR LOS DATOS
                //DE LO CONTRARIO LLEVAR AL PEFIL
                if (validar_usuario.includes(true)) {
                    this.coursesService.getSchoolByID(SchoolID).subscribe(p => {
                        this.SchoolByID = p;
                        console.debug("ESL SCHOOL ID FUE EL" + this.SchoolByID)
                        console.debug(this.SchoolByID)
                    });
                    this.coursesService.getAllCoursesBySchool(SchoolID).subscribe(p => {
                        this.course_by_school = p.sort(function (a, b) {
                            // Ascending: first age less than the previous
                            return a["NameCourseOfer"].localeCompare(b["NameCourseOfer"]);
                        });

                        console.debug(this.course_by_school)
                    });
                    var state = 1;
                    this.AreStudents = false;
                    this.AreGroups = false;
                    this.student = {
                        Document: '',
                        Name1: '',
                        LastName1: '',
                        CedulaMil: '',
                        IsMilitar: true,
                        SchoolID: 4,
                        CourseOferID: 0,
                        SubjectOferID: 0,
                    }
                    this.Groups = []
                    this.AcademicArea = []


                    //CARGAR GlobalBreaks
                    this.coursesService.getAllGlobalBreaksBySchoolID(SchoolID).subscribe(p => {
                        this.sourceGlobalBreaks.load(p)
                    }, err => {
                        this.authService.swalError("Error Al Cargar", "No se pudo cargar la lista de recesos globales, detalles: " + err)
                    })

                }
                else {

                    this.router.navigate(['/user/my-account']);

                }
            }
        }
    }

    //:DAVID @HostListener allows us to also guard against browser refresh, close, etc.
    @HostListener('window:beforeunload', ['$event'])
    unloadNotification($event: any) {
        if (!this.canDeactivate()) {
            $event.returnValue = "This message is displayed to the user in IE and Edge when they navigate without using Angular routing (type another URL/close the browser/etc)";
        }
    }



    callgroups(model: Course) {

        this.EnableTableBreakTimes = false
        this.collapseCards = false


        this.AcademicArea_init = { id: 0 }
        this.Groups_init = { id: 0 }

        if (this.isPersonal == true)
            this.enableSecondarystudentsasociation = false

        this.array_to_asociate = []
        $("#Group").prop("disabled", true);
        $("#AcademicAreaID").prop("disabled", true);
        this.EnableAsociations = false

        this.enablestudentsasociation = false
        this.EnableTable = false
        this.courseofferidseleted = model.id;
        this.CourseInfo = this.infocoursebyID(model.id)
        this.courseofferidseletedsemestrlize = this.textSemestralize(model.id)
        $('#calendar').fullCalendar('removeEvents')
        this.SubjectsModifyAddProfessor = []


        //LLAMO TODOS LOS RECESOS
        this.coursesService.getAllBreakTimesByCourseOferByID(model.id).subscribe(breakTimes => {

            this.sourceBreaks.empty()
            this.sourceBreaks.load(breakTimes)
            this.EnableTableBreakTimes = true
            this.collapseCards = false

        })

        //CON EL ID DEL CURSO BUSCO LOS GRUPOS QUE HAY PARA ESTE     
        this.coursesService.getAllCourseGroupsByCourseOferByID(model.id).subscribe(p => {

            ;
            if (p.length == 0) {
                this.Groups = [{ id: 0, name: "No Existen Grupos Para el Curso Seleccionado" }]
            } else {

                p = p.sort(function (a, b) {
                    // Ascending: first age less than the previous
                    return a["name"] == b["name"] ? 0 : a["name"] < b["name"] ? -1 : 1;
                })


                this.Groups = p;

            }
            $("#Group").prop("disabled", false);
        });
        //Con el id del curso busco la cantidad de usuarios en el curso
        this.coursesService.getCountStudentsByOfer(model.id).subscribe(p => {
            this.Studends_by_Course = p;
            console.debug("el numero de estudiantes de ese curso es : " + JSON.stringify(this.Studends_by_Course["count"]));
        });
        //descargo la lista de estudiantes del curso (obtengo el id)
        this.coursesService.getStudentsByCourseOferByID(model.id).subscribe(p => {
            this.List_students_by_course = []
            for (var k = 0; k < p.length; k++) {

                if (p[k].UserCourses.length > 0) {

                    this.List_students_by_course.push(p[k])

                }


            }
            this.AreStudents = true;
            //console.debug("La lista de estudiantes son : " + JSON.stringify(this.List_students_by_course));
            $("#SplitStudents").prop("disabled", false);
        });
    }


    setJquerry() {

        $(function () {
            // card controls


            // header double click
            $('.cat__core__sortable .card-header').on('click', function () {
                $(this).closest('.card').toggleClass('cat__core__sortable__collapsed');

            });

        });

    }

    textSemestralize(id: number) {
        var num = this.course_by_school.find(x => x.id == id);
        if (num)
            return num.IsSemestralize;
        return false
    }



    infocoursebyID(id: number) {
        var num = this.course_by_school.find(x => x.id == id);
        if (num)
            return num;
        return false
    }


    onGlobalBreakTimes(event) {

        var validateDateStart = moment(event.newData.StarTime, "YYYY-MM-DD HH:mm", true).isValid() //VALIDO SI ES FECHA
        var validateDateEnd = moment(event.newData.EndTime, "YYYY-MM-DD HH:mm", true).isValid() //VALIDO SI ES FECHA

        var startDate = moment(event.newData.StarTime, "YYYY-MM-DD HH:mm")
        var endDate = moment(event.newData.EndTime, "YYYY-MM-DD HH:mm")


        if (validateDateStart == true &&
            validateDateEnd == true &&
            moment(event.newData.StarTime, "YYYY-MM-DD HH:mm").isBefore(moment(event.newData.EndTime, "YYYY-MM-DD HH:mm")) &&
            (endDate.diff(startDate, "days") === 0) &&
            (endDate.diff(startDate, "minutes") % 5 === 0)
        ) {

            this.authService.swalLoading("Creando Receso")
            this.coursesService.patchGlobalBreakTimes({
                id: parseInt(event.newData.id),
                StarTime: event.newData.StarTime,
                EndTime: event.newData.EndTime,
                NameBreak: event.newData.NameBreak,
                SchoolID: this.SchoolIDSelected,
                isAvailable: event.newData.isAvailable
            }).subscribe(breakTime => {
                this.authService.swalEndLoad()

                event.confirm.resolve(breakTime);
            }, err => {
                this.authService.swalError("Error creando receso", "Hubo un error al actualizar el receso, detalles: " + err)
            })

        } else {
            this.authService.swalError("Error validando horas", "Recuerde que el formato es YYYY-MM-DD HH:mm,  la hora inicial debe menor a la hora final, El receso debe estar contenido en un día y la diferencia de horas debe tener un factor de 5 minutos ")
        }

    }

    onDeleteBreakTime(event) {
        this.authService.swalLoading("Borrando Receso")
        this.coursesService.deleteGlobalBreakTimeByID(parseInt(event.data.id)).subscribe(breakTime => {
            this.authService.swalEndLoad()

            event.confirm.resolve();
        }, err => {
            this.authService.swalError("Error Borrando receso", "Hubo un error al borrar el receso, detalles: " + err)
        })
    }



    onBreakTimes(event) {

        var validateDateStart = moment(event.newData.StarTime, "HH:mm", true).isValid() //VALIDO SI ES FECHA
        var validateDateEnd = moment(event.newData.EndTime, "HH:mm", true).isValid() //VALIDO SI ES FECHA

        var startDate = moment(event.newData.StarTime, "HH:mm")
        var endDate = moment(event.newData.EndTime, "HH:mm")

        if (validateDateStart == true &&
            validateDateEnd == true &&
            moment(event.newData.StarTime, "HH:mm").isBefore(moment(event.newData.EndTime, "HH:mm")) &&
            (endDate.diff(startDate, "minutes") % 5 === 0)
        ) {

            this.authService.swalLoading("Creando Receso")
            this.coursesService.patchBreakTimes({
                id: parseInt(event.newData.id),
                StarTime: event.newData.StarTime,
                EndTime: event.newData.EndTime,
                NameBreak: event.newData.NameBreak,
                IsWeekly: event.newData.IsWeekly,
                DaySelected: event.newData.DaySelected,
                CourseOferID: this.courseofferidseleted,
                isAvailable: event.newData.isAvailable
            }).subscribe(breakTime => {
                this.authService.swalEndLoad()

                event.confirm.resolve(breakTime);
            }, err => {
                this.authService.swalError("Error creando receso", "Hubo un error al actualizar el receso, detalles: " + err)
            })

        } else {
            this.authService.swalError("Error validando horas", "Recuerde que las horas deben ser formato 24H (HH:mm) ,  la hora inicial debe menor a la hora final y el tiempo total debe ser factor de 5 minutos ")
        }
    }





}