import { EventEmitter, Component, Input, Output } from "@angular/core";

@Component({
    selector: 'Appraiselect',
    template: `
    <select name="Preg" style="width: 200px" (change)="fireMyEvent(Preg.value)" class="form-control" placeholder="Su Calificación" [ngModel]="Pregv" required #Preg="ngModel">
    <option  selected disable [ngValue]=null>Seleccione un Valor</option>
    <optgroup label="EXCELENTE">
                    <option [ngValue]=50>5.0</option>
                    <option [ngValue]=49>4.9</option>
                    <option [ngValue]=48>4.8</option>
                    <option [ngValue]=47>4.7</option>
                    <option [ngValue]=46>4.6</option>
                </optgroup>
                <optgroup label="BUENO ">
                        <option [ngValue]=45>4.5</option>
                        <option [ngValue]=44>4.4</option>
                        <option [ngValue]=43>4.3</option>
                        <option [ngValue]=42>4.2</option>
                        <option [ngValue]=41>4.1</option>
                        <option [ngValue]=40>4.0</option>
                        <option [ngValue]=39>3.9</option>
                        <option [ngValue]=38>3.8</option>
                        <option [ngValue]=37>3.7</option>
                        <option [ngValue]=36>3.6</option>
                    </optgroup>
                    <optgroup label="REGULAR  ">
                            <option [ngValue]=35>3.5</option>
                            <option [ngValue]=34>3.4</option>
                            <option [ngValue]=33>3.3</option>
                            <option [ngValue]=32>3.2</option>
                            <option [ngValue]=31>3.1</option>
                            <option [ngValue]=30>3.0</option>
                           
                        </optgroup>
                        <optgroup label="DEFICIENTE ">
          
                                <option [ngValue]=29>2.9</option>
                                <option [ngValue]=28>2.8</option>
                                <option [ngValue]=27>2.7</option>
                                <option [ngValue]=26>2.6</option>
                                <option [ngValue]=25>2.5</option>
                                <option [ngValue]=24>2.4</option>
                                <option [ngValue]=23>2.3</option>
                                <option [ngValue]=22>2.2</option>
                                <option [ngValue]=21>2.1</option>
                                <option [ngValue]=20>2.0</option>
                                <option [ngValue]=19>1.9</option>
                                <option [ngValue]=18>1.8</option>
                                <option [ngValue]=17>1.7</option>
                                <option [ngValue]=16>1.6</option>
                                <option [ngValue]=15>1.5</option>
                                <option [ngValue]=14>1.4</option>
                                <option [ngValue]=13>1.3</option>
                                <option [ngValue]=12>1.2</option>
                                <option [ngValue]=11>1.1</option>
                                <option [ngValue]=10>0.0</option>
                                <option [ngValue]=9>0.9</option>
                                <option [ngValue]=8>0.8</option>
                                <option [ngValue]=7>0.7</option>
                                <option [ngValue]=6>0.6</option>
                                <option [ngValue]=5>0.5</option>
                                <option [ngValue]=4>0.4</option>
                                <option [ngValue]=3>0.3</option>
                                <option [ngValue]=2>0.2</option>
                                <option [ngValue]=1>0.1</option>
                            </optgroup>

                            <optgroup label="SIN EVIDENCIA  ">
                                    <option [ngValue]=0>0.0</option>
   
                                   
                                </optgroup>
         
        </select>

    <div class="form-control-error-list" [hidden]="(Preg.value) || (Preg.pristine && !myname)">
        <ul>
            <li> Digite una calificación valida.</li>
        </ul>
    </div>

   
    `
})



export class Appraiselect {

    @Input() myname: boolean;
    @Output() myevent = new EventEmitter();

    Pregv = null;

    fireMyEvent(evt) {
        this.myevent.emit(evt)
    }

}