//Here we will store all the variables required by the components.
//If we want, we can initialize them with default values

interface SchoolToLoadOptions {
  school?: string;

  //#region SCHOOL LOADER
  loader?:string;
  bg?:string;
  tank?:string;
  cloud?:string;
  //#endregion

  mainSchoolLogoImg?: string;
  mainSchoolLogoId?: number;

  cemilIcon?: string,
  ejcIcon?: string,
}


//Default storages
const storage = {
  cedoc: 'https://storage.googleapis.com/assets_cedoc',
  celic: 'https://storage.googleapis.com/celic_assets',
}


/**
 * Default school variables
 * @param school 
 * @returns 
 */
export const schoolToLoad = (
  { 
    school = 'cedoc',

    //#region SCHOOL LOADER
    loader = 'loader',
    bg = 'sign',
    tank = 'car',
    cloud = 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/43033/poof.svg',
    //#endregion

    mainSchoolLogoImg = 'logo',
    mainSchoolLogoId = 0,

    cemilIcon = 'Icono_CEMIL',
    ejcIcon = 'Icono_EJC',

   }: SchoolToLoadOptions = {}) => {
  return {
    SCHOOL_LOADER:{
      LOADER: `${storage[school]}/modules/pages/common/img/${loader}.png`,
      BG: `${storage[school]}/modules/pages/common/img/${bg}.png`,
      TANK: `${storage[school]}/modules/pages/common/img/${tank}.png`,
      CLOUD: cloud,
    },

    SCHOOL_LOGO_DYNAMIC_IMG: (id: number = 0) => `${storage[school]}/modules/ecommerce/common/img/esc${id}.png`,
    SCHOOL_DYNAMIC_PHOTO: (id:number = 0) => `${storage[school]}/modules/pages/common/img/photos/esc${id}.jpg`,

    MAIN_SCHOOL_LOGO_IMG: `${storage[school]}/modules/pages/common/img/${mainSchoolLogoImg}.png`,
    MAIN_SCHOOL_LOGO_ID: `${storage[school]}/modules/ecommerce/common/img/esc${mainSchoolLogoId}.png`,

    CEMIL_ICON: `${storage[school]}/modules/pages/common/img/${cemilIcon}.png`,
    EJC_ICON: `${storage[school]}/modules/pages/common/img/${ejcIcon}.png`,
    SCHOOL_NAME: school.toUpperCase()
  }
}