import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../../../models/Auth/auth.service';
import { CoursesService } from '../../../../models/Coursesinfo/courses.service';
import { EvaluationsService } from '../../../../models/evaluation/evaluations.service';
import { ProfileNavComponent } from '../../../../components/profile-nav/profile-nav.component';
import { Institutional } from '../../../../models/evaluation/institutional';
import { Course } from 'app/models/Coursesinfo/course';
import { elementAt } from 'rxjs/operators';
import { isNullOrUndefined } from 'util';

import Swal from 'sweetalert2/dist/sweetalert2.js';
import { environment } from 'environments/environment';

@Component({
    selector: 'Institucional',
    templateUrl: './institutional-evaluation.component.html',
    styleUrls: ['./institutional-evaluation.css'],

})

export class InstitutionalEvaluationComponent {
    institutional: Institutional;
    user: string;
    showdata: boolean = false;
    schoolId: number;
    stateButton: boolean = false
    coursesOferLists =[];
    courseOferID: any;
    ErrorGroup: string;
    public SCHOOL_NAME: string = environment.SCHOOL_NAME;
    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private evaluatiosService: EvaluationsService,
        private authService: AuthService,
        public profileNavComponent: ProfileNavComponent,
        private coursesService: CoursesService,
    ) {

        //SI NO HAY USUARIOS EN LOCAL STORAGE, LLEVAR A LOGIN
        if (this.authService.getCurrentUser() == null) {
            this.router.navigate(['/register']);
        } else {
            //DE LO CONTRARO CARGAR EL ID DEL USUARIO
            this.user = this.authService.getCurrentUser(); //devuelve el id del usuario
            //SI EL ID DEL USUARIO ES UN NUMERO ENTERO
            if (Number.isInteger(parseInt(this.user))) {
                //CONSULTO EL PERFIL DEL USUARIO
                var validar_usuario = [false];

                for (let role of this.profileNavComponent.roles) {
                    if (role.name == "Estudiante") {
                        validar_usuario = validar_usuario.concat([true])
                        this.schoolId = role.SchoolID

                        break
                    }

                }

                if (!validar_usuario.includes(true)) { this.router.navigate(['/']); }


                //this.authService.swalLoading("Cargando Encuesta")

                this.coursesService.getCoursesByUserID(parseInt(this.user)).subscribe(response => {
                    
                    this.coursesOferLists=[]
                    response.forEach(element=>{

                        if (!isNullOrUndefined(element.coursesOfer)){
                            this.coursesOferLists.push(element)
                        }
                        
                    })
     
                })

                this.evaluatiosService.getInstitutionalCountBySchoolAndUserID(this.schoolId, parseInt(this.user)).subscribe(p => {

             

                })



                /* this.coursesService.getSubjectByProfesorID(parseInt(this.user)).subscribe(response => {
                     
                     
                     
                     
                             if (response.length > 0) {
                               this.SubjectsProfesor = response;
                              

                     
                             }
                     
                     
                     
                     
                     
                     
                     
                           });*/




            }
            else { this.router.navigate(['/']); }
        }

        this.institutional = {

            SchoolID: null,
            Preg1: null,
            Preg2: null,
            ObsCat1: '',
            Preg3: null,
            Preg4: null,
            ObsCat2: '',
            Preg5: null,
            Preg6: null,
            ObsCat3: '',
            Preg7: null,
            Preg8: null,
            Preg9: null,
            Preg10: null,
            Preg11: null,
            Preg12: null,
            Preg13: null,
            Preg14: null,
            Preg15: null,
            Preg16: null,
            Preg17: null,
            Preg18: null,
            Preg19: null,
            Preg20: null,
            Preg21: null,
        }

    }
    handleMyEvent(arg, vari) {
        this.institutional[vari] = arg;
    }
    saveInstitutional(model: Institutional) {
        this.institutional.SchoolID = this.schoolId
        this.institutional.UserID = parseInt(this.user)
        this.institutional.CourseOferID = this.courseOferID



        Swal({
            title: 'Estas Seguro',
            text: "No Podrás Modificar La Calificación",
            type: 'warning',
            showCancelButton: true,

            confirmButtonText: 'Si, Enviar!',
            cancelButtonText: 'No, cancelar!',

        }).then((result) => {
            if (result.value) {
            this.stateButton = true
            //una VEZ CONFIRMADO EJECUTAR NORMALMENTE EL SCRIPT

            if (this.institutional.SchoolID && this.institutional.Preg1 && this.institutional.Preg2 && this.institutional.Preg3 && this.institutional.Preg4 && this.institutional.Preg5 && this.institutional.Preg6
                && this.institutional.Preg7 && this.institutional.Preg8 && this.institutional.Preg9 && this.institutional.Preg10 && this.institutional.Preg11 && this.institutional.Preg12
                && this.institutional.Preg13 && this.institutional.Preg14 && this.institutional.Preg15 && this.institutional.Preg16 && this.institutional.Preg17 && this.institutional.Preg18 && this.institutional.Preg19 && this.institutional.Preg19 && this.institutional.Preg20 && this.institutional.Preg21) {
                //no null
                this.authService.swalLoading("Guardando Calificación")

                this.evaluatiosService.createInstitutional(this.institutional).subscribe(p => {

                    this.authService.swalSuccess("Guardado exitosamente", "")
                    this.institutional = p
                    this.showdata = false;
                    this.stateButton = false



                },
                    e => {
                        this.authService.swalError("Error Guardando Evaluación", "Intente nuevamente")
                        this.stateButton = false
                    }
                );
            }
            else {

                this.authService.swalError("Error Guardando Evaluación", "Asegúrese de responder todas las preguntas")

                this.stateButton = false
            }
        }
        })






    }

    selectcourse(model){
        this.courseOferID = model.CourseOferID 
       

        this.evaluatiosService.getCountStudentByCourseOFer(parseInt(this.user), model.CourseOferID).subscribe(p => {
            console.log("User", p)
            var Contador = p
            if (Contador["count"] > 0) {
                this.ErrorGroup = "Ya Realizó La Evaluación Para Esta Materia";
                this.showdata = false;
                this.authService.swalError("Ya Realizó La Evaluación Para Esta Materia", "")
            } else {
                this.showdata = true;
            }
        })
    }
}