import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { CustomFormsModule } from 'ng2-validation';
import { CorreosComponent } from './correos/correos.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { HonorRollComponent } from './honor-roll/honor-roll.component';
import { HttpModule } from '@angular/http';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { TextMaskModule } from 'angular2-text-mask';
import { OrderModule } from 'ngx-order-pipe';
import { NewHonorRollComponent } from './new-honor-roll/new-honor-roll.component';

const routes: Routes = [
  { path: 'general/sendmail', component: CorreosComponent },
  { path: 'general/honorroll', component: HonorRollComponent },
  { path: 'general/new-honorroll', component: NewHonorRollComponent }

];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    CustomFormsModule,
    FormsModule,
    CarouselModule,
    HttpModule,
    Ng2SmartTableModule,
    TextMaskModule,
    OrderModule,
  ],
  exports: [RouterModule],
  declarations: [
    CorreosComponent,
    HonorRollComponent,
    NewHonorRollComponent
  ]
})
export class modGeneral { }