import { Injectable } from "@angular/core";

import { AuthService } from "../Auth/auth.service";

import { catchError, map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

import { SlidesInfo } from "./slidesinfo";
import { OvasModuleInfo } from "./ovasmoduleinfo";
import { OvaInfo } from "./ovainfo";
import { SlideTemplate } from "./slidetemplate";

@Injectable()
export class OvaService {
  constructor(private http: HttpClient, private authService: AuthService) {}

  private baseUrl: string = this.authService.UrlAPI;

  getHeaders(): HttpHeaders{
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.authService.getToken()
    });
    return headers;
  }
  
  putHeaders(): HttpHeaders{
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.authService.getToken()
    });
    return headers;
  }

  CreateOva(ovaInfo: OvaInfo): Observable<OvaInfo> {
    let response = this.http
      .post(`${this.baseUrl}/Ovas`, JSON.stringify(ovaInfo), {
        headers: this.putHeaders()
      })
      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )  
    return response;
  }

  GetOva(): Observable<any> {
    let total = this.http
      .get(`${this.baseUrl}/Ovas`, {
        headers: this.getHeaders()
      })
      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )  
    return total;
  }
  
  GetOvaModules(id: number): Observable<OvaInfo> {
    let total = this.http
      .get(
        `${
          this.baseUrl
        }/Ovas/${id}/?filter={"include":{"relation":"OvasModules", "scope":{"include":{"relation":"Slides"}}}}`,
        {
          headers: this.getHeaders()
        }
      )
      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )  
    return total;
  }

  SaveModule(ovaModule: OvasModuleInfo): Observable<OvasModuleInfo> {
    let response = this.http
      .patch(`${this.baseUrl}/OvasModules`, JSON.stringify(ovaModule), {
        headers: this.putHeaders()
      })
      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )  
    return response;
  }

  GetModuleSlides(id: number): Observable<OvasModuleInfo> {
    let total = this.http
      .get(`${this.baseUrl}/OvasModules/${id}/?filter={"include":"Slides"}`, {
        headers: this.getHeaders()
      })
      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )  
    return total;
  }

  SaveSlide(slideInfo: SlidesInfo): Observable<SlidesInfo> {
    let response = this.http
      .post(`${this.baseUrl}/Slides`, JSON.stringify(slideInfo), {
        headers: this.putHeaders()
      })
      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )  
    return response;
  }

  deleteSlide(id: number): Observable<any> {
    let total = this.http.delete(`${this.baseUrl}/Slides/${id}`, {
      headers: this.putHeaders()
    });

    return total;
  }

  GetSlideTemplate(): Observable<SlideTemplate> {
    let total = this.http
      .get(`${this.baseUrl}/SlideTemplates1s/`, { headers: this.getHeaders() })
      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )  
    return total;
  }
}
