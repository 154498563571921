import { Component } from '@angular/core';
import { LocalDataSource } from 'ng2-smart-table';
import { Course } from 'app/models/Coursesinfo/course';
import { School } from 'app/models/Coursesinfo/school';
import { CoursesService } from 'app/models/Coursesinfo/courses.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'app/models/Auth/auth.service';
import { ProfileNavComponent } from 'app/components/profile-nav/profile-nav.component';
import * as moment from 'moment';
import { environment } from 'environments/environment';


var async = require("async");
@Component({
  selector: 'HonorRoll',
  templateUrl: './honor-roll.component.html',
  styleUrls: ['./honor-roll.component.css']

})
export class HonorRollComponent {
  SubjectsCourse: Course[];
  public mask = [/[1-2]/, /\d/, /\d/, /\d/, '-', /[0-1]/, /\d/, '-', /[0-3]/, /\d/];
  serverTime: Date
  totalStudent: number;
  date1: any;
  date2: any;
  date3: any;
  user: string;
  ErrorGroup: string;
  showdata: boolean = false;
  SubjectsProfesor: any;
  Subjects: any = [];
  SubjectGID: number = 0;
  coursesbyschool: Course[];
  schools: School[] = [];
  SchoolID: number;
  counterNota1: number = 0
  counterNota2: number = 0
  counterNota3: number = 0
  midTermSelected: string = ""
  enablesave: boolean = false
  enableclose: boolean = true
  enablesaveprocess: boolean = false
  today = new Date()
  ModelSelected: any
  SubjectSelected: any
  submitbuton = "Crear"
  Groupss: any = { id: 0 };
  Groups: any;
  DatosARegistrar: number
  ContadorWhile: number
  ContadorWhileAnterior: number
  index: number
  HonorRollFinal: any
  HonorRollFinalFinal: any
  public isActive = 0;

  //isDataReady = false;

  courses: Course = {

    CourseOferID: 0
  }

  extendDates = {
    SubjectGroupID: 0,
    MidTermID: 0,
    ProfileWhAutorizeID: 0,
    IsExtension: false,
    DateStartRecord: new Date().toJSON().slice(0, 10).replace(/-/g, '/'),
    DateEndRecord: new Date().toJSON().slice(0, 10).replace(/-/g, '/')

  }

  public SCHOOL_LOGO_DYNAMIC_IMG:any  = (id:number) => environment.SCHOOL_LOGO_DYNAMIC_IMG(id);
  public CEMIL_ICON:any = environment.CEMIL_ICON;
  public EJC_ICON:any = environment.EJC_ICON;

  constructor(
    private coursesService: CoursesService,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    public profileNavComponent: ProfileNavComponent,
  ) {
    //GUARDO LA HORA DEL SERVIDOR
    this.authService.getTime().then(server2 => this.serverTime = server2.time);


    this.coursesService.getAllSchools().subscribe(p => this.schools = p);
    this.sourceHonorRoll = new LocalDataSource(); // create the source


    if (this.authService.getCurrentUser() == null) {
      this.router.navigate(['/register']);
    } else {
      //DE LO CONTRARO CARGAR EL ID DEL USUARIO
      this.user = this.authService.getCurrentUser(); //devuelve el id del usuario
      //SI EL ID DEL USUARIO ES UN NUMERO ENTERO
      if (Number.isInteger(parseInt(this.user))) {
        //CONSULTO EL PERFIL DEL USUARIO
        var validar_usuario = [false];

        for (let role of this.profileNavComponent.roles) {
          if (role.name == "General") {
            this.settingsHonorRoll["actions"] = [{
              edit: false
            }]
          }



          if (role.name == "Inspector Escuela") {
            validar_usuario = validar_usuario.concat([true])
            this.SchoolID = role.SchoolID;
            break
          }

        }

        if (!validar_usuario.includes(true)) { this.router.navigate(['/']); }

       





        this.coursesService.getSubjectGroups(parseInt(this.user), this.SchoolID).subscribe(response => {
          if (response.length > 0) {
            this.SubjectsProfesor = response
            /*
            .sort(function (a, b) {
              // Ascending: first age less than the previous
              return a["coursesgroup"]["name"].localeCompare(b["coursesgroup"]["name"]);
      
      
              //return a.coursesgroup.name == b.coursesgroup.name ? 0 : a.coursesgroup.name < b.coursesgroup.name ? -1 : 1;
            });
            */
            console.debug("el subject profesor es")
            console.debug(this.SubjectsProfesor)

          }
        });

        /*this.coursesService.getAllSchoolByDepend(this.SchoolID).subscribe(depend => {
          if(depend.length > 0){
            this.schools = depend
           
          }else{
            this.isDataReady = true
            this.LoadData()
          }
        })*/
      }

    }
  }

  /* setNewSchool(id){
     this.SchoolID=id
     this.isDataReady = true
     this.LoadData() 
   }
 
 LoadData(){
   
 }*/

  IsBetwen(p) {

    var startDate = moment(p[0].DateStartRecord, 'YYYY-MM-DD HH:mm:ss').startOf('day').toString()
    var untilDate = moment(p[0].DateEndRecord, 'YYYY-MM-DD HH:mm:ss').endOf('day').toString()

    var today = moment(this.serverTime).add(5, 'hours').toString();
    var oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    var firstDate = new Date(startDate);
    var secondDate = new Date(untilDate);
    var todayDate = new Date(today);

    console.debug(startDate)
    console.debug(untilDate)
    console.debug(today)

    console.debug(firstDate);
    console.debug(secondDate);
    console.debug(todayDate);




    if ((firstDate.getTime() < todayDate.getTime()) && (todayDate.getTime() < secondDate.getTime()))
      return true;

    return false;





  }

  callSubjects(model) {

    //  1. Encuentro el index de la oferta seleccionada en el select del html
    var pos = this.coursesbyschool.findIndex(x => x.id === model.CourseSchoolID)

    //2. con la posicion busco todas las materias  del curseID
    this.coursesService.getAllSubjectsByCourseBox(this.coursesbyschool[pos].CourseID).subscribe(responseSubjects => {

      //Obtengo todas las materias
      this.SubjectsCourse = responseSubjects.sort(function (a, b) {
        // Ascending: first age less than the previous
        return a["NameSubject"].localeCompare(b["NameSubject"]);
      });

      console.debug(responseSubjects)
    }
    )


  }


  calltable(model) {


    var courseSelected = model.CourseSchoolID
    var SubjectSelected = model.SubjectID

    this.coursesService.getHonorRollByCourseOfferAndSubjectID(courseSelected, SubjectSelected).subscribe(response => {
      this.sourceHonorRoll.empty()
      this.sourceHonorRoll.load(response)
      this.showdata = true
      console.debug(response)
    })

  }






  /*
    selectmateria(model) {
      this.SubjectSelected = model
      this.showdata = false;
      this.SubjectGID = model.id;
  
      this.date1 = null;
      this.date2 = null;
      this.date3 = null;
      this.settingsHonorRoll.columns.Note1.editable = false;
      this.settingsHonorRoll.columns.Note2.editable = false;
      this.settingsHonorRoll.columns.Note3.editable = false;
  
      var subgroup = this.SubjectsProfesor.find(x => x.id == this.SubjectGID);
  
  
  
      //obtener los id de cada minterm
      if (subgroup.MidTerms > 0) {
        var midterm = subgroup.minTerms.find(x => x.MidTermNum == 1);
        this.settingsHonorRoll.columns.Note1.title = midterm.NameMid;
        this.coursesService.getAllDaterecordByMidterm(midterm.id).subscribe(p => {
          //ORDENO DEL ID MAYOR AL MENOR PARA CONOCER EL ULTIMO REGISTRO DE DATERECORD
          p = p.sort(function (a, b) {
            // SE ORDENA DE FORMA ASCENDENTE
            return a["id"] == b["id"] ? 0 : a["id"] < b["id"] ? 1 : -1;
          });
          this.counterNota1 = p.length;
          console.debug("LA RESPUESTA DE getAllDaterecordByMidterm")
          console.debug(p)
          this.settingsHonorRoll.columns.Note1.editable = this.IsBetwen(p);
          this.date1 = p[0];
  
          if (subgroup.MidTerms > 1) {
            var midterm = subgroup.minTerms.find(x => x.MidTermNum == 2);
            this.settingsHonorRoll.columns.Note2.title = midterm.NameMid;
            this.coursesService.getAllDaterecordByMidterm(midterm.id).subscribe(p => {
              //ORDENO DEL ID MAYOR AL MENOR PARA CONOCER EL ULTIMO REGISTRO DE DATERECORD
              p = p.sort(function (a, b) {
                // SE ORDENA DE FORMA ASCENDENTE
                return a["id"] == b["id"] ? 0 : a["id"] < b["id"] ? 1 : -1;
              });
              this.counterNota2 = p.length;
              this.settingsHonorRoll.columns.Note2.editable = this.IsBetwen(p);
              this.date2 = p[0];
  
              if (subgroup.MidTerms > 2) {
                var midterm = subgroup.minTerms.find(x => x.MidTermNum == 3);
                this.settingsHonorRoll.columns.Note3.title = midterm.NameMid;
                this.coursesService.getAllDaterecordByMidterm(midterm.id).subscribe(p => {
                  //ORDENO DEL ID MAYOR AL MENOR PARA CONOCER EL ULTIMO REGISTRO DE DATERECORD
                  p = p.sort(function (a, b) {
                    // SE ORDENA DE FORMA ASCENDENTE
                    return a["id"] == b["id"] ? 0 : a["id"] < b["id"] ? 1 : -1;
                  });
                  this.settingsHonorRoll.columns.Note3.editable = this.IsBetwen(p);
                  this.counterNota3 = p.length;
                  this.date3 = p[0];
                  this.getStudents()
                });
              }
              else {
                this.getStudents()
              }
            });
          } else {
            this.getStudents()
          }
        });
      } else {
        this.getStudents()
      }
  
  
  
  
  
  
  
  
    }
  */
  getStudents() {
    this.coursesService.getAllStudentRecord(this.SubjectGID).subscribe(p => {
      p.sort(function (a, b) {
        // Ascending: first age less than the previous

        return a["LastNames"].localeCompare(b["LastNames"]);

      });

      console.debug("LA LISTA DE ESTUDIANTES PARA EL CURSO SELECCIONADO SON")
      console.debug(p)
      this.totalStudent = p.length;
      this.sourceHonorRoll.load(p); this.showdata = true;
    });
  }


  midtTermSelectedModal(nombre: string, dateObject: any) {

    this.extendDates.DateStartRecord = new Date(this.serverTime).toJSON().slice(0, 10).replace(/-/g, '/'),
      this.extendDates.DateEndRecord = new Date(this.serverTime).toJSON().slice(0, 10).replace(/-/g, '/')

    this.enablesaveprocess = false
    this.enablesave = true
    this.enableclose = true
    this.midTermSelected = nombre;
    console.debug(this.extendDates)
    console.debug(dateObject)
    this.extendDates.SubjectGroupID = dateObject.SubjectGroupID
    this.extendDates.MidTermID = dateObject.MidTermID


  }


  settingsHonorRoll = {
    add: {
      addButtonContent: '',
      createButtonContent: '',
      cancelButtonContent: '',
      confirmCreate: false
    },
    edit: {
      editButtonContent: '<i class="fa fa-pencil-square"></i>',
      saveButtonContent: '<i class="fa fa-floppy-o"></i>',
      cancelButtonContent: '<i class="fa fa-window-close"></i>',
      confirmSave: false
    },
    pager: {
      perPage: 30,
    },
    delete: {
      deleteButtonContent: '',
      confirmDelete: false
    },
    columns: {

      LastNames: {
        title: 'Apellidos',
        filter: false,
        editable: false
      },
      Names: {
        title: 'Nombres',
        filter: false,
        editable: false
      },

      FinalRecord: {
        title: 'Nota Final',
        filter: false,
        editable: false


      },


    }
  };

  sourceHonorRoll: LocalDataSource;

  ngOnInit() {

  }

  ngOnDestroy() {

  }

  onSearch(query: string = '') { }

  /*
    onEditConfirm(event): any {
      console.debug(event.newData)
  
      if (this.settingsHonorRoll.columns.Note1.editable) {
  
  
        this.coursesService.saveUserRecord(event.newData.Note1Id, { "ParcialRecord": event.newData.Note1 }).subscribe(p => {
  
        });
      }
  
      if (this.settingsHonorRoll.columns.Note2.editable) {
        this.coursesService.saveUserRecord(event.newData.Note2Id, { "ParcialRecord": event.newData.Note2 }).subscribe(p => {
  
        });
      }
  
      if (this.settingsHonorRoll.columns.Note3.editable) {
        this.coursesService.saveUserRecord(event.newData.Note3Id, { "ParcialRecord": event.newData.Note3 }).subscribe(p => {
  
        });
      }
  
  
      event.confirm.resolve(event.newData);
  
  
    }
  */


  colorSchool(idSchool: number) {
    let classes = {};

    switch (idSchool) {
      case 0: classes = { cat__info__item_ALL: true }; break;
      case 1: classes = { cat__info__item_CEMIL: true }; break;
      case 4: classes = { cat__info__item_EAS: true }; break;
      case 5: classes = { cat__info__item_ESINF: true }; break;
      case 6: classes = { cat__info__item_ESCAB: true }; break;
      case 7: classes = { cat__info__item_ESART: true }; break;
      case 8: classes = { cat__info__item_ESING: true }; break;
      case 9: classes = { cat__info__item_ESCOM: true }; break;
      case 10: classes = { cat__info__item_ESICI: true }; break;
      case 16: classes = { cat__info__item_ESAVE: true }; break;
      case 17: classes = { cat__info__item_ESLOG: true }; break;
      case 18: classes = { cat__info__item_ESCEQ: true }; break;
      case 19: classes = { cat__info__item_ESPOM: true }; break;
      case 20: classes = { cat__info__item_ESMAI: true }; break;
      case 21: classes = { cat__info__item_ESDIH: true }; break;
      case 22: classes = { cat__info__item_CEMAI: true }; break;
      case 23: classes = { cat__info__item_ESIDE: true }; break;
      case 24: classes = { cat__info__item_ESFES: true }; break;
      default: classes = { cat__info__item_ALL: true }; break;


    }
    return classes;
  }





  textSchools(id: number) {
    var num = this.schools.find(x => x.id == id);
    if (num)
      return num.NameTSchool;
    return "Nombre de Escuela"
  }




  ValidateDate(modelstart, modelend) {
    var response = false;
    if (this.enablesaveprocess == false)
      this.enablesave = true
    else
      this.enablesave = false

    var ActualDate = new Date(this.serverTime)
    var InputDateSince = new Date(modelstart)//EL TEXTO INGRESADO SE CONVIERTE EN TIPO DATE
    var InputDateUntil = new Date(modelend) //EL TEXTO INGRESADO SE CONVIERTE EN TIPO DATE


    //console.debug(InputDateUntil.toISOString())
    //cuando indica que trabaja actualmente



    if (InputDateSince >= InputDateUntil) {
      response = true

      this.enablesave = false


    }





    return response;

  }




  callgroups(model: any) {
    this.Subjects = []
    this.Groups = []

    //CON EL ID DEL CURSO BUSCO LOS GRUPOS QUE HAY PARA ESTE     
    this.coursesService.getAllCourseGroupsByCourseOferByID(model.CourseSchoolID).subscribe(p => {
      p = p.sort(function (a, b) {
        // Ascending: first age less than the previous
        return a["name"] == b["name"] ? 0 : a["name"] < b["name"] ? -1 : 1;
      });
      if (p.length == 0) {
        this.Groups = [{ id: 0, name: "No Existen Grupos Para el Curso Seleccionado" }]
      } else {
        this.Groups = p;

      }

    });


  }

  callsubjects(model: any) {

    //this.authService.swalLoading("Cargando Estudiantes")
    this.sourceHonorRoll.empty()
    this.Subjects = []
    //var groupSelected = model.Group
    var courseSelected = model.CourseSchoolID
    var SubjectSelected = model.SubjectID

    if (this.SubjectsProfesor.length === 0)
      this.authService.swalError("Error cargando", "")



    for (this.index = 0; this.index < this.SubjectsProfesor.length; this.index++) {

      // if (this.SubjectsProfesor[this.index].CourseGroupID == groupSelected) {
      //   this.Subjects.push(this.SubjectsProfesor[this.index])
      // }


    }
    console.debug("LAS MATERIAS QUE SE VAN A CARGAR SON")
    if (this.Subjects.length == 0)
      this.authService.swalError("Error Cargando Materias", "No hay materias para cargar")

    console.debug(this.Subjects)
    this.Subjects = this.Subjects.sort(function (a, b) {
      return a["subjects"]["NameSubject"].localeCompare(b["subjects"]["NameSubject"]);
    });





    //CARGAR ESTUDIANTES DE CADA MATERIA


    this.ContadorWhile = 0
    this.HonorRollFinal = []

    for (this.index = 0; this.index < this.Subjects.length; this.index++) {
      console.debug("LA MATERIA QUE SE VA A CONSULTAR  ES")
      console.debug(this.Subjects[this.index])




      this.coursesService.getHonorRollByCourseGroupID(this.Subjects[this.index].id).subscribe(RespuestaHonor => {

        if (RespuestaHonor.length == 0)
          this.authService.swalEndLoad()

        console.debug(this.ContadorWhile)
        console.debug("LOS ESTUDIANTES SON:")
        console.debug(RespuestaHonor)
        this.HonorRollFinal = this.HonorRollFinal.concat(RespuestaHonor)




        if (this.ContadorWhile + 1 >= this.Subjects.length) {

          console.debug("EL HONOR FINAL ES")
          console.debug(this.HonorRollFinal)



          var Materias = []
          for (var index = 0; index < this.HonorRollFinal.length; index++) {

            if (Materias.findIndex(x => x.NameSubject == this.HonorRollFinal[index].NameSubject) == -1) {
              Materias.push({ NameSubject: this.HonorRollFinal[index].NameSubject, SubjectGroupID: this.HonorRollFinal[index].SubjectGroupID })
            }


          }

          console.debug("LAS MATERIAS ENCONTRADAS FUERON")
          this.authService.swalEndLoad()
          console.debug(Materias)
          for (var indexMat = 0; indexMat < Materias.length; indexMat++) {
            this.settingsHonorRoll.columns["Note" + (indexMat + 1)].title = Materias[indexMat].NameSubject

          }







          this.HonorRollFinalFinal = []

          //CONSULTO LOS DATOS MEZCLADOS
          for (var index = 0; index < RespuestaHonor.length; index++) {


            var results = [];
            //PREGUNTA EN QUE PARTES PARECE ESE USUARIO
            for (var i = 0; i < this.HonorRollFinal.length; i++) {
              if (this.HonorRollFinal[i].UserID == this.HonorRollFinal[index].UserID) {
                results.push(i);
              }
            }


            var PrepareItem = {
              LastNames: this.HonorRollFinal[index].LastNames,
              Names: this.HonorRollFinal[index].Names
            }

            console.debug("PARA " + this.HonorRollFinal[index].LastNames + " se ha encontrado ")
            console.debug(results)

            console.debug(PrepareItem)






            for (var indexFinal = 0; indexFinal < results.length; indexFinal++) {

              //this.HonorRollFinalFinal.push(this.HonorRollFinal[results[indexFinal]])

              PrepareItem["Note" + (indexFinal + 1)] = this.HonorRollFinal[results[indexFinal]].FinalRecord


              console.debug(this.HonorRollFinal[results[indexFinal]])

              //delete this.HonorRollFinal[results[index]]



            }

            console.debug(PrepareItem)
            this.HonorRollFinalFinal.push(PrepareItem)



            for (var indexNota6 = 0; indexNota6 < this.HonorRollFinalFinal.length; indexNota6++) {

              var Nota8 = this.HonorRollFinalFinal[indexNota6].Note1 +
                this.HonorRollFinalFinal[indexNota6].Note2 +
                this.HonorRollFinalFinal[indexNota6].Note3 +
                this.HonorRollFinalFinal[indexNota6].Note4 +
                this.HonorRollFinalFinal[indexNota6].Note5 +
                this.HonorRollFinalFinal[indexNota6].Note6 +
                this.HonorRollFinalFinal[indexNota6].Note7;

              this.HonorRollFinalFinal[indexNota6]["Note8"] = Nota8
              console.debug(this.HonorRollFinalFinal[indexNota6])
              console.debug(Nota8)


            }














          }

          console.debug("EL FINAL FINAL")
          this.HonorRollFinalFinal = this.HonorRollFinalFinal.sort(function (a, b) {
            // Ascending: first age less than the previous
            return a["Note8"] == b["Note8"] ? 0 : a["Note8"] < b["Note8"] ? 1 : -1;
          });



          console.debug(this.HonorRollFinalFinal)




          this.showdata = true

          this.sourceHonorRoll.load(this.HonorRollFinalFinal)

          this.totalStudent = this.HonorRollFinalFinal.length

          this.authService.swalSuccess("Usuarios Cargados", "")

        }
        else
          this.ContadorWhile = this.ContadorWhile + 1
      })

    }
  }


  loadCourses(){
     //LLAMO LOS CURSOS
     this.coursesService.getAllCoursesBySchool(this.SchoolID,this.isActive).subscribe(p => {
      this.coursesbyschool = p.sort(function (a, b) {
        // Ascending: first age less than the previous
        return a["NameCourseOfer"].localeCompare(b["NameCourseOfer"]);
      });

      console.debug(this.coursesbyschool)
    });
  }

}