import { Component } from '@angular/core';
import { AuthService } from '../../../../models/Auth/auth.service';
import { Router } from '@angular/router';
import { ProfileNavComponent } from '../../../../components/profile-nav/profile-nav.component';
import { EvaluationsService } from '../../../../models/evaluation/evaluations.service';
import { Graduates } from '../../../../models/evaluation/graduates';
import { CoursesService } from '../../../../models/Coursesinfo/courses.service';

import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
    selector: 'Graduates',
    templateUrl: './graduates.component.html',
    styleUrls: ['./graduates.css'],

})

export class GraduatesComponent {

    user: string;
    schoolId: number;
    showdata: boolean = false;
    graduates: Graduates
    stateButton: boolean = false

    constructor(
        private authService: AuthService,
        private router: Router,
        public profileNavComponent: ProfileNavComponent,
        private evaluatiosService: EvaluationsService,
        private coursesService: CoursesService,

    ) {
        //SI NO HAY USUARIOS EN LOCAL STORAGE, LLEVAR A LOGIN
        if (this.authService.getCurrentUser() == null) {
            this.router.navigate(['/register']);
        } else {
            //DE LO CONTRARO CARGAR EL ID DEL USUARIO
            this.user = this.authService.getCurrentUser(); //devuelve el id del usuario
            //SI EL ID DEL USUARIO ES UN NUMERO ENTERO
            if (Number.isInteger(parseInt(this.user))) {
                //CONSULTO EL PERFIL DEL USUARIO
                var validar_usuario = [false];

                for (let role of this.profileNavComponent.roles) {
                    if (role.name == "Estudiante") {
                        validar_usuario = validar_usuario.concat([true])
                        this.schoolId = role.SchoolID

                        break
                    }

                }

                if (!validar_usuario.includes(true)) { this.router.navigate(['/']); }


                this.authService.swalLoading("Cargando Encuesta")


                this.evaluatiosService.getGraduatesCountBySchoolAndUserID(this.schoolId, parseInt(this.user)).subscribe(p => {

                    if (p["count"] > 0) {
                        this.showdata = false;
                        this.authService.swalError("Ya Realizó La Evaluación", "")
                    } else {

                        this.showdata = true
                        this.authService.swalEndLoad()


                    }


                })



                /* this.coursesService.getSubjectByProfesorID(parseInt(this.user)).subscribe(response => {
                     
                     
                     
                     
                             if (response.length > 0) {
                               this.SubjectsProfesor = response;
                              

                     
                             }
                     
                     
                     
                     
                     
                     
                     
                           });*/




            }
            else { this.router.navigate(['/']); }
        }

        this.graduates = {

            SchoolID: null,
            Preg1: null,
            Preg2: null,
            ObsCat1: '',
            Preg3: null,
            Preg4: null,
            ObsCat2: '',
            Preg5: null,
            Preg6: null,
            ObsCat3: '',
            Preg7: null,
            Preg8: null,
            Preg9: null,
            Preg10: null,
            Preg11: null,
            Preg12: null,
            Preg13: null,
            Preg14: null,
            Preg15: null,
            Preg16: null,
            Preg17: null,
            Preg18: null,
            Preg19: null,
            Preg20: null,
            Preg21: null,
        }
    }

    handleMyEvent(arg, vari) {
        this.graduates[vari] = arg;
    }
    saveInstitutional(model: Graduates) {
        this.graduates.SchoolID = this.schoolId
        this.graduates.UserID = parseInt(this.user)



        Swal({
            title: 'Estas Seguro',
            text: "No Podrás Modificar La Calificación",
            type: 'warning',
            showCancelButton: true,

            confirmButtonText: 'Si, Enviar!',
            cancelButtonText: 'No, cancelar!',

        }).then((result) => {
            if (result.value) {
            this.stateButton = true
            //una VEZ CONFIRMADO EJECUTAR NORMALMENTE EL SCRIPT

            if (this.graduates.SchoolID && this.graduates.Preg1 && this.graduates.Preg2 && this.graduates.Preg3 && this.graduates.Preg4 && this.graduates.Preg5 && this.graduates.Preg6
                && this.graduates.Preg7 && this.graduates.Preg8 && this.graduates.Preg9 && this.graduates.Preg10 && this.graduates.Preg11 && this.graduates.Preg12
                && this.graduates.Preg13 && this.graduates.Preg14 && this.graduates.Preg15 && this.graduates.Preg16 && this.graduates.Preg17 && this.graduates.Preg18 && this.graduates.Preg19 && this.graduates.Preg20 && this.graduates.Preg21) {
                //no null
                this.authService.swalLoading("Guardando Calificación")

                this.evaluatiosService.createGraduates(this.graduates).subscribe(p => {

                    this.authService.swalSuccess("Guardado exitosamente", "")
                    this.graduates = p
                    this.showdata = false;
                    this.stateButton = false



                },
                    e => {
                        this.authService.swalError("Error Guardando Evaluación", "Intente nuevamente")
                        this.stateButton = false
                    }
                );
            }
            else {

                this.authService.swalError("Error Guardando Evaluación", "Asegúrese de responder todas las preguntas")

                this.stateButton = false
            }
        }
        })






    }
}