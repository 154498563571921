import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../../../models/Auth/auth.service';
import { Course } from '../../../../models/Coursesinfo/course';
import { CoursesService } from '../../../../models/Coursesinfo/courses.service';
import { Delegate } from '../../../../models/evaluation/delegate';
import { EvaluationsService } from '../../../../models/evaluation/evaluations.service';
import { ProfileNavComponent } from '../../../../components/profile-nav/profile-nav.component';
import { isNullOrUndefined } from 'util';
import { RadarStudentBoxComponent } from './radar-student-box/radar-student-box.component'
import { RadarSelfApprBoxComponent } from './radar-selfappr-box/radar-selfappr-box.component'
import { RadarSchoolApprBoxComponent } from './radar-schoolappr-box/radar-schoolappr-box.component';
import { RadarGraduatedapprBoxComponent } from './radar-graduatedappr-box/radar-graduatedappr-box.component';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { environment } from 'environments/environment';

declare var $: any;


@Component({
    selector: 'delegate',
    templateUrl: './delegate.component.html',
    styleUrls: ['./delegate.component.css'],
    providers: [RadarStudentBoxComponent, RadarSelfApprBoxComponent, RadarSchoolApprBoxComponent, RadarGraduatedapprBoxComponent]
})

export class DelegateComponent {

    delegate: Delegate;
    user: string;
    ErrorGroup: string;
    showdata: boolean = false;
    SubjectID: number;
  

    SchoolID: number;
    coursesbyschool: Course[];
    Groups: any;
    courses_search  = {
        CourseOferID: 0,
        CourseGroupID: 0,
        SubjectID: 0
    }
    stateButton: boolean = false
    public Subjects: Course[]
    public SubjectsProfesor_Pofile: Course[];
    public isActive = 0

    public CEMIL_ICON:any = environment.CEMIL_ICON;
    public EJC_ICON:any = environment.EJC_ICON;
    public SCHOOL_NAME:string = environment.SCHOOL_NAME;

    
    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private evaluatiosService: EvaluationsService,
        private authService: AuthService,
        public profileNavComponent: ProfileNavComponent,
        private coursesService: CoursesService,
        public radarstudentboxcomponent: RadarStudentBoxComponent,
        public radarselfapprboxcomponent: RadarSelfApprBoxComponent,
        public radarSchoolApprBoxComponent: RadarSchoolApprBoxComponent,
        public radarGraduatesApprBoxComponent: RadarGraduatedapprBoxComponent,
       
    ) {

        //SI NO HAY USUARIOS EN LOCAL STORAGE, LLEVAR A LOGIN
        if (this.authService.getCurrentUser() == null) {
            this.router.navigate(['/register']);
        } else {
            //DE LO CONTRARO CARGAR EL ID DEL USUARIO
            this.user = this.authService.getCurrentUser(); //devuelve el id del usuario
            //SI EL ID DEL USUARIO ES UN NUMERO ENTERO
            if (Number.isInteger(parseInt(this.user))) {
                //CONSULTO EL PERFIL DEL USUARIO
                var validar_usuario = [false];

                for (let role of this.profileNavComponent.roles) {
                    if (role.name == "Calidad") {
                        validar_usuario = validar_usuario.concat([true])
                        this.SchoolID = role.SchoolID;
                        break
                    }

                }

                if (!validar_usuario.includes(true)) { this.router.navigate(['/']); }


                //llamo los resultados de la escuela
                this.radarSchoolApprBoxComponent.idSchool = this.SchoolID
                this.radarGraduatesApprBoxComponent.idSchool = this.SchoolID
                console.log("this.radarGraduatesApprBoxComponent.idSchool", this.radarGraduatesApprBoxComponent.idSchool)
                //this.radarSchoolApprBoxComponent.ngOnInit()


              

                //LLAMO TODAS LAS MATERIAS DEL CURSO 
                this.coursesService.getSubjectGroups(parseInt(this.user), this.SchoolID).subscribe(response => {
                    if (response.length > 0) {

                      

                       /* for (let index = 0; index < response.length; index++) {
                            console.log(index + " " + response[index]["coursesgroup"].name);

                        }*/

                        this.SubjectsProfesor_Pofile = response.sort(function (a, b) {
                            // Ascending: first age less than the previous
                            return a["coursesgroup"].name.localeCompare(b["coursesgroup"].name);
                        });

                        console.log("el subject profesor es")
                        console.log(this.SubjectsProfesor_Pofile)

                    }
                });

            }
            else { this.router.navigate(['/']); }
        }

       this.InitData();

    }

InitData(){
    this.delegate = {

        SubjectGroupID: null,
        Preg1: null,
        Preg2: null,
        ObsCat1: '',
        Preg3: null,
        Preg4: null,
        ObsCat2: '',
        Preg5: null,
        Preg6: null,
        ObsCat3: '',
        Preg7: null,
        Preg8: null,
        ObsCat4: '',
        Preg9: null,
        Preg10: null,
        ObsCat5: '',
        Preg11: null,
        Preg12: null,
        Preg13: null,
        ObsCat6: '',
        Preg14: null,
        ObsCat7: '',
        Preg15: null,
        Preg16: null,
        ObsCat8: '',
        Preg17: null,
        Preg18: null,
        ObsCat9: ''
    }
    
}

    callgroups(model: any) {
        this.Subjects = []
        this.Groups = []

        //CON EL ID DEL CURSO BUSCO LOS GRUPOS QUE HAY PARA ESTE     
        this.coursesService.getAllCourseGroupsByCourseOferByID(model.CourseSchoolID).subscribe(p => {
            p = p.sort(function (a, b) {
                // Ascending: first age less than the previous
                return a["name"] == b["name"] ? 0 : a["name"] < b["name"] ? -1 : 1;
            });
            if (p.length == 0) {
                this.Groups = [{ id: 0, name: "No Existen Grupos Para el Curso Seleccionado" }]
            } else {
                this.Groups = p;

            }

        });


    }
    callsubjects(model: any) {
        this.Subjects = []
        var groupSelected = model.Group


        for (var index = 0; index < this.SubjectsProfesor_Pofile.length; index++) {

            if (this.SubjectsProfesor_Pofile[index].CourseGroupID == groupSelected) {
                this.Subjects.push(this.SubjectsProfesor_Pofile[index])
            }


        }
        //console.debug(this.Subjects)
        this.Subjects = this.Subjects.sort(function (a, b) {
            return a["subjects"]["NameSubject"].localeCompare(b["subjects"]["NameSubject"]);
        });

    }

    textSubject(id) {
        if (isNullOrUndefined(this.Subjects)) { }
        else {
            var num = this.Subjects.find(x => x.id == id);
            if (num)
                return num.coursesgroup.name.toUpperCase() + " " + num.subjects.NameSubject.toUpperCase();
            else
                return ""
        }
    }

    textSubjectProfessor(id) {
        if (isNullOrUndefined(this.Subjects)) { }
        else {
            var num = this.Subjects.find(x => x.id == id);
            if (num) {

                var profesor = $.grep([num["userapp"]["LastName1"], num["userapp"]["LastName2"], num["userapp"]["Name1"], num["userapp"]["Name2"]], Boolean).join(" ");

                return "DICTADA POR: " + profesor.toUpperCase();
            }
            else
                return ""
        }
    }
    selectmateria(model) {
        this.radarstudentboxcomponent.TotalCat1 = 0;
        this.radarstudentboxcomponent.TotalCat2 = 0;
        this.radarstudentboxcomponent.TotalCat3 = 0;
        this.radarstudentboxcomponent.TotalCat4 = 0;
        this.radarstudentboxcomponent.TotalCat5 = 0;
        this.radarstudentboxcomponent.TotalCat6 = 0;
        this.radarstudentboxcomponent.TotalCat7 = 0;
        this.radarstudentboxcomponent.TotalCat8 = 0;
        this.radarstudentboxcomponent.TotalCat9 = 0;

        this.radarselfapprboxcomponent.TotalCat1 = 0;
        this.radarselfapprboxcomponent.TotalCat2 = 0;
        this.radarselfapprboxcomponent.TotalCat3 = 0;
        this.radarselfapprboxcomponent.TotalCat4 = 0;
        this.radarselfapprboxcomponent.TotalCat5 = 0;
        this.radarselfapprboxcomponent.TotalCat6 = 0;
        this.radarselfapprboxcomponent.TotalCat7 = 0;
        this.radarselfapprboxcomponent.TotalCat8 = 0;
        this.radarselfapprboxcomponent.TotalCat9 = 0;

        this.radarGraduatesApprBoxComponent.TotalCat1 = 0;
        this.radarGraduatesApprBoxComponent.TotalCat2 = 0;
        this.radarGraduatesApprBoxComponent.TotalCat3 = 0;

        //EL ID  QUE PARECE ES EL DEL SUBJECTGROUP
        this.delegate.SubjectGroupID = model.id
        console.log(model)

        this.evaluatiosService.getDelegateCountBySubjectGroup(model.id).subscribe(p => {
            var Contador = p

            if (Contador["count"] > 0) {
                this.ErrorGroup = "Ya Realizó La Evaluación Para Esta Materia";
                this.showdata = false;
                this.authService.swalError("Ya Realizó La Evaluación Para Esta Materia", "")

            } else {

                this.showdata = true


            }

            //LLAMO LOS DATOS PARA LLENAR LA ARAÑA DE CALIFICACION ESTUDIANTES
            this.radarstudentboxcomponent.idSubjectGroup = model.id
            this.radarstudentboxcomponent.ngOnInit()
            //LLAMO LOS DATOS PARA LLENAR LA ARAÑA DE CALIFICACION EL MISMO
            this.radarselfapprboxcomponent.idSubjectGroup = model.id
            this.radarselfapprboxcomponent.ngOnInit()
            //LLAMO LOS DATOS PARA LLENAR LA ARANA DE CALIFICACION DE EGRESADOS
            //this.radarGraduatesApprBoxComponent.idSchool = model.id
            this.radarGraduatesApprBoxComponent.ngOnInit()
        });








    }


    handleMyEvent(arg, vari) {
        this.delegate[vari] = arg;

        console.log(this.delegate)
    }

    saveDelegate(model: Delegate, isValid: boolean) {


        Swal({
            title: 'Estas Seguro',
            text: "No Podrás Modificar La Calificación",
            type: 'warning',
            showCancelButton: true,

            confirmButtonText: 'Si, Enviar!',
            cancelButtonText: 'No, cancelar!',

        }).then((result) => {
            if (result.value) {
            this.stateButton = true
            //una VEZ CONFIRMADO EJECUTAR NORMALMENTE EL SCRIPT
            if (this.delegate.SubjectGroupID && this.delegate.Preg1 && this.delegate.Preg2 && this.delegate.Preg3 && this.delegate.Preg4 && this.delegate.Preg5 && this.delegate.Preg6
                && this.delegate.Preg7 && this.delegate.Preg8 && this.delegate.Preg9 && this.delegate.Preg10 && this.delegate.Preg11 && this.delegate.Preg12
                && this.delegate.Preg13 && this.delegate.Preg14 && this.delegate.Preg15 && this.delegate.Preg16 && this.delegate.Preg17 && this.delegate.Preg18) {
                //no null

                this.evaluatiosService.createDelegate(this.delegate, parseInt(this.user)).subscribe(p => {
                    //this.delegate = p;
                    this.InitData();
                    this.ErrorGroup = "Gracias por Evaluar";
                    this.showdata = false;
                    this.authService.swalSuccess("Gracias por Evaluar", "")

                    this.stateButton = false

                });
            }
            else {
                this.authService.swalError("Error Guardando Evaluación", "Asegúrese de calificar todas las categorías")

                this.stateButton = false
            }






        }



        })






    }


    loadCourses(){
          //LLAMO LOS CURSOS PARA MOSTRAR EN EL SELECT
          this.coursesService.getAllCoursesBySchool(this.SchoolID,this.isActive).subscribe(p => {
            this.coursesbyschool = p.sort(function (a, b) {
                // Ascending: first age less than the previous
                return a["NameCourseOfer"].localeCompare(b["NameCourseOfer"]);
            });

            console.debug(this.coursesbyschool)
        });

    }

}