import { Component } from '@angular/core';
import { LocalDataSource } from 'ng2-smart-table';
import { Course } from '../../../models/Coursesinfo/course';
import { School } from '../../../models/Coursesinfo/school';
import { CoursesService } from '../../../models/Coursesinfo/courses.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../../models/Auth/auth.service';
import { ProfileNavComponent } from '../../../components/profile-nav/profile-nav.component';
import { Student } from '../../../models/evaluation/student';
import { elementAt } from '../../../../../node_modules/rxjs/operators';
import { isNullOrUndefined } from 'util';
import { AngularCsv } from 'angular7-csv';
import { forEach } from '@angular/router/src/utils/collection';
import { OwlOptions } from 'ngx-owl-carousel-o';
import * as moment from 'moment';
import { environment } from 'environments/environment';



@Component({
  selector: 'NewHonorRoll',
  templateUrl: './new-honor-roll.component.html',
  styleUrls: ['./new-honor-roll.component.css']

})
export class NewHonorRollComponent {

  customOptions: OwlOptions = {
    loop: true,
    navSpeed: 700,
    nav: false,
    dots: false,
    margin: 10,
    responsive: {
      0: {
        items: 3
      },
      400: {
        items: 6
      },
      740: {
        items: 10
      },
      940: {
        items: 13
      }
    }
  }



  totalStudent: number;
  coursesbyschool: Course[];
  schools: School[] = [];
  SchoolID: number;
  serverTime: string
  user: any
  isResgistre = false
  isJefe = false
  isDataReady = false;
  isSchoolReady = false;
  year: number = 2017;
  currentYear: number = new Date().getFullYear()
  listYear = [2017]

  //isDataReady = false;

  courses: Course = {

    CourseOferID: 0
  }
  showdata: boolean = false;
  honorRollView: any[] = []
  subjects: any[] = []

  public SCHOOL_LOGO_DYNAMIC_IMG: any = (id:number) => environment.SCHOOL_LOGO_DYNAMIC_IMG(id);

  constructor(
    private coursesService: CoursesService,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    public profileNavComponent: ProfileNavComponent,
  ) {


    while (this.year != this.currentYear) {
      this.year++
      this.listYear.push(this.year)
    }
    this.year=0
    //GUARDO LA HORA DEL SERVIDOR
    this.authService.getTime().then(server2 => this.serverTime = server2.time);






    if (this.authService.getCurrentUser() == null) {
      this.router.navigate(['/register']);
    } else {
      //DE LO CONTRARO CARGAR EL ID DEL USUARIO
      this.user = this.authService.getCurrentUser(); //devuelve el id del usuario
      //SI EL ID DEL USUARIO ES UN NUMERO ENTERO
      if (Number.isInteger(parseInt(this.user))) {
        //CONSULTO EL PERFIL DEL USUARIO
        var validar_usuario = [false];

        for (let role of this.profileNavComponent.roles) {
          if (role.name == "General") {
            validar_usuario = validar_usuario.concat([true])
            this.SchoolID = role.SchoolID;
          }
          if (role.name == "Registro y Control") {

            this.isResgistre = true
            validar_usuario = validar_usuario.concat([true])
            this.SchoolID = role.SchoolID;
          }
          if (role.name == "Jefe") {

            this.isJefe = true
            validar_usuario = validar_usuario.concat([true])
            this.SchoolID = role.SchoolID;
          }

          if (role.name == "Inspector Escuela") {
            validar_usuario = validar_usuario.concat([true])
            this.SchoolID = role.SchoolID;
            break
          }



        }


        this.coursesService.getAllSchoolByDepend(this.SchoolID).subscribe(depend => {
          if (depend.length > 0) {
            this.schools = depend

            console.log("Depden", this.schools)

            this.isSchoolReady = true
            //this.callHonorRoll()
          } else {
            // this.callHonorRoll()

          }
        })

        if (!validar_usuario.includes(true)) { this.router.navigate(['/']); }






      }

    }


  }


  setCourses() {

    //LLAMO LOS CURSOS
    this.coursesService.getAllCoursesBySchoolByDate(this.SchoolID, this.year).subscribe(p => {
      this.coursesbyschool = p.sort(function (a, b) {
        // Ascending: first age less than the previous
        return a["NameCourseOfer"].localeCompare(b["NameCourseOfer"]);
      });

      console.debug(this.coursesbyschool)
    });
  }

  setNewSchool(id) {
    this.SchoolID = id
    this.isDataReady = true

    //LLAMO LOS CURSOS
    this.coursesService.getAllCoursesBySchoolByDate(this.SchoolID, this.year).subscribe(p => {
      this.coursesbyschool = p.sort(function (a, b) {
        // Ascending: first age less than the previous
        return a["NameCourseOfer"].localeCompare(b["NameCourseOfer"]);
      });

      console.debug(this.coursesbyschool)
    });

  }


  ngOnInit() {

  }

  ngOnDestroy() {

  }
  colorSchool(idSchool: number) {
    let classes = {};

    switch (idSchool) {
      case 0: classes = { cat__info__item_ALL: true }; break;
      case 1: classes = { cat__info__item_CEMIL: true }; break;
      case 4: classes = { cat__info__item_EAS: true }; break;
      case 5: classes = { cat__info__item_ESINF: true }; break;
      case 6: classes = { cat__info__item_ESCAB: true }; break;
      case 7: classes = { cat__info__item_ESART: true }; break;
      case 8: classes = { cat__info__item_ESING: true }; break;
      case 9: classes = { cat__info__item_ESCOM: true }; break;
      case 10: classes = { cat__info__item_ESICI: true }; break;
      case 16: classes = { cat__info__item_ESAVE: true }; break;
      case 17: classes = { cat__info__item_ESLOG: true }; break;
      case 18: classes = { cat__info__item_ESCEQ: true }; break;
      case 19: classes = { cat__info__item_ESPOM: true }; break;
      case 20: classes = { cat__info__item_ESMAI: true }; break;
      case 21: classes = { cat__info__item_ESDIH: true }; break;
      case 22: classes = { cat__info__item_CEMAI: true }; break;
      case 23: classes = { cat__info__item_ESIDE: true }; break;
      case 24: classes = { cat__info__item_ESFES: true }; break;
      default: classes = { cat__info__item_ALL: true }; break;


    }
    return classes;
  }

  textSchools(id: number) {
    var num = this.schools.find(x => x.id == id);
    if (num)
      return num.NameTSchool;
    return "Nombre de Escuela"
  }


  exportData() {
    let cardex = []

    let cardexRow = {}

    cardexRow["Rango"] = "Rango";
    cardexRow["Apellidos"] = "Apellidos";
    cardexRow["Nombre"] = "Nombre";
    cardexRow["Documento"] = "Documento";
    cardexRow["CedulaMil"] = "CedulaMil";
    cardexRow["Arma"] = "Arma";

    this.subjects.forEach((subject, index) => {
      cardexRow[subject.NameSubject] = subject.NameSubject;
    });

    cardexRow["GPA"] = "GPA";



    cardex.push(cardexRow);


    this.honorRollView.forEach(  (honor, index) => {
      let cardexRow = {}
      cardexRow["Rango"] = honor.Sigla;
      cardexRow["Apellidos"] = honor.LastNames;
      cardexRow["Nombre"] = honor.Names;
      cardexRow["Documento"] = honor.Document;
      cardexRow["CedulaMil"] = honor.CedulaMil;
      cardexRow["Arma"] = honor.Unit;
       this.subjects.forEach(  (subject, index) => {
        if (  isNullOrUndefined(honor.Subjects[index])) { }
        else {
          cardexRow[subject.NameSubject] = honor.Subjects[index].FinalRecord;
        }
      });
      cardexRow["GPA"] = honor.GPA;
      cardex.push(cardexRow);
    });


    new AngularCsv(cardex, 'cardex');



  }
  callHonorRoll(model) {
    this.subjects = []
    this.honorRollView = []
    this.showdata = false


    this.authService.swalLoading("Cargando Datos")
  
    this.coursesService.getAllHonorRollCoursesOferActiveByCourseOferID(model.CourseOferID).subscribe(students => {


      //PRIMERO CREAR LISTA DE ESTUDIANTES
      students.forEach((element, index) => {
        var position = this.honorRollView.findIndex(x => x.UserID === element.UserID)
        if (position >= 0) {
          //SI EL USUARIO YA EXISTE MIRAMOS SI LE LE PUEDE AGREGAR UNA MATERIA
          var positionSubject = this.honorRollView[position].Subjects.findIndex(x => x.id === element.Subjects.SubjectID)

          if (positionSubject >= 0) { }
          else {
            if (isNullOrUndefined(element.FinalRecord) || isNullOrUndefined(element.Subjects)) { }
            else {
              element.Subjects.FinalRecord = element.FinalRecord.toFixed(2)
            }
            this.honorRollView[position].Subjects.push(element.Subjects)
            this.honorRollView[position].Subjects.sort(function (a, b) {
              // Ascending: first age less than the previous
              return a["id"] == b["id"] ? 0 : a["id"] < b["id"] ? -1 : 1;
            });
          }

        }
        else {

          if (isNullOrUndefined(element.FinalRecord) || isNullOrUndefined(element.Subjects)) { }
          else
            element.Subjects.FinalRecord = element.FinalRecord.toFixed(2)
          var temp = {
            UserID: element.UserID,
            Names: element.Names,
            Document: element.Document,
            CedulaMil: element.CedulaMil,
            Unit: element.Unit,
            Sigla: element.Sigla,
            GPA: isNullOrUndefined(element.GPA) ? 0 : element.GPA.toFixed(2),
            LastNames: element.LastNames,
            Subjects: isNullOrUndefined(element.SubjectID) ? [] : [element.Subjects]
          }
          this.honorRollView.push(temp)
        }
      })



      //COLOCAR MATERIA A ESTUDIANTE
      students.forEach(element => {
        var position = this.subjects.findIndex(x => x.SubjectID === element.SubjectID)
        if (position >= 0) {
        }
        else {
          if (!isNullOrUndefined(element.SubjectID)) {
            var temp = {
              SubjectID: element.SubjectID,
              NameSubject: element.Subjects.NameSubject,
            }
            this.subjects.push(temp)
          }
        }
      })








      this.subjects = this.subjects.sort(function (a, b) {
        // Ascending: first age less than the previous
        return a["SubjectID"] == b["SubjectID"] ? 0 : a["SubjectID"] < b["SubjectID"] ? -1 : 1;
      });



      this.showdata = true
      this.authService.swalEndLoad()

    })



  }


}