import { isNullOrUndefined } from 'util';
import { Component, OnInit } from '@angular/core';
import { Course } from '../../../../models/Coursesinfo/course';
import { CoursesService } from "../../../../models/Coursesinfo/courses.service";
import { UsersService } from "../../../../models/usersInfo/users.service";
import { ActivatedRoute, Router } from "@angular/router";
import { RecordService } from '../../../../models/midterm/record.service';
import { AuthService } from '../../../../models/Auth/auth.service';
import { ProfileNavComponent } from '../../../../components/profile-nav/profile-nav.component';
import { EvaluationsService } from '../../../../models/evaluation/evaluations.service';

declare var $: any;
declare var Chart: any;

@Component({
  moduleId: module.id,
  selector: 'chart-calidad-teacher',
  templateUrl: './chart-calidad-teacher.component.html',
  styleUrls: ['./chart-calidad-teacher.component.css'],

})


export class ChartCalidadTeacherComponent implements OnInit {

  public Courses: Course[];
  public Subjects: Course[]
  public IsProfessor: boolean = false;
  public IsStudent: boolean = false;
  public IsStudent2: boolean = false;
  public SubjectsProfesor: Course[];
  public EventCalendarProfessor: any[] = []
  public EventCalendarColorIndexProfessor: any[] = []

  public EventCalendarStudent: any[] = []
  public EventCalendarColorIndexStudent: any[] = []

  TotalCat1: number = 0;
  TotalCat2: number = 0;
  TotalCat3: number = 0;
  TotalCat4: number = 0;
  TotalCat5: number = 0;
  TotalCat6: number = 0;
  TotalCat7: number = 0;
  TotalCat8: number = 0;
  TotalCat9: number = 0;
  dataRadar: any
  ChartRadar: any
  IsInspector = false;
  TotalDelegate: number = 0;
  TotalSelf: number = 0;
  TotalStudents: number = 0
  

  public ColorCourses = ["#5d80a2", "#680345", "#7C6445", "#E154CE", "#258C64", "#A44015", "#FE9A2E", "#DF0101", "#01DF01", "#0040FF", "#565ACB", "#144A20", "#AAE951", "#6CF132", "#F19663", "#891CBB", "#21EBF3", "#F19663", "#61265F", "#6916E4", "#464208", "#393D78", "#E2337D", "#A1924C", "#52B743", "#176548", "#A0B03D", "#7C188A", "#77BB3E"]

  SchoolID: number = 0;
  subjectgroupsprofessor: any = []

  public idProfileCV: any;

  constructor(
    private recordService: RecordService,
    private authService: AuthService,
    private usersService: UsersService,
    private coursesService: CoursesService,
    private route: ActivatedRoute,
    private router: Router,
    public profileNavComponent: ProfileNavComponent,
    public evaluationsservice: EvaluationsService

  ) {

  }

  //INDICO QUE LOS DATOS NO ESTAN DISPONIBLES AL CARGAR EL HTML PARA NO MOSTRAR ERRORES
  ngOnInit() {


    // RADAR CHART
    //var radarCtx = document.getElementById('chart-radar').getContext('2d');
    //var radarCtx = document.getElementById('radar-chart').getContext('2d');

    var radarCtx = $('#chart-radar-teacher')[0].getContext('2d');



    this.dataRadar = {
      labels: ["Planeación y Organización", "Conocimiento", "Pedagogia/Metodología", "Evaluacion y Retroalimentación", "Relacion y Comunicación", "Compromiso Institucional", "Currículo Propuesto", "Currículo En Uso", "Análisis Curricular"],
      datasets: [
        {
          label: "Calidad Académica",
          backgroundColor: "rgba(47, 121, 238,0.2)",
          borderColor: "rgba(47, 121, 238,1)",
          pointBackgroundColor: "rgba(47, 121, 238,1)",
          pointBorderColor: "#fff",
          pointHoverBackgroundColor: "#fff",
          pointHoverBorderColor: "rgba(47, 121, 238,1)",
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0]
        }
        ,
        {
          label: "Evaluación Estudiantes",
          backgroundColor: "rgba(174, 238, 48,0.2)",
          borderColor: "rgba(174, 238, 48,1)",
          pointBackgroundColor: "rgba(174, 238, 48,1)",
          pointBorderColor: "#fff",
          pointHoverBackgroundColor: "#fff",
          pointHoverBorderColor: "rgba(174, 238, 48,1)",
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0]
        }
        ,
        {
          label: "Auto-Evaluación",
          backgroundColor: "rgba(238, 79, 48,0.2)",
          borderColor: "rgba(238, 79, 48, 1)",
          pointBackgroundColor: "rgba(238, 79, 48, 1)",
          pointBorderColor: "#fff",
          pointHoverBackgroundColor: "#fff",
          pointHoverBorderColor: "rgba(238, 79, 48, 1)",
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0]
        }
      ]
    };

    this.ChartRadar = new Chart(radarCtx, {
      type: 'radar',
      data: this.dataRadar,
      options: {
        scale: {
          ticks: {
            max: 5,
            stepSize: 1,
            min: 0,
            beginAtZero: true
          }
        }
      }
    });

    if (isNullOrUndefined(this.idProfileCV)) {
      //NO HAGO NADA
    }
    else {
      console.log("Entrando araña docente")
      console.log(this.idProfileCV)
      this.coursesService.getSubjectByProfesorID(this.idProfileCV).subscribe(responseProfessor => {
        //GUARDO TODOS LOS SUBJECTGROUPS

        this.subjectgroupsprofessor = []

        var filterarray = []

        for (var index = 0; index < responseProfessor.length; index++) {
          filterarray.push({ "SubjectGroupID": responseProfessor[index].id })
          //this.subjectgroupsprofessor.push(responseProfessor[index].id)
        }


        //CREO EL FILTER PARA CONSULTAR TODAS LAS NOTAS
        // var filterarray = []
        // for (var index = 0; index < this.subjectgroupsprofessor.length; index++) {
        //   filterarray.push({ "SubjectGroupID": this.subjectgroupsprofessor[index] })
        // }


        var filterdb = `{"where": { "or": ` + JSON.stringify(filterarray) + `}}`;
        console.log(filterdb)
        //consulto las calificaciones de calidad

        this.evaluationsservice.getDelegateBySubjectGroup(filterdb).subscribe(responseDelegate => {
          console.log(responseDelegate)
          this.TotalCat1 = 0
          this.TotalCat2 = 0
          this.TotalCat3 = 0
          this.TotalCat4 = 0
          this.TotalCat5 = 0
          this.TotalCat6 = 0
          this.TotalCat7 = 0
          this.TotalCat8 = 0
          this.TotalCat9 = 0
          this.TotalDelegate = responseDelegate.length
          console.log("Total Calidad", this.TotalDelegate)
          //CALCULAR EL PROMEDIO DE TODAS LAS NOTAS
          for (var i = 0; i < responseDelegate.length; i++) {

            this.TotalCat1 += responseDelegate[i].TotalCat1
            this.TotalCat2 += responseDelegate[i].TotalCat2
            this.TotalCat3 += responseDelegate[i].TotalCat3
            this.TotalCat4 += responseDelegate[i].TotalCat4
            this.TotalCat5 += responseDelegate[i].TotalCat5
            this.TotalCat6 += responseDelegate[i].TotalCat6
            this.TotalCat7 += responseDelegate[i].TotalCat7
            this.TotalCat8 += responseDelegate[i].TotalCat8
            this.TotalCat9 += responseDelegate[i].TotalCat9
          }

          this.TotalCat1 = Number((this.TotalCat1 / responseDelegate.length).toFixed(1));
          this.TotalCat2 = Number((this.TotalCat2 / responseDelegate.length).toFixed(1));
          this.TotalCat3 = Number((this.TotalCat3 / responseDelegate.length).toFixed(1));
          this.TotalCat4 = Number((this.TotalCat4 / responseDelegate.length).toFixed(1));
          this.TotalCat5 = Number((this.TotalCat5 / responseDelegate.length).toFixed(1));
          this.TotalCat6 = Number((this.TotalCat6 / responseDelegate.length).toFixed(1));
          this.TotalCat7 = Number((this.TotalCat7 / responseDelegate.length).toFixed(1));
          this.TotalCat8 = Number((this.TotalCat8 / responseDelegate.length).toFixed(1));
          this.TotalCat9 = Number((this.TotalCat9 / responseDelegate.length).toFixed(1));

          this.dataRadar.datasets[0].data = [this.TotalCat1/10, this.TotalCat2/10, this.TotalCat3/10, this.TotalCat4/10, this.TotalCat5/10, this.TotalCat6/10, this.TotalCat7/10, this.TotalCat8/10, this.TotalCat9/10]
          //this.ChartRadar.removeData();
          // this.ChartRadar.addData(this.dataRadar.datasets[0].data);
          console.log(this.dataRadar.datasets[0].data)
          this.ChartRadar.update();
        })


        //consulto las calificaciones de estudianntes
        this.evaluationsservice.getStudentsApprBySubjectGroup(filterdb).subscribe(responseStudent => {
          console.log(responseStudent)
          this.TotalCat1 = 0
          this.TotalCat2 = 0
          this.TotalCat3 = 0
          this.TotalCat4 = 0
          this.TotalCat5 = 0
          this.TotalCat6 = 0
          this.TotalCat7 = 0
          this.TotalCat8 = 0
          this.TotalCat9 = 0
          this.TotalStudents = responseStudent.length
          console.log("Total Estudiantes", this.TotalStudents)
          //CALCULAR EL PROMEDIO DE TODAS LAS NOTAS
          for (var i = 0; i < responseStudent.length; i++) {

            this.TotalCat1 += responseStudent[i].TotalCat1
            this.TotalCat2 += responseStudent[i].TotalCat2
            this.TotalCat3 += responseStudent[i].TotalCat3
            this.TotalCat4 += responseStudent[i].TotalCat4
            this.TotalCat5 += responseStudent[i].TotalCat5
            this.TotalCat6 += responseStudent[i].TotalCat6
            this.TotalCat7 += responseStudent[i].TotalCat7
            this.TotalCat8 += responseStudent[i].TotalCat8
            this.TotalCat9 += responseStudent[i].TotalCat9
          }

          this.TotalCat1 = Number((this.TotalCat1 / responseStudent.length).toFixed(1));
          this.TotalCat2 = Number((this.TotalCat2 / responseStudent.length).toFixed(1));
          this.TotalCat3 = Number((this.TotalCat3 / responseStudent.length).toFixed(1));
          this.TotalCat4 = Number((this.TotalCat4 / responseStudent.length).toFixed(1));
          this.TotalCat5 = Number((this.TotalCat5 / responseStudent.length).toFixed(1));
          this.TotalCat6 = Number((this.TotalCat6 / responseStudent.length).toFixed(1));
          this.TotalCat7 = Number((this.TotalCat7 / responseStudent.length).toFixed(1));
          this.TotalCat8 = Number((this.TotalCat8 / responseStudent.length).toFixed(1));
          this.TotalCat9 = Number((this.TotalCat9 / responseStudent.length).toFixed(1));

          this.dataRadar.datasets[1].data = [this.TotalCat1/10, this.TotalCat2/10, this.TotalCat3/10, this.TotalCat4/10, this.TotalCat5/10, this.TotalCat6/10, this.TotalCat7/10, this.TotalCat8/10, this.TotalCat9/10]
          //this.ChartRadar.removeData();
          // this.ChartRadar.addData(this.dataRadar.datasets[0].data);
          console.log(this.idProfileCV)
          console.log(this.dataRadar.datasets[1].data)
          this.ChartRadar.update();
        })

        //consulto las calificaciones del docente
        this.evaluationsservice.getSelfsApprBySubjectGroup(this.idProfileCV).subscribe(responseSelf => {
          console.log(responseSelf)
          this.TotalCat1 = 0
          this.TotalCat2 = 0
          this.TotalCat3 = 0
          this.TotalCat4 = 0
          this.TotalCat5 = 0
          this.TotalCat6 = 0
          this.TotalCat7 = 0
          this.TotalCat8 = 0
          this.TotalCat9 = 0
          this.TotalSelf = responseSelf.length
          console.log("Total Self", this.TotalSelf);
          
          //CALCULAR EL PROMEDIO DE TODAS LAS NOTAS
          for (var i = 0; i < responseSelf.length; i++) {

            this.TotalCat1 += responseSelf[i].TotalCat1
            this.TotalCat2 += responseSelf[i].TotalCat2
            this.TotalCat3 += responseSelf[i].TotalCat3
            this.TotalCat4 += responseSelf[i].TotalCat4
            this.TotalCat5 += responseSelf[i].TotalCat5
            this.TotalCat6 += responseSelf[i].TotalCat6
            this.TotalCat7 += responseSelf[i].TotalCat7
            this.TotalCat8 += responseSelf[i].TotalCat8
            this.TotalCat9 += responseSelf[i].TotalCat9
          }

          this.TotalCat1 = Number((this.TotalCat1 / responseSelf.length).toFixed(1));
          this.TotalCat2 = Number((this.TotalCat2 / responseSelf.length).toFixed(1));
          this.TotalCat3 = Number((this.TotalCat3 / responseSelf.length).toFixed(1));
          this.TotalCat4 = Number((this.TotalCat4 / responseSelf.length).toFixed(1));
          this.TotalCat5 = Number((this.TotalCat5 / responseSelf.length).toFixed(1));
          this.TotalCat6 = Number((this.TotalCat6 / responseSelf.length).toFixed(1));
          this.TotalCat7 = Number((this.TotalCat7 / responseSelf.length).toFixed(1));
          this.TotalCat8 = Number((this.TotalCat8 / responseSelf.length).toFixed(1));
          this.TotalCat9 = Number((this.TotalCat9 / responseSelf.length).toFixed(1));

          this.dataRadar.datasets[2].data = [this.TotalCat1/10, this.TotalCat2/10, this.TotalCat3/10, this.TotalCat4/10, this.TotalCat5/10, this.TotalCat6/10, this.TotalCat7/10, this.TotalCat8/10, this.TotalCat9/10]
          //this.ChartRadar.removeData();
          // this.ChartRadar.addData(this.dataRadar.datasets[0].data);
          console.log(this.dataRadar.datasets[2].data)
          this.ChartRadar.update();
        })

      })
    }
  }

  ngAfterViewInit() {
  }
}