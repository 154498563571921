import { Component } from '@angular/core';
import { Course } from '../../../../models/Coursesinfo/course';
import { School } from '../../../../models/Coursesinfo/school';
import { CoursesService } from '../../../../models/Coursesinfo/courses.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../../../models/Auth/auth.service';
import { ProfileNavComponent } from '../../../../components/profile-nav/profile-nav.component';
import { EvaluationsService } from '../../../../models/evaluation/evaluations.service';
import { AngularCsv } from 'angular7-csv';
import { TeacherView } from 'app/models/evaluation/teacher.view';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { environment } from 'environments/environment';

declare var $: any;


@Component({
  selector: 'TeacherView',
  templateUrl: './teacher-view.component.html',
  styleUrls: ['./teacher-view.component.css']

})
export class TeacherViewComponent {

  customOptions: OwlOptions = {
    loop: true,
    navSpeed: 700,
    nav: false,
    dots: false,
    margin: 10,
    responsive: {
      0: {
        items: 3
      },
      400: {
        items: 6
      },
      740: {
        items: 10
      },
      940: {
        items: 13
      }
    }
  }


  totalStudent: number;
  coursesbyschool: Course[];
  schools: School[] = [];
  SchoolID: number;
  serverTime: string
  user: any
  isResgistre = false
  isJefe = false
  isCalidad = false
  schoolID: number = 0;
  isDataReady = false;
  isSchoolReady = false;
  courseOferID: number = 0
  courseOferSelectID: number = 0
  //isDataReady = false;
  subjectByCourses: any
  courseID: number = 0
  subjectID: number = 0
  teacherViews: any
  teacherViewDelegates: any
  teacherViewSelfs: any
  listTeacher: any
  TotalF: number = 0
  TotalFC: number = 0
  nameSubject: string

  courses: Course = {

    CourseOferID: 0,
    SubjectID:0
  }
  showdata: boolean = false;
  subjects: any[] = []
  isActive: boolean = null;
  public teacherViewsComments: TeacherView[]=[];

  public SCHOOL_LOGO_DYNAMIC_IMG:any = (id:number) => environment.SCHOOL_LOGO_DYNAMIC_IMG(id);


  constructor(
    private coursesService: CoursesService,
    private router: Router,
    private authService: AuthService,
    public profileNavComponent: ProfileNavComponent,
    public evaluationService: EvaluationsService
  ) {
    //GUARDO LA HORA DEL SERVIDOR
    this.authService.getTime().then(server2 => this.serverTime = server2.time);


    this.coursesService.getAllSchools().subscribe(p => this.schools = p);


    if (this.authService.getCurrentUser() == null) {
      this.router.navigate(['/register']);
    } else {
      //DE LO CONTRARO CARGAR EL ID DEL USUARIO
      this.user = this.authService.getCurrentUser(); //devuelve el id del usuario
      //SI EL ID DEL USUARIO ES UN NUMERO ENTERO
      if (Number.isInteger(parseInt(this.user))) {
        //CONSULTO EL PERFIL DEL USUARIO
        var validar_usuario = [false];

        for (let role of this.profileNavComponent.roles) {
          if (role.name == "General") {
            validar_usuario = validar_usuario.concat([true])
            this.SchoolID = role.SchoolID;
          }
          if (role.name == "Registro y Control") {

            this.isResgistre = true
            validar_usuario = validar_usuario.concat([true])
            this.SchoolID = role.SchoolID;
          }
          if (role.name == "Jefe") {

            this.isJefe = true
            validar_usuario = validar_usuario.concat([true])
            this.SchoolID = role.SchoolID;
          }
          if (role.name == "Calidad") {

            this.isCalidad = true
            validar_usuario = validar_usuario.concat([true])
            this.SchoolID = role.SchoolID;
          }


          if (role.name == "Inspector Escuela") {
            validar_usuario = validar_usuario.concat([true])
            this.SchoolID = role.SchoolID;
            break
          }



        }

        if (!validar_usuario.includes(true)) { this.router.navigate(['/']); }

        this.coursesService.getAllSchoolByDepend(this.SchoolID).subscribe(depend => {
          if (depend.length > 0) {
            this.schools = depend

            this.isSchoolReady = true
          } else {

          }
        })



      }

      this.evaluationService.getTeacherViewBySchool(this.SchoolID).subscribe(p => {
        console.log("View Teache", p)
      })



    }


  }


  setNewSchool(id) {
    this.schoolID = id
    this.isDataReady = true

    //LLAMO LOS CURSOS
    this.coursesService.getAllCoursesBySchool(this.schoolID).subscribe(p => {
      this.coursesbyschool = p.sort(function (a, b) {
        // Ascending: first age less than the previous
        return a["NameCourseOfer"].localeCompare(b["NameCourseOfer"]);
      });

      console.debug(this.coursesbyschool)
    });

  }


  ngOnInit() {

  }

  ngOnDestroy() {

  }
  colorSchool(idSchool: number) {
    let classes = {};

    switch (idSchool) {
      case 0: classes = { cat__info__item_ALL: true }; break;
      case 1: classes = { cat__info__item_CEMIL: true }; break;
      case 4: classes = { cat__info__item_EAS: true }; break;
      case 5: classes = { cat__info__item_ESINF: true }; break;
      case 6: classes = { cat__info__item_ESCAB: true }; break;
      case 7: classes = { cat__info__item_ESART: true }; break;
      case 8: classes = { cat__info__item_ESING: true }; break;
      case 9: classes = { cat__info__item_ESCOM: true }; break;
      case 10: classes = { cat__info__item_ESICI: true }; break;
      case 16: classes = { cat__info__item_ESAVE: true }; break;
      case 17: classes = { cat__info__item_ESLOG: true }; break;
      case 18: classes = { cat__info__item_ESCEQ: true }; break;
      case 19: classes = { cat__info__item_ESPOM: true }; break;
      case 20: classes = { cat__info__item_ESMAI: true }; break;
      case 21: classes = { cat__info__item_ESDIH: true }; break;
      case 22: classes = { cat__info__item_CEMAI: true }; break;
      case 23: classes = { cat__info__item_ESIDE: true }; break;
      case 24: classes = { cat__info__item_ESFES: true }; break;
      default: classes = { cat__info__item_ALL: true }; break;


    }
    return classes;
  }

  textSchools(id: number) {
    var num = this.schools.find(x => x.id == id);
    if (num)
      return num.NameTSchool;
    return "Nombre de Escuela"
  }

  callAllSubjectByCourse(model) {
    this.courseOferID = model.CourseOfer.CourseID
    this.courseOferSelectID = model.CourseOfer.id
    
    console.log("model", model)

    this.coursesService.getAllSubjectsByCourse(this.courseOferID).subscribe(subject => {
      this.subjectByCourses = subject.sort(function (a, b) {
        // Ascending: first age less than the previous
        return a["NameSubject"].localeCompare(b["NameSubject"]);
      });


      console.log("materias", subject)
    })
  }
  callTeacherView(model) {
    this.subjectID = model.SubjectID
    this.subjects = []
    this.showdata = true

    console.log("Modelo View", model)
    // HETERO EVALUACION
    this.evaluationService.getTeacherViewBySchoolAndSubjectID(this.subjectID, this.courseOferSelectID).subscribe(teachers => {
      this.teacherViews = [];
      this.teacherViewsComments = teachers;
      this.TotalF = 0
      this.listTeacher = teachers



      //PRIMERO CREAR LA LISTA DE DOCENTES
      teachers.forEach((element, index) => {
        var position = -1
        position = this.teacherViews.findIndex(x => x.UserID === element.UserID)
        if (position == -1) {
          this.teacherViews.push({

            LastNames: element.LastNames,
            Names: element.Names,
            NameSubject: element.NameSubject,
            NameCourseOfer: element.NameCourseOFer,
            Preg1: element.Preg1,
            Preg2: element.Preg2,
            Preg3: element.Preg3,
            Preg4: element.Preg4,
            Preg5: element.Preg5,
            Preg6: element.Preg6,
            Preg7: element.Preg7,
            Preg8: element.Preg8,
            Preg9: element.Preg9,
            Preg10: element.Preg10,
            Preg11: element.Preg11,
            Preg12: element.Preg12,
            Preg13: element.Preg13,
            Preg14: element.Preg14,
            Preg15: element.Preg15,
            Preg16: element.Preg16,
            Preg17: element.Preg17,
            Preg18: element.Preg18,
            TotalCat1: element.TotalCat1,
            TotalCat2: element.TotalCat2,
            TotalCat3: element.TotalCat3,
            TotalCat4: element.TotalCat4,
            TotalCat5: element.TotalCat5,
            TotalCat6: element.TotalCat6,
            TotalCat7: element.TotalCat7,
            TotalCat8: element.TotalCat8,
            TotalCat9: element.TotalCat9,
            UserID: element.UserID,
            Total: 1
          })
        } else {
          this.teacherViews[position].Preg1 += element.Preg1,
            this.teacherViews[position].Preg2 += element.Preg2,
            this.teacherViews[position].Preg3 += element.Preg3,
            this.teacherViews[position].Preg4 += element.Preg4,
            this.teacherViews[position].Preg5 += element.Preg5,
            this.teacherViews[position].Preg6 += element.Preg6,
            this.teacherViews[position].Preg7 += element.Preg7,
            this.teacherViews[position].Preg8 += element.Preg8,
            this.teacherViews[position].Preg9 += element.Preg9,
            this.teacherViews[position].Preg10 += element.Preg10,
            this.teacherViews[position].Preg11 += element.Preg11,
            this.teacherViews[position].Preg12 += element.Preg12,
            this.teacherViews[position].Preg13 += element.Preg13,
            this.teacherViews[position].Preg14 += element.Preg14,
            this.teacherViews[position].Preg15 += element.Preg15,
            this.teacherViews[position].Preg16 += element.Preg16,
            this.teacherViews[position].Preg17 += element.Preg17,
            this.teacherViews[position].Preg18 += element.Preg18,
            this.teacherViews[position].TotalCat1 += element.TotalCat1,
            this.teacherViews[position].TotalCat2 += element.TotalCat2,
            this.teacherViews[position].TotalCat3 += element.TotalCat3,
            this.teacherViews[position].TotalCat4 += element.TotalCat4,
            this.teacherViews[position].TotalCat5 += element.TotalCat5,
            this.teacherViews[position].TotalCat6 += element.TotalCat6,
            this.teacherViews[position].TotalCat7 += element.TotalCat7,
            this.teacherViews[position].TotalCat8 += element.TotalCat8,
            this.teacherViews[position].TotalCat9 += element.TotalCat9,
            this.teacherViews[position].Total += 1
        }

      })

      this.teacherViews.forEach((element, index) => {
        element.Preg1 = Number((element.Preg1 / element.Total).toFixed(1)),
          element.Preg2 = Number((element.Preg2 / element.Total).toFixed(1)),
          element.Preg3 = Number((element.Preg3 / element.Total).toFixed(1)),
          element.Preg4 = Number((element.Preg4 / element.Total).toFixed(1)),
          element.Preg5 = Number((element.Preg5 / element.Total).toFixed(1)),
          element.Preg6 = Number((element.Preg6 / element.Total).toFixed(1)),
          element.Preg7 = Number((element.Preg7 / element.Total).toFixed(1)),
          element.Preg8 = Number((element.Preg8 / element.Total).toFixed(1)),
          element.Preg9 = Number((element.Preg9 / element.Total).toFixed(1)),
          element.Preg10 = Number((element.Preg10 / element.Total).toFixed(1)),
          element.Preg11 = Number((element.Preg11 / element.Total).toFixed(1)),
          element.Preg12 = Number((element.Preg12 / element.Total).toFixed(1)),
          element.Preg13 = Number((element.Preg13 / element.Total).toFixed(1)),
          element.Preg14 = Number((element.Preg14 / element.Total).toFixed(1)),
          element.Preg15 = Number((element.Preg15 / element.Total).toFixed(1)),
          element.Preg16 = Number((element.Preg16 / element.Total).toFixed(1)),
          element.Preg17 = Number((element.Preg17 / element.Total).toFixed(1)),
          element.Preg18 = Number((element.Preg18 / element.Total).toFixed(1)),
          element.TotalCat1 = Number((element.TotalCat1 / element.Total).toFixed(1)),
          element.TotalCat2 = Number((element.TotalCat2 / element.Total).toFixed(1)),
          element.TotalCat3 = Number((element.TotalCat3 / element.Total).toFixed(1)),
          element.TotalCat4 = Number((element.TotalCat4 / element.Total).toFixed(1)),
          element.TotalCat5 = Number((element.TotalCat5 / element.Total).toFixed(1)),
          element.TotalCat6 = Number((element.TotalCat6 / element.Total).toFixed(1)),
          element.TotalCat7 = Number((element.TotalCat7 / element.Total).toFixed(1)),
          element.TotalCat8 = Number((element.TotalCat8 / element.Total).toFixed(1)),
          element.TotalCat9 = Number((element.TotalCat9 / element.Total).toFixed(1)),
          this.TotalF += element.Total

      })




      console.log("Subject view", teachers)


    })

    //CO - EVALUACIÓN
    let filter = `{"where": {"SubjectID": ${this.subjectID}, "CourseOferID": ${this.courseOferSelectID}}} `
    this.evaluationService.getTeacherViewDelegateBySchool(this.subjectID,filter).subscribe(delegates => {
      this.teacherViewDelegates = []
      this.TotalFC = 0

      //PRIMERO CREAR LA LISTA DE DOCENTES
      delegates.forEach((element, index) => {
        var position = -1
        position = this.teacherViewDelegates.findIndex(x => x.UserID === element.UserID)
        if (position == -1) {
          this.teacherViewDelegates.push({

            LastNames: element.LastNames,
            Names: element.Names,
            NameSubject: element.NameSubject,
            NameCourseOfer: element.NameCourseOFer,
            Preg1: element.Preg1,
            Preg2: element.Preg2,
            Preg3: element.Preg3,
            Preg4: element.Preg4,
            Preg5: element.Preg5,
            Preg6: element.Preg6,
            Preg7: element.Preg7,
            Preg8: element.Preg8,
            Preg9: element.Preg9,
            Preg10: element.Preg10,
            Preg11: element.Preg11,
            Preg12: element.Preg12,
            Preg13: element.Preg13,
            Preg14: element.Preg14,
            Preg15: element.Preg15,
            Preg16: element.Preg16,
            Preg17: element.Preg17,
            Preg18: element.Preg18,
            TotalCat1: element.TotalCat1,
            TotalCat2: element.TotalCat2,
            TotalCat3: element.TotalCat3,
            TotalCat4: element.TotalCat4,
            TotalCat5: element.TotalCat5,
            TotalCat6: element.TotalCat6,
            TotalCat7: element.TotalCat7,
            TotalCat8: element.TotalCat8,
            TotalCat9: element.TotalCat9,
            UserID: element.UserID,
            Total: 1
          })
        } else {
          this.teacherViewDelegates[position].Preg1 += element.Preg1,
            this.teacherViewDelegates[position].Preg2 += element.Preg2,
            this.teacherViewDelegates[position].Preg3 += element.Preg3,
            this.teacherViewDelegates[position].Preg4 += element.Preg4,
            this.teacherViewDelegates[position].Preg5 += element.Preg5,
            this.teacherViewDelegates[position].Preg6 += element.Preg6,
            this.teacherViewDelegates[position].Preg7 += element.Preg7,
            this.teacherViewDelegates[position].Preg8 += element.Preg8,
            this.teacherViewDelegates[position].Preg9 += element.Preg9,
            this.teacherViewDelegates[position].Preg10 += element.Preg10,
            this.teacherViewDelegates[position].Preg11 += element.Preg11,
            this.teacherViewDelegates[position].Preg12 += element.Preg12,
            this.teacherViewDelegates[position].Preg13 += element.Preg13,
            this.teacherViewDelegates[position].Preg14 += element.Preg14,
            this.teacherViewDelegates[position].Preg15 += element.Preg15,
            this.teacherViewDelegates[position].Preg16 += element.Preg16,
            this.teacherViewDelegates[position].Preg17 += element.Preg17,
            this.teacherViewDelegates[position].Preg18 += element.Preg18,
            this.teacherViewDelegates[position].TotalCat1 += element.TotalCat1,
            this.teacherViewDelegates[position].TotalCat2 += element.TotalCat2,
            this.teacherViewDelegates[position].TotalCat3 += element.TotalCat3,
            this.teacherViewDelegates[position].TotalCat4 += element.TotalCat4,
            this.teacherViewDelegates[position].TotalCat5 += element.TotalCat5,
            this.teacherViewDelegates[position].TotalCat6 += element.TotalCat6,
            this.teacherViewDelegates[position].TotalCat7 += element.TotalCat7,
            this.teacherViewDelegates[position].TotalCat8 += element.TotalCat8,
            this.teacherViewDelegates[position].TotalCat9 += element.TotalCat9,
            this.teacherViewDelegates[position].Total += 1
        }

      })

      this.teacherViewDelegates.forEach((element, index) => {
        element.Preg1 = Number((element.Preg1 / element.Total).toFixed(1)),
          element.Preg2 = Number((element.Preg2 / element.Total).toFixed(1)),
          element.Preg3 = Number((element.Preg3 / element.Total).toFixed(1)),
          element.Preg4 = Number((element.Preg4 / element.Total).toFixed(1)),
          element.Preg5 = Number((element.Preg5 / element.Total).toFixed(1)),
          element.Preg6 = Number((element.Preg6 / element.Total).toFixed(1)),
          element.Preg7 = Number((element.Preg7 / element.Total).toFixed(1)),
          element.Preg8 = Number((element.Preg8 / element.Total).toFixed(1)),
          element.Preg9 = Number((element.Preg9 / element.Total).toFixed(1)),
          element.Preg10 = Number((element.Preg10 / element.Total).toFixed(1)),
          element.Preg11 = Number((element.Preg11 / element.Total).toFixed(1)),
          element.Preg12 = Number((element.Preg12 / element.Total).toFixed(1)),
          element.Preg13 = Number((element.Preg13 / element.Total).toFixed(1)),
          element.Preg14 = Number((element.Preg14 / element.Total).toFixed(1)),
          element.Preg15 = Number((element.Preg15 / element.Total).toFixed(1)),
          element.Preg16 = Number((element.Preg16 / element.Total).toFixed(1)),
          element.Preg17 = Number((element.Preg17 / element.Total).toFixed(1)),
          element.Preg18 = Number((element.Preg18 / element.Total).toFixed(1)),
          element.TotalCat1 = Number((element.TotalCat1 / element.Total).toFixed(1)),
          element.TotalCat2 = Number((element.TotalCat2 / element.Total).toFixed(1)),
          element.TotalCat3 = Number((element.TotalCat3 / element.Total).toFixed(1)),
          element.TotalCat4 = Number((element.TotalCat4 / element.Total).toFixed(1)),
          element.TotalCat5 = Number((element.TotalCat5 / element.Total).toFixed(1)),
          element.TotalCat6 = Number((element.TotalCat6 / element.Total).toFixed(1)),
          element.TotalCat7 = Number((element.TotalCat7 / element.Total).toFixed(1)),
          element.TotalCat8 = Number((element.TotalCat8 / element.Total).toFixed(1)),
          element.TotalCat9 = Number((element.TotalCat9 / element.Total).toFixed(1)),
          this.TotalFC += element.Total

      })

      console.log("Delegados", delegates);

    })

    this.evaluationService.getTeacherViewSelfBySchool(this.subjectID,filter).subscribe(selfs => {
      this.teacherViewSelfs = []

      //PRIMERO CREAR LA LISTA DE DOCENTES
      selfs.forEach((element, index) => {
        var position = -1
        position = this.teacherViewSelfs.findIndex(x => x.UserID === element.UserID)
        if (position == -1) {
          this.teacherViewSelfs.push({

            LastNames: element.LastNames,
            Names: element.Names,
            NameSubject: element.NameSubject,
            NameCourseOfer: element.NameCourseOFer,
            Preg1: element.Preg1,
            Preg2: element.Preg2,
            Preg3: element.Preg3,
            Preg4: element.Preg4,
            Preg5: element.Preg5,
            Preg6: element.Preg6,
            Preg7: element.Preg7,
            Preg8: element.Preg8,
            Preg9: element.Preg9,
            Preg10: element.Preg10,
            Preg11: element.Preg11,
            Preg12: element.Preg12,
            Preg13: element.Preg13,
            Preg14: element.Preg14,
            Preg15: element.Preg15,
            Preg16: element.Preg16,
            Preg17: element.Preg17,
            Preg18: element.Preg18,
            TotalCat1: element.TotalCat1,
            TotalCat2: element.TotalCat2,
            TotalCat3: element.TotalCat3,
            TotalCat4: element.TotalCat4,
            TotalCat5: element.TotalCat5,
            TotalCat6: element.TotalCat6,
            TotalCat7: element.TotalCat7,
            TotalCat8: element.TotalCat8,
            TotalCat9: element.TotalCat9,
            UserID: element.UserID,
            Total: 1
          })
        } else {
          this.teacherViewSelfs[position].Preg1 += element.Preg1,
            this.teacherViewSelfs[position].Preg2 += element.Preg2,
            this.teacherViewSelfs[position].Preg3 += element.Preg3,
            this.teacherViewSelfs[position].Preg4 += element.Preg4,
            this.teacherViewSelfs[position].Preg5 += element.Preg5,
            this.teacherViewSelfs[position].Preg6 += element.Preg6,
            this.teacherViewSelfs[position].Preg7 += element.Preg7,
            this.teacherViewSelfs[position].Preg8 += element.Preg8,
            this.teacherViewSelfs[position].Preg9 += element.Preg9,
            this.teacherViewSelfs[position].Preg10 += element.Preg10,
            this.teacherViewSelfs[position].Preg11 += element.Preg11,
            this.teacherViewSelfs[position].Preg12 += element.Preg12,
            this.teacherViewSelfs[position].Preg13 += element.Preg13,
            this.teacherViewSelfs[position].Preg14 += element.Preg14,
            this.teacherViewSelfs[position].Preg15 += element.Preg15,
            this.teacherViewSelfs[position].Preg16 += element.Preg16,
            this.teacherViewSelfs[position].Preg17 += element.Preg17,
            this.teacherViewSelfs[position].Preg18 += element.Preg18,
            this.teacherViewSelfs[position].TotalCat1 += element.TotalCat1,
            this.teacherViewSelfs[position].TotalCat2 += element.TotalCat2,
            this.teacherViewSelfs[position].TotalCat3 += element.TotalCat3,
            this.teacherViewSelfs[position].TotalCat4 += element.TotalCat4,
            this.teacherViewSelfs[position].TotalCat5 += element.TotalCat5,
            this.teacherViewSelfs[position].TotalCat6 += element.TotalCat6,
            this.teacherViewSelfs[position].TotalCat7 += element.TotalCat7,
            this.teacherViewSelfs[position].TotalCat8 += element.TotalCat8,
            this.teacherViewSelfs[position].TotalCat9 += element.TotalCat9,
            this.teacherViewSelfs[position].Total += 1
        }

      })

      this.teacherViewSelfs.forEach((element, index) => {
        element.Preg1 = Number((element.Preg1 / element.Total).toFixed(1)),
          element.Preg2 = Number((element.Preg2 / element.Total).toFixed(1)),
          element.Preg3 = Number((element.Preg3 / element.Total).toFixed(1)),
          element.Preg4 = Number((element.Preg4 / element.Total).toFixed(1)),
          element.Preg5 = Number((element.Preg5 / element.Total).toFixed(1)),
          element.Preg6 = Number((element.Preg6 / element.Total).toFixed(1)),
          element.Preg7 = Number((element.Preg7 / element.Total).toFixed(1)),
          element.Preg8 = Number((element.Preg8 / element.Total).toFixed(1)),
          element.Preg9 = Number((element.Preg9 / element.Total).toFixed(1)),
          element.Preg10 = Number((element.Preg10 / element.Total).toFixed(1)),
          element.Preg11 = Number((element.Preg11 / element.Total).toFixed(1)),
          element.Preg12 = Number((element.Preg12 / element.Total).toFixed(1)),
          element.Preg13 = Number((element.Preg13 / element.Total).toFixed(1)),
          element.Preg14 = Number((element.Preg14 / element.Total).toFixed(1)),
          element.Preg15 = Number((element.Preg15 / element.Total).toFixed(1)),
          element.Preg16 = Number((element.Preg16 / element.Total).toFixed(1)),
          element.Preg17 = Number((element.Preg17 / element.Total).toFixed(1)),
          element.Preg18 = Number((element.Preg18 / element.Total).toFixed(1)),
          element.TotalCat1 = Number((element.TotalCat1 / element.Total).toFixed(1)),
          element.TotalCat2 = Number((element.TotalCat2 / element.Total).toFixed(1)),
          element.TotalCat3 = Number((element.TotalCat3 / element.Total).toFixed(1)),
          element.TotalCat4 = Number((element.TotalCat4 / element.Total).toFixed(1)),
          element.TotalCat5 = Number((element.TotalCat5 / element.Total).toFixed(1)),
          element.TotalCat6 = Number((element.TotalCat6 / element.Total).toFixed(1)),
          element.TotalCat7 = Number((element.TotalCat7 / element.Total).toFixed(1)),
          element.TotalCat8 = Number((element.TotalCat8 / element.Total).toFixed(1)),
          element.TotalCat9 = Number((element.TotalCat9 / element.Total).toFixed(1))
      })

      console.log("Delegados", selfs);

    })

  }

  ExportListStudents() {
    var Exporar = [];

    Exporar.push({
      "NameCourseOfer": "Nombre de la Oferta",
      "NameSubject": "Nombre de la Materia",
      "LastNames": "APELLIDOS",
      "Names": "NOMBRES",
      "Preg1": "Preg1",
      "Preg2": "Preg2",
      "TotalCat1": "TotalCat1",
      "Preg3": "Preg3",
      "Preg4": "Preg4",
      "TotalCat2": "TotalCat2",
      "Preg5": "Preg5",
      "Preg6": "Preg6",
      "TotalCat3": "TotalCat3",
      "Preg7": "Preg7",
      "Preg8": "Preg8",
      "TotalCat4": "TotalCat4",
      "Preg9": "Preg9",
      "Preg10": "Preg10",
      "TotalCat5": "TotalCat5",
      "Preg11": "Preg11",
      "Preg12": "Preg12",
      "Preg13": "Preg13",
      "TotalCat6": "TotalCat6",
      "Preg14": "Preg14",
      "TotalCat7": "TotalCat7",
      "Preg15": "Preg15",
      "Preg16": "Preg16",
      "TotalCat8": "TotalCat8",
      "Preg17": "Preg17",
      "Preg18": "Preg18",
      "TotalCat9": "TotalCat9",
      "Total": "Número de Estudiantes",
    })
    this.teacherViews.forEach((element, index) => {
      Exporar.push({
        "NameCourseOfer": element.NameCourseOfer,
        "NameSubject": element.NameSubject,
        "LastNames": element.LastNames.toUpperCase(),
        "Names": element.Names.toUpperCase(),
        "Preg1": element.Preg1,
        "Preg2": element.Preg2,
        "TotalCat1": element.TotalCat1,
        "Preg3": element.Preg3,
        "Preg4": element.Preg4,
        "TotalCat2": element.TotalCat2,
        "Preg5": element.Preg5,
        "Preg6": element.Preg6,
        "TotalCat3": element.TotalCat3,
        "Preg7": element.Preg7,
        "Preg8": element.Preg8,
        "TotalCat4": element.TotalCat4,
        "Preg9": element.Preg9,
        "Preg10": element.Preg10,
        "TotalCat5": element.TotalCat5,
        "Preg11": element.Preg11,
        "Preg12": element.Preg12,
        "Preg13": element.Preg13,
        "TotalCat6": element.TotalCat6,
        "Preg14": element.Preg14,
        "TotalCat7": element.TotalCat7,
        "Preg15": element.Preg15,
        "Preg16": element.Preg16,
        "TotalCat8": element.TotalCat8,
        "Preg17": element.Preg17,
        "Preg18": element.Preg18,
        "TotalCat9": element.TotalCat9,
        "Total": element.Total
      })
    });
    new AngularCsv(Exporar, 'Calificación Estudiantes');
  }

  ExportListStudentsComments():void{

    let Exporar = [];

    Exporar.push({
      "NameCourseOfer": "Nombre de la Oferta",
      "NameSubject": "Nombre de la Materia",
      "LastNames": "APELLIDOS",
      "Names": "NOMBRES",
      "Preg1": "Preg1",
      "Preg2": "Preg2",
      "ObsCat1": "ObsCat1",
      "TotalCat1": "TotalCat1",
      "Preg3": "Preg3",
      "Preg4": "Preg4",
      "ObsCat2": "ObsCat2",
      "TotalCat2": "TotalCat2",
      "Preg5": "Preg5",
      "Preg6": "Preg6",
      "ObsCat3": "ObsCat3",
      "TotalCat3": "TotalCat3",
      "Preg7": "Preg7",
      "Preg8": "Preg8",
      "ObsCat4": "ObsCat4",
      "TotalCat4": "TotalCat4",
      "Preg9": "Preg9",
      "Preg10": "Preg10",
      "ObsCat5": "ObsCat5",
      "TotalCat5": "TotalCat5",
      "Preg11": "Preg11",
      "Preg12": "Preg12",
      "Preg13": "Preg13",
      "ObsCat6": "ObsCat6",
      "TotalCat6": "TotalCat6",
      "Preg14": "Preg14",
      "ObsCat7": "ObsCat7",
      "TotalCat7": "TotalCat7",
      "Preg15": "Preg15",
      "Preg16": "Preg16",
      "ObsCat8": "ObsCat8",
      "TotalCat8": "TotalCat8",
      "Preg17": "Preg17",
      "Preg18": "Preg18",
      "ObsCat9": "ObsCat9",
      "TotalCat9": "TotalCat9",
      
    })
    this.teacherViewsComments.forEach((element, index) => {
      Exporar.push({
        "NameCourseOfer": element.NameCourseOFer,
        "NameSubject": element.NameSubject,
        "LastNames": element.LastNames.toUpperCase(),
        "Names": element.Names.toUpperCase(),
        "Preg1": element.Preg1,
        "Preg2": element.Preg2,
        "ObsCat1": element.ObsCat1,
        "TotalCat1": element.TotalCat1,
        "Preg3": element.Preg3,
        "Preg4": element.Preg4,
        "ObsCat2": element.ObsCat2,
        "TotalCat2": element.TotalCat2,
        "Preg5": element.Preg5,
        "Preg6": element.Preg6,
        "ObsCat3": element.ObsCat3,
        "TotalCat3": element.TotalCat3,
        "Preg7": element.Preg7,
        "Preg8": element.Preg8,
        "ObsCat4": element.ObsCat4,
        "TotalCat4": element.TotalCat4,
        "Preg9": element.Preg9,
        "Preg10": element.Preg10,
        "ObsCat5": element.ObsCat1,
        "TotalCat5": element.TotalCat5,
        "Preg11": element.Preg11,
        "Preg12": element.Preg12,
        "Preg13": element.Preg13,
        "ObsCat6": element.ObsCat1,
        "TotalCat6": element.TotalCat6,
        "Preg14": element.Preg14,
        "ObsCat7": element.ObsCat7,
        "TotalCat7": element.TotalCat7,
        "Preg15": element.Preg15,
        "Preg16": element.Preg16,
        "ObsCat8": element.ObsCat8,
        "TotalCat8": element.TotalCat8,
        "Preg17": element.Preg17,
        "Preg18": element.Preg18,
        "ObsCat9": element.ObsCat9,
        "TotalCat9": element.TotalCat9,
      })
    });
    new AngularCsv(Exporar, 'Comentarios Estudiantes');
  }

  ExportListDelegates() {
    var Exporar = [];

    Exporar.push({
      "NameCourseOfer": "Nombre de la Oferta",
      "NameSubject": "Nombre de la Materia",
      "LastNames": "APELLIDOS",
      "Names": "NOMBRES",
      "Preg1": "Preg1",
      "Preg2": "Preg2",
      "TotalCat1": "TotalCat1",
      "Preg3": "Preg3",
      "Preg4": "Preg4",
      "TotalCat2": "TotalCat2",
      "Preg5": "Preg5",
      "Preg6": "Preg6",
      "TotalCat3": "TotalCat3",
      "Preg7": "Preg7",
      "Preg8": "Preg8",
      "TotalCat4": "TotalCat4",
      "Preg9": "Preg9",
      "Preg10": "Preg10",
      "TotalCat5": "TotalCat5",
      "Preg11": "Preg11",
      "Preg12": "Preg12",
      "Preg13": "Preg13",
      "TotalCat6": "TotalCat6",
      "Preg14": "Preg14",
      "TotalCat7": "TotalCat7",
      "Preg15": "Preg15",
      "Preg16": "Preg16",
      "TotalCat8": "TotalCat8",
      "Preg17": "Preg17",
      "Preg18": "Preg18",
      "TotalCat9": "TotalCat9",
      "Total": "Número de Estudiantes",
    })
    this.teacherViewDelegates.forEach((element, index) => {
      Exporar.push({
        "NameCourseOfer": element.NameCourseOfer,
        "NameSubject": element.NameSubject,
        "LastNames": element.LastNames.toUpperCase(),
        "Names": element.Names.toUpperCase(),
        "Preg1": element.Preg1,
        "Preg2": element.Preg2,
        "TotalCat1": element.TotalCat1,
        "Preg3": element.Preg3,
        "Preg4": element.Preg4,
        "TotalCat2": element.TotalCat2,
        "Preg5": element.Preg5,
        "Preg6": element.Preg6,
        "TotalCat3": element.TotalCat3,
        "Preg7": element.Preg7,
        "Preg8": element.Preg8,
        "TotalCat4": element.TotalCat4,
        "Preg9": element.Preg9,
        "Preg10": element.Preg10,
        "TotalCat5": element.TotalCat5,
        "Preg11": element.Preg11,
        "Preg12": element.Preg12,
        "Preg13": element.Preg13,
        "TotalCat6": element.TotalCat6,
        "Preg14": element.Preg14,
        "TotalCat7": element.TotalCat7,
        "Preg15": element.Preg15,
        "Preg16": element.Preg16,
        "TotalCat8": element.TotalCat8,
        "Preg17": element.Preg17,
        "Preg18": element.Preg18,
        "TotalCat9": element.TotalCat9,
        "Total": element.Total
      })
    });
    new AngularCsv(Exporar, 'Calificación Calidad');
  }


  ExportListSelfs() {
    var Exporar = [];

    Exporar.push({
      "NameCourseOfer": "Nombre de la Oferta",
      "NameSubject": "Nombre de la Materia",
      "LastNames": "APELLIDOS",
      "Names": "NOMBRES",
      "Preg1": "Preg1",
      "Preg2": "Preg2",
      "TotalCat1": "TotalCat1",
      "Preg3": "Preg3",
      "Preg4": "Preg4",
      "TotalCat2": "TotalCat2",
      "Preg5": "Preg5",
      "Preg6": "Preg6",
      "TotalCat3": "TotalCat3",
      "Preg7": "Preg7",
      "Preg8": "Preg8",
      "TotalCat4": "TotalCat4",
      "Preg9": "Preg9",
      "Preg10": "Preg10",
      "TotalCat5": "TotalCat5",
      "Preg11": "Preg11",
      "Preg12": "Preg12",
      "Preg13": "Preg13",
      "TotalCat6": "TotalCat6",
      "Preg14": "Preg14",
      "TotalCat7": "TotalCat7",
      "Preg15": "Preg15",
      "Preg16": "Preg16",
      "TotalCat8": "TotalCat8",
      "Preg17": "Preg17",
      "Preg18": "Preg18",
      "TotalCat9": "TotalCat9",
      "Total": "Número de Estudiantes",
    })
    this.teacherViewSelfs.forEach((element, index) => {
      Exporar.push({
        "NameCourseOfer": element.NameCourseOfer,
        "NameSubject": element.NameSubject,
        "LastNames": element.LastNames.toUpperCase(),
        "Names": element.Names.toUpperCase(),
        "Preg1": element.Preg1,
        "Preg2": element.Preg2,
        "TotalCat1": element.TotalCat1,
        "Preg3": element.Preg3,
        "Preg4": element.Preg4,
        "TotalCat2": element.TotalCat2,
        "Preg5": element.Preg5,
        "Preg6": element.Preg6,
        "TotalCat3": element.TotalCat3,
        "Preg7": element.Preg7,
        "Preg8": element.Preg8,
        "TotalCat4": element.TotalCat4,
        "Preg9": element.Preg9,
        "Preg10": element.Preg10,
        "TotalCat5": element.TotalCat5,
        "Preg11": element.Preg11,
        "Preg12": element.Preg12,
        "Preg13": element.Preg13,
        "TotalCat6": element.TotalCat6,
        "Preg14": element.Preg14,
        "TotalCat7": element.TotalCat7,
        "Preg15": element.Preg15,
        "Preg16": element.Preg16,
        "TotalCat8": element.TotalCat8,
        "Preg17": element.Preg17,
        "Preg18": element.Preg18,
        "TotalCat9": element.TotalCat9,
        "Total": element.Total
      })
    });
    new AngularCsv(Exporar, 'Calificación autoEvaluación');
  }



  loadCourses() {
    //LLAMO LOS CURSOS
    this.coursesService.getAllCoursesBySchool(this.SchoolID, this.isActive).subscribe(p => {

      this.coursesbyschool = p.sort(function (a, b) {
        // Ascending: first age less than the previous
        return a["NameCourseOfer"].localeCompare(b["NameCourseOfer"]);
      });

      console.debug(this.coursesbyschool)
    });


  }



}