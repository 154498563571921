import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { ReportComponent, ButtonViewFechas } from './report/report.component';
import { HttpModule } from '@angular/http';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { AgmCoreModule } from '@agm/core'
import { ChartCalidadSchoolComponent } from './report/ChartCalidad/chart-calidad-school.component';
import { ChartCalidadTeacherComponent } from './report/ChartCalidad/chart-calidad-teacher.component';
import { ColombiaPipeMododule } from '../../components/profile-nav/profile-nav.component';
import { CarouselModule } from 'ngx-owl-carousel-o';


// Charts api => see imports: ChartModule
import { ChartsModule } from 'ng2-charts';

export const routes: Routes = [
  { path: 'director/report', component: ReportComponent },

];

@NgModule({
  imports: [
    CommonModule,
    ColombiaPipeMododule,
    RouterModule.forChild(routes),
    HttpModule,
    Ng2SmartTableModule,
    ChartsModule,
    CarouselModule,
   
  ],
  entryComponents: [ButtonViewFechas],
  exports: [RouterModule],
  declarations: [
    ReportComponent,
    ChartCalidadSchoolComponent,
    ChartCalidadTeacherComponent,
    ButtonViewFechas,
  ]
})
export class modDirector { }