import { Component, OnInit, OnDestroy } from '@angular/core';
import swal from 'sweetalert2'
import { MailService } from '../../../models/mail/mail.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ProfileNavComponent } from '../../../components/profile-nav/profile-nav.component';
import { Mail } from '../../../models/mail/mail';
import { Course } from '../../../models/Coursesinfo/course';
import { School } from '../../../models/Coursesinfo/school';
import { CoursesService } from '../../../models/Coursesinfo/courses.service';
import { AttachmentService } from 'app/models/attachment/attachment.service';
import { environment } from 'environments/environment';

declare var $: any;

@Component({
  selector: 'Correos',
  templateUrl: './correos.component.html'
})

export class CorreosComponent implements OnInit, OnDestroy {

  mailfields: Mail;
  isAuth = false;
  isGeneral = false;
  OferCourses: Course[] = [];
  schools: School[] = [];
  group = "Seleccione un grupo";
  fileToUpload: File;
  imagebuton = "Subir Archivo";
  isImageError = true;
  enablesavefile = false;


  public SCHOOL_LOGO_DYNAMIC_IMG:any = (id:number) => environment.SCHOOL_LOGO_DYNAMIC_IMG(id);
  public MAIN_SCHOOL_LOGO_IMG:any = environment.MAIN_SCHOOL_LOGO_IMG;
  public SCHOOL_NAME:string = environment.SCHOOL_NAME;

  constructor(
    private mailService: MailService,
    private route: ActivatedRoute,
    private router: Router,
    private coursesService: CoursesService,
    public attachmentService: AttachmentService,
    public profileNavComponent: ProfileNavComponent
  ) {


    this.mailfields = {
      body: "",
      subject: "",
      courseOfer: 0,
      group: 0,
      school: 0,
      fromname: ""
    }


    for (let role of this.profileNavComponent.roles) {
      if (role.name == "General") {
        this.isAuth = true;
        this.isGeneral = true;
        this.mailfields.fromname = "General " + role.SchoolAcronim;

      }
      if (role.name == "Director") {
        this.isAuth = true;
        this.mailfields.fromname = "Director " + role.SchoolAcronim;
        this.mailfields.school = role.SchoolID;
        this.coursesService.getAllCoursesBySchool(role.SchoolID).subscribe(p => this.OferCourses = p);
      }
      if (role.name == "Información") {
        this.isAuth = true;
        this.mailfields.fromname = "Información " + role.SchoolAcronim;
        this.mailfields.school = role.SchoolID;
        this.coursesService.getAllCoursesBySchool(role.SchoolID).subscribe(p => this.OferCourses = p);
      }
    }

    if (!this.isAuth) this.router.navigate(['/']);



    this.coursesService.getAllSchools().subscribe(p => this.schools = p);

  }

  ngOnInit() {


  }


  callOferCourses(isActive: boolean) {
    if (isActive == true)
      this.coursesService.getAllCoursesBySchool(this.mailfields.school, true).subscribe(p => this.OferCourses = p);
    if (isActive == false)
      this.coursesService.getAllCoursesBySchool(this.mailfields.school, false).subscribe(p => this.OferCourses = p);


  }



  ngOnDestroy() {

  }


  uploadFile(fileInput: any) {
    this.fileToUpload = <File>fileInput.target.files[0];
    if (this.fileToUpload.size > 10000000) {//10M
      this.imagebuton = 'Error de Archivo'
      this.isImageError = true
      this.enablesavefile = false
    }
    else {
      this.imagebuton = 'Archivo OK'
      this.isImageError = false
      this.enablesavefile = true
    }
  }

  callSchool(SchoolID: number) {
    if (!this.isGeneral && SchoolID == 0)
      return;
    else {
      this.mailfields.school = SchoolID;
      this.coursesService.getAllCoursesBySchool(SchoolID).subscribe(p => this.OferCourses = p);
    }
  }
  callcourse(CourseOferID: number, txt) {
    this.mailfields.courseOfer = CourseOferID;
    this.group = txt + " CEDOC360";
  }
  callgrupo(GroupID: number, txt) {
    this.mailfields.group = GroupID;
    this.group = txt
  }


  textSchools(id: number) {
    var num = this.schools.find(x => x.id == id);
    if (num)
      return num.NameTSchool;
    return "Nombre de Escuela"
  }

  AcronimSchools(id: number) {
    var num = this.schools.find(x => x.id == id);
    if (num)
      return num.SchoolAcronim;
    return this.SCHOOL_NAME;
  }

  colorSchool(idSchool: number) {
    let classes = {};

    switch (idSchool) {
      case 0: classes = { cat__info__item_ALL: true }; break;
      case 1: classes = { cat__info__item_CEMIL: true }; break;
      case 4: classes = { cat__info__item_EAS: true }; break;
      case 5: classes = { cat__info__item_ESINF: true }; break;
      case 6: classes = { cat__info__item_ESCAB: true }; break;
      case 7: classes = { cat__info__item_ESART: true }; break;
      case 8: classes = { cat__info__item_ESING: true }; break;
      case 9: classes = { cat__info__item_ESCOM: true }; break;
      case 10: classes = { cat__info__item_ESICI: true }; break;
      case 16: classes = { cat__info__item_ESAVE: true }; break;
      case 17: classes = { cat__info__item_ESLOG: true }; break;
      case 18: classes = { cat__info__item_ESCEQ: true }; break;
      case 19: classes = { cat__info__item_ESPOM: true }; break;
      case 20: classes = { cat__info__item_ESMAI: true }; break;
      case 21: classes = { cat__info__item_ESDIH: true }; break;
      case 22: classes = { cat__info__item_CEMAI: true }; break;
      case 23: classes = { cat__info__item_ESIDE: true }; break;
      case 24: classes = { cat__info__item_ESFES: true }; break;
      default: classes = { cat__info__item_ALL: true }; break;


    }
    return classes;
  }

  sendmail(model: Mail, isValid: boolean) {


    if (isValid && this.group.includes("CEDOC360")) {
      swal({
        title: "Se enviarán correos a",
        text: this.group,
        type: "warning",
        showCancelButton: true,
        confirmButtonClass: "btn-danger",
        confirmButtonText: "Si, Enviar!",

      }).then((result) => {

        if (result.value) {
          this.mailService.sendEmail(this.mailfields, this.fileToUpload).then((result) => {
            swal("Correo Enviado!", "Correo Enviado", "success");
          }, (error) => {
            console.error(error);
            swal(
              "Error en Archivo",
              ""
            );
          })
        }
      });



    }


  }



}




