import { Component, ChangeDetectorRef, NgZone } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';


@Component({
  selector: 'cedoc360-root',
  templateUrl: './app.html',
})
export class AppComponent {
  constructor(
    public router: Router,
    public cd: ChangeDetectorRef,
    public ngZone: NgZone
  ) {
    this.router.events.pipe(filter(e => e instanceof NavigationEnd)).subscribe((e: NavigationEnd) => {
      this.ngZone.run(() => {
        this.cd.detectChanges();
      })
    })
  }
}
