import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EmptyRouteComponent } from './empty-route/empty-route.component';
import { APP_BASE_HREF } from '@angular/common';
import { modGeneral } from './structure/modGeneral/mod-general.module';
import { modHorarios } from './structure/modHorarios/modhorarios.module';
import { modDirector } from './structure/modDirector/moddirector.module';
import { TeacherEvaluationModule } from './structure/modUser/modevaluation/teacher-evaluation.module';




const routes: Routes = [
  {
    path: '**',
    component: EmptyRouteComponent
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
    modGeneral,
    modDirector,
    modHorarios,
    TeacherEvaluationModule
  ],
  exports: [
    RouterModule,
    modGeneral,
    modDirector,
    modHorarios,
    TeacherEvaluationModule
  ],
  providers: [
    {provide: APP_BASE_HREF, useValue: '/'}
  ]
})
export class AppRoutingModule { }
