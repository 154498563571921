import { Component, OnInit, OnDestroy, AfterViewChecked } from "@angular/core";
import { Input, Output, EventEmitter } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "../../../models/Auth/auth.service";
import { ProfileNavComponent } from "../../../components/profile-nav/profile-nav.component";
import { CoursesService } from "../../../models/Coursesinfo/courses.service";
import { UsersService } from "../../../models/usersInfo/users.service";
import { UsersField } from "../../../models/usersInfo/users-fields";
import { Course } from "../../../models/Coursesinfo/course";
import { MouseEvent } from "@agm/core";
import { LocalDataSource, ViewCell } from "ng2-smart-table";
import { isNullOrUndefined } from "util";
import { School } from "../../../models/Coursesinfo/school";
import { ChartCalidadSchoolComponent } from "./ChartCalidad/chart-calidad-school.component";
import { ChartCalidadTeacherComponent } from "./ChartCalidad/chart-calidad-teacher.component";
import { EvaluationsService } from "../../../models/evaluation/evaluations.service";
import * as moment from 'moment';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { environment } from "environments/environment";

declare var $: any;
declare var jQuery: any;
declare var Chartist: any;


// INICIO COMPONENTE PARA EL NG SMART TABLE USUARIO
@Component({
  selector: "button-view-fechas",
  template: `
    {{ renderValue | colombiapipe: "YYYY/MM/DD" }}
  `
})
export class ButtonViewFechas implements ViewCell, OnInit, AfterViewChecked {
  constructor() {}
  renderValue: string;
  @Input() value: string;
  @Input() rowData: any;
  @Output() save: EventEmitter<any> = new EventEmitter();

  public SCHOOL_LOGO_DYNAMIC_IMG:any = (id:number) => environment.SCHOOL_LOGO_DYNAMIC_IMG(id);
  public SCHOOL_DYNAMIC_PHOTO:any = (id:number) => environment.SCHOOL_DYNAMIC_PHOTO(id);
 
  ngOnInit() {
    // console.log("aca esta el render despues")
    // console.log(this.intermediate.Professor)
    this.renderValue = this.value;
  }
  ngAfterViewChecked() {
    this.renderValue = this.value;
  }
  onClick() {
    this.save.emit(this.rowData);
  }
}
// FIN COMPONENTE PARA EL NG SMART TABLE USUARIO

@Component({
  selector: "report",
  templateUrl: "./report.component.html",
  styleUrls: ["./report.component.css"],
  providers: [ChartCalidadSchoolComponent, ChartCalidadTeacherComponent]
})
export class ReportComponent implements OnInit, OnDestroy {

  customOptions: OwlOptions = {
    loop: true,
    navSpeed: 700,
    nav: false,
    dots: false,
    margin: 10,
    responsive: {
      0: {
        items: 3
      },
      400: {
        items: 6
      },
      740: {
        items: 10
      },
      940: {
        items: 13
      }
    }
  }


  
  readypoints: boolean = false;
  user: string;
  SchoolID: number;
  Url: any;
  IsUrl: boolean = false;
  schoolID = 4;
  DependSchoolID = 4;
  schools: School[];
  User_info: UsersField;
  Category1: number;
  Category2: number;
  Category3: number;
  Category4: number;
  Role2: number;
  Acepted: number;
  User_course: Course;
  course: Course;
  ejex1 = [];
  ejey1 = [];
  ejex2 = [];
  ejey2 = [];
  ejex3 = [];
  ejey3 = [];
  ejex4 = [];
  ejey4 = [];
  eventsReport: any[] = [];
  NameDir: string;
  EmailDir: string;
  NameIns: string;
  EmailIns: string;
  year: number;

  locations = [];

  isSchoolReady = false;

  latitude = 4.15333;
  longitude = -73.635;
  locationChose = false;

  isDataReady = false;
  numberStudents: number = 0;
  TotalEgresados: number = 0;
  TotalEscuela: number = 0;
  TotalDelegate: number = 0;
  TotalStudents: number = 0;
  TotalSelf: number = 0;

  ////  by DAVID

  /// Doughnut1
  estudiantesIns = 0;
  estudiantesAcep = 0;

  public doughnutChartLabels: string[] = [
    "Estudiantes Inscritos",
    "Estudiantes Aceptados"
  ];

  public doughnutChartData: number[] = [
    this.estudiantesIns,
    this.estudiantesAcep
  ];

  public doughnutChartType: string = "doughnut";

  /// Doughnut2
  docentesAct = 0;
  docentesNoAct = 0;

  public doughnutChartLabels2: string[] = [
    "Docentes Activos",
    "Docentes No Activos"
  ];

  public doughnutChartData2: number[] = [this.docentesAct, this.docentesNoAct];

  public doughnutChartType2: string = "doughnut";

  /// Radar
  public radarChartLabels: string[] = [
    "Planeación y Organización",
    "Conocimiento",
    "Pedagogía/Metodología",
    "Evaluación y Retroalimentación",
    "Relación y Comunicación",
    "Compromiso Institucional",
    "Currículo Propuesto",
    "Currículo en Uso",
    "Análisis Curricular"
  ];

  public radarChartData: any = [
    {
      data: [3.5, 4, 4.9, 3.8, 5, 3.2, 2, 2.7, 2.2],
      label: "Calidad Académica"
    },

    {
      data: [3, 3.8, 4, 4.2, 4.5, 4.5, 3.5, 4.5, 5],
      label: "Evaluación Estudiantes"
    },

    {
      data: [4.5, 4.8, 4.9, 4.2, 4.5, 4.5, 4.5, 4.5, 5],
      label: "Auto- Evaluación"
    }
  ];

  public radarChartOptions: any = { scale: { ticks: { max : 5, stepSize: 1, min : 0, beginAtZero: true } } };

  public radarChartType: string = "radar";

  /// Radar2

  averageSchool = 3.5;
  average1School = 4;
  average2School = 4.9;
  averageGraduated = 3;
  average1Graduated = 4.1;
  average2Graduated = 5;
  // data: [3.5, 4, 4.9, 3.8]
  // data: [3, 4.1, 5, 3]
  public radarChartLabels2: string[] = [
    "Aspectos Institucionales",
    "Recursos Académicos",
    "Recursos Administrativos"
  ];

  public radarChartData2: any = [
    {
      data: [this.averageSchool, this.average1School, this.average2School],
      label: "Escuela", 
    },
    {
      data: [
        this.averageGraduated,
        this.average1Graduated,
        this.average2Graduated
      ],
      label: "Egresados"
    }
  ];
  
  public radarChartOptions2: any = { scale: { ticks: { max : 5, stepSize: 1, min : 0, beginAtZero: true } } };

  public radarChartType2: string = "radar";

  /// lineChart
  public lineChartData: Array<any> = [
    [65, 59, 80, 81, 56, 55, 40],
    [28, 48, 40, 19, 86, 27, 90],
    [38, 60, 55, 45, 21, 32, 80],
    [45, 19, 32, 29, 65, 11, 60]
  ];
  public lineChartLabels: Array<any> = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July"
  ];
  public lineChartType: string = "line";
  
  public lineChartOptions: any = {
    responsive: true
  };
  public lineChartLegend: boolean = true;

  public randomizeType(): void {
    this.lineChartType = this.lineChartType === "line" ? "bar" : "line";
  }

  /// Events
  public chartClicked(e: any): void {
    console.log(e);
  }

  public chartHovered(e: any): void {
    console.log(e);
  }

  onChoseLocation(event) {
    this.latitude = event.coords.lat;
    this.longitude = event.coords.lng;
    this.locationChose = true;
  }

  //Tabla Lista de Estudiantes - Inicio
  settingsReportStudent = {
    noDataMessage: "No hay datos disponibles",

    actions: false,
    add: {
      addButtonContent: "",
      createButtonContent: "",
      cancelButtonContent: "",
      confirmCreate: false
    },
    edit: {
      editButtonContent: "",
      saveButtonContent: "",
      cancelButtonContent: "",
      confirmSave: false
    },
    delete: {
      editButtonContent: "",
      saveButtonContent: "",
      cancelButtonContent: "",
      confirmSave: false
    },
    pager: {
      perPage: 5
    },
    columns: {
      NameCourseOfer: {
        title: "Nombre del Curso",
        filter: false,
        editable: false
      },
      Since: {
        title: "Fecha Inicio",
        filter: false,
        editable: false,
        type: "custom",
        renderComponent: ButtonViewFechas,
        onComponentInitFunction(instance) {
          instance.save.subscribe(row => {
            console.log("algo se edito");
            // this.saveDocenteDetails(row.id)
          });
        }
      },

      Until: {
        title: "Fecha Final",
        filter: false,
        editable: false,
        type: "custom",
        renderComponent: ButtonViewFechas,
        onComponentInitFunction(instance) {
          instance.save.subscribe(row => {
            console.log("algo se edito");
            // this.saveDocenteDetails(row.id)
          });
        }
      },
      registeredStudent: {
        title: "Estudiantes Inscritos",
        filter: false,
        editable: false
      },
      aceptedStudents: {
        title: "Estudiantes Aceptados",
        filter: false,
        editable: false
      } /*,
      CategoryID: {
        title: 'Categoria',
        filter: false,
        editable: false

      }*/
    }
  };

  sourceReportStudent: LocalDataSource;
  //Tabla Lista de Estudiantes - Fin

  //Tabla Lista de Docentes - Inicio
  settingsReportTeacher = {
    noDataMessage: "No hay datos disponibles",

    actions: {
      edit: false,
      columnTitle: "Acciones"
    },
    add: {
      addButtonContent: "",
      createButtonContent: "",
      cancelButtonContent: "",
      confirmCreate: false
    },
    edit: {
      editButtonContent: "",
      saveButtonContent: "",
      cancelButtonContent: "",
      confirmSave: false
    },
    delete: {
      deleteButtonContent: "Ver Resultados",
      confirmDelete: true
    },
    pager: {
      perPage: 5
    },
    columns: {
      NameRange: {
        title: "Rango",
        filter: false,
        editable: false
      },
      Names: {
        title: "Docente",
        filter: false,
        editable: false
      },
      NameCourse: {
        title: "Curso",
        filter: false,
        editable: false
      },
      NameSubject: {
        title: "Nombre de la Materia",
        filter: false,
        editable: false
      },
      CodeClassRoom: {
        title: "Código ClassRoom",
        filter: false,
        editable: false
      }
    }
  };

  sourceReportTeacher: LocalDataSource;
  //Tabla Lista de Docentes - Fin



  constructor(
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    public profileNavComponent: ProfileNavComponent,
    private coursesService: CoursesService,
    public sanitizer: DomSanitizer,
    private servicios: UsersService,
    public chartcalidadschool: ChartCalidadSchoolComponent,
    public chartcalidadteacher: ChartCalidadTeacherComponent,
    public evaluationsservice: EvaluationsService
  ) {
    //Cargo datos Estudiantes y cursos
    this.sourceReportStudent = new LocalDataSource();
    //Cargo datos Profesores
    this.sourceReportTeacher = new LocalDataSource();
    //SI NO HAY USUARIOS EN LOCAL STORAGE, LLEVAR A LOGIN
    if (this.authService.getCurrentUser() == null) {
      this.router.navigate(["/register"]);
    } else {
      //DE LO CONTRARO CARGAR EL ID DEL USUARIO
      this.user = this.authService.getCurrentUser(); //devuelve el id del usuario

      //SI EL ID DEL USUARIO ES UN NUMERO ENTERO
      if (Number.isInteger(parseInt(this.user))) {
        //CONSULTO EL PERFIL DEL USUARIO
        var validar_usuario = [false];

        for (let role of this.profileNavComponent.roles) {
          if (role.name == "Inspector Escuela") {
            validar_usuario = validar_usuario.concat([true]);
            this.SchoolID = role.SchoolID;
            break;
          }
          if (role.name == "Director") {
            validar_usuario = validar_usuario.concat([true]);
            this.SchoolID = role.SchoolID;
            break;
          }
          if (role.name == "General") {
            validar_usuario = validar_usuario.concat([true]);
            this.SchoolID = role.SchoolID;
            break;
          }
          if (role.name == "Calidad") {
            validar_usuario = validar_usuario.concat([true]);
            this.SchoolID = role.SchoolID;
            break;
          }
          if (role.name == "Jefe") {
            validar_usuario = validar_usuario.concat([true]);
            this.SchoolID = role.SchoolID;
            break;
          }
        }
        if (!validar_usuario.includes(true)) {
          this.router.navigate(["/"]);
        }

        this.authService.swalLoading("Cargando");

        this.coursesService.getSchoolByID(this.SchoolID).subscribe(resp => {
          //console.log(resp)

          this.Url = sanitizer.bypassSecurityTrustResourceUrl(
            resp.ReportSchool
          );
          this.authService.swalEndLoad();
          this.IsUrl = true;
        });
        this.coursesService
          .getAllSchoolByDepend(this.SchoolID)
          .subscribe(depend => {
            if (depend.length > 0) {
              this.schools = depend;
              this.isSchoolReady = true;
              this.isDataReady = true;
              this.LoadData();
            } else {
              this.isDataReady = true;
              this.LoadData();
            }
          });
      }
    }
  }


  setNewSchool(id) {
    this.SchoolID = id;
    this.isDataReady = true;
    this.LoadData();
  }

  LoadData() {
    this.authService.swalLoading("Cargando Información Escuela");

    var filterarray = [];

    filterarray.push({ SchoolID: this.SchoolID });

    var filterdb = `{"where": { "or": ` + JSON.stringify(filterarray) + `}}`;
    //consulto todas las notas de esa escuela
    this.evaluationsservice
      .getSchoolApprBySubjectGroup(filterdb)
      .subscribe(responseSchool => {
        this.numberStudents = responseSchool.length;
      });
    this.chartcalidadteacher.TotalDelegate = this.TotalDelegate;
    this.chartcalidadteacher.TotalStudents = this.TotalStudents;
    this.chartcalidadteacher.TotalSelf = this.TotalSelf;
    this.chartcalidadschool.idSchool = this.SchoolID;
    this.chartcalidadschool.TotalEgresados = this.TotalEgresados;
    this.chartcalidadschool.TotalEscuela = this.TotalEscuela;
    console.log("Escuela idSchool", this.chartcalidadschool.idSchool);
    this.chartcalidadschool.ngOnInit();
    //Cargo Latitud y Longitud de los cursos de entrenamiento
    this.coursesService
      .getCoursesOferByEntrenamientos(this.SchoolID, 17)
      .subscribe(ent => {
        for (let index = 0; index < ent.length; index++) {
          var aleatorio = parseFloat(
            Math.min(0.01 + Math.random() * (0.1 - 0.01), 0.1).toFixed(3)
          );
          var lugar = {
            latitude: parseFloat(ent[index].Altitud) + aleatorio,
            longitude: parseFloat(ent[index].Longitud) + aleatorio,
            title: ent[index].NameCourseOfer,
            draggable: true,
            Image: ent[index].TypeTrainingID
          };

          this.locations.push(lugar);
          console.log(this.locations);
        }
        this.readypoints = true;
      });

    this.coursesService
      .getCoursesOferByEntrenamientos(this.SchoolID, 18)
      .subscribe(ent => {
        for (let index = 0; index < ent.length; index++) {
          var aleatorio = parseFloat(
            Math.min(0.01 + Math.random() * (0.1 - 0.01), 0.1).toFixed(3)
          );
          var lugar = {
            latitude: parseFloat(ent[index].Altitud) + aleatorio,
            longitude: parseFloat(ent[index].Longitud) + aleatorio,
            title: ent[index].NameCourseOfer,
            draggable: true,
            Image: ent[index].TypeTrainingID
          };

          this.locations.push(lugar);
          console.log(this.locations);
        }
        this.readypoints = true;
      });

    //Carga Nombre director y Inspector
    this.coursesService
      .getAllRoleDirectorInspectorBySchool(this.SchoolID, 4)
      .subscribe(dir => {
        this.NameDir = dir[0].Names;
        this.EmailDir = dir[0].CedocEmail;
      });
    this.coursesService
      .getAllRoleDirectorInspectorBySchool(this.SchoolID, 7)
      .subscribe(ins => {
        this.NameIns = ins[0].Names;
        this.EmailIns = ins[0].CedocEmail;
      });

    //Carga tabla de número de alumnos por curso
    this.coursesService
      .getAllStudentCourseOferBySchool(this.SchoolID)
      .subscribe(p => {
        // console.log(p)
        this.sourceReportStudent.load(p);

        for (var index = 0; index < p.length; index++) {
          var event = {};
          //CARGAR DIA DE INICIO DEL CURSO
          if (!isNullOrUndefined(p[index].Since)) {
            event = {
              title: "INICIO " + p[index].NameCourseOfer.toLocaleUpperCase(),
              start: p[index].Since,
              allDay: true
            };
            this.eventsReport = this.eventsReport.concat(event);
          }

          //CARGAR DIA FINAL DEL CURSO.
          if (!isNullOrUndefined(p[index].Since)) {
            event = {
              title: "FIN " + p[index].NameCourseOfer.toLocaleUpperCase(),
              start: moment(p[index].Until)
                .utc()
                .endOf("day")
                .toString(),
              allDay: true
            };
            this.eventsReport = this.eventsReport.concat(event);
          }
        }

        this.loadCalendar();
        // Var declaration to SUM student's Function
        var studentInscr = 0;
        var studentAcep = 0;
        // SUM Student's Function
        for (var i = 0; i < p.length; i++) {
          studentInscr += p[i].registeredStudent;
          studentAcep += p[i].aceptedStudents;
        }

        this.estudiantesIns = studentInscr;
        //console.log("M", studentInscr, this.estudiantesIns);
        this.estudiantesAcep = studentAcep;

        this.doughnutChartData = [this.estudiantesIns, this.estudiantesAcep];
      });

    //Carga tabla de profesores
    this.coursesService
      .getAllTeacherSubjectBySchool(this.SchoolID)
      .subscribe(p => {
        this.sourceReportTeacher.load(p);
        // Var declaration to SUM Teacher's Function
        var teacherAcepted = 0;
        var teacherNoAcepted = 0;
        // SUM Teacher's Function
        for (var i = 0; i < p.length; i++) {
          if (p[i].isAcepted == true) {
            teacherAcepted++;
          } else {
            teacherNoAcepted++;
          }
        }

        this.docentesAct = teacherAcepted;

        this.docentesNoAct = teacherNoAcepted;

        this.doughnutChartData2 = [this.docentesAct, this.docentesNoAct];
      });

      /*var avGr = this.chartcalidadschool.TotalCat4;
      var av1Gr = this.chartcalidadschool.TotalCat5;
      var av2Gr = this.chartcalidadschool.TotalCat6;

      this.averageGraduated = avGr;
      this.average1Graduated = av1Gr;
      this.average2Graduated = av2Gr;

      console.log("Prueba Array", this.averageGraduated, this.average1Graduated, this.average2Graduated );*/
      
    // Cargar Cursos por fecha y categoria
    this.coursesService
      .getAllCoursesDatesBySchoolByCategory1(this.SchoolID, 1)
      .subscribe(cc1 => {
        var anyo = new Date().getFullYear();
        this.year = anyo;
        cc1.forEach(element => {
          this.ejex1.push(element.Date);
          this.ejey1.push(element.NumberCourse);
        });

        this.coursesService
          .getAllCoursesDatesBySchoolByCategory1(this.SchoolID, 2)
          .subscribe(cc2 => {
            cc2.forEach(element => {
              this.ejex2.push(element.Date);
              this.ejey2.push(element.NumberCourse);
            });

            this.coursesService
              .getAllCoursesDatesBySchoolByCategory1(this.SchoolID, 3)
              .subscribe(cc3 => {
                cc3.forEach(element => {
                  this.ejex3.push(element.Date);
                  this.ejey3.push(element.NumberCourse);
                });

                this.coursesService
                  .getAllCoursesDatesBySchoolByCategory1(this.SchoolID, 4)
                  .subscribe(cc4 => {
                    cc4.forEach(element => {
                      this.ejex4.push(element.Date);
                      this.ejey4.push(element.NumberCourse);
                    });

                    this.plotGraph();
                    this.authService.swalEndLoad();
                  });
              });
          });
      });

    //SI EL ID DEL USUARIO ES UN NUMERO ENTERO
    if (Number.isInteger(parseInt(this.user))) {
      //LLAMO EL SERVICIO QUE CONTIENE LOS DATOS DEL USUARIO
      this.servicios.get(parseInt(this.user)).subscribe(response => {
        this.User_info = response;
        this.schoolID = this.profileNavComponent.roles[0].SchoolID;
      });
    }
    //Llama cada una de las categorias por escuela
    this.coursesService.getAllCourseByCategory1(this.SchoolID).subscribe(c1 => {
      this.Category1 = c1.count;
    });
    this.coursesService.getAllCourseByCategory2(this.SchoolID).subscribe(c2 => {
      this.Category2 = c2.count;
    });
    this.coursesService.getAllCourseByCategory3(this.SchoolID).subscribe(c3 => {
      this.Category3 = c3.count;
    });
    this.coursesService.getAllCourseByCategory4(this.SchoolID).subscribe(c4 => {
      this.Category4 = c4.count;
    });
    //Llamo el número de estudiantes registrados por escuela
    this.coursesService.getAllUserByRole2(this.SchoolID).subscribe(r2 => {
      this.Role2 = r2.count;
    });
    //Llamo el número de estudiantes aceptados por escuela
    this.coursesService.getAllUserAcepted(this.SchoolID).subscribe(a => {
      this.Acepted = a.count;
    });

  }

  plotGraph() {
    var maxlen1 = this.ejex2;
    var maxlen2 = this.ejex4;

    if (this.ejex1.length > this.ejex2.length) maxlen1 = this.ejex1;

    if (this.ejex3.length > this.ejex4.length) maxlen2 = this.ejex3;

    var maxlen3 = maxlen2;
    if (maxlen1.length > maxlen2.length) maxlen3 = maxlen1;
    // SMIL ANIMATIONS

  }

  loadCalendar() {
    var cx = this;

    $(function() {

      /**
       * TODO: enable Full Calendar
       */



      ///////////////////////////////////////////////////
      // FULL CALENDAR
      // $(".cat__apps__calendar").fullCalendar({
      //   locale: "es",

      //   //aspectRatio: 2,
      //   height: 800,
      //   header: {
      //     left: "prev, next",
      //     center: "title",
      //     right: "month, today"
      //   },
      //   buttonIcons: {
      //     prev: "none fa fa-arrow-left",
      //     next: "none fa fa-arrow-right",
      //     prevYear: "none fa fa-arrow-left",
      //     nextYear: "none fa fa-arrow-right"
      //   },
      //   // defaultDate: '2016-05-12',
      //   editable: true,
      //   eventLimit: true, // allow "more" link when too many events

      //   viewRender: function(view, element) {
      //     if (!/Mobi/.test(navigator.userAgent) && jQuery().jScrollPane) {
      //       $(".fc-scroller").jScrollPane({
      //         autoReinitialise: true,
      //         autoReinitialiseDelay: 100
      //       });
      //     }
      //   },
      //   events: cx.eventsReport,

      //   eventRender: function(event, element, view) {
      //     var title = element.find(
      //       ".fc-title, .fc-list-item-title, .fc-content"
      //     );
      //     title.html(title.text());

      //     $(element).tooltip({ title: event.title, html: true });
      //   },

      //   eventClick: function(calEvent, jsEvent, view) {
      //     if (!$(this).hasClass("event-clicked")) {
      //       $(".fc-event").removeClass("event-clicked");
      //       $(this).addClass("event-clicked");
      //     }
      //   }
      // });
    });
  }

  mapClicked($event: MouseEvent) {
    this.locations.push({
      latitude: $event.coords.lat,
      longitude: $event.coords.lng,
      draggable: true
    });

    console.log(this.locations);
  }

  ngOnInit() {

  }

  ngOnDestroy() {}

  onSearch(query: string = "") {
    if (query == "") {
      this.sourceReportStudent.setFilter([]);
    } else {
      query = query.replace(/\s/g, "");
      this.sourceReportStudent.setFilter(
        [
          {
            field: "CategoryID",
            search: query
          }
        ],
        false
      );
    }
  }

  onSearchTR(query: string = "") {
    if (query == "") {
      this.sourceReportTeacher.setFilter([]);
    } else {
      query = query.replace(/\s/g, "");
      this.sourceReportTeacher.setFilter(
        [
          {
            field: "Names",
            search: query
          },
          {
            field: "NameSubject",
            search: query
          }
        ],
        false
      );
    }
  }

  onSearchTeacher(event): any {
    console.log("id");
    console.log(event.data.id);

    this.chartcalidadteacher.idProfileCV = event.data.id;
    this.chartcalidadteacher.ngOnInit();
  }
}
