import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../../../models/Auth/auth.service';
import { CoursesService } from '../../../../models/Coursesinfo/courses.service';
import { EvaluationsService } from '../../../../models/evaluation/evaluations.service';
import { Self } from '../../../../models/evaluation/self';
import { ProfileNavComponent } from '../../../../components/profile-nav/profile-nav.component';
import { School } from '../../../../models/Coursesinfo/school';
import * as moment from 'moment';
import { environment } from 'environments/environment';
@Component({
    selector: 'Evaluations',
    templateUrl: './self_appraisal.component.html',
    styleUrls: ['./self_appraisal.component.css'],
})

export class SelfAppraisalComponent {
    self: Self;
    user: string;
    ErrorGroup: string;
    showdata: boolean = false;
    SubjectsProfesor: any;
    SubjectID: number;
    stateButton: boolean = false
    schools: School[] = [];
    serverTime: any;

    public MAIN_SCHOOL_LOGO_ID:any = environment.MAIN_SCHOOL_LOGO_ID;
    public SCHOOL_NAME:string = environment.SCHOOL_NAME;

    constructor(
        private evaluatiosService: EvaluationsService,
        private authService: AuthService,
        private coursesService: CoursesService,
        private route: ActivatedRoute,
        public profileNavComponent: ProfileNavComponent,
        private router: Router) {
            this.authService.getTime().then(server2 => this.serverTime = server2.time);
        //SI NO HAY USUARIOS EN LOCAL STORAGE, LLEVAR A LOGIN
        if (this.authService.getCurrentUser() == null) {
            this.router.navigate(['/register']);
        } else {
            //DE LO CONTRARO CARGAR EL ID DEL USUARIO
            this.user = this.authService.getCurrentUser(); //devuelve el id del usuario
            //SI EL ID DEL USUARIO ES UN NUMERO ENTERO
            if (Number.isInteger(parseInt(this.user))) {
                //CONSULTO EL PERFIL DEL USUARIO
                var validar_usuario = [false];

                for (let role of this.profileNavComponent.roles) {
                    if (role.name == "Docente") {
                        validar_usuario = validar_usuario.concat([true])

                        break
                    }
                    if (role.name == "admin") {
                        validar_usuario = validar_usuario.concat([true])

                        break
                    }
                }

                this.coursesService.getAllSchools().subscribe(p => this.schools = p);
                if (!validar_usuario.includes(true)) { this.router.navigate(['/']); }
                var sixmonthsAgo = moment(this.serverTime).utc().subtract(6,'months').format("YYYY-MM-DD")
                this.coursesService.getSubjectByProfesorIDByDate(parseInt(this.user),sixmonthsAgo).subscribe(response => {
                    if (response.length > 0) {
                        this.SubjectsProfesor = response;

                    }
                });
            }
            else { this.router.navigate(['/']); }
        }


        this.self = {

            SubjectGroupID: null,
            Preg1: null,
            Preg2: null,
            ObsCat1: '',
            Preg3: null,
            Preg4: null,
            ObsCat2: '',
            Preg5: null,
            Preg6: null,
            ObsCat3: '',
            Preg7: null,
            Preg8: null,
            ObsCat4: '',
            Preg9: null,
            Preg10: null,
            ObsCat5: '',
            Preg11: null,
            Preg12: null,
            Preg13: null,
            ObsCat6: '',
            Preg14: null,
            ObsCat7: '',
            Preg15: null,
            Preg16: null,
            ObsCat8: '',
            Preg17: null,
            Preg18: null,
            ObsCat9: ''
        }

    }

    handleMyEvent(arg, vari) {
        this.self[vari] = arg;
    }

    selectmateria(model) {

        this.SubjectID = model.id;
        this.evaluatiosService.getCountSelfByID(parseInt(this.user), model.id).subscribe(p => {
            var Contador = p

            if (Contador["count"] > 0) {
                this.ErrorGroup = "Ya Realizó La Autoevaluación Para Esta Materia";
                this.showdata = false;
                this.authService.swalError("Ya Realizó La Autoevaluación Para Esta Materia", "")

            } else {
                this.showdata = true;
            }
        });
    }

    saveSelf(model: Self, isValid: boolean) {

        this.stateButton = true

        this.self.SubjectGroupID = this.SubjectID;
        if (this.self.SubjectGroupID && this.self.Preg1 && this.self.Preg2 && this.self.Preg3 && this.self.Preg4 && this.self.Preg5 && this.self.Preg6
            && this.self.Preg7 && this.self.Preg8 && this.self.Preg9 && this.self.Preg10 && this.self.Preg11 && this.self.Preg12
            && this.self.Preg13 && this.self.Preg14 && this.self.Preg15 && this.self.Preg16 && this.self.Preg17 && this.self.Preg18) {
            //no null

            this.evaluatiosService.createSelf(this.self, parseInt(this.user)).subscribe(p => {
                this.self = p;
                this.ErrorGroup = "Gracias por Evaluar";
                this.showdata = false;
                this.stateButton = false
            });
        }
    }

    textSchools(id: number) {
        var num = this.schools.find(x => x.id == id);
        if (num)
            return num.NameTSchool;
        return "Cedoc360 para educación"
    }


    colorSchool(idSchool: number) {
        let classes = {};

        switch (idSchool) {
            case 0: classes = { cat__info__item_ALL: true }; break;
            case 1: classes = { cat__info__item_CEMIL: true }; break;
            case 4: classes = { cat__info__item_EAS: true }; break;
            case 5: classes = { cat__info__item_ESINF: true }; break;
            case 6: classes = { cat__info__item_ESCAB: true }; break;
            case 7: classes = { cat__info__item_ESART: true }; break;
            case 8: classes = { cat__info__item_ESING: true }; break;
            case 9: classes = { cat__info__item_ESCOM: true }; break;
            case 10: classes = { cat__info__item_ESICI: true }; break;
            case 16: classes = { cat__info__item_ESAVE: true }; break;
            case 17: classes = { cat__info__item_ESLOG: true }; break;
            case 18: classes = { cat__info__item_ESCEQ: true }; break;
            case 19: classes = { cat__info__item_ESPOM: true }; break;
            case 20: classes = { cat__info__item_ESMAI: true }; break;
            case 21: classes = { cat__info__item_ESDIH: true }; break;
            case 22: classes = { cat__info__item_CEMAI: true }; break;
            case 23: classes = { cat__info__item_ESIDE: true }; break;
            case 24: classes = { cat__info__item_ESFES: true }; break;
            default: classes = { cat__info__item_ALL: true }; break;


        }
        return classes;
    }

}