import { Injectable } from '@angular/core';

import { Router } from "@angular/router";

import { isNullOrUndefined } from 'util';


import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'environments/environment';
import Swal from 'sweetalert2/dist/sweetalert2.js';




@Injectable()


export class AuthService {
  public token="";

  constructor(private http: HttpClient, private router: Router) { }


  public UrlAPI: string = environment.UrlAPI;
  public Url: string = environment.Url;

  private getHeaders() {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.getToken()
    });
    return headers;

  }


  errorMessages(error: any) {

    let errorMsg = "Error de red...";
    var err = `Details: Problemas de conexion`;

    if (error.status == 422) {

      err = error.error.message || `Details: Problemas de conexion`;
      var errd = err.split("Details: ");
      errorMsg = errd[1];

    }

    if (error.status == 401) {

      if (error.error.message)
        err = `Details: Error De Autenticación (401)`
      var errd = err.split("Details: ");
      errorMsg = errd[1];

    }

    return throwError(errorMsg);

  }


  getTime() {

    var hora = fetch(`${this.UrlAPI}/Times/COTime`)
      .then(
        response => response.json() // .json(), etc.
        // same as function(response) {return response.text();}
      ).then(
        html => {
          //console.log(html)
          return html
        }
      );

    return hora

  }


  getUserTokens(id: number): Observable<any> {

    let TypeCourses$ = this.http
      .get(`${this.UrlAPI}/Userapps/${id}/accessTokens`, { headers: this.getHeaders() })
      .pipe(
        catchError(err => this.errorMessages(err))
      )


    return TypeCourses$;

  }


  DeleteUserToken(id: number): Observable<any > {

    let TypeCourses$ = this.http
      .delete(`${this.UrlAPI}/Userapps/${id}/accessTokens/`, { headers: this.getHeaders() })
      .pipe(
        catchError(err => this.errorMessages(err))
      )

    return TypeCourses$;

  }


  setUser(user: string) {

    let userString = user;

    localStorage.setItem("currentUser", userString);

  }


  getCurrentUser(): string {

    let userString = localStorage.getItem("currentUser");

    if (!isNullOrUndefined(userString)) {

      let user: string = userString;

      // REVISO EL TOKEN DEL USUARIO
      this.getUserTokens(parseInt(user)).subscribe(p => {

        var checkToken = [true]

        for (var index = 0; index < p.length; index++) {

          //SI EL TOKEN DEL USUARIO COINCIDE CON EL ID NO HACER NADA
          if (p[index].id === this.getToken() && parseInt(localStorage.getItem("currentUser")) === p[index].userId) {
            checkToken = checkToken.concat([true])

          }

          else {
            //DE LO CONTRARIO BORRO TODOS LOS TOKENS QUE TIENE EL USUARIO
            checkToken = checkToken.concat([false])

          }

        }

        //SI EL TOKEN NO CORRESPONDE AL ID BORRAR LA SESION
        if ((checkToken.includes(false) && checkToken.filter(i => i === true).length == 1) || checkToken.filter(i => i === true).length == 1) {

          this.DeleteUserToken(parseInt(localStorage.getItem("currentUser"))).subscribe(p => {

          })
          localStorage.removeItem("currentUser");
          localStorage.removeItem("accessToken");
          this.router.navigate(['/register']);


        }

        else {

          return user

        }


      })

      return user;

    } else {

      return null;

    }

  }


  setToken(token: string) {
    localStorage.setItem("accessToken", token);

  }


  getToken(): string {

     let token = localStorage.getItem('accessToken');
    this.token = "";
    if (token) {
      this.token = token;
    }
    return this.token;

  }


  logout() {

    console.log("Se va a salir");
    localStorage.removeItem("currentUser");
    localStorage.removeItem("accessToken");

  }



  swalLoading(titleString: string) {

    Swal({

      type: 'success',
      title: titleString,
      text: "Esto Puede Tomar Un Tiempo Dependiendo De Su Conexión A Internet",
      showConfirmButton: false,
      allowEscapeKey: false,
      allowOutsideClick: false,

      onOpen: function () {

        Swal.showLoading()

      }

    })

  }

  swalEndLoad() {
    Swal({

      type: 'success',
      timer: 5,

    }).catch(Swal.noop)

  }

  loginGoogle(data): Observable<any>{
    let url = '/auth/client';
    return this.http.post(this.Url + url, data )
    .pipe(
      catchError(err => this.errorMessages(err))
    )
  }

  swalSuccess(titleString: string, textString: string) {

    Swal({

      type: 'success',
      title: titleString,
      text: textString,
      showConfirmButton: true,
      allowEscapeKey: false,
      allowOutsideClick: false,

    }).catch(Swal.noop)

  }


  swalError(titleString: string, textString: string) {

    Swal({

      type: 'error',
      title: titleString,
      text: textString,
      showConfirmButton: true,
      allowEscapeKey: false,
      allowOutsideClick: false,

    }).catch(Swal.noop)

  }

}
