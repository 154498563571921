
import { isNullOrUndefined } from 'util';
import { Component, OnInit } from '@angular/core';
import { Course } from '../../../../models/Coursesinfo/course';
import { ProfileNavComponent } from '../../../../components/profile-nav/profile-nav.component';
import { EvaluationsService } from '../../../../models/evaluation/evaluations.service';

declare var $: any;
declare var Chart: any;

@Component({
  moduleId: module.id,
  selector: 'chart-calidad-school',
  templateUrl: './chart-calidad-school.component.html',
  styleUrls: ['./chart-calidad-school.component.css'],
})


export class ChartCalidadSchoolComponent implements OnInit {

  public Courses: Course[];
  public Subjects: Course[]
  public IsProfessor: boolean = false;
  public IsStudent: boolean = false;
  public IsStudent2: boolean = false;
  public SubjectsProfesor: Course[];
  public EventCalendarProfessor: any[] = []
  public EventCalendarColorIndexProfessor: any[] = []

  public EventCalendarStudent: any[] = []
  public EventCalendarColorIndexStudent: any[] = []

  TotalCat1: number = 0;
  TotalCat2: number = 0;
  TotalCat3: number = 0;
  TotalCat4: number = 0;
  TotalCat5: number = 0;
  TotalCat6: number = 0;
  dataRadar: any
  ChartRadar: any
  IsInspector = false;
  TotalEscuela: number = 0
  TotalEgresados: number =0
  public ColorCourses = ["#5d80a2", "#680345", "#7C6445", "#E154CE", "#258C64", "#A44015", "#FE9A2E", "#DF0101", "#01DF01", "#0040FF", "#565ACB", "#144A20", "#AAE951", "#6CF132", "#F19663", "#891CBB", "#21EBF3", "#F19663", "#61265F", "#6916E4", "#464208", "#393D78", "#E2337D", "#A1924C", "#52B743", "#176548", "#A0B03D", "#7C188A", "#77BB3E"]

  SchoolID: number = 0;
  subjectgroupsprofessor: any = []

  public idSchool: any;

  constructor(
 
    public profileNavComponent: ProfileNavComponent,
    public evaluationsservice: EvaluationsService,

  ) {

  }

  //INDICO QUE LOS DATOS NO ESTAN DISPONIBLES AL CARGAR EL HTML PARA NO MOSTRAR ERRORES
  ngOnInit() {
    console.log("Chart School");
    console.log(this.idSchool);

    // RADAR CHART
    //var radarCtx = document.getElementById('chart-radar').getContext('2d');
    var radarCtx = $("#chart-radar-school")[0].getContext('2d');

    this.dataRadar = {


      labels: ["Aspectos Institucionales", "Recursos académicos", "Recursos administrativos"],
      datasets: [
        {
          label: "Escuela",
          backgroundColor: "rgba(47, 121, 238,0.2)",
          borderColor: "rgba(47, 121, 238,1)",
          pointBackgroundColor: "rgba(47, 121, 238,1)",
          pointBorderColor: "#fff",
          pointHoverBackgroundColor: "#fff",
          pointHoverBorderColor: "rgba(47, 121, 238,1)",
          data: [0, 0, 0]
        }
        ,
        {
          label: "Egresados",
          backgroundColor: "rgba(174, 238, 48,0.2)",
          borderColor: "rgba(174, 238, 48,1)",
          pointBackgroundColor: "rgba(174, 238, 48,1)",
          pointBorderColor: "#fff",
          pointHoverBackgroundColor: "#fff",
          pointHoverBorderColor: "rgba(174, 238, 48,1)",
          data: [0, 0, 0]
        }
      ]
    };

    this.ChartRadar = new Chart(radarCtx, {
      type: 'radar',
      data: this.dataRadar,
      options: {
        responsive: true,

        scale: {

          ticks: {
            max: 5,
            stepSize: 1,
            min: 0,
            beginAtZero: true,
          }
        },

      }
    });

    //It's the beginnig
    if (isNullOrUndefined(this.idSchool)) {
      //NO HAGO NADA
    }
    else {

      var filterarray = []

      filterarray.push({ "SchoolID": this.idSchool })

      var filterdb = `{"where": { "or": ` + JSON.stringify(filterarray) + `}}`;
      //1. Consulto todas las << Notas de esa ESCUELA >>
      this.evaluationsservice.getSchoolApprBySubjectGroup(filterdb).subscribe(responseSchool => {

        console.log(responseSchool, 'getSchoolAppr')
        this.TotalCat1 = 0
        this.TotalCat2 = 0
        this.TotalCat3 = 0
        this.TotalEscuela = responseSchool.length
        console.log("Total Escuela", this.TotalEscuela);
        
        //CALCULAR EL PROMEDIO DE TODAS LAS NOTAS
        for (var i = 0; i < responseSchool.length; i++) {
          console.log('length', responseSchool.length)
          this.TotalCat1 += responseSchool[i].TotalCat1
          this.TotalCat2 += responseSchool[i].TotalCat2
          this.TotalCat3 += responseSchool[i].TotalCat3

        }

        this.TotalCat1 = Number((( this.TotalCat1 / responseSchool.length / 10)).toFixed(1));
        this.TotalCat2 = Number((( this.TotalCat2 / responseSchool.length / 10)).toFixed(1));
        this.TotalCat3 = Number((( this.TotalCat3 / responseSchool.length / 10)).toFixed(1));
        console.log("MDR", this.TotalCat2); // Qué pasa acá y por qué lo arreglan con / 10 ??? WTF
        
        this.dataRadar.datasets[0].data = [this.TotalCat1 , this.TotalCat2 , this.TotalCat3]
        //this.ChartRadar.removeData();
        // this.ChartRadar.addData(this.dataRadar.datasets[0].data);
        console.log("informacion Escuelas")
        console.log("idSchool", this.idSchool)
        console.log(this.dataRadar.datasets[0].data)
        this.ChartRadar.update();
      })

      //2. Consulta todas las << Notas EGRESADOS >>
      this.evaluationsservice.getGraduatesApprBySubjectGroup(filterdb).subscribe(responseGraduates => {

        console.log(responseGraduates, 'getGraduatesAppr')
        this.TotalCat4 = 0
        this.TotalCat5 = 0
        this.TotalCat6 = 0
        this.TotalEgresados = responseGraduates.length
        console.log("Total Egresados", this.TotalEgresados);
        
        //CALCULAR EL PROMEDIO DE TODAS LAS NOTAS
        for (var i = 0; i < responseGraduates.length; i++) {
          console.log('length', responseGraduates.length)
          this.TotalCat4 += responseGraduates[i].TotalCat1
          this.TotalCat5 += responseGraduates[i].TotalCat2
          this.TotalCat6 += responseGraduates[i].TotalCat3

        }

        this.TotalCat4 = Number((( this.TotalCat4 / responseGraduates.length) / 10).toFixed(1));
        this.TotalCat5 = Number((( this.TotalCat5 / responseGraduates.length) / 10).toFixed(1));
        this.TotalCat6 = Number((( this.TotalCat6 / responseGraduates.length) / 10).toFixed(1));
        this.dataRadar.datasets[1].data = [this.TotalCat4, this.TotalCat5, this.TotalCat6]
        //this.ChartRadar.removeData();
        // this.ChartRadar.addData(this.dataRadar.datasets[0].data);
        console.log("informacion Egresados")
        console.log(this.idSchool)
        console.log(this.dataRadar.datasets[1].data)
        this.ChartRadar.update();
      })
    }
  }

  ngAfterViewInit() {
  }
}