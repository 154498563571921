import { Component } from '@angular/core';
import { LocalDataSource } from 'ng2-smart-table';
import { Course } from '../../../../models/Coursesinfo/course';
import { School } from '../../../../models/Coursesinfo/school';
import { CoursesService } from '../../../../models/Coursesinfo/courses.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../../../models/Auth/auth.service';
import { ProfileNavComponent } from '../../../../components/profile-nav/profile-nav.component';
import { Student } from '../../../../models/evaluation/student';
import { elementAt } from 'rxjs/operators';
import { isNullOrUndefined } from 'util';
import { EvaluationsService } from '../../../../models/evaluation/evaluations.service';
import { AngularCsv } from 'angular7-csv';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { environment } from 'environments/environment';


@Component({
  selector: 'SchoolView',
  templateUrl: './school-view.component.html',
  styleUrls: ['./school-view.component.css']

})
export class SchoolViewComponent {

  customOptions: OwlOptions = {
    loop: true,
    navSpeed: 700,
    nav: false,
    dots: false,
    margin: 10,
    responsive: {
      0: {
        items: 3
      },
      400: {
        items: 6
      },
      740: {
        items: 10
      },
      940: {
        items: 13
      }
    }
  }


  totalStudent: number;
  coursesbyschool: Course[];
  schools: School[] = [];
  SchoolID: number;
  serverTime: string
  user: any
  isResgistre = false
  isJefe = false
  isCalidad = false
  schoolID: number = 0;
  isDataReady = false;
  isSchoolReady = false;
  courseOferID: number = 0
  //isDataReady = false;
  subjectByCourses: any
  courseID: number = 0
  subjectID: number = 0
  teacherViews: any
  teacherViewDelegates: any
  teacherViewSelfs: any
  listTeacher: any
  TotalF: number = 0
  TotalFC: number = 0
  nameSubject: string

  courses: Course = {

    CourseOferID: 0
  }
  showdata: boolean = false;
  subjects: any[] = []
  schoolViews: any;
  schoolObs: any;

  public SCHOOL_LOGO_DYNAMIC_IMG: any = (id:number) => environment.SCHOOL_LOGO_DYNAMIC_IMG(id);

  constructor(
    private coursesService: CoursesService,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    public profileNavComponent: ProfileNavComponent,
    public evaluationService: EvaluationsService
  ) {
    //GUARDO LA HORA DEL SERVIDOR
    this.authService.getTime().then(server2 => this.serverTime = server2.time);


    this.coursesService.getAllSchools().subscribe(p => this.schools = p);


    if (this.authService.getCurrentUser() == null) {
      this.router.navigate(['/register']);
    } else {
      //DE LO CONTRARO CARGAR EL ID DEL USUARIO
      this.user = this.authService.getCurrentUser(); //devuelve el id del usuario
      //SI EL ID DEL USUARIO ES UN NUMERO ENTERO
      if (Number.isInteger(parseInt(this.user))) {
        //CONSULTO EL PERFIL DEL USUARIO
        var validar_usuario = [false];

        for (let role of this.profileNavComponent.roles) {
          if (role.name == "General") {
            validar_usuario = validar_usuario.concat([true])
            this.SchoolID = role.SchoolID;
          }
          if (role.name == "Registro y Control") {

            this.isResgistre = true
            validar_usuario = validar_usuario.concat([true])
            this.SchoolID = role.SchoolID;
          }
          if (role.name == "Jefe") {

            this.isJefe = true
            validar_usuario = validar_usuario.concat([true])
            this.SchoolID = role.SchoolID;
          }
          if (role.name == "Calidad") {

            this.isCalidad = true
            validar_usuario = validar_usuario.concat([true])
            this.SchoolID = role.SchoolID;
          }


          if (role.name == "Inspector Escuela") {
            validar_usuario = validar_usuario.concat([true])
            this.SchoolID = role.SchoolID;
            break
          }



        }

        if (!validar_usuario.includes(true)) { this.router.navigate(['/']); }

        this.coursesService.getAllSchoolByDepend(this.SchoolID).subscribe(depend => {
          if (depend.length > 0) {
            this.schools = depend

            this.isSchoolReady = true
          } else {

          }
        })

        //LLAMO LOS CURSOS
        this.evaluationService.getSchoolApprByCourseOfer(this.SchoolID).subscribe(schools => {
          this.schoolViews = []


          for (var index = 0; index < schools.length; index++) {
            var element = schools[index];

          }


          schools.forEach((element, index) => {
            console.log(element)
            var position = -1
            position = this.schoolViews.findIndex(x => x.CourseOferID === element.CourseOferID)
            console.log("course", element.CourseOferID)
            if (position == -1) {
              this.schoolViews.push({

                NameCourseOfer: element.NameCourseOfer,
                CourseOferID: element.CourseOferID,
                Preg1: element.Preg1,
                Preg2: element.Preg2,
                Preg3: element.Preg3,
                Preg4: element.Preg4,
                Preg5: element.Preg5,
                Preg6: element.Preg6,
                Preg7: element.Preg7,
                Preg8: element.Preg8,
                Preg9: element.Preg9,
                Preg10: element.Preg10,
                Preg11: element.Preg11,
                Preg12: element.Preg12,
                Preg13: element.Preg13,
                Preg14: element.Preg14,
                Preg15: element.Preg15,
                Preg16: element.Preg16,
                Preg17: element.Preg17,
                Preg18: element.Preg18,
                Preg19: element.Preg19,
                Preg20: element.Preg20,
                Preg21: element.Preg21,
                TotalCat1: element.TotalCat1,
                TotalCat2: element.TotalCat2,
                TotalCat3: element.TotalCat3,
                Total: 1,
                ObsCat3: element.ObsCat3
              })
            } else {
              this.schoolViews[position].Preg1 += element.Preg1,
                this.schoolViews[position].Preg2 += element.Preg2,
                this.schoolViews[position].Preg3 += element.Preg3,
                this.schoolViews[position].Preg4 += element.Preg4,
                this.schoolViews[position].Preg5 += element.Preg5,
                this.schoolViews[position].Preg6 += element.Preg6,
                this.schoolViews[position].Preg7 += element.Preg7,
                this.schoolViews[position].Preg8 += element.Preg8,
                this.schoolViews[position].Preg9 += element.Preg9,
                this.schoolViews[position].Preg10 += element.Preg10,
                this.schoolViews[position].Preg11 += element.Preg11,
                this.schoolViews[position].Preg12 += element.Preg12,
                this.schoolViews[position].Preg13 += element.Preg13,
                this.schoolViews[position].Preg14 += element.Preg14,
                this.schoolViews[position].Preg15 += element.Preg15,
                this.schoolViews[position].Preg16 += element.Preg16,
                this.schoolViews[position].Preg17 += element.Preg17,
                this.schoolViews[position].Preg18 += element.Preg18,
                this.schoolViews[position].Preg19 += element.Preg19,
                this.schoolViews[position].Preg20 += element.Preg20,
                this.schoolViews[position].Preg21 += element.Preg21,
                this.schoolViews[position].TotalCat1 += element.TotalCat1,
                this.schoolViews[position].TotalCat2 += element.TotalCat2,
                this.schoolViews[position].TotalCat3 += element.TotalCat3,
                this.schoolViews[position].Total += 1
                if( element.ObsCat3  != "") { 
                  this.schoolViews[position].ObsCat3 +=  '--'+element.ObsCat3
                }

            }
            console.log("Observaciones", element.ObsCat3)
          


          })


          console.log("Sale", this.schoolViews)


          this.schoolViews.forEach((element, index) => {
            element.Preg1 = Number((element.Preg1 / element.Total).toFixed(1)),
              element.Preg2 = Number((element.Preg2 / element.Total).toFixed(1)),
              element.Preg3 = Number((element.Preg3 / element.Total).toFixed(1)),
              element.Preg4 = Number((element.Preg4 / element.Total).toFixed(1)),
              element.Preg5 = Number((element.Preg5 / element.Total).toFixed(1)),
              element.Preg6 = Number((element.Preg6 / element.Total).toFixed(1)),
              element.Preg7 = Number((element.Preg7 / element.Total).toFixed(1)),
              element.Preg8 = Number((element.Preg8 / element.Total).toFixed(1)),
              element.Preg9 = Number((element.Preg9 / element.Total).toFixed(1)),
              element.Preg10 = Number((element.Preg10 / element.Total).toFixed(1)),
              element.Preg11 = Number((element.Preg11 / element.Total).toFixed(1)),
              element.Preg12 = Number((element.Preg12 / element.Total).toFixed(1)),
              element.Preg13 = Number((element.Preg13 / element.Total).toFixed(1)),
              element.Preg14 = Number((element.Preg14 / element.Total).toFixed(1)),
              element.Preg15 = Number((element.Preg15 / element.Total).toFixed(1)),
              element.Preg16 = Number((element.Preg16 / element.Total).toFixed(1)),
              element.Preg17 = Number((element.Preg17 / element.Total).toFixed(1)),
              element.Preg18 = Number((element.Preg18 / element.Total).toFixed(1)),
              element.Preg19 = Number((element.Preg19 / element.Total).toFixed(1)),
              element.Preg20 = Number((element.Preg20 / element.Total).toFixed(1)),
              element.Preg21 = Number((element.Preg21 / element.Total).toFixed(1)),
              element.TotalCat1 = Number((element.TotalCat1 / element.Total).toFixed(1)),
              element.TotalCat2 = Number((element.TotalCat2 / element.Total).toFixed(1)),
              element.TotalCat3 = Number((element.TotalCat3 / element.Total).toFixed(1))

          })
          console.log("Subject view", schools)

        })


      }





    }


  }

  setNewSchool(id) {
    this.schoolID = id
    this.isDataReady = true

    //LLAMO LOS CURSOS
    this.evaluationService.getSchoolApprByCourseOfer(this.schoolID).subscribe(schools => {
      this.schoolViews = []


      for (var index = 0; index < schools.length; index++) {
        var element = schools[index];

      }

      schools.forEach((element, index) => {
        console.log(element)
        var position = -1
        position = this.schoolViews.findIndex(x => x.CourseOferID === element.CourseOferID)
        console.log("course", element.CourseOferID)
        if (position == -1) {
          this.schoolViews.push({

            NameCourseOfer: element.NameCourseOfer,
            CourseOferID: element.CourseOferID,
            Preg1: element.Preg1,
            Preg2: element.Preg2,
            Preg3: element.Preg3,
            Preg4: element.Preg4,
            Preg5: element.Preg5,
            Preg6: element.Preg6,
            Preg7: element.Preg7,
            Preg8: element.Preg8,
            Preg9: element.Preg9,
            Preg10: element.Preg10,
            Preg11: element.Preg11,
            Preg12: element.Preg12,
            Preg13: element.Preg13,
            Preg14: element.Preg14,
            Preg15: element.Preg15,
            Preg16: element.Preg16,
            Preg17: element.Preg17,
            Preg18: element.Preg18,
            Preg19: element.Preg19,
            Preg20: element.Preg20,
            Preg21: element.Preg21,
            TotalCat1: element.TotalCat1,
            TotalCat2: element.TotalCat2,
            TotalCat3: element.TotalCat3,
            Total: 1,
            ObsCat3: element.ObsCat3
          })
        } else {
          this.schoolViews[position].Preg1 += element.Preg1,
            this.schoolViews[position].Preg2 += element.Preg2,
            this.schoolViews[position].Preg3 += element.Preg3,
            this.schoolViews[position].Preg4 += element.Preg4,
            this.schoolViews[position].Preg5 += element.Preg5,
            this.schoolViews[position].Preg6 += element.Preg6,
            this.schoolViews[position].Preg7 += element.Preg7,
            this.schoolViews[position].Preg8 += element.Preg8,
            this.schoolViews[position].Preg9 += element.Preg9,
            this.schoolViews[position].Preg10 += element.Preg10,
            this.schoolViews[position].Preg11 += element.Preg11,
            this.schoolViews[position].Preg12 += element.Preg12,
            this.schoolViews[position].Preg13 += element.Preg13,
            this.schoolViews[position].Preg14 += element.Preg14,
            this.schoolViews[position].Preg15 += element.Preg15,
            this.schoolViews[position].Preg16 += element.Preg16,
            this.schoolViews[position].Preg17 += element.Preg17,
            this.schoolViews[position].Preg18 += element.Preg18,
            this.schoolViews[position].Preg19 += element.Preg19,
            this.schoolViews[position].Preg20 += element.Preg20,
            this.schoolViews[position].Preg21 += element.Preg21,
            this.schoolViews[position].TotalCat1 += element.TotalCat1,
            this.schoolViews[position].TotalCat2 += element.TotalCat2,
            this.schoolViews[position].TotalCat3 += element.TotalCat3,
            this.schoolViews[position].Total += 1
            //this.schoolViews[position].ObsCat3 +=  '--'+element.ObsCat3

            if( element.ObsCat3  != "") { 
              this.schoolViews[position].ObsCat3 +=  '--'+element.ObsCat3
            }

        
        }
        console.log("Observaciones", element.ObsCat3)

      })


      console.log("Sale", this.schoolViews)


      this.schoolViews.forEach((element, index) => {
        element.Preg1 = Number((element.Preg1 / element.Total).toFixed(1)),
          element.Preg2 = Number((element.Preg2 / element.Total).toFixed(1)),
          element.Preg3 = Number((element.Preg3 / element.Total).toFixed(1)),
          element.Preg4 = Number((element.Preg4 / element.Total).toFixed(1)),
          element.Preg5 = Number((element.Preg5 / element.Total).toFixed(1)),
          element.Preg6 = Number((element.Preg6 / element.Total).toFixed(1)),
          element.Preg7 = Number((element.Preg7 / element.Total).toFixed(1)),
          element.Preg8 = Number((element.Preg8 / element.Total).toFixed(1)),
          element.Preg9 = Number((element.Preg9 / element.Total).toFixed(1)),
          element.Preg10 = Number((element.Preg10 / element.Total).toFixed(1)),
          element.Preg11 = Number((element.Preg11 / element.Total).toFixed(1)),
          element.Preg12 = Number((element.Preg12 / element.Total).toFixed(1)),
          element.Preg13 = Number((element.Preg13 / element.Total).toFixed(1)),
          element.Preg14 = Number((element.Preg14 / element.Total).toFixed(1)),
          element.Preg15 = Number((element.Preg15 / element.Total).toFixed(1)),
          element.Preg16 = Number((element.Preg16 / element.Total).toFixed(1)),
          element.Preg17 = Number((element.Preg17 / element.Total).toFixed(1)),
          element.Preg18 = Number((element.Preg18 / element.Total).toFixed(1)),
          element.Preg19 = Number((element.Preg19 / element.Total).toFixed(1)),
          element.Preg20 = Number((element.Preg20 / element.Total).toFixed(1)),
          element.Preg21 = Number((element.Preg21 / element.Total).toFixed(1)),
          element.TotalCat1 = Number((element.TotalCat1 / element.Total).toFixed(1)),
          element.TotalCat2 = Number((element.TotalCat2 / element.Total).toFixed(1)),
          element.TotalCat3 = Number((element.TotalCat3 / element.Total).toFixed(1))

      })
      console.log("Subject view", schools)

    })

  }


  ngOnInit() {

  }

  ngOnDestroy() {

  }
  colorSchool(idSchool: number) {
    let classes = {};

    switch (idSchool) {
      case 0: classes = { cat__info__item_ALL: true }; break;
      case 1: classes = { cat__info__item_CEMIL: true }; break;
      case 4: classes = { cat__info__item_EAS: true }; break;
      case 5: classes = { cat__info__item_ESINF: true }; break;
      case 6: classes = { cat__info__item_ESCAB: true }; break;
      case 7: classes = { cat__info__item_ESART: true }; break;
      case 8: classes = { cat__info__item_ESING: true }; break;
      case 9: classes = { cat__info__item_ESCOM: true }; break;
      case 10: classes = { cat__info__item_ESICI: true }; break;
      case 16: classes = { cat__info__item_ESAVE: true }; break;
      case 17: classes = { cat__info__item_ESLOG: true }; break;
      case 18: classes = { cat__info__item_ESCEQ: true }; break;
      case 19: classes = { cat__info__item_ESPOM: true }; break;
      case 20: classes = { cat__info__item_ESMAI: true }; break;
      case 21: classes = { cat__info__item_ESDIH: true }; break;
      case 22: classes = { cat__info__item_CEMAI: true }; break;
      case 23: classes = { cat__info__item_ESIDE: true }; break;
      case 24: classes = { cat__info__item_ESFES: true }; break;
      default: classes = { cat__info__item_ALL: true }; break;


    }
    return classes;
  }

  textSchools(id: number) {
    var num = this.schools.find(x => x.id == id);
    if (num)
      return num.NameTSchool;
    return "Nombre de Escuela"
  }

  ExportListSchools() {
    var Exporar = [];

    Exporar.push({
      "NameCourseOfer": "Nombre del Programa",
      "Preg1": "Preg1",
      "Preg2": "Preg2",
      "Preg3": "Preg3",
      "Preg4": "Preg4",
      "TotalCat1": "TotalCat1",
      "Preg5": "Preg5",
      "Preg6": "Preg6",
      "Preg7": "Preg7",
      "Preg8": "Preg8",
      "Preg9": "Preg9",
      "Preg10": "Preg10",
      "Preg11": "Preg11",
      "Preg12": "Preg12",
      "Preg13": "Preg13",
      "Preg14": "Preg14",
      "TotalCat2": "TotalCat2",
      "Preg15": "Preg15",
      "Preg16": "Preg16",
      "Preg17": "Preg17",
      "Preg18": "Preg18",
      "Preg19": "Preg19",
      "Preg20": "Preg20",
      "Preg21": "Preg21",
      "TotalCat3": "TotalCat3",
      "Total": "Número de Estudiantes",
    })
    this.schoolViews.forEach((element, index) => {
      Exporar.push({
        "NameCourseOfer": element.NameCourseOfer,
        "Preg1": element.Preg1,
        "Preg2": element.Preg2,
        "Preg3": element.Preg3,
        "Preg4": element.Preg4,
        "TotalCat1": element.TotalCat1,
        "Preg5": element.Preg5,
        "Preg6": element.Preg6,
        "Preg7": element.Preg7,
        "Preg8": element.Preg8,
        "Preg9": element.Preg9,
        "Preg10": element.Preg10,
        "Preg11": element.Preg11,
        "Preg12": element.Preg12,
        "Preg13": element.Preg13,
        "Preg14": element.Preg14,
        "TotalCat2": element.TotalCat2,
        "Preg15": element.Preg15,
        "Preg16": element.Preg16,
        "Preg17": element.Preg17,
        "Preg18": element.Preg18,
        "Preg19": element.Preg19,
        "Preg20": element.Preg20,
        "Preg21": element.Preg21,
        "TotalCat3": element.TotalCat3,
        "Total": element.Total
      })
    });
    new AngularCsv(Exporar, 'Calificación Escuela');
  }

  ExportObservationSchools() {
    var Exporar = [];

    Exporar.push({
      "NameCourseOfer": "Nombre del Programa",
      "ObsCat3": "Observaciones"
    })
    this.schoolViews.forEach((element, index) => {
      Exporar.push({
        "NameCourseOfer": element.NameCourseOfer,
        "Preg1": element.ObsCat3
      })
    });
    new AngularCsv(Exporar, 'Observaciones Escuela');
  }

}