import { Component, OnInit, AfterViewChecked, HostListener } from '@angular/core';
import { Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { isNullOrUndefined, isNull } from 'util';
import { LocalDataSource } from 'ng2-smart-table';
import { ViewCell } from 'ng2-smart-table';
import { School } from "../../../models/Coursesinfo/school";
import { Module } from "../../../models/Coursesinfo/module";
import { UsersField } from '../../../models/usersInfo/users-fields';
import { Subject } from '../../../models/Coursesinfo/subject';
import { Course } from '../../../models/Coursesinfo/course';
import { AuthService } from '../../../models/Auth/auth.service';
import { UsersService } from '../../../models/usersInfo/users.service';
import { CoursesService } from '../../../models/Coursesinfo/courses.service';
import { ProfileNavComponent } from '../../../components/profile-nav/profile-nav.component';

import { ButtonViewdPhysicalTest } from '../new-schedule/new-schedule.component';

//import { MODULE_SUFFIX } from '@angular/compiler/src/util';
import Swal from 'sweetalert2/dist/sweetalert2.js';

declare var $: any;

declare var notify: any;
var async = require("async");

var Moment = require('moment');
var MomentRange = require('moment-range');

var moment = MomentRange.extendMoment(Moment);
const JSON = require('circular-json')

export class Intermediate {
    public Disponible = 0;
    public Professor: object = {
        // 2941: "--ASIGNAR DESPUÉS",
    }
    public Grups: object = {
        0: " NO GROUP",
    }
}


// INICIO COMPONENTE PARA EL NG SMART TABLE GRUPOS
@Component({
    selector: 'button-view-groups',
    template: `
    {{ renderValue }}
  `
})
export class ButtonViewScheduleGroupsComponent2 implements ViewCell, OnInit, AfterViewChecked {
    constructor(
        public intermediate: Intermediate,
    ) { }
    renderValue: string;

    @Input() value: string | number;
    @Input() rowData: any;
    @Output() save: EventEmitter<any> = new EventEmitter()
    ngOnInit() {
        // console.debug("aca esta el render despues")
        //  console.debug(this.intermediate.Grups)
        this.renderValue = this.intermediate.Grups[this.value];
    }
    ngAfterViewChecked() {
        this.renderValue = this.intermediate.Grups[this.value];
    }
}
// FIN COMPONENTE PARA EL NG SMART TABLE GRUPOS

// INICIO COMPONENTE PARA EL NG SMART TABLE USUARIO
@Component({
    selector: 'button-view-courses',
    template: `
    {{ renderValue }}
  `
})
export class ButtonViewScheduleComponent2 implements ViewCell, OnInit, AfterViewChecked {
    constructor(
        public intermediate: Intermediate,
    ) { }
    renderValue: string;
    @Input() value: string | number;
    @Input() rowData: any;
    @Output() save: EventEmitter<any> = new EventEmitter()
    ngOnInit() {
        // console.debug("aca esta el render despues")
        // console.debug(this.intermediate.Professor)
        this.renderValue = this.intermediate.Professor[this.value];
    }
    ngAfterViewChecked() {
        this.renderValue = this.intermediate.Professor[this.value];
    }
    onClick() {
        this.save.emit(this.rowData);
    }
}
// FIN COMPONENTE PARA EL NG SMART TABLE USUARIO



@Component({
    selector: 'smart-schedule',
    templateUrl: './smart-schedule.component.html',
    styleUrls: ['./smart-schedule.component.css'],
    providers:
        [UsersService,
            CoursesService,
            ButtonViewScheduleComponent2,
            ButtonViewScheduleGroupsComponent2,
            Intermediate,
            ButtonViewdPhysicalTest]
})
export class SmartScheduleComponent implements OnInit {

    allSubjectsByAllAreas: any[] = []  //RECOGE TODAS LAS MATERIAS DE TODAS LAS AREAS DEL CURSO SELECCIONADO
    enableSmartTableSubjects: boolean = false
    allSubjectsByAllAreasAllGroups: any[] = []  //GUARDA UN ARRAY CON TODAS LAS MATERIA DE TODAS LAS AREAS MULTIPLOCADO POR CADA GRUPO DEL CURSEOFER
    courseGroupsData: any[] = []
    subjectsSelectedToSchedule: any[] = []
    subJectGtoupsNgSmartTable: any[] = []
    selectSubjects: boolean = true
    stateButton: boolean = true
    allSubjectsWithCourseGroups: any[] = [];
    subjectCoursegroupData: any[] = [];
    allSubjectsByAllGroupsAllSubjectGroups: any[] = []
    classRoomsTimeSlots: any[] = []
    tempTotalEventsToAssignSplit: any[];
    SelectedWeekDefault = null;


    //VARIABLES FINALES DONDE ESTA LA INFORMACION DE TODOS LAS RESTRICCIONES Y MATERIAS SELECCIONADAS, KUNTO CON LOS GRUPOS
    breakTimesFinal: any[];
    CourseGroupsFinal: any[] = [];
    courseOfersFinal: any[];
    classRoomsFinal: any[];
    subjectsFinal: any[];
    coursesFinal: any[];
    globalBreakTimes: any[];
    Mensaje = ""

    TotalEventsToAssign: any[];



    enableSecondarystudentsasociation: boolean = false;
    isPersonal: boolean = false;
    CourseGroupIDSelected: any;
    previousEvents: any[] = [];
    collapseCards: boolean = true;
    mapClassRoom: any[];
    groupsSchedule: any[];







    isloading = false;
    serverTime: Date
    //variables usadas en este componente
    public KeyColorAcademicArea: any;
    public colorSubjects =
        ["#5d80a2",
            "#680345",
            "#7C6445",
            "#E154CE",
            "#258C64",
            "#A44015",
            "#FE9A2E",
            "#DF0101",
            "#01DF01",
            "#0040FF",
            "#565ACB",
            "#144A20",
            "#AAE951",
            "#6CF132",
            "#F19663",
            "#891CBB",
            "#21EBF3",
            "#F19663",
            "#61265F",
            "#6916E4",
            "#464208",
            "#393D78",
            "#E2337D",
            "#A1924C",
            "#52B743",
            "#176548",
            "#A0B03D",
            "#7C188A",
            "#77BB3E"]

    public Groups: any = { id: 0 };
    Groups_init: any = { id: 0 };
    AcademicArea_init: any = { id: 0 };
    public AcademicArea: any;
    public AcademicAreaSelected: any;
    public EnableTable: boolean = false;
    EnableTableBreakTimes: boolean = false
    public ListProfessors: UsersField[];
    public SubjectsModifyAddProfessor: Subject[];
    public ModelAcademicAreaID: number;
    public ModelCourseGroupID: number;
    user: string;
    student: UsersField = {
        CourseOferID: 0,

    };
    public CourseID;
    public Subject: Subject[];
    course_by_school: Course[];
    CourseSelected: Course
    subjects_by_course: Course[];
    Studends_by_Course: any;
    public List_students_by_course: any[];
    public List_students_by_course2: any[];
    public Students: any[];
    public StudentsByGroup: any[];
    public Contador: number;
    public AreStudents: boolean;
    public AreGroups: boolean;
    public school: Course[];
    public NumberGroups =
        [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26]
    public SchoolByID: School;
    public ModelProfessorSmartTable;
    public ModelProfessorSmartTable2;
    abecedario = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"]
    public SubjectGtoupID_Verified = []
    public Disponible: number = 0;
    public EnableAsociations: boolean = false
    public CreateSchedule: boolean = true
    public ShowCalendar: boolean = false
    public TotalEvents = 0;
    public CurrenEvent = 0;
    public courseofferidseleted: number;
    public SchoolIDSelected: number;
    public courseofferidseletedsemestrlize: boolean;
    public SearchSubjects = [];
    public CourseInfo;
    public midtermData: any[];
    public enablestudentsasociation: boolean = false;
    public contador_calendario: number;
    public array_to_asociate: any[] = [];
    public infoarray_to_asociate: any[] = []
    public infop: any[] = []

    isHorarios: boolean = false
    //:DAVID 
    alertLoad: boolean = false;//:DAVID 

    //inicia configuracion smart table
    modules: Module[];
    schools: School[];
    courses: Course[];
    subjects: Course[];
    userid: string;
    areaID: number = 0;
    courseID: number = 0;
    settings = {
        noDataMessage: "No hay materias para el área seleccionada",

        edit: {
            editButtonContent: '<i class="fa fa-pencil-square"></i>',
            saveButtonContent: '<i class="fa fa-floppy-o"></i>',
            cancelButtonContent: '<i class="fa fa-window-close"></i>',
            confirmSave: true,
        },
        actions: {
            add: false,
            delete: false,
            edit: true,
            columnTitle: "Acciones"
        },
        pager: {
            perPage: 20,
        },
        columns: {


            SubjectID: {
                title: 'Id Materia',
                filter: false,
                editable: false,
            },
            NameSubject: {
                title: 'Materia',
                filter: false,
                editable: false,
            },
            CourseGroupID: {
                title: 'Grupo',
                filter: false,
                editable: false,
                type: 'custom',
                renderComponent: ButtonViewScheduleGroupsComponent2
            },
            UserID: {
                title: 'Docente',
                filter: false,
                type: 'custom',
                editor: {
                    type: 'list',
                    config: {
                        list: [],
                    },
                },
                renderComponent: ButtonViewScheduleComponent2,
                onComponentInitFunction(instance) {
                    instance.save.subscribe(row => {
                        console.debug("algo se edito")
                        // this.saveDocenteDetails(row.id)
                    });
                }
            },

            TeachHours: {
                title: '# Horas a Generar',
                filter: false,
                editable: true,

            },

            DateStart: {
                title: 'Fecha Inicio',
                filter: false,
                editable: true,

            },
            DateEnd: {
                title: 'Fecha Final',
                filter: false,
                editable: true,
            },
            IsPhysicalTest:{
                title: 'Materia Prueba Fisica',
                filter: false,
                editable: true,
                type: 'custom',
                editor: {
                    type: 'list',
                    config: {
                        list: [{ value: true , title: 'Si' } , { value: false, title: 'No' } ],
                    },
                },
                renderComponent: ButtonViewdPhysicalTest
            }
            /*
                id: {
                  title: 'GRUPO',
                  filter: false,
                  type: 'custom',
                  renderComponent: ButtonViewCoursesComponent,
                  onComponentInitFunction(instance) {
                      instance.save.subscribe(row => {
                         // this.saveDocenteDetails(row.id)
                      });
                    }
                }
                */
        }
    };
    source: LocalDataSource;
    avaliableRooms: any[];
    dayOfWeekSelected: any;
    progressText = "Esto Puede Tomar Un Tiempo Dependiendo De Su Conexión A Internet, Ubicando Materias ";




    //fin configuracion smart table





    //FIND E CONFIGURACION SMART TABLE RECESOS
    constructor(private authService: AuthService,
        private route: ActivatedRoute,
        private router: Router,
        public servicios: UsersService,
        public coursesService: CoursesService,
        public profileNavComponent: ProfileNavComponent,
        private Render: ButtonViewScheduleComponent2,
        public intermediate: Intermediate,

    ) {
        this.source = new LocalDataSource(); // create the source
        this.authService.getTime().then(server2 => this.serverTime = server2.time);

    }
    //INDICO QUE LOS DATOS NO ESTAN DISPONIBLES AL CARGAR EL HTML PARA NO MOSTRAR ERRORES
    DatosDisponibles: boolean = false;
    ngOnInit() {




        this.setJquerry();
        //GUARDO LA HORA DEL SERVIDOR

        $("#Group").prop("disabled", true);
        $("#AcademicAreaID").prop("disabled", true);
        //SI NO HAY USUARIOS EN LOCAL STORAGE, LLEVAR A LOGIN
        if (this.authService.getCurrentUser() == null) {
            this.router.navigate(['/register']);
        } else {
            //DE LO CONTRARO CARGAR EL ID DEL USUARIO
            this.user = this.authService.getCurrentUser(); //devuelve el id del usuario
            //SI EL ID DEL USUARIO ES UN NUMERO ENTERO
            if (Number.isInteger(parseInt(this.user))) {
                //console.debug(this.authService.getCurrentUser());
                //CONSULTO EL PERFIL DEL USUARIO
                var validar_usuario = [false];
                var SchoolID = 0;

                for (let role of this.profileNavComponent.roles) {
                    if (role.name == "Horarios") {
                        validar_usuario = validar_usuario.concat([true])
                        SchoolID = role.SchoolID;
                        this.SchoolIDSelected = role.SchoolID
                        this.isHorarios = true

                    }
                    if (role.name == "Personal") {
                        validar_usuario = validar_usuario.concat([true])
                        SchoolID = role.SchoolID;
                        this.SchoolIDSelected = role.SchoolID
                        this.isHorarios = true
                        this.isPersonal = true
                    }

                }
                //SI ALGUN ROL PUEDE VERLO CARGAR LOS DATOS
                //DE LO CONTRARIO LLEVAR AL PEFIL
                if (validar_usuario.includes(true)) {
                    this.coursesService.getSchoolByID(SchoolID).subscribe(p => {
                        this.SchoolByID = p;
                        console.debug("ESL SCHOOL ID FUE EL", this.SchoolByID)

                        //CARGAR PROFESORES QUE ESTEN ACTIVOS EN LA ESCUELA
                        console.debug(this.serverTime.toString())
                        this.coursesService.getAllUserJobsContractBySchoolID(this.SchoolByID["id"], this.serverTime.toString()).subscribe(Userapps => {

                            //ESTO ES PARA MOSTRAR EN EL SELECT CUANDO SE ACTIVA LA CASILLA EDITAR
                            this.ModelProfessorSmartTable = []

                            /*
                             this.ModelProfessorSmartTable.push({
                                 value: 2941,
                                 title: "--ASIGNAR DESPUÉS",
                             })
                             */

                            //ESTO ES PARA MOSTRAR EN EL RENDER
                            this.ModelProfessorSmartTable2 = []
                            // Object.assign(this.ModelProfessorSmartTable2, { 2941: "--ASIGNAR DESPUÉS" })


                            Userapps.forEach(profesor => {
                                let CompleteName = $.grep([profesor.Userapps.LastName1, profesor.Userapps.LastName2, profesor.Userapps.Name1, profesor.Userapps.Name2], Boolean).join(" ");
                                this.ModelProfessorSmartTable.push({
                                    //value: CompleteName.toUpperCase(),
                                    value: profesor.Userapps.id,
                                    title: CompleteName.toUpperCase(),
                                })
                                Object.assign(this.ModelProfessorSmartTable2, { [profesor.Userapps.id]: CompleteName.toUpperCase() })
                                this.RenderProfessor()
                            });
                            this.settings.columns.UserID.editor.config.list = this.ModelProfessorSmartTable.sort(function (a, b) {
                                // Ascending: first age less than the previous
                                return a["title"] == b["title"] ? 0 : a["title"] < b["title"] ? -1 : 1;
                            });


                        })





                    });
                    this.coursesService.getAllCoursesBySchool(SchoolID).subscribe(p => {
                        this.course_by_school = p.sort(function (a, b) {
                            // Ascending: first age less than the previous
                            return a["NameCourseOfer"].localeCompare(b["NameCourseOfer"]);

                        });

                        console.debug(this.course_by_school)
                        $("#AcademicAreaID").prop("disabled", true);
                    });





                    var state = 1;
                    this.AreStudents = false;
                    this.AreGroups = false;
                    this.student = {
                        Document: '',
                        Name1: '',
                        LastName1: '',
                        CedulaMil: '',
                        IsMilitar: true,
                        SchoolID: 4,
                        CourseOferID: 0,
                        SubjectOferID: 0,
                    }
                    this.Groups = []
                    this.AcademicArea = []

                }
                else {

                    this.router.navigate(['/user/my-account']);

                }
            }
        }
    }




    setAssociations(showAlert?: boolean) {
        //RECOGE TODAS LAS MATERIAS SELECCIONADAS, REALIZA LA BUSQUEDA DE GRUPOS DE ACUERDO AL COURSEGROUP Y LUEGO BUSCA EN SUBJECTGROUS
        if (showAlert == false) { }
        else
            this.authService.swalLoading("Cargando Información")
        console.debug(this.subjectsSelectedToSchedule)
        var cx = this
        this.Groups = []
        this.allSubjectsWithCourseGroups = []
        this.subjectCoursegroupData = []
        this.allSubjectsByAllGroupsAllSubjectGroups = []

        //PARA CADA UNA DE LAS MATERIAS SELECCIONADAS BUSCO  LOS COURSEGROUPS QUE EXISTAN
        async.forEach(this.subjectsSelectedToSchedule, function (subjectsSelected, callback) {

            cx.coursesService.getAllCourseGroupsByCourseOferByID(subjectsSelected.CourseOfer.id).subscribe(courseGroups => {

                if (courseGroups.length == 0) {

                } else {
                    courseGroups = courseGroups.sort(function (a, b) {
                        // Ascending: first age less than the previous
                        return a["name"] == b["name"] ? 0 : a["name"] < b["name"] ? -1 : 1;
                    })


                    //REVISO QUE EL COURSEGROUPS NO SE ENCUENTRE EN LA VARIABLE GROUPS
                    courseGroups.forEach(courseGroup => {
                        var exist = -1
                        exist = cx.Groups.findIndex(x => x.id === courseGroup.id)
                        if (exist == -1) cx.Groups.push(courseGroup);
                    });



                    cx.intermediate.Grups = {}
                    for (let i = 0; i < cx.Groups.length; i++) {
                        Object.assign(cx.intermediate.Grups, { [cx.Groups[i].id]: cx.Groups[i].name.toUpperCase() })
                    }



                }
                callback()


            }, err => {
                callback()
            })


        }, function (err) {

            console.debug("LOS courseGroups DE LAS MATERIAS SELECCIONADAS FUERON: ", cx.Groups)

            //LLENO LA VARIABLE allSubjectsWithCourseGroups CON TODA LA INFORMACION DE LOS GUPOS Y DE LAS MATERIAS 
            //EL TOTAL SERA DE n GRUPOS del courseOffer * k MATERIAS SELECCIONADAS 
            var idexTemp = 1
            cx.Groups.forEach(grupo => {
                cx.subjectsSelectedToSchedule.forEach((materia) => {
                    if ((grupo.CourseOferID === materia.CourseOfer.id)) {
                        var tempObject = {
                            idTemp: idexTemp,
                            FullNameSubject: grupo.name + " " + materia.NameSubject,
                            CourseGroup: grupo,
                            Subject: materia,
                            SubjectGroup: null
                        }
                        cx.allSubjectsWithCourseGroups.push(tempObject)
                        idexTemp++
                    }
                })
            })

            console.debug("LOS allSubjectsWithCourseGroups : ", cx.allSubjectsWithCourseGroups)
            //AHORA BUSCO PARA ESE coursegroup y para el subject id SI TIENE UN SUBJECTGROUP
            async.forEach(cx.allSubjectsWithCourseGroups, function (subjectCoursegroup, callback) {
                cx.coursesService.getSubjectGroupByCourseGroupIDAndSubjectID(subjectCoursegroup.CourseGroup.id, subjectCoursegroup.Subject.id).subscribe(dataSubjectGroup => {
                    if (!isNullOrUndefined(dataSubjectGroup[0]))
                        cx.subjectCoursegroupData.push(dataSubjectGroup[0])
                    callback()
                }, e => { callback() }
                );
            }, function (err) {

                console.debug("subjectCoursegroupData", cx.subjectCoursegroupData)



                cx.subjectCoursegroupData.forEach(subjectGroupAllData => {
                    var positionFound = -1
                    var positionFound = cx.allSubjectsWithCourseGroups.findIndex(x => (x.CourseGroup.id === subjectGroupAllData.CourseGroupID) && (x.Subject.id === subjectGroupAllData.SubjectID))
                    if (positionFound >= 0)
                        cx.allSubjectsWithCourseGroups[positionFound].SubjectGroup = subjectGroupAllData
                })



                console.debug("EL FINAL DE LOS DATOS CON MATERIAS GROUPS Y SUBJECTGROUPS SON: ", cx.allSubjectsWithCourseGroups)


                //SI DE CASUALIDAD ALGUN GRUPO TIENE UN PROFESOR QUE NO ESTA DENTRO DE userSubjectsJobs LOS MUESTRO
                // DESPUES CUANDO SE VA A GENERAR EL HORARIO SE DEBE VALIDAR QUE ESE DOCENTE NO SE PUEDE PORQUE NO ESTA ACTIVO
                // O SE LE ACABARAON LAS HORAS

                cx.allSubjectsWithCourseGroups.forEach(subjectsALL => {
                    var position = -1
                    if (!isNullOrUndefined(subjectsALL.SubjectGroup)) {
                        position = cx.ModelProfessorSmartTable.findIndex(x => x.value === subjectsALL.SubjectGroup.userapp.id)
                        if (position >= 0) {
                            //NO HACER NADA PORQUE YA ESTA 
                        }
                        else {
                            let CompleteName = $.grep([subjectsALL.SubjectGroup.userapp.LastName1, subjectsALL.SubjectGroup.userapp.LastName2, subjectsALL.SubjectGroup.userapp.Name1, subjectsALL.SubjectGroup.userapp.Name2], Boolean).join(" ");
                            CompleteName = CompleteName + " (NO HABILITADO-ESCOGER OTRO DOCENTE)"
                            cx.ModelProfessorSmartTable.push({
                                value: subjectsALL.SubjectGroup.userapp.id,
                                title: CompleteName.toUpperCase(),
                            })
                            Object.assign(cx.ModelProfessorSmartTable2, { [subjectsALL.SubjectGroup.userapp.id]: CompleteName.toUpperCase() })
                            cx.RenderProfessor()

                        }

                    }

                });

                cx.settings.columns.UserID.editor.config.list = cx.ModelProfessorSmartTable.sort(function (a, b) {
                    // Ascending: first age less than the previous
                    return a["title"] == b["title"] ? 0 : a["title"] < b["title"] ? -1 : 1;
                });



                cx.enableSmartTableSubjects = true
                cx.selectSubjects = false
                if (showAlert == false) { }
                else
                    cx.authService.swalEndLoad()
                cx.converdataToSmartTable()





            })



        })




    }


    callSubjects(model) {
        this.authService.swalLoading("Cargando Materias")
        this.collapseCards = false
        this.allSubjectsByAllAreas = []

        this.coursesService.getAllAcademicAreasBySchool(this.SchoolByID["id"]).subscribe(p => {
            this.AcademicArea = p;
            this.AcademicAreaSelected = this.AcademicArea


            console.debug("LAS AREAS ACADEMICAS SON: ", this.AcademicArea)
            this.CourseSelected = this.course_by_school.find(x => x.id == model.id);
            console.debug("CourseSelected", this.CourseSelected)
            var cx = this
            //####################################################################################
            //############     PRIMER LLAMAR LAS MATERIAS DE TODAS LAS AREAS ACADEMICAs  #########
            //####################################################################################

            //PARA CADA UNA DE LAS AREAS ACADEMICAS VOY A CARGAR LAS MATERIAS
            async.forEach(this.AcademicAreaSelected, function (academics, callback) {
                cx.coursesService.getAllSubjectsByAreaByCourse(academics.id, cx.CourseSelected.CourseID).subscribe(subjectsByArea => {
                    //RECOJO TODAS LAS MATERIAS DE TODAS LAS AREAS Y LA CONCATENO EN LA VARIABLE PRINCIPAL
                    //LE AGREGO LA OPCION isSelected para saber si se selecciono la materia
                    subjectsByArea.forEach(subject => {

                        subject["isSelected"] = false
                        subject["CourseOfer"] = cx.CourseSelected
                        cx.allSubjectsByAllAreas = cx.allSubjectsByAllAreas.concat(subject)
                    });
                    callback()
                }, e => { callback() }
                );
            }, function (err) {
                console.debug("TODAS LAS MATERIAS PARA ESTE CURSO SON: ", cx.allSubjectsByAllAreas)

                //DESPUES DE CARGAR LAS MATERIAS COMPARO CON  subjectsSelectedToSchedule PARA CHECKEAR SI FUERON ESCOGIDAS ANTERIOIRMENTE
                cx.allSubjectsByAllAreas.forEach(element => {

                    var pos = cx.subjectsSelectedToSchedule.findIndex(x => (x.id === element.id))
                    if (pos >= 0) {
                        element.isSelected = true
                    }

                });

                cx.authService.swalEndLoad()







            })


        })






    }




    deleteSelectedSubject(id: number) {
        //REVISA SI LA MATERIA ESTA EN EL ARREGLO DE MATERIAS SELECCIONADAS PARA HORARIOS
        var pos = this.subjectsSelectedToSchedule.findIndex(x => (x.id === id))
        if (pos >= 0) {
            this.subjectsSelectedToSchedule.splice(pos, 1)
        }
        //CAMBIO EL ESTADO DEL ARREGLO allSubjectsByAllAreas
        var pos2 = this.allSubjectsByAllAreas.findIndex(x => (x.id === id))
        if (pos2 >= 0) {
            this.allSubjectsByAllAreas[pos2].isSelected = false
        }



    }


    subjectsSelected() {
        this.stateButton = false
        this.allSubjectsByAllAreas.forEach(subject => {
            if (subject.isSelected) {
                var pos = this.subjectsSelectedToSchedule.findIndex(x => (x.id === subject.id))
                if (pos >= 0) {
                    //NO HACER NADA
                }
                else {
                    this.subjectsSelectedToSchedule.push(subject)
                }

            }
            else {

                var pos = this.subjectsSelectedToSchedule.findIndex(x => (x.id === subject.id))
                if (pos >= 0) {
                    //NO HACER NADA
                    this.subjectsSelectedToSchedule.splice(pos, 1)
                }



            }
        })

        console.debug("this.subjectsSelectedToSchedule", this.subjectsSelectedToSchedule)


    }


    setJquerry() {
        $(function () {
            // card controls


            // header double click
            $('.cat__core__sortable .card-header').on('click', function () {
                $(this).closest('.card').toggleClass('cat__core__sortable__collapsed');

            });

        });

    }




    converdataToSmartTable() {
        //CONVIERTE LOS DATOS GENERADOS EN allSubjectsByAllAreasAllGroups PARA VERLOS EN EL SMART TABLE
        this.subJectGtoupsNgSmartTable = []
        this.allSubjectsWithCourseGroups.forEach(SubjectsByAllSubjectGroups => {
            var date = new Date(this.dayOfWeekSelected)

            var dataSubjectGroup = {
                "id": (!isNullOrUndefined(SubjectsByAllSubjectGroups.SubjectGroup)) ? SubjectsByAllSubjectGroups.SubjectGroup.id : null,
                "CourseGroupID": SubjectsByAllSubjectGroups.CourseGroup.id,
                "SubjectID": SubjectsByAllSubjectGroups.Subject.id,
                "DateStart": (!isNullOrUndefined(SubjectsByAllSubjectGroups.SubjectGroup)) ? moment(SubjectsByAllSubjectGroups.SubjectGroup.DateStart).utc().format('YYYY-MM-DD') : moment(date).format('YYYY-MM-DD'),
                "DateEnd": (!isNullOrUndefined(SubjectsByAllSubjectGroups.SubjectGroup)) ? moment(SubjectsByAllSubjectGroups.SubjectGroup.DateEnd).utc().format('YYYY-MM-DD') : moment(date).add(1, 'days').format('YYYY-MM-DD'),
                "UserID": (!isNullOrUndefined(SubjectsByAllSubjectGroups.SubjectGroup)) ? SubjectsByAllSubjectGroups.SubjectGroup.UserID : 2941,
                "CodeClassRoom": (!isNullOrUndefined(SubjectsByAllSubjectGroups.SubjectGroup)) ? SubjectsByAllSubjectGroups.SubjectGroup.CodeClassRoom : null,
                "CodeBlackBoard": (!isNullOrUndefined(SubjectsByAllSubjectGroups.SubjectGroup)) ? SubjectsByAllSubjectGroups.SubjectGroup.CodeBlackBoard : null,
                "NameSubject": SubjectsByAllSubjectGroups.Subject.NameSubject,
                "TeachHours": SubjectsByAllSubjectGroups.Subject.MaxSlots,
                "OriginalTeachHours": SubjectsByAllSubjectGroups.Subject.MaxSlots,
                "IsPhysicalTest": SubjectsByAllSubjectGroups.SubjectGroup.IsPhysicalTest,
            }

            if (isNullOrUndefined(SubjectsByAllSubjectGroups.SubjectGroup) == true) {
                dataSubjectGroup["MidTerms"] = 0
                if (SubjectsByAllSubjectGroups.Subject.HPM == 0)
                    dataSubjectGroup["MidTerms"] = 1
                else if (SubjectsByAllSubjectGroups.Subject.HPM >= 1 && SubjectsByAllSubjectGroups.Subject.HPM <= 8)
                    dataSubjectGroup["MidTerms"] = 1
                else if (SubjectsByAllSubjectGroups.Subject.HPM >= 9 && SubjectsByAllSubjectGroups.Subject.HPM < 16)
                    dataSubjectGroup["MidTerms"] = 2
                else
                    dataSubjectGroup["MidTerms"] = 3
            }
            else {
                //SI YA ESTA DEFINIDO DEJARLO
                dataSubjectGroup["MidTerms"] = SubjectsByAllSubjectGroups.SubjectGroup.MidTerms

            }


            this.subJectGtoupsNgSmartTable.push(dataSubjectGroup)
        });


        console.debug("Lo que se va a cargar en el smart table", this.subJectGtoupsNgSmartTable)
        this.source.empty()
        this.source.load(this.subJectGtoupsNgSmartTable)

    }


    asociarestudiantes() {

        this.authService.swalLoading("Asociando Estudiantes")
        this.isloading = true;

        var count = 1
        var cx = this;
        async.forEachLimit(this.AcademicAreaSelected, 1, function (respuesta_u, callback) {
            cx.coursesService.assignUserSubject(
                {

                    SubjectGroupID: respuesta_u.id,
                    CourseGroupID: cx.ModelCourseGroupID

                }
            ).subscribe(ll => {
                callback()
            })
        }, function (err) {
            cx.authService.swalSuccess("Estudiantes asociados", "Todos los Estudiantes le Han Sido Asignadas Las Materias Seleccionadas")
            cx.enablestudentsasociation = false;
        });













    }

    saveusersubject(indexsubjectgroup: number) {
        //PRIMERO LLAMO A LOS ESTUDIANTES QUE PERTENEZCAN AL COURSEOFFERID
        this.coursesService.getUsersByCourseOfferIDandCourseGroupID(this.courseofferidseleted, this.midtermData[indexsubjectgroup].groupid).subscribe(UsersByCourse => {


            for (let i = 0; i < UsersByCourse.length; i++) {
                //POR CADA ESTUDIANTE CREO EN UserSubject UN REGISTRO CON LOS SIGUIENTES DATOS
                this.coursesService.createUserSubjects({
                    "SubjectGroupID": this.midtermData[indexsubjectgroup].idsubjectgroup,
                    "FinalRecord": 0,
                    "UserID": UsersByCourse[i]["UserID"],

                }).subscribe(usersubject => {

                    for (var j = 0; j < this.midtermData[indexsubjectgroup].subjectinfo.length; j++) {




                        this.coursesService.saveUserRecord(0, {
                            "UserSubjectID": usersubject.id,
                            "ParcialRecord": 0,
                            "DateRecordID": this.midtermData[indexsubjectgroup].subjectinfo[j].iddaterecord,
                            "MidTermID": this.midtermData[indexsubjectgroup].subjectinfo[j].idmidterm,
                            "IsAccording": true
                        }).subscribe(l => { var l2 = l })






                    }


                })

            }

        })


    }



    RenderProfessor() {
        //RENDERIZAR PROFESOR AL SMART TABLE
        this.intermediate.Professor = this.ModelProfessorSmartTable2
        this.Render.ngOnInit()
    }
    AddSubjectGrupID() {
        //FUNCION UTILIZADA PARA INSERTAR EL ID DE LA TABLA SUBJETGROUP EN EL OBJETO QUE CARGA EL SMART TABLE
        for (let j = 0; j < this.SubjectGtoupID_Verified.length; j++) {
            //CUANDO SE GUARDÓ LA INFORMACION EN SUBJECT GROUP LA VARIABLE  SubjectGtoupID_Verified
            //CONTIENE EL ID DE LO QUE SE GUARDO
            //AHORA SE PRETENDE GUARDAR ESE ID EN LA VARIABLE SubjectsModifyAddProfessor 
            //LA CUAL CARGA EL SMART TABLE
            console.debug(this.SubjectGtoupID_Verified[j].SubjectGroupID)

            //BUSCA EL INDEX DE UN OBJETO
            var index = this.SubjectsModifyAddProfessor.findIndex(i => i.SubjectID === this.SubjectGtoupID_Verified[j].SubjectID);
            //CON EL INDEX  CONCATENO EL ID DE SUBJECTGROUP
            this.SubjectsModifyAddProfessor[index]["SubjectGroupID"] = this.SubjectGtoupID_Verified[j].SubjectGroupID
            this.SubjectGtoupID_Verified = []
        }
        //veo como quedo el array cuando le inserto el SubjectGroupID
        console.debug(this.SubjectsModifyAddProfessor)
    }

    textSemestralize(id: number) {
        var num = this.course_by_school.find(x => x.id == id);
        if (num)
            return num.IsSemestralize;
        return false
    }


    infocoursebyID(id: number) {
        var num = this.course_by_school.find(x => x.id == id);
        if (num)
            return num;
        return false
    }



    HPMSubjects(SubjectID: number) {
        var num = this.SearchSubjects.find(x => x.SubjectID == SubjectID);
        if (num)
            return num.HPM;
        else
            return false
    }


    onEditConfirm(event, showAlert?: boolean): any {

        console.debug(event)
        //valido las fechas
        if (moment(event.newData.DateStart, 'YYYY-MM-DD').isValid() == true && moment(event.newData.DateEnd, 'YYYY-MM-DD').isValid() == true) {

            if (parseInt(event.newData.TeachHours) > parseInt(event.data.OriginalTeachHours) * 5) {
                this.authService.swalError("Error al modificar las horas catedra a generar ", "La cantidad de horas ingresadas supera el máximo por semana: " + parseInt(event.data.OriginalTeachHours) * 5)

            }
            else {

                //if (parseInt(event.newData.TeachHours) < parseInt(event.data.OriginalTeachHours)) {
                //  this.authService.swalError("Error al modificar las horas catedra a generar ", "El minimo de horas catedra para asignar a esta materia es: " + event.data.TeachHours)
                // }



                // ########## VALIDO QUE EL DOCENTE QUE SE GUARDO SI ESTE HABILITADO
                var docentesNoHabilitados = []
                this.ModelProfessorSmartTable.forEach(docente => {
                    //REVISO TODOS LOS DOCENTES QUE NO ESTEN HABILITADOS
                    //PARA ELLO VOY A LLENAR LA VARIABLE docentesNoHabilitados

                    if (docente.title.includes("NO HABILITADO-ESCOGER OTRO DOCENTE")) {
                        var pos = -1
                        pos = docentesNoHabilitados.findIndex(x => x.value === docente.value)
                        if (pos >= 0) {
                        }
                        else {
                            console.debug(docente)
                            docentesNoHabilitados.push({
                                value: docente.value,
                                title: docente.title,
                            })
                        }

                    }

                })
                console.debug("DOCENTES NO HABILITADOS", docentesNoHabilitados)
                // REVISO QUE NINGUNO DE LOS UserID no esten dentro del array de los docentes no habilitados
                var error = 0

                var positionHabilitado = -1
                positionHabilitado = docentesNoHabilitados.findIndex(x => x.value === parseInt(event.newData.UserID))
                if (positionHabilitado >= 0) {
                    error = 1
                }


                if (error == 1) {
                    this.authService.swalError("Error al asociar docente", "No se puede guardar el usuario porque no esta habilitado, por favor seleccione otro")
                    console.debug("No se puede guardar el usuario porque no esta habilitado, por favor seleccione otro.")
                    return
                }
                // ########## TERMINA LA VALIDACIÓN DE DOCENTES 


                if (showAlert == false) { }
                else
                    this.authService.swalLoading("Guardando Información")
                var Info = {
                    "CourseGroupID": event.newData.CourseGroupID,
                    "SubjectID": event.newData.SubjectID,
                    "MidTerms": event.newData.MidTerms,
                    "DateStart": moment(event.newData.DateStart).format('YYYY-MM-DD'),
                    "DateEnd": moment(event.newData.DateEnd).format('YYYY-MM-DD'),
                    "UserID": parseInt(event.newData["UserID"]),
                    "HPM": event.newData.HPM,
                    "IsPhysicalTest": event.newData.IsPhysicalTest
                }

                //SI EL DATO VIENE CON ID ES PORQUE YA TIENE SUBJECTGROUP, LO ADJUNTO A Info
                //
                if (isNullOrUndefined(event.newData.id) == false) {
                    Info["id"] = event.newData.id
                }


                console.debug("Se le va a mandar al enpoiint", Info)






                // #########################
                //    1 CREO EL SUBJECGROUP
                //##########################

                this.coursesService.createSubjectGroup(Info).subscribe(respuesta_nuevo => {
                    event.newData.id = respuesta_nuevo.id
                    // #########################
                    //    2. ASOCIO A LOS ESTUDIANTES
                    //##########################

                    this.coursesService.assignUserSubject({
                        SubjectGroupID: respuesta_nuevo.id,
                        CourseGroupID: respuesta_nuevo.CourseGroupID

                    }).subscribe(ll => {
                        if (showAlert == false) { }
                        else {
                            this.authService.swalEndLoad()
                            //this.authService.swalSuccess("Docente asociado correctamente", "Se ha asociado la materia al docente correctamente")
                            event.confirm.resolve(event.newData);
                        }
                    }, e => {
                        this.authService.swalError("Error asociando estudiantes", "No se pudo asociar los estudiantes, error: " + e)
                    })

                }, e => {
                    this.authService.swalError("Error asociando docente  ", "No se pudo asociar el docente, error: " + e)
                })


            }


        } else {
            this.authService.swalError("Error asignando fechas  ", "Verifique el formato de las fechas sea el correcto:  YYYY-MM-DD")
            return
        }

    }



    generateSmartSchedule() {

        //ESTA FUNCION CARGA TODAS LA INFORMACION Y RESTRICCIONES DE LOS CURSOS DE LAS MATERAS QUE EL USUARIO SELECCIONO

        // PASOS PARA EL ALGORITMO
        //  1. REVISO QUE EL DOCENTE QUE ESTA ASOCIADO A LA MATERIA SI ESTE PERMITIDO
        //  2. GENERO SUBJECTGROUPS QUE NO TENGAN id , CON ESTO ASOCIO A LOS ESTUDIANTES A LAS MATERIAS
        //  3. REVISO QUE EN subJectGtoupsNgSmartTable LAS HORAS A ASIGNAR SEAN MAYORES A 0
        //  4. CARGO LOS BREAKTIMES, LOS COURSEGROUPS, CLASSROOMS DE LOS COURSEOFFER MEDIANTE EL ASINCRONO
        //  5. GUARDO EN UNA VARIABLE LLAMADA COURSEOFERSSCHEDULE TODA LA INFORMACION DE LAS RESTRICCCIONES  Y OTRA LLAMADA SUBJECTSSCHEDULE LAS MATERIAS SELCCIONADAS
        // LLAMO FUNCION PARA CREAR EL SMARTSCHEDULE

        this.breakTimesFinal = []
        this.CourseGroupsFinal = []
        this.courseOfersFinal = []
        this.classRoomsFinal = []
        this.subjectsFinal = []
        this.coursesFinal = []

        this.ShowCalendar = true
        $('#Calendario').show()
        $('#calendar').fullCalendar('removeEvents')
        this.student.CourseOferID = 0


        if (isNullOrUndefined(this.SelectedWeekDefault)) {
            this.authService.swalError("Error al generar horario", "Seleccione la semana para generar horario")
            return
        }


        this.authService.swalLoading("Creando Horarios")






        // 1. REVISO QUE EL DOCENTE QUE ESTA ASOCIADO A LA MATERIA SI ESTE PERMITIDO
        var docentesNoHabilitados = []
        this.ModelProfessorSmartTable.forEach(docente => {
            //REVISO TODOS LOS DOCENTES QUE NO ESTEN HABILITADOS
            //PARA ELLO VOY A LLENAR LA VARIABLE docentesNoHabilitados
            console.debug(docente)
            if (docente.title.includes("NO HABILITADO-ESCOGER OTRO DOCENTE")) {
                var pos = -1
                pos = docentesNoHabilitados.findIndex(x => x.value === docente.value)
                if (pos >= 0) {
                }
                else {
                    docentesNoHabilitados.push({
                        value: docente.value,
                        title: docente.title,
                    })
                }

            }

        })
        console.debug("DOCENTES NO HABILITADOS", docentesNoHabilitados)
        // REVISO QUE NINGUNO DE LOS UserID no esten dentro del array de los docentes no habilitados
        var error = 0
        this.subJectGtoupsNgSmartTable.forEach(elementSmartTable => {
            var positionHabilitado = -1

            positionHabilitado = docentesNoHabilitados.findIndex(x => x.value === elementSmartTable.UserID)
            if (positionHabilitado >= 0 && docentesNoHabilitados.length > 0) {
                error = 1
            }
        })
        if (error == 1) {
            this.authService.swalError("Error al generar el horario", "Hay docentes que no pueden ser seleccionados para generar el horario porque no estan habilitados.")
            console.debug("Hay docentes que no pueden ser seleccionados para generar el horario porque no estan habilitados.")
            return
        }




        // 2. GENERO SUBJECTGROUPS QUE NO TENGAN id , CON ESTO ASOCIO A LOS ESTUDIANTES A LAS MATERIAS
        this.subJectGtoupsNgSmartTable.forEach(elementSmartTable => {
            //SI EL DATO TIENE id =null ES PORQUE NO EXISTE EN subjectgroups, ejecutar funcion que guarda los datos y asocia esudiantes
            if (isNullOrUndefined(elementSmartTable.id)) {
                this.onEditConfirm({ newData: elementSmartTable }, false)
            }
        })


        this.source.empty()
        this.source.load(this.subJectGtoupsNgSmartTable)
        console.debug("LOS subJectGtoupsNgSmartTable actualizados son: ", this.subJectGtoupsNgSmartTable)

        // 3. REVISO QUE EN subJectGtoupsNgSmartTable LAS HORAS A ASIGNAR SEAN MAYORES A 0
        if (this.subJectGtoupsNgSmartTable.findIndex(x => x.TeachHours === 0) >= 0) {
            this.authService.swalError("Error al generar horario", "Asegurese que todas las materias tienen al menos 1 hora a generar")
            return
        }

        console.log("las materias", JSON.stringify(this.subJectGtoupsNgSmartTable))



        console.log("los grupos", JSON.stringify(this.Groups))


        console.log("los course_by_school", JSON.stringify(this.course_by_school))

        console.log("subjectsSelectedToSchedule", JSON.stringify(this.subjectsSelectedToSchedule))








        var data = {
                subjects: this.subJectGtoupsNgSmartTable,
                weekNumber: this.dayOfWeekSelected,
                schoolID: this.SchoolIDSelected,
                groups: this.Groups,
                course_by_school: this.course_by_school,
                subjectsSelectedToSchedule: this.subjectsSelectedToSchedule
        }



        this.coursesService.createSchedule(data).subscribe(schedule => {

            this.CourseGroupsFinal = JSON.parse(schedule["CourseGroupsFinal"])
            this.classRoomsFinal = JSON.parse(schedule["classRoomsFinal"])
            this.mapClassRoom = JSON.parse(schedule["mapClassRoom"])
            this.groupsSchedule = JSON.parse(schedule["groupsSchedule"])
            this.authService.swalEndLoad()

        }, err => {
            this.authService.swalError("Error al cargar horario","Ocurrio un error, detalles: "+err)
        })

        /*
        
                var breakTimes = []
                var courseOfers = []
                var courseOfersData = []
                var classRoomsOffers = []
                var courses = []
                var cx = this
        
        
                var date = new Date(this.dayOfWeekSelected)
                var m = moment(date, 'YYYY-MM-DD');
                var RangeDates1 = this.getDateRangeOfWeek(m.format('W'), m.format('YYYY'))
                var day = moment(RangeDates1[0], 'YYYY-MM-DD').startOf('day');
                var endOfWeek = moment(RangeDates1[1], 'YYYY-MM-DD').endOf('day');
        
        
                this.coursesService.getAllActiveGlobalBreakTimesBySchoolID(this.SchoolIDSelected, [day.format("YYYY-MM-DD HH:mm:ss"), endOfWeek.format("YYYY-MM-DD HH:mm:ss")]).subscribe(globalbreak => {
                    this.globalBreakTimes = globalbreak
                }, err => {
                    this.authService.swalError("Error al cargar recesos globales", "No se pudo cargar los recesos globales, detalles: " + err)
                })
        
                async.forEach(this.Groups, function (courses, callback) {
                    var pos = -1
                    pos = courseOfers.findIndex(x => x.CourseOferID === courses.CourseOferID)
                    if (pos >= 0) {
                    }
                    else {
                        courseOfers.push({ CourseOferID: courses.CourseOferID })
                    }
                    callback()
        
        
                }, function (err) {
        
                    console.debug("TODOS LOS CURSE OFFERS SELECCIONADOS FUERON:", courseOfers)
                    //CARGO ASYNC PARA CARGAR LA INFORMACION DE LOS BREAKS por courseoffer
                    async.forEach(courseOfers, function (courses, callback) {
                        cx.coursesService.getAllActiveBreakTimesByCourseOferByID(courses.CourseOferID).subscribe(breaktime => {
                            breakTimes = breakTimes.concat(breaktime)
                            callback()
                        }, err => {
                            callback()
                        })
                    }, function (err2) {
                        console.debug("TODOS LOS BREAKS DE LAS OFERTAS SON:", breakTimes)
                        console.debug("TODOS LOS CourseGroups son ", cx.Groups)
        
                        //CREO EL ARRAY DE LOS CURSOS OFERADOS SELECCIONADOS A PARTIR DE LOS CourseGroups
                        //RECORRO TODOS LOS COURSEOFERS DE LA ESCUELA Y LOS COMPARO CON LOS CURSE OFFERS SELECCIONADOS CUANDO ESCOGI LAS MATERIAS
                        courseOfers.forEach(course => {
                            var pos = -1
                            pos = cx.course_by_school.findIndex(x => x.id === course.CourseOferID)
                            if (pos >= 0) {
                                courseOfersData.push(cx.course_by_school[pos])
                            }
                        })
                        console.debug("TODOS LOS CourseOfers seleccionados fueron: ", courseOfersData)
        
                        //CARGAR TODOS LOS CLASSRROOMS DE LAS OFERTAS SELECCIONADAS
                        async.forEach(courseOfers, function (courses, callback) {
                            cx.coursesService.getAllClassroomAvailabilitiesByCourseOfferID(courses.CourseOferID).subscribe(classRoom => {
                                classRoomsOffers = classRoomsOffers.concat(classRoom)
                                callback()
                            }, err => {
                                callback()
                            })
        
                        }, function (err2) {
                            console.debug("LOS SALONES DE LOS CURSOS OFERTADOS SELECCIONADOS SON:", classRoomsOffers)
                            console.debug("LAS MATERIAS SELECCIONADAS FUERON: ", cx.subjectsSelectedToSchedule)
        
        
                            courseOfersData.forEach(elementAt => {
                                var pos = -1
                                pos = courses.findIndex(x => x.id === elementAt.course.id)
                                if (pos >= 0) {
                                }
                                else {
                                    courses.push(elementAt.course)
                                }
                            })
        
        
        
                            cx.breakTimesFinal = breakTimes
                            cx.CourseGroupsFinal = cx.Groups
                            cx.courseOfersFinal = courseOfersData
                            cx.classRoomsFinal = classRoomsOffers
                            cx.subjectsFinal = cx.subjectsSelectedToSchedule
                            cx.coursesFinal = courses
        
        
                            cx.generateEvents()
        
        
        
                        })
        
        
        
                    })
        
        
                })
         
        
                */


    }




    generateEvents() {
        //1. VALIDAR TODOS LOS CAMPOS
        //   1.A VALIDAR EL TeachingHoursmin DE LOS CURSOS
        //   1.B VALIDAR EL MaxSlots de las materias
        //2. CREO EL CALENDARIO CON LOS TIMESLOTS DE L-V de 00:00:00 - 23:59:00 CON LOS TeachingHoursmin
        //   2.A CONSULTO LA CANTIDAD DE ESTUDIANTES DE CADA GRUPO Y LOS ORDENO DE MAYOR  A MENOR, ESTO ES PARA TENER PRELACION EN LOS SALONES CON MAYOR CAPACIDAD
        //3. PARA CADA CourseGroupsFinal REVISO LAS MATERIAS QUE TIENE SE ADJUTAN AL OBJETO Y TAMBIEN SE LE ADJUNTA LA INFORMACION DE subjectgroup
        //   3.A BUSCAR LA SEMANA EN LA QUE ESTOY PARA ASIGNARLO A LOS TIMESLOTS CEADOS
        //   3.B ADJUNTAR LAS RESTRICCIONES GLOBALES A classRoomsTimeSlots
        //4. A CADA CourseGroupsFinal INCLUIRLE LOS classRoomsTimeSlots TENIENDO EN CUENTA EL TIEMPO QUE CADA GRUPO VE CLASES 
        //5. INCLUIR LOS breaktimes DE CADA OFERTA A CourseGroupsFinal


        console.debug("-----------  generateEvents ------------------")
        console.debug("LOS SUBJECTS GROUP FINALES SON: ", this.subJectGtoupsNgSmartTable)
        console.debug("LOS RECESOS A PROCESAR SON: ", this.breakTimesFinal)
        console.debug("LOS GRUPOS TOTALES A PROCESAR SON: ", this.CourseGroupsFinal)
        console.debug("LAS OFERTAS TOTALES  A PROCESAR SON: ", this.courseOfersFinal)
        console.debug("LOS SALONES A PROCESAR SON: ", this.classRoomsFinal)
        console.debug("LAS MATERIAS A PROCESAR SON: ", this.subjectsFinal)
        console.debug("LOS CURSOS A PROCESAR SON: ", this.coursesFinal)
        console.debug("LOS RECESOS GLOBALES A PROCESAR SON:", this.globalBreakTimes)


        var classRoomsTimeSlots = []

        var errorLog = []

        //1. VALIDAR TODOS LOS CAMPOS
        //1.A VALIDAR EL TeachingHoursmin DE LOS CURSOS
        this.coursesFinal.forEach(courses => {
            if (courses.TeachingHoursmin == 0) {
                errorLog = errorLog.concat(["Al curso <b>" + courses.NameCourse + "</b> no se le ha asignado el tiempo de hora catedra"])
            }
        })
        //1.B VALIDAR EL MaxSlots de las materias
        this.subjectsFinal.forEach(subject => {
            if (subject.MaxSlots == 0 || isNullOrUndefined(subject.MaxSlots)) {
                errorLog = errorLog.concat(["La matería <b>" + subject.NameSubject + "</b><span class='text-muted'><small><i> (" + subject.CourseOfer.NameCourseOfer + ")</i></small></span> no se le ha asignado el tiempo máximo que puede ser vista en un día"])
            }
        })

        if (errorLog.length > 0) {
            var mensaje = ""
            errorLog.forEach(element => {
                mensaje = mensaje + "<br>" + element
            })
            Swal({
                type: 'error',
                title: "Error al generar horarios",
                html: "Se encontraron los siguientes errores: <br>" + mensaje,
                showConfirmButton: true,
                allowEscapeKey: false,
                allowOutsideClick: false,

            });
        }
        else {


            //2.A CONSULTO LA CANTIDAD DE ESTUDIANTES DE CADA GRUPO Y LOS ORDENO DE MAYOR  A MENOR, ESTO ES PARA TENER PRELACION EN LOS SALONES CON MAYOR CAPACIDAD
            var cx = this
            async.forEach(this.CourseGroupsFinal, function (group, callback) {
                cx.coursesService.countAllUserCoursesByCourseOferID(group.id).subscribe(number => {
                    group.CourseGroupID = group.id
                    group.TotalStudents = number.count
                    callback()
                }, err => {
                    callback()
                })
            }, function (err) {
                //var ascending = homes.sort((a, b) => Number(a.price) - Number(b.price));
                //var descending = homes.sort((a, b) => Number(b.price) - Number(a.price))
                cx.CourseGroupsFinal = cx.CourseGroupsFinal.sort((a, b) => Number(b.TotalStudents) - Number(a.TotalStudents))
                console.debug("los grupos con los numeros de estudiantes es: ", cx.CourseGroupsFinal)



                //3. PARA CADA  courseGroup LE AGREGO LA INFORMACION DE LA MATERIA, EL SUBJECTGROUP, Y SLOTTIME DE ACUERDO A CUANDO INICIA Y TERMINA CLASE EL GRUPO
                for (var CourseGroupi = 0; CourseGroupi < cx.CourseGroupsFinal.length; CourseGroupi++) {
                    var CourseGroup = cx.CourseGroupsFinal[CourseGroupi]
                    //console.debug(CourseGroup)
                    CourseGroup.Subjects = []
                    CourseGroup.TimeSlots = {}
                    CourseGroup.InfoTimes = []



                    for (var subjecti = 0; subjecti < cx.subjectsFinal.length; subjecti++) {
                        var subject = cx.subjectsFinal[subjecti];
                        var subjectTemp = {
                            AcademicAreaID: subject.AcademicAreaID,
                            AcademicaArea: subject.AcademicaArea,
                            CourseID: subject.CourseID,
                            CourseOfer: subject.CourseOfer,
                            Creditos: subject.Creditos,
                            Description: subject.Description,
                            HFH: subject.HFH,
                            HPM: subject.HPM,
                            HTI: subject.HTI,
                            MaxSlots: subject.MaxSlots,
                            ModuleID: subject.ModuleID,
                            NameSubject: subject.NameSubject,
                            SemesterNumber: subject.SemesterNumber,
                            SubjectGroup: subject.SubjectGroup,
                            SubjectID_Requirement: subject.SubjectID_Requirement,
                            id: subject.id,
                            isSelected: subject.isSelected,

                        }
                        //RECORRO CADA MATERIA
                        var pos = -1
                        pos = CourseGroup.Subjects.findIndex(x => (x.id === subject.id))  //BUSCAR SI LA MATERIA ESTA EN CourseGroup.Subjects  
                        if (pos == -1) { //SI NO ESTA EN  CourseGroup.Subjects   PREGUNTO SI ESA MATERIA HACE PARTE DEL COURSEOFERID
                            if (subject.CourseOfer.id == CourseGroup.CourseOferID) {
                                // AGREGO EN COURSEGROUPSSFINAL.Subjects la informacion de SUBJECTSGROUP
                                var subjectgroup = cx.subJectGtoupsNgSmartTable.find(x => (x.SubjectID === subject.id) && (x.CourseGroupID === CourseGroup.id))
                                subjectTemp.SubjectGroup = subjectgroup
                                CourseGroup.CourseID = subject.CourseOfer.course.id
                                CourseGroup.TeachingHoursmin = subject.CourseOfer.course.TeachingHoursmin
                                CourseGroup.Subjects.push(subjectTemp) //SI HACE PARTE LO AGREGO AL ARREGLO}
                                //console.debug("el subject que se la a colocar al subjectgroup es:", subjectTemp)
                                //console.debug("el subjectgroup que se la a colocar al subject es es:", subjectgroup)
                                //console.debug("el CourseGroup que se  va acumulando es", cx.CourseGroupsFinal)
                            }
                        }
                    }


                    //  DIVIDO EL DIA EN EL NUMERO DE TIMESLOTS
                    var start = moment(CourseGroup.StartDayTime, "HH:mm");
                    var final = moment(CourseGroup.EndDayTime, "HH:mm");
                    var difference = final.diff(start, 'hours') // 1
                    var temp = {
                        Mon: [],
                        Tue: [],
                        Wed: [],
                        Thu: [],
                        Fri: [],

                    }
                    //obtengo el numero de timeslots de acuerdo a la hora de inicio y final de cada curso
                    var totalDaySlots = cx.range(0, difference / (5 / 60))
                    //var totalDaySlots = cx.range(0, difference / (CourseGroup.TeachingHoursmin / 60))
                    //console.debug(totalDaySlots)
                    //  PARA EL NUMERO TOTAL DE SLOTS LO AGREGO EN EL ARREGLO temp PARA CADA DIA, DE ESTA MANERA TENDRIA LOS SLOTS DE TIEMPO PARA CADA CURSO
                    totalDaySlots.forEach((slot, indexslot) => {
                        var zerotime = moment(CourseGroup.StartDayTime, "HH:mm").add((slot * 5), "minutes").format('HH:mm')
                        var endTime = moment(zerotime, "HH:mm").add(5, "minutes").format('HH:mm')
                        if (endTime == moment(CourseGroup.StartDayTime, "HH:mm").format('HH:mm')) {
                            endTime = moment("23:59", "HH:mm").format('HH:mm')

                        }
                        temp.Mon.push({ StartTime: zerotime, EndTime: endTime, Event: null }),
                            temp.Tue.push({ StartTime: zerotime, EndTime: endTime, Event: null }),
                            temp.Wed.push({ StartTime: zerotime, EndTime: endTime, Event: null }),
                            temp.Thu.push({ StartTime: zerotime, EndTime: endTime, Event: null }),
                            temp.Fri.push({ StartTime: zerotime, EndTime: endTime, Event: null })
                        //temp.Sat.push({ StartTime: zerotime, EndTime: endTime, Event: null }),
                        //temp.Sun.push({ StartTime: zerotime, EndTime: endTime, Event: null })
                    })
                    CourseGroup.TimeSlots = temp

                    var date = new Date(cx.dayOfWeekSelected)
                    //3.A BUSCAR LA SEMANA EN LA QUE ESTOY PARA ASIGNARLO A LOS TIMESLOTS CEADOS
                    //se recoge la fecha en YYYY-MM-DD del dia actual
                    var m = moment(date, 'YYYY-MM-DD');
                    //se recoge a fecha en YYYY-MM-DD de la siguiente semana
                    var m2 = moment(m, 'YYYY-MM-DD').add(1, 'week');
                    console.debug("estamos en la semana #   " + m.week())
                    //rango de la semana 1
                    var RangeDates1 = cx.getDateRangeOfWeek(m.format('W'), m.format('YYYY'))
                    //rango de la semana 2
                    var RangeDates2 = cx.getDateRangeOfWeek(m2.format('W'), m2.format('YYYY'))
                    //mostrar ls detalles de la fecha que van a tener los dias de los timeslots 
                    var day = moment(RangeDates1[0], 'YYYY-MM-DD').startOf('day');
                    var endOfWeek = moment(RangeDates1[1], 'YYYY-MM-DD').endOf('day');
                    var days = []
                    while (day < endOfWeek) {
                        days.push(day.toDate());
                        day = day.clone().add(1, 'd');
                    }
                    //console.debug(days);
                    CourseGroup.InfoTimes = days



                    //3.B LE COLOCO LA RESTRICCION GLOBAL A CADA GRUPO
                    cx.globalBreakTimes.forEach(globalBreak => {
                        //Obtengo el dia de globalBreak
                        var nameDay = moment(globalBreak.StarTime, 'YYYY-MM-DD').format('ddd');
                        //recorro el resultado del día para agregar el receso global
                        // console.debug("nameDay", nameDay)
                        //var time= timeslots[""+nameDay+""].find(x=> (x.StarTime >=moment(globalBreak.StarTime).subtract(5, 'hours').format('HH:mm')) && (x.EndTime <=moment(globalBreak.EndTime).subtract(5, 'hours').format('HH:mm')) )
                        //console.debug(globalBreak)
                        CourseGroup.TimeSlots[nameDay].forEach(time => {
                            var format = 'hh:mm'
                            var globalStartSplit = globalBreak.StarTime.trim().split(' ')
                            var globalEndSplit = globalBreak.EndTime.trim().split(' ')
                            var globalStart = moment(globalStartSplit[1], format)
                            var globalEnd = moment(globalEndSplit[1], format)
                            var timeStart = moment(time.StartTime, format)
                            var timeEnd = moment(time.EndTime, format)
                            if (
                                //Si el evento esta entre el tiempo del timeslot
                                (timeStart.isSameOrAfter(globalStart) && globalEnd.isSameOrAfter(timeEnd)) ||
                                //compueba si parte del final del eventoglobal hace parte   del timeslot
                                (timeStart.isSameOrAfter(globalStart) && globalEnd.isBetween(timeStart, timeEnd)) ||
                                //compueba si parte del inicio del eventoglobal hace parte del timeslot
                                (timeStart.isSameOrBefore(globalStart) && globalStart.isBetween(timeStart, timeEnd))
                            ) {
                                time.Event = globalBreak
                            }
                        });
                    })

                    // 3.C AGREGAR LOS BREAKTIMES
                    var courseOferBreaks = cx.breakTimesFinal.filter(x => x.CourseOferID === CourseGroup.CourseOferID)
                    console.debug("los breaktimes nuevos son:", courseOferBreaks)
                    //recorro cada evento
                    for (let breakEventi = 0; breakEventi < courseOferBreaks.length; breakEventi++) {
                        var breakEvent = courseOferBreaks[breakEventi]

                        var dayOfWeek = breakEvent.DaySelected
                        // console.debug("dayOfWeek", dayOfWeek)

                        //recorro cada hora
                        for (let hourDayi = 0; hourDayi < CourseGroup.TimeSlots[dayOfWeek].length; hourDayi++) {
                            var hourDay = CourseGroup.TimeSlots[dayOfWeek][hourDayi];
                            // console.debug("hourDay", hourDay)


                            var format = 'hh:mm'
                            var globalStart = moment(breakEvent.StarTime, format)
                            var globalEnd = moment(breakEvent.EndTime, format)
                            var timeStart = moment(hourDay.StartTime, format)
                            var timeEnd = moment(hourDay.EndTime, format)
                            if (
                                //Si el evento esta entre el tiempo del timeslot
                                (timeStart.isSameOrAfter(globalStart) && globalEnd.isSameOrAfter(timeEnd)) ||
                                //compueba si parte del final del eventoglobal hace parte   del timeslot
                                (timeStart.isSameOrAfter(globalStart) && globalEnd.isBetween(timeStart, timeEnd)) ||
                                //compueba si parte del inicio del eventoglobal hace parte del timeslot
                                (timeStart.isSameOrBefore(globalStart) && globalStart.isBetween(timeStart, timeEnd))
                            ) {
                                if (!isNullOrUndefined(hourDay.Event)) {

                                }
                                else {
                                    hourDay.Event = breakEvent

                                }
                            }
                        }

                    }

                }

                console.debug("los CourseGroupsFinales con Breaktimes y globalBreaks quedo: ", cx.CourseGroupsFinal)
                cx.prepareRooms()

            })


        }

    }

    prepareRooms() {
        console.debug("--------------- prepareRooms ----------------- ")
        console.debug("VARIABLES A TRABAJAR EN ESTA FUNCION ")
        console.debug("LAS OFERTAS CON SUS RESTRICCIONES SON: ", this.CourseGroupsFinal)
        console.debug("LOS SALONES: ", this.classRoomsFinal)



        //1. RECORRO CADA SALON Y VERIFICO SI EXISTE LA CAPACIDAD DE ESTUDIANTES
        //2. SEPARAR TODOS LOS CourseGroupsFinal para saber cuantas materias en total se van a necesitar
        //3. AHORA SE REVISA CUANTAS VECES LA MATERIA TIENE QUE DIVIDIRSE POR EL MAXIMO DE SLOTS QUE SE PUEDE VER 
        //   EN UN DÍA, LO QUE SOBBRE SE CREA OTRO EVENTO CON EL VALOR DEL SLOT SOBRANTE

        //1. RECORRO CADA SALON Y VERIFICO SI EXISTE LA CAPACIDAD DE ESTUDIANTES
        var newclassRoomsFinal = []
        this.classRoomsFinal.forEach(classRoom => {
            classRoom.Chairs = classRoom.Classrooms.Chairs
            classRoom.NameClassroom = classRoom.Classrooms.NameClassroom
            classRoom.TypeClassroomID = classRoom.Classrooms.TypeClassroomID
            delete classRoom.Classrooms
            delete classRoom.CourseOfers

        })
        //reviso si para cada courseofer existe la maxima capacidad de salon
        this.courseOfersFinal.forEach(courseofer => {
            var totalChais = 0
            var totalStudents = 0
            //reviso el salon que tenga mas sillas para ese curso
            this.classRoomsFinal.forEach(classroom => {
                if (courseofer.id === classroom.CourseOferID)
                    if (classroom.Chairs >= totalChais)
                        totalChais = classroom.Chairs
            })
            //reviso cual es la mayor cantidad de estudiantes que tiene un grupo para el curso
            this.CourseGroupsFinal.forEach(courseGroupFinal => {
                if (courseofer.id === courseGroupFinal.CourseOferID)
                    if (courseGroupFinal.TotalStudents >= totalStudents)
                        totalStudents = courseGroupFinal.TotalStudents
            })
            //check si hay mas estudiantes que sillas en el grupo con mayor gente y el salon con mayor sillas
            if (totalStudents > totalChais) {
                var message = "Para " + courseofer.name + " existe un grupo con " + totalStudents + ", pero el salon que mayor capacidad tiene es " + totalChais
                this.authService.swalError("Error al asignar salones", message)
                return
            } else {
                console.debug("SI HAY ESTUDIANTES PARA LOS SALONES")
            }
        })



        //2. SEPARAR TODOS LOS CourseGroupsFinal para saber cuantas materias en total se van a necesitar
        this.TotalEventsToAssign = []
        this.CourseGroupsFinal.forEach(element => {
            element.Subjects.forEach(subject => {
                this.TotalEventsToAssign.push(
                    {
                        CourseID: element.CourseID,
                        TeachingHoursmin: element.TeachingHoursmin,
                        CourseOferID: element.CourseOferID,
                        name: element.name,
                        TotalStudents: element.TotalStudents,
                        CourseGroupID: element.CourseGroupID,
                        TimeSlots: element.TimeSlots,
                        InfoTimes: element.InfoTimes,
                        StartDayTime: element.StartDayTime,
                        EndDayTime: element.EndDayTime,
                        SubjectID: subject.id,
                        SubjectGroupID: subject.SubjectGroup.id,
                        Subject: subject,
                        MaxSlots: subject.MaxSlots
                    }

                )

            });


        })
        console.debug("EL TOTAL DE EVENTOS SIN ASIGNAR ES", this.TotalEventsToAssign)


        // 3. AHORA SE REVISA CUANTAS VECES LA MATERIA TIENE QUE DIVIDIRSE POR EL MAXIMO DE SLOTS QUE SE PUEDE VER 
        //    EN UN DÍA, LO QUE SOBBRE SE CREA OTRO EVENTO CON EL VALOR DEL SLOT SOBRANTE
        var tempTotalEventsToAssignSplit = []
        this.source.getAll().then(value => {
            value.forEach(element => {
                //console.debug("el elemento del smart table es ", element)
                var event = this.TotalEventsToAssign.find(x => (x.SubjectID === element.SubjectID) && (x.CourseGroupID === element.CourseGroupID))
                //console.debug("se encontro el elemento", event) 
                var splitT = (parseInt(element.TeachHours) / event.MaxSlots + "").split(".")
                // console.debug("parseInt(element.TeachHours)", parseInt(element.TeachHours))
                //console.debug("event.MaxSlots", event.MaxSlots)
                //console.debug("splitT", splitT)
                var splitTimes = 0
                if (splitT.length > 1) {
                    splitTimes = parseInt(splitT[0]) + 1
                } else if (splitT.length == 1) {
                    splitTimes = parseInt(splitT[0])
                }

                //console.debug("la materia se va a dividir en ", splitTimes)
                var range = this.range(1, splitTimes)
                // si se remplaza todo el objeto por una variable no va a servir, ya se probo
                range.forEach((splitNumb, index) => {
                    //console.debug(index)
                    if (splitNumb == splitTimes && splitTimes == 1) {
                        // console.debug("cumple la condicion de splitTimes == 1, debe quedar ", event.MaxSlots)
                        tempTotalEventsToAssignSplit.push({
                            CourseID: event.CourseID,
                            TeachingHoursmin: event.TeachingHoursmin,
                            CourseOferID: event.CourseOferID,
                            name: event.name,
                            TotalStudents: event.TotalStudents,
                            CourseGroupID: event.CourseGroupID,
                            TimeSlots: event.TimeSlots,
                            InfoTimes: event.InfoTimes,
                            StartDayTime: event.StartDayTime,
                            EndDayTime: event.EndDayTime,
                            SubjectID: event.SubjectID,
                            SubjectGroupID: event.SubjectGroupID,
                            Subject: event.Subject,
                            MaxSlots: event.MaxSlots,
                            NumberSlots: event.MaxSlots
                        })
                    }
                    else if (splitNumb == splitTimes && splitTimes > 1) {
                        //console.debug("cumple la condicion de splitTimes > 1, debe quedar ", parseInt(splitT[0]))
                        tempTotalEventsToAssignSplit.push({
                            CourseID: event.CourseID,
                            TeachingHoursmin: event.TeachingHoursmin,
                            CourseOferID: event.CourseOferID,
                            name: event.name,
                            TotalStudents: event.TotalStudents,
                            CourseGroupID: event.CourseGroupID,
                            TimeSlots: event.TimeSlots,
                            InfoTimes: event.InfoTimes,
                            StartDayTime: event.StartDayTime,
                            EndDayTime: event.EndDayTime,
                            SubjectID: event.SubjectID,
                            SubjectGroupID: event.SubjectGroupID,
                            Subject: event.Subject,
                            MaxSlots: event.MaxSlots,
                            NumberSlots: (parseInt(element.TeachHours) % event.MaxSlots) == 0 ? event.MaxSlots : (parseInt(element.TeachHours) % event.MaxSlots)
                        })
                    }
                    else {
                        //console.debug("no cumple nunguna condicion, debe quedar ", event.MaxSlots)
                        tempTotalEventsToAssignSplit.push({
                            CourseID: event.CourseID,
                            CourseOferID: event.CourseOferID,
                            name: event.name,
                            TeachingHoursmin: event.TeachingHoursmin,
                            TotalStudents: event.TotalStudents,
                            CourseGroupID: event.CourseGroupID,
                            TimeSlots: event.TimeSlots,
                            InfoTimes: event.InfoTimes,
                            StartDayTime: event.StartDayTime,
                            EndDayTime: event.EndDayTime,
                            SubjectID: event.SubjectID,
                            SubjectGroupID: event.SubjectGroupID,
                            Subject: event.Subject,
                            MaxSlots: event.MaxSlots,
                            NumberSlots: event.MaxSlots
                        })
                    }
                })





            });
            this.tempTotalEventsToAssignSplit = tempTotalEventsToAssignSplit
            console.debug("tempTotalEventsToAssignSplit<-- ESTE ES EL TOTAL DE EVENTOS", this.tempTotalEventsToAssignSplit)


            //4. SABER CON CUANTOS SALONES DISPONGO EN TOTAL Y UNA VARIABLE QUE RELACIONE LA OFERTA CON DICHO SALON
            var mapClassRoom = []
            var classRooms = []
            this.classRoomsFinal.forEach(classroom => {

                var pos = -1
                pos = classRooms.findIndex(x => (x.ClassroomID === classroom.ClassroomID))
                if (pos == -1) {
                    if (classroom.TypeClassroomID === 4)
                        classRooms.push(classroom)
                }
                else { }
                var pos2 = -1
                pos2 = mapClassRoom.findIndex(x => (x.ClassroomID === classroom.ClassroomID) && (x.CourseOferID === classroom.CourseOferID) && (classroom.TypeClassroomID === 4))
                if (pos2 == -1) {
                    if (classroom.TypeClassroomID === 4)
                        mapClassRoom.push({ ClassroomID: classroom.ClassroomID, CourseOferID: classroom.CourseOferID })
                }
                else { }
            })
            classRooms.forEach(element => {
                delete element.id
                delete element.CourseOferID
                element.mON
                element["Events"] = []
            })


            this.classRoomsFinal = classRooms
            this.mapClassRoom = mapClassRoom


            console.debug("mapClassRoom", this.mapClassRoom)
            console.debug("classRooms", this.classRoomsFinal)


            //EXTRAER EL CALENDARIO DE CADA GRUPO

            var groupsSchedule = []
            this.tempTotalEventsToAssignSplit.forEach(element => {

                var pos = -1
                pos = groupsSchedule.findIndex(x => x.CourseGroupID == element.CourseGroupID)
                if (pos == -1) {
                    groupsSchedule.push({
                        CourseGroupID: element.CourseGroupID,
                        CourseOferID: element.CourseOferID,
                        TimeSlots: element.TimeSlots
                    })
                }

            })


            //ordenar de mayor a menor  numero de slots

            //var ascending = homes.sort((a, b) => Number(a.price) - Number(b.price));
            //var descending = homes.sort((a, b) => Number(b.price) - Number(a.price))



            this.tempTotalEventsToAssignSplit = this.tempTotalEventsToAssignSplit.sort((a, b) => Number(b.NumberSlots) - Number(a.NumberSlots))

            this.groupsSchedule = groupsSchedule
            console.debug("groupsSchedule", this.groupsSchedule)
            this.runAlgorithm()


        });


    }


    runAlgorithm() {
        console.debug("--------------- runAlgorithm ----------------- ")
        console.debug("VARIABLES A TRABAJAR EN ESTA FUNCION ")
        console.debug("EVENTOS SIN ASIGNAR ", this.tempTotalEventsToAssignSplit)
        console.debug("LOS SALONES: ", this.classRoomsFinal)
        console.debug("LOS MAPEOS DE SALONES", this.mapClassRoom)
        console.debug("CALENDARIO DE LOS GRUPOS", this.groupsSchedule)


        var events = this.tempTotalEventsToAssignSplit



        for (var groupi = 0; groupi < this.groupsSchedule.length; groupi++) {
            var group = this.groupsSchedule[groupi];

            this.progressText = "Ubicando grupos " + (groupi + 1) + "/" + this.groupsSchedule.length;
            console.debug("@@@@@@@@@@@ --", this.progressText)

            var cx = this



            $.notify({
                title: '<strong>Progreso</strong>',
                message: cx.progressText
            }, {
                    type: 'success'
                });










            console.debug("------->el grupo para ponerle materias es: ", group)
            //seleccionar un salon de forma aleatoria
            var classRoomSelected = null
            var avaiableClassRooms = this.mapClassRoom.filter(x => x.CourseOferID === group.CourseOferID)
            var randomValueRoom = Math.floor((Math.random() * (avaiableClassRooms.length + 1)))
            while (isNullOrUndefined(classRoomSelected)) {
                randomValueRoom = Math.floor((Math.random() * (avaiableClassRooms.length + 1)))
                classRoomSelected = avaiableClassRooms[randomValueRoom]
            }
            //console.debug("el salon escogido fue: ", classRoomSelected)

            //busco las materias que el grupo debe ver
            var subjectsByGroup = events.filter(x => x.CourseGroupID === group.CourseGroupID)
            //console.debug("LAS MATERIAS QUE DEBE VER EL GRUPO SON (subjectsByGroup)", subjectsByGroup)

            //RECORRO CADA UNA DE LAS MATERIAS
            for (var indexeventGroup = 0; indexeventGroup < subjectsByGroup.length; indexeventGroup++) {
                var eventGroup = subjectsByGroup[indexeventGroup]

                console.debug("------->####la materia que se va a cargar (eventGroup)", eventGroup)

                var totalSplit5min = (eventGroup.NumberSlots * eventGroup.TeachingHoursmin) / 5
                //console.debug("El eventGroup debe dividirse en :", totalSplit5min)

                //seleccionar un día al azar

                //INICIO CON EL PRIMER DIA DE LA SEMANA PARA ASIGNAR LAS MATERIAS
                var avaliableDays = Object.keys(group.TimeSlots)
                var randomValueDay = 0
                var daySelected = avaliableDays[randomValueDay]


                // console.debug("para la materia " + eventGroup.Subject.NameSubject + " se selecciono el dia", daySelected)
                // console.debug("los datos del timeslot para ese dia es:", group.TimeSlots[daySelected])
                var foundSlot = false  // me indica si se encontro el slot de esa matería
                var foundSlotInWeek = false
                //recorro los dias 
                for (var nextDay = 0; nextDay < 5; nextDay++) {
                    var daybeofre = daySelected

                    //esto se utiliza si la materia no encaja en el día, busca si encaja en el día siguiente
                    if (randomValueDay === 0)
                        daySelected = avaliableDays[((randomValueDay) + nextDay) % 5]
                    else
                        daySelected = avaliableDays[((randomValueDay - 1) + nextDay) % 5]



                    //console.debug("cambiando dia seleccionado", daySelected)

                    //EEMPIEZO A RECORRER LAS HORAS DEL DÍA
                    for (var houri = 0; houri < group.TimeSlots[daySelected].length; houri++) {
                        var hour = group.TimeSlots[daySelected][houri];
                        //reviso si el timeslot esta libre
                        if (isNullOrUndefined(hour.Event)) {
                            //si esta libre reviso los siguientes totalSplit5min para saber si es posible agregar dicho evento
                            //console.debug("El slot esta libre")                   
                            //REVISO EN EL SLOT QUE ESTA , MAS LOS totalSplit5min SLOTS ESTAN LIBRES
                            var allFreeSlots = [];
                            //si el slot mas los que necesito es menor a los slots totales del dia, verifico si puedo poner la materia
                            if ((houri + totalSplit5min) <= group.TimeSlots[daySelected].length) {
                                //console.debug("cabe dentro del dia " + daySelected)
                                var indexes = []
                                var times = []
                                //reviso si el evento puede estar en los numero de slots cuando sse dividio la materia en 5 minutos
                                for (var checkSlot = houri; checkSlot < (houri + totalSplit5min); checkSlot++) {
                                    if (isNullOrUndefined(group.TimeSlots[daySelected][checkSlot].Event)) {
                                        allFreeSlots = allFreeSlots.concat([true])
                                        // indexes = indexes.concat([checkSlot])
                                        times = times.concat([group.TimeSlots[daySelected][checkSlot]])
                                    }
                                    else {
                                        allFreeSlots = allFreeSlots.concat([false])
                                        break
                                    }


                                }

                                //si todos los slots estan libres buso que el docente no este dictando a la misma hora dentro de esos slots
                                if (this.allEquals(allFreeSlots)) {
                                    //console.log("LA MATERIA SE PUEDE PONER EN EL SLOT, VALIDANDO DOCENTE.....")
                                    var avaliableTeaching = this.teachingAvaliable(eventGroup.Subject.SubjectGroup.UserID, this.groupsSchedule, daySelected, times)
                                    if (avaliableTeaching == false) {
                                        //console.debug("EL PROFESOR NO PUEDE DICTAR A ESA HORA")
                                        //se mira la siguiente hora
                                        //console.debug("Revisano si puede dictar en otra hora")
                                        var checkOtherSlot = this.checkUserOtherSlot(eventGroup.Subject.SubjectGroup.UserID, eventGroup, totalSplit5min, group.TimeSlots[daySelected], times, daySelected)

                                        if (checkOtherSlot) {
                                            foundSlot = true
                                            foundSlotInWeek = true
                                            break
                                        }




                                    }
                                    else {
                                        //console.debug("EL PROFESOR PUEDE DICTAR, VALIDANDO QUE NO SE REPITA LA MATERÍA El MISMO DIA ....")

                                        var isSameSubjetSameD = this.avaliableSameSubjectSameDay(eventGroup, group.TimeSlots[daySelected])

                                        if (isSameSubjetSameD == false) {
                                            //console.debug("LA MATERIA SE REPITE EL MISMO DIA")
                                            break
                                        } else {
                                            //console.debug("SE CUMUPLERON LAS RESTRICCIONES DE LA MATERIA EN LOS SLOTS, LOS DOCENTES QUE NO SE REPITA LA MATERIA")
                                            for (var checkSlot = houri; checkSlot < (houri + totalSplit5min); checkSlot++) {
                                                group.TimeSlots[daySelected][checkSlot].Event = eventGroup
                                            }
                                            delete subjectsByGroup[indexeventGroup]
                                            foundSlot = true
                                            foundSlotInWeek = true
                                            break
                                        }





                                    }
                                }







                            }
                            else {
                                break //ME SALTO PORQUE YA NO HAY MAS SLOTS DISPONIBLES EN EL DIA ACTUAL, BUSCO EN EL DIA SIGUIENTE
                            }



                            /*
                            var isTeaching = this.isTeachingSameTime(eventGroup.Subject.UserID, this.groupsSchedule, daySelected, hour)
                            //si esta libre, miro los NumberSlots 
                            if (eventGroup.NumberSlots == 1 && !isTeaching) {
                                group.TimeSlots[daySelected][houri]["Event"] = {
                                    CourseGroupID: eventGroup.CourseGroupID,
                                    CourseOferID: eventGroup.CourseOferID,
                                    SubjectID: eventGroup.SubjectID,
                                    SubjectGroupID: eventGroup.SubjectGroupID,
                                    Subject: eventGroup.Subject,
                                    InfoTimes: eventGroup.InfoTimes,
                                    UserID: eventGroup.Subject.UserID
                                }
                                //remover el item que ya no necesitamos
                                delete subjectsByGroup[indexeventGroup]
                                break
                                */

                        }

                    }
                    if (foundSlot == true) {
                        //SI SE ENCONTRO UN ESPACIO EN EL DIA ACTUAL, SE CIERRA EL CICLO FOR DE BUSCAR EN LA SEMANA
                        foundSlotInWeek = true
                        break
                    }
                    else {
                        //console.debug("Hubo materias que no se agregaron al dia que es, se correr nuavente algoritmo para buscar el dia siguiente")
                    }

                }

                //ACA DEBE HACER ALGO CUANDO NO SE HAYA PUESTO LA MATERIA EN NIGUNO DE LOS DIAS

                if (foundSlotInWeek == false) {
                    console.error("NO SE PUDO COLOCAR LA MATERIA EN NUNGUN DIA DE LA SEMANA", eventGroup)
                }



            }



        }


        this.chooseRooms()


    }

    chooseRooms() {
        console.debug("--------------- chooseRooms ----------------- ")
        console.debug("VARIABLES A TRABAJAR EN ESTA FUNCION ")
        console.debug("LA INFORMACION DE LOS GRUPOS SON:  ", this.CourseGroupsFinal)
        console.debug("LOS SALONES: ", this.classRoomsFinal)
        console.debug("LOS MAPEOS DE SALONES", this.mapClassRoom)
        console.debug("CALENDARIO DE LOS GRUPOS", this.groupsSchedule)

        this.avaliableRooms = this.mapClassRoom

        //ASIGNAR SALONES A CADA UNO DE LOS GRUPOS
        for (var indexgroup = 0; indexgroup < this.CourseGroupsFinal.length; indexgroup++) {
            var element = this.CourseGroupsFinal[indexgroup];
            //console.debug("se va asignar el salon al grupo", element)
            //tengo todos los salones disponibles para la oferta
            var rooms = this.avaliableRooms.map(x => x.CourseOferID === element.CourseOferID)
            //console.debug("rooms", rooms)
            for (var index = 0; index < rooms.length; index++) {
                var roomIn = rooms[index];
                if (roomIn) {
                    //console.debug("info del salon")
                    var roomId = this.avaliableRooms[index].ClassroomID
                    var roomInfo = this.classRoomsFinal.find(x => x.ClassroomID === roomId)
                    var infoGroup = this.CourseGroupsFinal.find(x => x.id === element.CourseGroupID) //RECOGO LA INFORMACION DEL GRUPO
                    //console.debug("roomId", roomId)
                    //console.debug("roomInfo", roomInfo)
                    //console.debug("infoGroup", infoGroup)

                    if (roomInfo.Chairs >= infoGroup.TotalStudents) {
                        var pos = this.groupsSchedule.findIndex(x => x.CourseGroupID === infoGroup.id && x.CourseOferID === element.CourseOferID)
                        this.groupsSchedule[pos]["ClassroomID"] = roomInfo.ClassroomID
                        this.groupsSchedule[pos]["NameClassroom"] = roomInfo.NameClassroom
                        //borrar salon de los disponibles
                        //console.debug("la nueva info quedo:", this.groupsSchedule[pos])
                        var delRoom = this.avaliableRooms.map(x => x.ClassroomID === roomInfo.ClassroomID)
                        for (var index2 = 0; index2 < delRoom.length; index2++) {
                            if (delRoom[index2]) {
                                this.avaliableRooms.splice(index2, 1)
                            }
                        }


                        break
                    }

                }
            };




        }


        this.authService.swalEndLoad()
    }


    allEquals(array) {
        var isSame = true;
        for (var i = 0; i < array.length; i++) {
            isSame = (array[0] === array[i] && array[i] === true) ? true : false;
        }
        return isSame;
    }


    checkUserOtherSlot(userID, suject, totalSplit5min, evetsChosenDay, slotsNoAvaliableChosenDay, dayName) {

        var allFreeSlotsGeneral = []
        var allFreeSlots2 = []
        var times2 = []
        for (var indexSlot = 0; indexSlot < evetsChosenDay.length; indexSlot++) {
            var slot = evetsChosenDay[indexSlot];


            //console.log("revisando", slot)
            //reviso el slot vacio
            if (isNullOrUndefined(slot.Event)) {
                //reviso si el slot vacio mas los n Slots siguientes siguen vacios
                allFreeSlots2 = []
                times2 = []
                if ((indexSlot + totalSplit5min) <= evetsChosenDay.length) {


                    //reviso si el evento puede estar en los numero de slots cuando sse dividio la materia en 5 minutos
                    for (var checkSlot2 = indexSlot; checkSlot2 < (indexSlot + totalSplit5min); checkSlot2++) {
                        if (isNullOrUndefined(evetsChosenDay[checkSlot2].Event)) {
                            allFreeSlots2 = allFreeSlots2.concat([true])
                            times2 = times2.concat([evetsChosenDay[checkSlot2]])
                        }
                        else {
                            allFreeSlots2 = allFreeSlots2.concat([false])
                            break
                        }


                    }





                    if (this.allEquals(allFreeSlots2) && allFreeSlots2.length == totalSplit5min) {
                        // console.log("LA MATERIA SE PUEDE PONER EN EL SLOT, VALIDANDO DOCENTE.....")
                        var avaliableTeaching = this.teachingAvaliable(userID, this.groupsSchedule, dayName, times2)
                        if (avaliableTeaching) {
                            // console.debug("EL PROFESOR PUEDE DICTAR, VALIDANDO QUE NO SE REPITA LA MATERÍA El MISMO DIA ....")

                            var isSameSubjetSameD2 = this.avaliableSameSubjectSameDay(suject, evetsChosenDay)

                            if (isSameSubjetSameD2) {
                                //console.debug("SE CUMUPLERON LAS RESTRICCIONES DE LA MATERIA EN LOS SLOTS, LOS DOCENTES QUE NO SE REPITA LA MATERIA")
                                for (var checkSlot2 = indexSlot; checkSlot2 < (indexSlot + totalSplit5min); checkSlot2++) {
                                    evetsChosenDay[checkSlot2].Event = {
                                        CourseGroupID: suject.CourseGroupID,
                                        CourseID: suject.CourseID,
                                        CourseOferID: suject.CourseOferID,
                                        EndDayTime: suject.EndDayTime,
                                        InfoTimes: suject.InfoTimes,
                                        MaxSlots: suject.MaxSlots,
                                        NumberSlots: suject.NumberSlots,
                                        StartDayTime: suject.StartDayTime,
                                        Subject: suject.Subject,
                                        SubjectGroupID: suject.SubjectGroupID,
                                        SubjectID: suject.SubjectID,
                                        TeachingHoursmin: suject.TeachingHoursmin,
                                        TimeSlots: suject.TimeSlots,
                                        TotalStudents: suject.TotalStudents,
                                        name: suject.name,
                                    }
                                }

                                allFreeSlotsGeneral = allFreeSlotsGeneral.concat([true])


                                return true
                            }
                            else {
                                return false
                            }




                        }
                        else {
                            allFreeSlotsGeneral = allFreeSlotsGeneral.concat([false])
                            break
                        }



                    }





                }

            }

        };

        if (this.allEquals(allFreeSlotsGeneral)) {
            return true
        }
        else
            return false


    }

    teachingAvaliable(userID: number, groupsSchedule, dayPut, time) {
        //console.debug("El UserID que llego es ", userID)

        var overlap = (timeSegments) => {
            var ret = false;
            var i = 0;
            while (!ret && i < timeSegments.length - 1) {
                var seg1 = timeSegments[i];
                var seg2 = timeSegments[i + 1];
                var range1 = moment.range(moment(seg1[0], 'HH:mm'), moment(seg1[1], 'HH:mm'));
                var range2 = moment.range(moment(seg2[0], 'HH:mm'), moment(seg2[1], 'HH:mm'));
                if (range1.overlaps(range2)) {
                    ret = true;
                }
                i++;
                return ret;
            }
        };

        /*
                  let overlap = (timeSegments) => {
                      let ret = false;
                      let i = 0;
                      while (!ret && i < timeSegments.length - 1) {
                          let seg1 = timeSegments[i];
                          let seg2 = timeSegments[i + 1];
                          let range1 = moment.range(moment(seg1[0], 'HH:mm'), moment(seg1[1], 'HH:mm'));
                          let range2 = moment.range(moment(seg2[0], 'HH:mm'), moment(seg2[1], 'HH:mm'));
                          if (range1.overlaps(range2)) {
                              ret = true;
                          }
                          i++;
                          return ret;
                      }
                  };
                  let timeSegments = [];
                  timeSegments.push([hour.StartTime, hour.EndTime])
                  timeSegments.push([hourPut.StartTime, hourPut.EndTime])
                  if (overlap(timeSegments) == false && userID == hour.UserID){
                      return false
                  }else if(overlap(timeSegments) == false && userID == hour.UserID){}
                  */

        var slotAvaliable = [];
        //RECORRO EL CALENDARIO DE CADA GRUPO
        for (var indexElement = 0; indexElement < groupsSchedule.length; indexElement++) {
            var element = groupsSchedule[indexElement]


            //A CADA GRUPO REIVISO LA HORA DE INICIO Y HORA FINAL
            var startTime = time[0].StartTime
            var lastTime = time[time.length - 1].EndTime

            //RECORRO EL DIA SELECCIONADO
            for (var index = 0; index < element.TimeSlots[dayPut].length; index++) {
                var hour = element.TimeSlots[dayPut][index]
                if (isNullOrUndefined(hour.Event))
                    slotAvaliable = slotAvaliable.concat([true])
                else {
                    //RECORRO LAS HORAS DONDE SE VA A COLOCAR LA MATERIA
                    for (var checkSlot = 0; checkSlot < time.length; checkSlot++) {
                        //HAGO UNA VALIDACIÓN DE LAS HORAS, PORQUE  PUEDE QUE EL FORMATO ESTE COMO UN EVENTO GLOBAL  O ENVENTO DE UNA MATERIA NORMAL
                        if (moment(hour.StartTime, 'YYYY-MM-DD HH:mm', true).isValid() && moment(hour.EndTime, 'YYYY-MM-DD HH:mm', true).isValid()) {
                            var hourEventStart = moment(hour.StartTime, 'YYYY-MM-DD HH:mm:ss').format("HH:mm")
                            var hourEventEnd = moment(hour.EndTime, 'YYYY-MM-DD HH:mm').format("HH:mm")
                        }
                        else {
                            var hourEventStart = hour.StartTime
                            var hourEventEnd = hour.EndTime
                        }

                        //CREO UN AREGLO DE LA HORA DEL EVENTO CONCATENADO CON CON EL SLOT DE LA HORA DEL CURSO PARA REVISAR SI SE SOBRELAPA
                        var timeSegments = [];
                        timeSegments.push([time[checkSlot].StartTime, time[checkSlot].EndTime])
                        timeSegments.push([hourEventStart, hourEventEnd])
                        var isOverlap = overlap(timeSegments)

                        //SI EXISTE UNA EVETO CON LA PROPIEDAD Subject ES PORQUE ESE EVENTO TIENE UNA MATERIA
                        if (!isNullOrUndefined(hour.Event.Subject)) {
                            //SI SE SOBRELAPA EL EVENTO Y A DEMAS ES EL MISMO PROFESOR ENTONCES RETORNAR UN FALSE 
                            // PARA INDICAR QUE EL PROFESOR YA ESTA DICTANDO EN ALGUNA PARTE DE LOS SLOTS.
                            if (isOverlap && hour.Event.Subject.SubjectGroup.UserID === userID) {
                                slotAvaliable = slotAvaliable.concat([false])
                                return false
                            }
                            else
                                slotAvaliable = slotAvaliable.concat([true])
                        }
                    }
                }

            }

        }
        if (this.allEquals(slotAvaliable))
            return true

        else
            return false

    }


    showCalendar(event) {

        // console.log(event)
        $('#calendar').fullCalendar('removeEvents')

        this.callEvents({ CourseGroupID: event.CourseGroupID })

    }

    avaliableSameSubjectSameDay(evento, eventoDia) {
        var subjectAvaliable = [];
        //RECORRO CADA GRUPO
        for (let index = 0; index < eventoDia.length; index++) {
            var hour = eventoDia[index]


            if (!isNullOrUndefined(hour.Event)) {

                if (!isNullOrUndefined(hour.Event.SubjectID)) {

                    if (evento.SubjectID === hour.Event.SubjectID) {
                        //console.debug("el evento", evento)
                        //console.debug("esta en la misma hour.event", hour.Event.SubjectID)
                        subjectAvaliable = subjectAvaliable.concat([false])
                        break
                    }
                    else
                        subjectAvaliable = subjectAvaliable.concat([true])

                }
                else {
                    subjectAvaliable = subjectAvaliable.concat([false]) //se agrego
                }
            }
            else {
                subjectAvaliable = subjectAvaliable.concat([true])
            }
        }

        if (this.allEquals(subjectAvaliable)) {
            return true
        }
        else
            return false

    }

    getDateOfISOWeek(w, y) {
        var simple = new Date(y, 0, 1 + (w - 1) * 7);
        var dow = simple.getDay();
        var ISOweekStart = simple;
        if (dow <= 4)
            ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
        else
            ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
        // ISOweekStart.setHours(-5, 0, 0, 0);

        return ISOweekStart;
    }

    getDateRangeOfWeek(w, y) {

        var date = this.getDateOfISOWeek(w, y);
        var date2 = this.getDateOfISOWeek(w, y);
        // easily get ending date by adding 6 days more
        date.setDate(date.getDate());
        date.setHours(0, 0, 0, 0);
        date2.setDate(date2.getDate() + 4);
        date2.setHours(0, 0, 0, 0);
        //RETORNA EL DIA DE INICIO DE ESA SEMANA Y EL DIA QUE ACABA
        //LUNES A DOMINGO

        return ([date, date2]);
    }



    range(start, count) {
        return Array.apply(0, Array(count))
            .map(function (element, index) {
                return index + start;
            });
    }



    idToNameTeacher(id: number) {

        var data = this.ModelProfessorSmartTable.find(x => x.value === id)
        return data.title
    }



    callEvents(OtherInfo) {

        this.authService.swalLoading("Cargando eventos")
        //SE REOGE EL DIA DE HOY
        var date = new Date(this.dayOfWeekSelected)


        //console.debug(RangeDates)
        //console.debug(OtherInfo)

        //LLAMO LOS EVENTOS QUE EXISTEN  PARA EL ID DEL GRUPO
        var p = this.groupsSchedule.find(x => x.CourseGroupID == OtherInfo.CourseGroupID)
        //console.log("se va a cargar los datos de", p)

        var tempEvents = []
        var events = []
        var calendarEvents = []
        var dateofWeek = []
        //3.A BUSCAR LA SEMANA EN LA QUE ESTOY PARA ASIGNARLO A LOS TIMESLOTS CEADOS
        //se recoge la fecha en YYYY-MM-DD del dia actual
        var m = moment(date, 'YYYY-MM-DD');
        //se recoge a fecha en YYYY-MM-DD de la siguiente semana
        var m2 = moment(m, 'YYYY-MM-DD').add(1, 'week');
        //console.debug("estamos en la semana #   " + m.week())
        //rango de la semana 1
        var RangeDates1 = this.getDateRangeOfWeek(m.format('W'), m.format('YYYY'))
        //rango de la semana 2
        var RangeDates2 = this.getDateRangeOfWeek(m2.format('W'), m2.format('YYYY'))
        //mostrar ls detalles de la fecha que van a tener los dias de los timeslots 
        var day = moment(RangeDates1[0], 'YYYY-MM-DD').startOf('day');
        var endOfWeek = moment(RangeDates1[1], 'YYYY-MM-DD').endOf('day');
        var days = []
        while (day < endOfWeek) {
            days.push(day.toDate());
            day = day.clone().add(1, 'd');
        }
        //console.debug(days);
        dateofWeek = days
        // console.debug("los dias son", dateofWeek)


        //recorro cada dia 

        for (let dayOfWeeki = 0; dayOfWeeki < Object.keys(p.TimeSlots).length; dayOfWeeki++) {
            var preEvents = []
            var dayOfWeek = Object.keys(p.TimeSlots)[dayOfWeeki]
            //console.debug("dayOfWeek", dayOfWeek)
            var dateFormat = moment(dateofWeek[dayOfWeeki])
            //console.debug("in formar is", dateFormat.format("YYYY-MM-DD"))

            p.TimeSlots[dayOfWeek].forEach(timeSlot => {

                if (!isNullOrUndefined(timeSlot.Event)) {
                    var pos = -1

                    //VERIFICO SI ES UN RECESO
                    if (!isNullOrUndefined(timeSlot.Event.NameBreak)) {
                        tempEvents.push(
                            {
                                title: " " + timeSlot.Event.NameBreak,
                                start: dateFormat.format("YYYY-MM-DD") + " " + timeSlot.StartTime + ":00",
                                end: dateFormat.format("YYYY-MM-DD") + " " + timeSlot.EndTime + ":00",
                                CourseGroupID: p.CourseGroupID,
                                SubjectGroupID: p.SubjectGroupID,
                                TypeEvent: "Break"
                            })
                    }
                    //VERIFICO SI ES UN EVENTO COMO TAL
                    if (!isNullOrUndefined(timeSlot.Event.Subject)) {
                        var positionSubject = this.subjectsFinal.findIndex(x => x.id === timeSlot.Event.Subject.id)
                        console.log(p)
                        tempEvents.push(
                            {
                                title: " " + timeSlot.Event.Subject.NameSubject + "<br> Dictada por " + this.idToNameTeacher(timeSlot.Event.Subject.SubjectGroup.UserID) + "<br> Salón: " + p.NameClassroom,
                                start: dateFormat.format("YYYY-MM-DD") + " " + timeSlot.StartTime + ":00",
                                end: dateFormat.format("YYYY-MM-DD") + " " + timeSlot.EndTime + ":00",
                                CourseGroupID: p.CourseGroupID,
                                UserID: timeSlot.Event.Subject.SubjectGroup.UserID,
                                SubjectGroupID: timeSlot.Event.SubjectGroupID,
                                TeachHours:timeSlot.Event.Subject.SubjectGroup.TeachHours,
                                ClassroomID: p.ClassroomID,
                                Subject: timeSlot.Event.Subject,
                                color: this.colorSubjects[positionSubject],
                                TypeEvent: "Class"

                            })
                    }


                }
            });
        }


        this.previousEvents = tempEvents


        tempEvents.forEach(element => {

            var pos = -1
            pos = events.findIndex(x => (x.CourseGroupID === element.CourseGroupID) &&
                (x.SubjectGroupID === element.SubjectGroupID) &&
                (x.title === element.title) &&
                (moment(x.start, "YYYY-MM-DD").isSame(moment(element.start, "YYYY-MM-DD")))
            )

            if (pos == -1) {
                events.push(
                    {
                        title: element.title,
                        start: element.start,
                        end: element.end,
                        CourseGroupID: element.CourseGroupID,
                        SubjectGroupID: element.SubjectGroupID,
                        Subject: element.Subject,
                        color: element.color
                    })
            }
            else {
                events[pos].end = element.end

            }




        })

        //console.debug("la variable del evento quedo")
        // console.debug(events)
        $('#calendar').fullCalendar('removeEvents')
        $('#calendar').fullCalendar('addEventSource', events);
        this.authService.swalEndLoad()

        //console.debug("clientEvents son", $('#calendar').fullCalendar('clientEvents'))


        /*
        for (let i = 0; i < p.length; i++) {

            //SI EXISTEN EVENTOS CON CourseGroupID ACTUALIZAR EL CALENDARIO
            if (!isNullOrUndefined(p[i].SubjectGroups)) {
                if (!isNullOrUndefined(p[i].SubjectGroups.CourseGroupID)) {
                    console.debug("SI HAY")

                    console.debug("el color es")
                    console.debug("el id del area es")
                    console.debug(p[i]["SubjectGroups"]["subjects"]["AcademicAreaID"])

                    var index = this.KeyColorAcademicArea.findIndex(x => x.areaid == p[i]["SubjectGroups"]["subjects"]["AcademicAreaID"]);
                    console.debug("se encontro en el indez de key color " + index)

                    let CompleteName = $.grep([p[i]["SubjectGroups"]["userapp"]["LastName1"], p[i]["SubjectGroups"]["userapp"]["LastName2"], p[i]["SubjectGroups"]["userapp"]["Name1"], p[i]["SubjectGroups"]["userapp"]["Name2"]], Boolean).join(" ");

                    var titulo = p[i]["SubjectGroups"]["subjects"]["NameSubject"].toUpperCase() + " <br> Dictada por: <i> " + CompleteName.toUpperCase() + "</i> <br> Para: <i>" + p[i]["SubjectGroups"]["coursesgroup"]["name"].toUpperCase() + "</i>"
                    //ACTUALIZAR CON EVENTOS EL CALENDARIO
                    events.push(
                        {
                            id: p[i].id,
                            title: " " + titulo,
                            start: p[i]["HourStart"],
                            end: p[i]["HourEnd"],
                            CourseGroupID: p[i]["SubjectGroups"]["CourseGroupID"],
                            SubjectGroupID: p[i]["SubjectGroupID"],
                            SubjectID: p[i]["SubjectGroups"]["SubjectID"],
                            color: this.ColorAcademicArea[this.KeyColorAcademicArea[index].colorposition]


                        })


                    console.debug("la variable del evento quedo")
                    console.debug(events)
                    $('#Calendario').show()



                }
            }



        }
        */




    }



    callEventsWithReturn(OtherInfo) {


        //SE REOGE EL DIA DE HOY
        var date = new Date(this.dayOfWeekSelected)


        //console.debug(RangeDates)
        //console.debug(OtherInfo)

        //LLAMO LOS EVENTOS QUE EXISTEN  PARA EL ID DEL GRUPO
        var p = this.groupsSchedule.find(x => x.CourseGroupID == OtherInfo.CourseGroupID)
        console.debug("//LLAMO LOS EVENTOS QUE EXISTEN  PARA EL ID DEL GRUPO", p)
        //console.log("se va a cargar los datos de", p)

        var tempEvents = []
        var events = []
        var calendarEvents = []
        var dateofWeek = []
        //3.A BUSCAR LA SEMANA EN LA QUE ESTOY PARA ASIGNARLO A LOS TIMESLOTS CEADOS
        //se recoge la fecha en YYYY-MM-DD del dia actual
        var m = moment(date, 'YYYY-MM-DD');
        //se recoge a fecha en YYYY-MM-DD de la siguiente semana
        var m2 = moment(m, 'YYYY-MM-DD').add(1, 'week');
        //console.debug("estamos en la semana #   " + m.week())
        //rango de la semana 1
        var RangeDates1 = this.getDateRangeOfWeek(m.format('W'), m.format('YYYY'))
        //rango de la semana 2
        var RangeDates2 = this.getDateRangeOfWeek(m2.format('W'), m2.format('YYYY'))
        //mostrar ls detalles de la fecha que van a tener los dias de los timeslots 
        var day = moment(RangeDates1[0], 'YYYY-MM-DD').startOf('day');
        var endOfWeek = moment(RangeDates1[1], 'YYYY-MM-DD').endOf('day');
        var days = []
        while (day < endOfWeek) {
            days.push(day.toDate());
            day = day.clone().add(1, 'd');
        }
        //console.debug(days);
        dateofWeek = days
        // console.debug("los dias son", dateofWeek)


        //recorro cada dia 

        for (let dayOfWeeki = 0; dayOfWeeki < Object.keys(p.TimeSlots).length; dayOfWeeki++) {
            var preEvents = []
            var dayOfWeek = Object.keys(p.TimeSlots)[dayOfWeeki]
            //console.debug("dayOfWeek", dayOfWeek)
            var dateFormat = moment(dateofWeek[dayOfWeeki])
            //console.debug("in formar is", dateFormat.format("YYYY-MM-DD"))

            p.TimeSlots[dayOfWeek].forEach(timeSlot => {

                if (!isNullOrUndefined(timeSlot.Event)) {
                    var pos = -1

                    //VERIFICO SI ES UN RECESO
                    if (!isNullOrUndefined(timeSlot.Event.NameBreak)) {
                        tempEvents.push(
                            {
                                title: " " + timeSlot.Event.NameBreak,
                                start: dateFormat.format("YYYY-MM-DD") + " " + timeSlot.StartTime + ":00",
                                end: dateFormat.format("YYYY-MM-DD") + " " + timeSlot.EndTime + ":00",
                                CourseGroupID: p.CourseGroupID,
                                SubjectGroupID: p.SubjectGroupID,
                                TypeEvent: "Break"
                            })
                    }
                    //VERIFICO SI ES UN EVENTO COMO TAL
                    if (!isNullOrUndefined(timeSlot.Event.Subject)) {
                        var positionSubject = this.subjectsFinal.findIndex(x => x.id === timeSlot.Event.Subject.id)

                        tempEvents.push(
                            {
                                title: " " + timeSlot.Event.Subject.NameSubject + "<br> Dictada por " + this.idToNameTeacher(timeSlot.Event.Subject.SubjectGroup.UserID) + "<br> Salón: " + p.NameClassroom,
                                start: dateFormat.format("YYYY-MM-DD") + " " + timeSlot.StartTime + ":00",
                                end: dateFormat.format("YYYY-MM-DD") + " " + timeSlot.EndTime + ":00",
                                CourseGroupID: p.CourseGroupID,
                                UserID: timeSlot.Event.Subject.SubjectGroup.UserID,
                                SubjectGroupID: timeSlot.Event.SubjectGroupID,
                                TeachHours:timeSlot.Event.Subject.SubjectGroup.TeachHours,
                                ClassroomID: p.ClassroomID,
                                SchoolID: this.SchoolIDSelected,
                                Subject: timeSlot.Event.Subject,
                                color: this.colorSubjects[positionSubject],
                                TypeEvent: "Class"

                            })
                    }


                }
            });
        }


        this.previousEvents = tempEvents


        tempEvents.forEach(element => {

            var pos = -1
            pos = events.findIndex(x => (x.CourseGroupID === element.CourseGroupID) &&
                (x.SubjectGroupID === element.SubjectGroupID) &&
                (x.title === element.title) &&
                (moment(x.start, "YYYY-MM-DD").isSame(moment(element.start, "YYYY-MM-DD")))
            )

            if (pos == -1) {
                events.push(
                    {
                        title: element.title,
                        start: element.start,
                        end: element.end,
                        CourseGroupID: element.CourseGroupID,
                        SubjectGroupID: element.SubjectGroupID,
                        TeachHours:element.TeachHours,
                        Subject: element.Subject,
                        color: element.color,
                        UserID: element.UserID,
                        ClassroomID: element.ClassroomID,
                        SchoolID: this.SchoolIDSelected,
                        TypeEvent: element.TypeEvent

                    })
            }
            else {
                events[pos].end = element.end

            }




        })

        return events


    }

    week(data) {
        console.debug(data)
        var weekNumber = parseInt(data.SelectedWeek.slice(-2))
        var YearNumber = parseInt(data.SelectedWeek.slice(0, 4))
        this.dayOfWeekSelected = moment([YearNumber]).isoWeek(weekNumber).add(2, "days").format("YYYY-MM-DD");
        console.debug(this.dayOfWeekSelected)

    }

}
