import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../../../models/Auth/auth.service';
import { CoursesService } from '../../../../models/Coursesinfo/courses.service';
import { ProfileNavComponent } from '../../../../components/profile-nav/profile-nav.component';
import { EvaluationsService } from '../../../../models/evaluation/evaluations.service';
import { Student } from '../../../../models/evaluation/student';
import { isNullOrUndefined } from 'util';
import * as moment from 'moment';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { environment } from 'environments/environment';
declare var $: any;


var async = require("async");

@Component({
    selector: 'Students',
    templateUrl: './students.component.html',
    styleUrls: ['./students.component.css']
})

export class StudentsComponent {
    serverTime: Date
    student: Student;
    user: string;
    showdata: boolean = false;
    public Courses: any;
    public IsStudent: boolean = false;
    public IsStudent2: boolean = false;
    SubjectID: number;
    ErrorGroup: string;
    SubjectsStudent: any = [];
    endDateCourse = moment('1990-01-01'); //selecciono una fecha muy lejana

    public SCHOOL_NAME:string = environment.SCHOOL_NAME;

    constructor(
        private evaluatiosService: EvaluationsService,
        private authService: AuthService,
        private coursesService: CoursesService,
        private route: ActivatedRoute,
        public profileNavComponent: ProfileNavComponent,
        private router: Router,
    ) {
        this.InitData();
        this.authService.swalLoading("Cargando materias para calificar")
        //SI NO HAY USUARIOS EN LOCAL STORAGE, LLEVAR A LOGIN
        if (this.authService.getCurrentUser() == null) {
            this.router.navigate(['/register']);
        } else {
            //DE LO CONTRARO CARGAR EL ID DEL USUARIO
            this.user = this.authService.getCurrentUser(); //devuelve el id del usuario
            //SI EL ID DEL USUARIO ES UN NUMERO ENTERO
            if (Number.isInteger(parseInt(this.user))) {
                //CONSULTO EL PERFIL DEL USUARIO
                var validar_usuario = [false];

                for (let role of this.profileNavComponent.roles) {
                    if (role.name == "Estudiante") {
                        validar_usuario = validar_usuario.concat([true])
                        break
                    }
                    if (role.name == "admin") {
                        validar_usuario = validar_usuario.concat([true])
                        break
                    }
                }

                if (!validar_usuario.includes(true)) { this.router.navigate(['/']); }

                //CONSULTO LA LISTA DE CURSOS POR EL USUARIO
                this.coursesService.getCoursesByUserID(parseInt(this.user)).subscribe(response => {
                    var temp = response
                    //RECORRO TODAS LAS MATERIAS ACTIVAS, SI NO TIENE COURSEOFFER BORRAR DEL ARREGLO
                    for (var index = 0; index < temp.length; index++) {
                        if (isNullOrUndefined(temp[index]["coursesOfer"]))
                            delete temp[index]
                    }
                    var temp2 = []
                    for (var index = 0; index < temp.length; index++) {
                        if (!isNullOrUndefined(temp[index]))
                            temp2 = temp2.concat(temp[index])
                    }
                    this.Courses = temp2

                    //SI DESPUES DE LIMPIAR ENCUENTRA UN CURSO, BUSCO LAS MATERIAS QUE ESTAN ASOCIADAS AL CURSO
                    if (this.Courses.length != 0) {
                   
                        var cx = this;
                        async.forEachLimit(this.Courses, 3, function (course, callback) {
                            cx.coursesService.getSubjectGroupsByCourseGroupID(course.CourseGroupID).subscribe(subject => {
                                //BUSCO LAS POSICIONES DONDE EL COURSEOFERID DE LA VARIABLE TEMP ESTE EN EL ID DEL CURSO CONSULTADO
                                for (var searchk = 0; searchk < cx.Courses.length; searchk++) {
                                    for (var searchj = 0; searchj < subject.length; searchj++) {
                                        if (cx.Courses[searchk].CourseGroupID === subject[searchj]["CourseGroupID"]) {
                                            var pos = searchk
                                            break
                                        }

                                    }

                                    // CUSTOM SCROLL
                                    $(document).ready(function () {
                                        $('.custom-scroll').jScrollPane({
                                            autoReinitialise: true,
                                            autoReinitialiseDelay: 100
                                        });
                                    })
                                }
                                //pos = this.Courses.findIndex(i => i.CourseGroupID == subject["CourseGroupID"]);
                                //console.log("la posicion es" + pos)
                                if (!isNullOrUndefined(pos))
                                    cx.Courses[pos]["Subjects"] = subject
                                //console.log("el curso quedo")
                                //console.log(this.Courses)
                                callback()
                            })
                        }
                            , function (err) {
                                //console.log("LOS COURSES SON:")
                                //console.log(this.Courses)
                                $(document).ready(function () {
                                    $('[data-tooltip="tooltip"]').tooltip({
                                        trigger: 'hover'
                                    })
                                });
                                cx.authService.getTime().then(server2 => {
                                    cx.serverTime = server2.time
                                    //SE REOGE EL DIA DE HOY
                                    var today = cx.serverTime
                                    //SE RECOGE LA FECHA EN YYYY-MM-DD
                                    var m = moment(today, 'YYYY-MM-DD');
                                    //REVISAR FECHAS DE MATERIAS QUE SE ACABEN TRES SEMANAS ANTES DE ALGUNA MATERIA
                                    for (let index = 0; index < cx.Courses.length; index++) {
                                        //  console.log("##############################")
                                        // console.log(cx.Courses[index])
                                        // console.log(cx.Courses[index]["Subjects"])
                                        if (!isNullOrUndefined(cx.Courses[index]["Subjects"])) {

                                            for (let index2 = 0; index2 < cx.Courses[index]["Subjects"].length; index2++) {
                                                cx.SubjectsStudent.push(cx.Courses[index]["Subjects"][index2])
                                                /*
                                                // console.log("------------------------------#")
                                                // console.log(cx.Courses[index]["Subjects"][index2])
                                                var endDateCourse = cx.Courses[index]["Subjects"][index2].DateEnd
                                                //console.log(endDateCourse)
                                                endDateCourse = moment(endDateCourse, 'YYYY-MM-DD');
                                                //COMPRUEBO QUE LA FECHA DE HOY SEA MENOR O IGUAL A TRES SEMANAS ANTES QUE SE ACABE EL CURSO
                                                var difference = endDateCourse.diff(m, 'weeks', true) // 1   
                                                //console.log(difference)
                                                if (difference <= 10 && difference >= -1.1) {
                                                    cx.SubjectsStudent.push(cx.Courses[index]["Subjects"][index2])
                                                    //console.log(cx.Courses[index]["Subjects"][index2])
                                                }*/
                                            }
                                        }
                                    }

                                    if (cx.SubjectsStudent.length == 0)
                                        cx.authService.swalError("No se encontraron materias", "No hay materias que  estén en las fechas de calificaciones");
                                    else
                                        cx.authService.swalEndLoad()
                                })
                            });
                    }
                });
            }
        }
        /*
                this.authService.getTime().then(server2 => {
                this.serverTime = server2.time  
                    //SI NO HAY USUARIOS EN LOCAL STORAGE, LLEVAR A LOGIN
                    if (this.authService.getCurrentUser() == null) {
                        this.router.navigate(['/register']);
                    } else {
                        //DE LO CONTRARO CARGAR EL ID DEL USUARIO
                        this.user = this.authService.getCurrentUser(); //devuelve el id del usuario
                        //SI EL ID DEL USUARIO ES UN NUMERO ENTERO
                        if (Number.isInteger(parseInt(this.user))) {
                            //CONSULTO EL PERFIL DEL USUARIO
                            var validar_usuario = [false];
        
                            for (let role of this.profileNavComponent.roles) {
                                if (role.name == "Estudiante") {
                                    validar_usuario = validar_usuario.concat([true])
                                        break
                                }
                                if (role.name == "admin") {
                                    validar_usuario = validar_usuario.concat([true])       
                                    break
                                }
                            }
        
                            if (!validar_usuario.includes(true)) { this.router.navigate(['/']); }
        
                            //SE REOGE EL DIA DE HOY
                            var date = new Date(this.serverTime)
        
                            //SE RECOGE LA FECHA EN YYYY-MM-DD
                            var m = moment(date, 'YYYY-MM-DD');
                            //SE RECOGE LA FECHA EN YYYY-MM-DD DE LA SIGUIENTE SEMANA
                            var m2 = moment(date, 'YYYY-MM-DD').add(1, 'week');
        
                            console.debug("estamos en la semana #   " + m.format('W'))
                            var RangeDates1 = this.getDateRangeOfWeek(m.format('W'), m.format('YYYY'))
                            var RangeDates2 = this.getDateRangeOfWeek(m2.format('W'), m2.format('YYYY'))
        
                            //SE CREA EL RANGO DE LA SEMANA ACTUAL Y LA SEMANA SIGUIENTE
                            // ESTO CON EL FIN DE PODER CREAR EL HORARIO DE LA SEMANA SIGUIENTE
                            var RangeDates = [moment(RangeDates1[0], 'YYYY-MM-DD').startOf('day'), moment(RangeDates2[1], 'YYYY-MM-DD').endOf('day')]
        
                            console.debug(RangeDates)        
                            //CONSULTO LA LISTA DE CURSOS POR EL USUARIO
                            this.coursesService.getCoursesByUserID(parseInt(this.user)).subscribe(ResponseCourses => {
        
                                //BUSCO LA FECHA MAS LEJANA
                                console.log(ResponseCourses)
                                for (var index = 0; index < ResponseCourses.length; index++) {
        
                                    if (ResponseCourses[index]["coursesOfer"]["Until"] > this.endDateCourse.format())
                                        this.endDateCourse = ResponseCourses[index]["coursesOfer"]["Until"]
        
                                }      
                                //COMPRUEBO QUE ESTE DENTRO DEL RANGO PARA CALIFICAR
                                var checkendDateCourse = moment(this.endDateCourse, 'YYYY-MM-DD');
                                //COMPRUEBO QUE LA FECHA DE HOY SEA MENO O IGUAL A TRES SEMANAS ANTES QUE SE ACABE EL CURSO
                                var difference = checkendDateCourse.diff(m, 'weeks', true)
                                if (difference <= 3 && difference >= -1.1) { }
                                else {
        
                                    this.authService.swalError("No Puede Realiza Evaluación Docente", "No se encuentra dentro de las fechas disponibles para calificar")
        
                                }
                                //CON LA FECHA MAS LARGA LA COLOCO EN EL RANGO
                                RangeDates[1] = moment(this.endDateCourse).endOf('day')
                                console.log("EL RANGO DE FECHAS ES")
                                console.log(RangeDates)
                                //BUSCO LAS MATERIAS QUE ESTEN EN EL RANGO DESDE EL DIA DE HOY HASTA CUANDO SE ACABA EL CURSO
                                this.coursesService.getSubjectsByUserIDRange(parseInt(this.user), RangeDates).subscribe(response => {
                                    var SubjectsRange = []
                                    //this.SubjectsStudent = response
                                    console.log(response)
                                    for (var index = 0; index < response.length; index++) {
        
                                        if (!isNullOrUndefined(response[index]["subjectGroup"]))
                                            SubjectsRange.push(response[index])
                                    }
        
                                    this.SubjectsStudent = SubjectsRange
                                })
                            })
                        }
                        else { this.router.navigate(['/']); }
                    }
        
                    this.student = {
        
                        SubjectGroupID: null,
                        Preg1: null,
                        Preg2: null,
                        ObsCat1: '',
                        Preg3: null,
                        Preg4: null,
                        ObsCat2: '',
                        Preg5: null,
                        Preg6: null,
                        ObsCat3: '',
                        Preg7: null,
                        Preg8: null,
                        ObsCat4: '',
                        Preg9: null,
                        Preg10: null,
                        ObsCat5: '',
                        Preg11: null,
                        Preg12: null,
                        Preg13: null,
                        ObsCat6: '',
                        Preg14: null,
                        ObsCat7: '',
                        Preg15: null,
                        Preg16: null,
                        ObsCat8: '',
                        Preg17: null,
                        Preg18: null,
                        ObsCat9: ''
                    }
                });
        */
    }
    InitData() {
        this.student = {

            SubjectGroupID: null,
            Preg1: null,
            Preg2: null,
            ObsCat1: '',
            Preg3: null,
            Preg4: null,
            ObsCat2: '',
            Preg5: null,
            Preg6: null,
            ObsCat3: '',
            Preg7: null,
            Preg8: null,
            ObsCat4: '',
            Preg9: null,
            Preg10: null,
            ObsCat5: '',
            Preg11: null,
            Preg12: null,
            Preg13: null,
            ObsCat6: '',
            Preg14: null,
            ObsCat7: '',
            Preg15: null,
            Preg16: null,
            ObsCat8: '',
            Preg17: null,
            Preg18: null,
            ObsCat9: ''
        }
    }
    handleMyEvent(arg, vari) {
        this.student[vari] = arg;
    }
    selectmateria(model) {
        this.SubjectID = model.id;
        this.evaluatiosService.getCountStudentByID(parseInt(this.user), model.id).subscribe(p => {
            var Contador = p
            if (Contador["count"] > 0) {
                this.ErrorGroup = "Ya Realizó La Evaluación Para Esta Materia";
                this.showdata = false;
                this.authService.swalError("Ya Realizó La Evaluación Para Esta Materia", "")
            } else {
                this.showdata = true;
            }
        }
        );
    }

    saveStudent(model: Student) {

        this.student.SubjectGroupID = this.SubjectID;
        Swal({
            title: 'Estas Seguro',
            text: "No Podrás Modificar La Calificación",
            type: 'warning',
            showCancelButton: true,

            confirmButtonText: 'Si, Enviar!',
            cancelButtonText: 'No, cancelar!',

        }).then((result) => {

            if (result.value) {
            //una VEZ CONFIRMADO EJECUTAR NORMALMENTE EL SCRIPT
            if (this.student.SubjectGroupID && this.student.Preg1 && this.student.Preg2 && this.student.Preg3 && this.student.Preg4 && this.student.Preg5 && this.student.Preg6
                && this.student.Preg7 && this.student.Preg8 && this.student.Preg9 && this.student.Preg10 && this.student.Preg11 && this.student.Preg12
                && this.student.Preg13 && this.student.Preg14 && this.student.Preg15 && this.student.Preg16 && this.student.Preg17 && this.student.Preg18) {
                //no null

                this.evaluatiosService.createStudent(this.student, parseInt(this.user)).subscribe(p => {
                    //this.student = p;
                    this.InitData();
                    this.ErrorGroup = "Gracias por Evaluar";
                    this.showdata = false;
                    this.authService.swalSuccess("Gracias por Evaluar", "")
                });
            } else {
                this.authService.swalError("Error Guardando Evaluación", "Asegúrese de calificar todas las categorías")
            }
        }
        })
    }

    //FUNCION PARA ENTREGAR LOS DIAS DE LA SEMANA EN BASE AL NUMERO DE LA SEMANA
    getDateOfISOWeek(w, y) {
        var simple = new Date(y, 0, 1 + (w - 1) * 7);
        var dow = simple.getDay();
        var ISOweekStart = simple;
        if (dow <= 4)
            ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
        else
            ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());

        //ISOweekStart.setHours(-5, 0, 0, 0);

        return ISOweekStart;
    }

    getDateRangeOfWeek(w, y) {

        var date = this.getDateOfISOWeek(w, y);
        var date2 = this.getDateOfISOWeek(w, y);
        // easily get ending date by adding 6 days more
        date.setDate(date.getDate());
        date.setHours(0, 0, 0, 0);
        date2.setDate(date2.getDate() + 6);
        date2.setHours(0, 0, 0, 0);
        //RETORNA EL DIA DE INICIO DE ESA SEMANA Y EL DIA QUE ACABA
        //LUNES A DOMINGO

        return ([date, date2]);
    }
}