import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { CustomFormsModule } from 'ng2-validation';
import {  ButtonViewWeeklyBreak, ButtonViewNameDay } from './recesos.component';
import { Ng2SmartTableModule } from 'ng2-smart-table';

import { PendingChangesGuard } from './pending-changes-guard';

import { OrderModule } from 'ngx-order-pipe';
import { RecesosComponent } from './recesos.component';


export const routes: Routes = [
  { path: 'horarios/recesos', component: RecesosComponent, canDeactivate: [PendingChangesGuard] },

];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FormsModule,
    CustomFormsModule,
    HttpModule,
    Ng2SmartTableModule,
    OrderModule
  ],
  entryComponents: [
    

    ButtonViewNameDay,
    ButtonViewWeeklyBreak,
  ],
  declarations: [
    RecesosComponent,
    ButtonViewWeeklyBreak,
    ButtonViewNameDay,
  ],
  exports: [RouterModule],
  providers: [PendingChangesGuard],


})
export class RecesosModule { }
