import { Injectable } from '@angular/core';

import { Delegate } from './delegate';
import { Institutional } from './institutional';
import { Self } from './self';
import { Student } from './student';

import { AuthService } from "../Auth/auth.service";
import { InstrumentUser } from './instrumentuser';
import { InstrumentInfo } from './instrumentinfo';
import { Instrument } from './instrument';
import { Graduates } from './graduates';
import { TeacherView } from './teacher.view';
import { TeacherViewDelegate } from './teacher.view.delegate';
import { TeacherViewSelf } from './teacher.view.self';
import { SchoolView } from './school.view';

import { catchError, map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';


@Injectable()

export class EvaluationsService {

  constructor(private http: HttpClient, private authService: AuthService) {
  }

  private baseUrl: string = this.authService.UrlAPI;


  getInstrumentUser(): Observable<any> {

    let typeinstruments$ = this.http
      .get(`${this.baseUrl}/InstrumentUsers`, { headers: this.getHeaders() })
      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )  
    return typeinstruments$;
  }


  getInstrumentInfoByTypeUserID(typeUser: number): Observable<any> {

    let responsePost$ = this.http
      .get(`${this.baseUrl}/InstrumentInfos?filter={"where": {"TypeUser": {"regexp": "${typeUser}"}}}`, { headers: this.getHeaders() })
      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )  
    return responsePost$;
  }


  evaluateInstruments(note: Instrument): Observable<Instrument> {
    let responsePost = this
      .http
      .post(`${this.baseUrl}/Instruments`,
        JSON.stringify(note),
        { headers: this.putHeaders() })
        .pipe(
          catchError(err => this.authService.errorMessages(err))
        )  
    return responsePost;

  }

  getTeacherViewBySchool(id: number): Observable<any> {

    let responsePost$ = this.http
      .get(`${this.baseUrl}/TeacherViews?filter={"where": {"SchoolID": ${id}}}`, { headers: this.getHeaders() })
      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )  
    return responsePost$;
  }

  getTeacherViewDelegateBySchool(id: number, filter?: string): Observable<any> {
    if (filter) {
      let responsePost$ = this.http
        .get(`${this.baseUrl}/TeacherViewDelegates?filter=${filter}`, { headers: this.getHeaders() })
        .pipe(
          catchError(err => this.authService.errorMessages(err))
        )  
      return responsePost$;
    }
    else {
      let responsePost$ = this.http
        .get(`${this.baseUrl}/TeacherViewDelegates?filter={"where": {"SubjectID": ${id}}}`, { headers: this.getHeaders() })
        .pipe(
          catchError(err => this.authService.errorMessages(err))
        )  
      return responsePost$;
    }

  }

  getTeacherViewSelfBySchool(id: number, filter?: string): Observable<any> {
    if (filter) {
      let responsePost$ = this.http
        .get(`${this.baseUrl}/TeacherViewSelves?filter=${filter}`, { headers: this.getHeaders() })
        .pipe(
          catchError(err => this.authService.errorMessages(err))
        )  
      return responsePost$;
    }
    else {
      let responsePost$ = this.http
        .get(`${this.baseUrl}/TeacherViewSelves?filter={"where": {"SubjectID": ${id}}}`, { headers: this.getHeaders() })
        .pipe(
          catchError(err => this.authService.errorMessages(err))
        )  
      return responsePost$;
    }

  }

  getTeacherViewBySchoolAndSubjectID(id: number, CourseOferID): Observable<any> {

    let responsePost$ = this.http
      .get(`${this.baseUrl}/TeacherViews?filter={"where": {"SubjectID": ${id},"CourseOferID": ${CourseOferID} } }`, { headers: this.getHeaders() })
      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )  
    return responsePost$;
  }

  createDelegate(delegate: Delegate, UserID): Observable<Delegate> {
    let responsePost = this
      .http
      .post(`${this.baseUrl}/Userapps/${UserID}/DelegateApprs`,
        JSON.stringify(delegate),
        { headers: this.putHeaders() })
        .pipe(
          catchError(err => this.authService.errorMessages(err))
        )  
    return responsePost;

  }


  getDelegateCountBySubjectGroup(id): Observable<any> {
    //devuelve cuantos grupos hay en la oferta del curso
    let responsePost$ = this.http
      .get(`${this.baseUrl}/DelegateApprs/count/?where={"SubjectGroupID":${id}} `, { headers: this.getHeaders() })
      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )  
    return responsePost$;
  }

  getInstitutionalCountBySchoolAndUserID(School: number, User: number): Observable<any> {
    //devuelve cuantos grupos hay en la oferta del curso
    let responsePost$ = this.http
      .get(`${this.baseUrl}/SchoolApprs/count?where={"and": [{"SchoolID": ${School}},{"UserID":${User}}]} `, { headers: this.getHeaders() })
      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )  
    return responsePost$;
  }

  getSchoolApprByCourseOfer(School: number): Observable<any> {
    //devuelve cuantos grupos hay en la oferta del curso
    let responsePost$ = this.http
      .get(`${this.baseUrl}/SchoolViews/?filter={"where":{"SchoolID":${School}}} `, { headers: this.getHeaders() })
      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )  
    return responsePost$;
  }



  getGraduatesCountBySchoolAndUserID(School: number, User: number): Observable<any> {
    //devuelve cuantos grupos hay en la oferta del curso
    let responsePost$ = this.http
      .get(`${this.baseUrl}/GraduatesApprs/count?where={"and": [{"SchoolID": ${School}},{"UserID":${User}}]} `, { headers: this.getHeaders() })
      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )  
    return responsePost$;
  }





  getDelegateBySubjectGroup(filer): Observable<any> {
    //devuelve cuantos grupos hay en la oferta del curso
    let responsePost$ = this.http
      .get(`${this.baseUrl}/DelegateApprs/?filter=${filer}`, { headers: this.getHeaders() })
      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )  
    return responsePost$;
  }

  getStudentsApprBySubjectGroup(filer): Observable<any> {
    //devuelve cuantos grupos hay en la oferta del curso
    let responsePost$ = this.http
      .get(`${this.baseUrl}/StudentApprs/?filter=${filer}`, { headers: this.getHeaders() })
      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )  
    return responsePost$;
  }

  getSelfsApprBySubjectGroup(id): Observable<any> {
    //devuelve cuantos grupos hay en la oferta del curso
    let responsePost$ = this.http
      .get(`${this.baseUrl}/SelfApprs/?filter={"where": {"UserID": ${id}} }`, { headers: this.getHeaders() })
      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )  
    return responsePost$;
  }

  getSchoolApprBySubjectGroup(filer): Observable<any> {
    //devuelve cuantos grupos hay en la oferta del curso
    let responsePost$ = this.http
      .get(`${this.baseUrl}/SchoolApprs/?filter=${filer}`, { headers: this.getHeaders() })
      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )  
    return responsePost$;
  }

  //Graduados
  getGraduatesApprBySubjectGroup(filer): Observable<any> {
    //devuelve cuantos grupos hay en la oferta del curso
    let responsePost$ = this.http
      .get(`${this.baseUrl}/GraduatesApprs/?filter=${filer}`, { headers: this.getHeaders() })
      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )  
    return responsePost$;
  }

  getStudentApprBySubjectGroup(id): Observable<any> {
    //devuelve cuantos grupos hay en la oferta del curso
    let responsePost$ = this.http
      .get(`${this.baseUrl}/StudentApprs/?filter={"where": {"and": [{"SubjectGroupID":${id}}  ]}}`, { headers: this.getHeaders() })
      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )  
    return responsePost$;
  }

  getStudentApprBySubjectGroupByTeache(id): Observable<Self> {
    //devuelve cuantos grupos hay en la oferta del curso
    let responsePost$ = this.http
      .get(`${this.baseUrl}/SubjectGroups/${id}/userSubjects/count`, { headers: this.getHeaders() })
      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )  
    return responsePost$;
  }

  getSelfApprBySubjectGroup(id): Observable<any> {
    //devuelve cuantos grupos hay en la oferta del curso
    let responsePost$ = this.http
      .get(`${this.baseUrl}/SelfApprs/?filter={"where": {"and": [{"SubjectGroupID":${id}}  ]}}`, { headers: this.getHeaders() })
      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )  
    return responsePost$;
  }




  createSelf(self: Self, UserID): Observable<Self> {
    console.log("SE LLEGO A LA FUNCION")
    console.log(self)


    let responsePost = this
      .http
      .post(`${this.baseUrl}/Userapps/${UserID}/SelfApprs`,
        JSON.stringify(self),
        { headers: this.putHeaders() })
        .pipe(
          catchError(err => this.authService.errorMessages(err))
        )  
    return responsePost;

  }

  getCountSelfByID(UserID, SubjectGroupID): Observable<Self> {
    //devuelve cuantos grupos hay en la oferta del curso
    let responsePost$ = this.http
      .get(`${this.baseUrl}/Userapps/${UserID}/SelfApprs/count?where={"SubjectGroupID":${SubjectGroupID}}`, { headers: this.getHeaders() })
      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )  
    return responsePost$;
  }

  getCountStudentByID(UserID, SubjectGroupID): Observable<Student> {
    //devuelve cuantos grupos hay en la oferta del curso
    let responsePost$ = this.http
      .get(`${this.baseUrl}/Userapps/${UserID}/StudentApprs/count?where={"SubjectGroupID":${SubjectGroupID}}`, { headers: this.getHeaders() })
      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )  
    return responsePost$;
  }

  getCountStudentByCourseOFer(UserID, CourseOferID): Observable<Institutional> {
    //devuelve cuantos grupos hay en la oferta del curso
    let responsePost$ = this.http
      .get(`${this.baseUrl}/Userapps/${UserID}/SchoolApprs/count?where={"CourseOferID":${CourseOferID}}`, { headers: this.getHeaders() })
      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )  
    return responsePost$;
  }

  getSubjectStudentByID(UserID): Observable<Student> {
    //devuelve cuantos grupos hay en la oferta del curso
    let responsePost$ = this.http
      .get(`${this.baseUrl}/Userapps/${UserID}/SubjectGroup/?filter={"include": ["subjects"]}`, { headers: this.getHeaders() })
      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )  
    return responsePost$;
  }


  createInstitutional(institutional: Institutional): Observable<Institutional> {
    let responsePost = this
      .http
      .post(`${this.baseUrl}/SchoolApprs`,
        JSON.stringify(institutional),
        { headers: this.putHeaders() })
        .pipe(
          catchError(err => this.authService.errorMessages(err))
        )  
    return responsePost;

  }

  createGraduates(graduates: Graduates): Observable<Graduates> {
    let responsePost = this
      .http
      .post(`${this.baseUrl}/GraduatesApprs`,
        JSON.stringify(graduates),
        { headers: this.putHeaders() })
        .pipe(
          catchError(err => this.authService.errorMessages(err))
        )  
    return responsePost;

  }

  createStudent(student: Student, UserID): Observable<Student> {

    console.log("SE LLEGO A LA FUNCION")
    console.log(student)

    let responsePost = this
      .http
      .post(`${this.baseUrl}/Userapps/${UserID}/StudentApprs`,
        JSON.stringify(student), { headers: this.putHeaders() })
        .pipe(
          catchError(err => this.authService.errorMessages(err))
        )  
    return responsePost;

  }


  getHeaders(): HttpHeaders{
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.authService.getToken()
    });
    return headers;
  }
  
  putHeaders(): HttpHeaders{
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.authService.getToken()
    });
    return headers;
  }

}
