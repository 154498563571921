import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { FormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { EmptyRouteComponent } from './empty-route/empty-route.component';


import { ProfileNavComponent } from './components/profile-nav/profile-nav.component';

import { UsersService } from './models/usersInfo/users.service';
import { OapinfoService } from './models/oapinfo/oapinfo.service';
import { CoursesService } from './models/Coursesinfo/courses.service';
import { AuthService } from "./models/Auth/auth.service";
import { AttachmentService } from "./models/attachment/attachment.service";
import { EvaluationsService } from './models/evaluation/evaluations.service';
import { RecordService } from './models/midterm/record.service';
import { OvaService } from './models/ova/ovas.service'
import { MailService } from './models/mail/mail.service';
import { ChatService } from './models/chat/chat.service';
import { AccordingView } from './components/according-view/according-view.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { OrderModule } from 'ngx-order-pipe';
import { ChartsModule } from 'ng2-charts';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app.routing';


import { NgbModule } from '@ng-bootstrap/ng-bootstrap';




@NgModule({
  declarations: [
    EmptyRouteComponent,
    AppComponent,
    ProfileNavComponent,
    AccordingView,

  ],
  imports: [
    BrowserModule,
    NgbModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    AppRoutingModule,
    OrderModule,
    ChartsModule
  ],

  entryComponents: [
    AccordingView],
  providers: [
    UsersService,
    OapinfoService,
    OvaService,
    CoursesService,
    AuthService,
    AttachmentService,
    EvaluationsService,
    RecordService,
    MailService,
    ChatService,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})

export class AppModule {}
