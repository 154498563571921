import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { CustomFormsModule } from 'ng2-validation';
import { NewScheduleComponent, ButtonViewScheduleComponent, ButtonViewScheduleGroupsComponent, ButtonViewdDateChanged, ButtonViewdPhysicalTest } from './new-schedule.component';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { CalendarComponent } from './calendar/calendar.component';
import { PendingChangesGuard } from './pending-changes-guard';

export const routes: Routes = [
  { path: 'horarios/newschedule', component: NewScheduleComponent, canDeactivate: [PendingChangesGuard] },
  { path: 'horarios/calendar', component: CalendarComponent }
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FormsModule,
    CustomFormsModule,
    HttpModule,
    Ng2SmartTableModule,
  ],
  entryComponents: [
    ButtonViewScheduleComponent,
    ButtonViewScheduleGroupsComponent,
    ButtonViewdDateChanged,
    ButtonViewdPhysicalTest,
  ],
  declarations: [
    NewScheduleComponent,
    ButtonViewScheduleComponent,
    ButtonViewScheduleGroupsComponent,
    ButtonViewdDateChanged,
    CalendarComponent,
    ButtonViewdPhysicalTest,
  ],
  exports: [RouterModule],
  providers: [PendingChangesGuard],


})
export class NewScheduleModule { }
