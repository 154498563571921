import { Component, OnInit, AfterViewChecked, HostListener } from '@angular/core';
import { Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { isNullOrUndefined } from 'util';
import { LocalDataSource } from 'ng2-smart-table';
import { ViewCell } from 'ng2-smart-table';
import { School } from "../../../models/Coursesinfo/school";
import { Module } from "../../../models/Coursesinfo/module";
import { UsersField } from '../../../models/usersInfo/users-fields';
import { Subject } from '../../../models/Coursesinfo/subject';
import { Course } from '../../../models/Coursesinfo/course';
import { AuthService } from '../../../models/Auth/auth.service';
import { UsersService } from '../../../models/usersInfo/users.service';
import { CoursesService } from '../../../models/Coursesinfo/courses.service';
import { ProfileNavComponent } from '../../../components/profile-nav/profile-nav.component';
import { Observable } from 'rxjs/Observable';
import { ComponentCanDeactivate } from './pending-changes-guard';
import { DateMidTerm } from '../../../models/midterm/datemidterm';
//import { MODULE_SUFFIX } from '@angular/compiler/src/util';
import * as moment from 'moment';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { environment } from 'environments/environment';
declare var $: any;


var async = require("async");

export class Intermediate {
    public Disponible = 0;
    public Professor: object = {
        2941: "--ASIGNAR DESPUÉS",
    }
    public Grups: object = {
        0: " NO GROUP",
    }
}
// INICIO COMPONENTE PARA EL NG SMART TABLE GRUPOS
@Component({
    selector: 'button-view-groups',
    template: `
    {{ renderValue }}
  `
})
export class ButtonViewScheduleGroupsComponent implements ViewCell, OnInit, AfterViewChecked {
    constructor(
        public intermediate: Intermediate,
    ) { }
    renderValue: string;

    @Input() value: string | number;
    @Input() rowData: any;
    @Output() save: EventEmitter<any> = new EventEmitter()
    ngOnInit() {
        // console.debug("aca esta el render despues")
        //  console.debug(this.intermediate.Grups)
        this.renderValue = this.intermediate.Grups[this.value];
    }
    ngAfterViewChecked() {
        this.renderValue = this.intermediate.Grups[this.value];
    }
}
// FIN COMPONENTE PARA EL NG SMART TABLE GRUPOS


// INICIO COMPONENTE PARA EL NG SMART TABLE ButtonViewdPhysicalTest
@Component({
    selector: 'button-view-dateChanged',
    template: `
  <b *ngIf="renderValue=='Si'"  style='color: green;'>  {{renderValue}}</b>
  <b *ngIf="renderValue=='No'"   >  {{renderValue}}</b>
      `
})
export class ButtonViewdPhysicalTest implements ViewCell, OnInit, AfterViewChecked {
    constructor(

    ) { }
    renderValue: string;
    @Input() value: string | number;
    @Input() rowData: any;
    @Output() save: EventEmitter<any> = new EventEmitter()
    
    ngOnInit() {
        // console.log("aca esta el render despues")
        //  console.log(this.intermediate.Grups)
        if (!isNullOrUndefined(this.value)) {
            if (this.value.toString() == "true" || this.value.toString() == "1")
                this.renderValue = "Si"
            if (this.value.toString() == "false" || this.value.toString() == "0")
                this.renderValue = "No"
        }
    }
    ngAfterViewChecked() {
        this.renderValue = String(this.value);
    }

}
// FIN COMPONENTE PARA EL NG SMART TABLE ButtonViewdPhysicalTest




// INICIO COMPONENTE PARA EL NG SMART TABLE USUARIO
@Component({
    selector: 'button-view-courses',
    template: `
    {{ renderValue }}
  `
})
export class ButtonViewScheduleComponent implements ViewCell, OnInit, AfterViewChecked {
    constructor(
        public intermediate: Intermediate,
    ) { }
    renderValue: string;
    @Input() value: string | number;
    @Input() rowData: any;
    @Output() save: EventEmitter<any> = new EventEmitter()
    ngOnInit() {
        // console.debug("aca esta el render despues")
        // console.debug(this.intermediate.Professor)
        this.renderValue = this.intermediate.Professor[this.value];
    }
    ngAfterViewChecked() {
        this.renderValue = this.intermediate.Professor[this.value];
    }
    onClick() {
        this.save.emit(this.rowData);
    }
}
// FIN COMPONENTE PARA EL NG SMART TABLE USUARIO






// INICIO COMPONENTE PARA EL NG SMART TABLE dateChanged
@Component({
    selector: 'button-view-dateChanged',
    template: `
  <b *ngIf="renderValue=='Si'"  style='color: red;'>  {{renderValue}}</b>
  <b *ngIf="renderValue=='No'"   >  {{renderValue}}</b>
      `
})
export class ButtonViewdDateChanged implements ViewCell, OnInit, AfterViewChecked {
    constructor(

    ) { }
    renderValue: string;
    @Input() value: string | number;
    @Input() rowData: any;
    @Output() save: EventEmitter<any> = new EventEmitter()
    ngOnInit() {
        // console.log("aca esta el render despues")
        //  console.log(this.intermediate.Grups)
        if (!isNullOrUndefined(this.value)) {
            if (this.value.toString() == "true" || this.value.toString() == "1")
                this.renderValue = "Si"
            if (this.value.toString() == "false" || this.value.toString() == "0")
                this.renderValue = "No"
        }
    }
    ngAfterViewChecked() {
        this.renderValue = String(this.value);
    }

}
// FIN COMPONENTE PARA EL NG SMART TABLE  ISWEEKLY



@Component({
    selector: 'new-schedule',
    templateUrl: './new-schedule.component.html',
    styleUrls: ['./new-schedule.component.css'],
    providers:
        [UsersService,
            CoursesService,
            ButtonViewScheduleComponent,
            ButtonViewScheduleGroupsComponent,
            Intermediate,
            ButtonViewdDateChanged,
            ButtonViewdPhysicalTest]
})
export class NewScheduleComponent implements ComponentCanDeactivate, OnInit {
    enableSecondarystudentsasociation: boolean = false;
    isPersonal: boolean = false;
    CourseGroupIDSelected: any;
    previousEvents: any[] = [];
    //:DAVID : CanDeactivate
    public CEMIL_ICON:any = environment.CEMIL_ICON;
    public EJC_ICON:any = environment.EJC_ICON;  
    canDeactivate(): Observable<boolean> | boolean {

        // insert logic to check if there are pending changes here;
        // returning true will navigate without confirmation
        // returning false will show a confirm alert before navigating away
        return !this.isloading
    }

    isloading = false;
    serverTime: Date
    //variables usadas en este componente
    public KeyColorAcademicArea: any;
    public ColorAcademicArea =
        ["#5d80a2",
            "#680345",
            "#7C6445",
            "#E154CE",
            "#258C64",
            "#A44015",
            "#FE9A2E",
            "#DF0101",
            "#01DF01",
            "#0040FF",
            "#565ACB",
            "#144A20",
            "#AAE951",
            "#6CF132",
            "#F19663",
            "#891CBB",
            "#21EBF3",
            "#F19663",
            "#61265F",
            "#6916E4",
            "#464208",
            "#393D78",
            "#E2337D",
            "#A1924C",
            "#52B743",
            "#176548",
            "#A0B03D",
            "#7C188A",
            "#77BB3E"]

    public Groups: any = { id: 0 };
    Groups_init: any = { id: 0 };
    AcademicArea_init: any = { id: 0 };
    public AcademicArea: any;
    public AcademicAreaSelected: any;
    public EnableTable: boolean = false;
    public ListProfessors: UsersField[];
    public SubjectsModifyAddProfessor: Subject[];
    public SubjectsModifyAddProfessorWithSubjectGroupID: Subject[];
    public ModelAcademicAreaID: number;
    public ModelCourseGroupID: number;
    user: string;
    student: UsersField = {
        CourseOferID: 0,

    };
    public CourseID;
    public Subject: Subject[];
    course_by_school: Course[];
    subjects_by_course: Course[];
    Studends_by_Course: any;
    public List_students_by_course: any[];
    public List_students_by_course2: any[];
    public Students: any[];
    public StudentsByGroup: any[];
    public Contador: number;
    public AreStudents: boolean;
    public AreGroups: boolean;
    public school: Course[];
    public NumberGroups =
        [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26]
    public SchoolByID: School;
    public ModelProfessorSmartTable = [];
    public ModelProfessorSmartTable2 = [];
    abecedario = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"]
    public SubjectGtoupID_Verified = []
    public Disponible: number = 0;
    public EnableAsociations: boolean = false
    public CreateSchedule: boolean = true
    public ShowCalendar: boolean = false
    public TotalEvents = 0;
    public CurrenEvent = 0;
    public courseofferidseleted: number;
    public SchoolIDSelected: number;
    public courseofferidseletedsemestrlize: boolean;
    public SearchSubjects = [];
    public CourseInfo;
    public midtermData: any[];
    public enablestudentsasociation: boolean = false;
    public contador_calendario: number;
    public array_to_asociate: any[] = [];
    public infoarray_to_asociate: any[] = []
    public infop: any[] = []

    isHorarios: boolean = false
    //:DAVID 
    alertLoad: boolean = false;//:DAVID 

    //inicia configuracion smart table
    modules: Module[];
    schools: School[];
    courses: Course[];
    subjects: Course[];
    userid: string;
    areaID: number = 0;
    courseID: number = 0;
    settings = {
        noDataMessage: "No hay materias para el área seleccionada",

        edit: {
            editButtonContent: '<i class="fa fa-pencil-square"></i>',
            saveButtonContent: '<i class="fa fa-floppy-o"></i>',
            cancelButtonContent: '<i class="fa fa-window-close"></i>',
            confirmSave: true,
        },
        actions: {
            add: false,
            delete: false,
            edit: true,
            columnTitle: "Acciones"
        },
        pager: {
            perPage: 20,
        },
        columns: {


            SubjectID: {
                title: 'Id Materia',
                filter: false,
                editable: false,
            },
            NameSubject: {
                title: 'Materia',
                filter: false,
                editable: false,
            },
            CourseGroupID: {
                title: 'Grupo',
                filter: false,
                editable: false,
                type: 'custom',
                renderComponent: ButtonViewScheduleGroupsComponent
            },
            UserID: {
                title: 'Docente',
                filter: false,
                type: 'custom',
                editor: {
                    type: 'list',
                    config: {
                        list: [],
                    },
                },
                renderComponent: ButtonViewScheduleComponent,
                onComponentInitFunction(instance) {
                    instance.save.subscribe(row => {
                        console.debug("algo se edito")
                        // this.saveDocenteDetails(row.id)
                    });
                }
            },
            IsPhysicalTest: {
                title: 'Materia Prueba Fisica',
                filter: false,
                editable: true,
                type: 'custom',
                editor: {
                    type: 'list',
                    config: {
                        list: [{ value: true, title: 'Si' }, { value: false, title: 'No' }],
                    },
                },
                renderComponent: ButtonViewdPhysicalTest
            }

            /*
            
            DateStart: {
                title: 'Fecha Inicio',
                filter: false,
                editable: true,

            },
            DateEnd: {
                title: 'Fecha Final',
                filter: false,
                editable: true,
            },
           
                id: {
                  title: 'GRUPO',
                  filter: false,
                  type: 'custom',
                  renderComponent: ButtonViewCoursesComponent,
                  onComponentInitFunction(instance) {
                      instance.save.subscribe(row => {
                         // this.saveDocenteDetails(row.id)
                      });
                    }
                }
                */
        }
    };

    settingsDate = {
        noDataMessage: "No hay materias para el área seleccionada",

        edit: {
            editButtonContent: '<i class="fa fa-pencil-square"></i>',
            saveButtonContent: '<i class="fa fa-floppy-o"></i>',
            cancelButtonContent: '<i class="fa fa-window-close"></i>',
            confirmSave: true,
        },
        actions: {
            add: false,
            delete: false,
            edit: true,
            columnTitle: "Acciones"
        },
        pager: {
            perPage: 20,
        },
        columns: {



            NameSubject: {
                title: 'Materia',
                filter: false,
                editable: false,
            },
            CourseGroupID: {
                title: 'Grupo',
                filter: false,
                editable: false,
                type: 'custom',
                renderComponent: ButtonViewScheduleGroupsComponent
            },

            DateStart: {
                title: 'Fecha Inicio',
                filter: false,
                editable: true,

            },
            DateEnd: {
                title: 'Fecha Final',
                filter: false,
                editable: true,
            },
            IsDateChanged: {
                title: 'Se han modificado las fechas?',
                filter: false,
                editable: false,
                type: 'custom',
                renderComponent: ButtonViewdDateChanged
            },
            /*
                id: {
                  title: 'GRUPO',
                  filter: false,
                  type: 'custom',
                  renderComponent: ButtonViewCoursesComponent,
                  onComponentInitFunction(instance) {
                      instance.save.subscribe(row => {
                         // this.saveDocenteDetails(row.id)
                      });
                    }
                }
                */
        }
    };

    source: LocalDataSource;
    sourceDates: LocalDataSource;

    //fin configuracion smart table
    constructor(private authService: AuthService,
        private route: ActivatedRoute,
        private router: Router,
        public servicios: UsersService,
        public coursesService: CoursesService,
        public profileNavComponent: ProfileNavComponent,
        private Render: ButtonViewScheduleComponent,
        public intermediate: Intermediate,

    ) {
        this.source = new LocalDataSource(); // create the source
        this.sourceDates = new LocalDataSource(); // create the source





    }
    //INDICO QUE LOS DATOS NO ESTAN DISPONIBLES AL CARGAR EL HTML PARA NO MOSTRAR ERRORES
    DatosDisponibles: boolean = false;
    ngOnInit() {
        this.EnableTable = false
        this.enableSecondarystudentsasociation = false
        this.enablestudentsasociation = false
        this.EnableAsociations = false
        this.ShowCalendar = false      //GUARDO LA HORA DEL SERVIDOR
        this.authService.getTime().then(server2 => this.serverTime = server2.time);

        $("#Group").prop("disabled", true);
        $("#AcademicAreaID").prop("disabled", true);
        //SI NO HAY USUARIOS EN LOCAL STORAGE, LLEVAR A LOGIN
        if (this.authService.getCurrentUser() == null) {
            this.router.navigate(['/register']);
        } else {
            //DE LO CONTRARO CARGAR EL ID DEL USUARIO
            this.user = this.authService.getCurrentUser(); //devuelve el id del usuario
            //SI EL ID DEL USUARIO ES UN NUMERO ENTERO
            if (Number.isInteger(parseInt(this.user))) {
                //console.debug(this.authService.getCurrentUser());
                //CONSULTO EL PERFIL DEL USUARIO
                var validar_usuario = [false];
                var SchoolID = 0;

                for (let role of this.profileNavComponent.roles) {
                    if (role.name == "Horarios") {
                        validar_usuario = validar_usuario.concat([true])
                        SchoolID = role.SchoolID;
                        this.SchoolIDSelected = role.SchoolID
                        this.isHorarios = true

                    }
                    if (role.name == "Personal") {
                        validar_usuario = validar_usuario.concat([true])
                        SchoolID = role.SchoolID;
                        this.SchoolIDSelected = role.SchoolID
                        this.isHorarios = true
                        this.isPersonal = true
                    }

                }
                //SI ALGUN ROL PUEDE VERLO CARGAR LOS DATOS
                //DE LO CONTRARIO LLEVAR AL PEFIL
                if (validar_usuario.includes(true)) {



                    this.coursesService.getSchoolByID(SchoolID).subscribe(p => {
                        this.SchoolByID = p;
                        console.debug("ESL SCHOOL ID FUE EL", this.SchoolByID)

                        //CARGAR PROFESORES QUE ESTEN ACTIVOS EN LA ESCUELA
                        console.debug(this.serverTime.toString())
                        this.coursesService.getAllUserJobsContractBySchoolID(this.SchoolByID["id"], this.serverTime.toString()).subscribe(Userapps => {

                            //ESTO ES PARA MOSTRAR EN EL SELECT CUANDO SE ACTIVA LA CASILLA EDITAR
                            this.ModelProfessorSmartTable = []

                            /*
                             this.ModelProfessorSmartTable.push({
                                 value: 2941,
                                 title: "--ASIGNAR DESPUÉS",
                             })
                             */

                            //ESTO ES PARA MOSTRAR EN EL RENDER
                            this.ModelProfessorSmartTable2 = []
                            // Object.assign(this.ModelProfessorSmartTable2, { 2941: "--ASIGNAR DESPUÉS" })


                            Userapps.forEach(profesor => {
                                let CompleteName = $.grep([profesor.Userapps.LastName1, profesor.Userapps.LastName2, profesor.Userapps.Name1, profesor.Userapps.Name2], Boolean).join(" ");
                                this.ModelProfessorSmartTable.push({
                                    //value: CompleteName.toUpperCase(),
                                    value: profesor.Userapps.id,
                                    title: CompleteName.toUpperCase(),
                                })
                                Object.assign(this.ModelProfessorSmartTable2, { [profesor.Userapps.id]: CompleteName.toUpperCase() })
                                this.RenderProfessor()
                            });
                            this.settings.columns.UserID.editor.config.list = this.ModelProfessorSmartTable.sort(function (a, b) {
                                // Ascending: first age less than the previous
                                return a["title"] == b["title"] ? 0 : a["title"] < b["title"] ? -1 : 1;
                            });

                            console.log("LOS PROFESORES SON:", Userapps)

                        })





                    });





                    this.coursesService.getSchoolByID(SchoolID).subscribe(p => {
                        this.SchoolByID = p;
                        console.debug("ESL SCHOOL ID FUE EL" + this.SchoolByID)
                        console.debug(this.SchoolByID)
                    });
                    this.coursesService.getAllCoursesBySchool(SchoolID).subscribe(p => {
                        this.course_by_school = p.sort(function (a, b) {
                            // Ascending: first age less than the previous
                            return a["NameCourseOfer"].localeCompare(b["NameCourseOfer"]);
                        });

                        console.debug(this.course_by_school)
                    });
                    var state = 1;
                    this.AreStudents = false;
                    this.AreGroups = false;
                    this.student = {
                        Document: '',
                        Name1: '',
                        LastName1: '',
                        CedulaMil: '',
                        IsMilitar: true,
                        SchoolID: 4,
                        CourseOferID: 0,
                        SubjectOferID: 0,
                    }
                    this.Groups = []
                    this.AcademicArea = []
                }
                else {

                    this.router.navigate(['/user/my-account']);

                }
            }
        }
    }

    //:DAVID @HostListener allows us to also guard against browser refresh, close, etc.
    @HostListener('window:beforeunload', ['$event'])
    unloadNotification($event: any) {
        if (!this.canDeactivate()) {
            $event.returnValue = "This message is displayed to the user in IE and Edge when they navigate without using Angular routing (type another URL/close the browser/etc)";
        }
    }
    /*beforeunloadHandler(event) {
        this.authService.swalAlert('ALERTA!', 'Recargar la página puede ocasionar que sus datos se pierdan');
    }*/

    callgroups(model: Course) {
        this.AcademicArea_init = { id: 0 }
        this.Groups_init = { id: 0 }

        if (this.isPersonal == true)
            this.enableSecondarystudentsasociation = false

        this.array_to_asociate = []
        $("#Group").prop("disabled", true);
        $("#AcademicAreaID").prop("disabled", true);
        this.EnableAsociations = false

        this.enablestudentsasociation = false
        this.EnableTable = false
        this.courseofferidseleted = model.id;

        this.CourseInfo = this.infocoursebyID(model.id)

        this.courseofferidseletedsemestrlize = this.textSemestralize(model.id)

        //$('#calendar').fullCalendar('removeEvents')
        this.SubjectsModifyAddProfessor = []

        //CON EL ID DEL CURSO BUSCO LOS GRUPOS QUE HAY PARA ESTE     
        this.coursesService.getAllCourseGroupsByCourseOferByID(model.id).subscribe(p => {

            ;
            if (p.length == 0) {
                this.Groups = [{ id: 0, name: "No Existen Grupos Para el Curso Seleccionado" }]
            } else {

                p = p.sort(function (a, b) {
                    // Ascending: first age less than the previous
                    return a["name"] == b["name"] ? 0 : a["name"] < b["name"] ? -1 : 1;
                })


                this.Groups = p;
                this.intermediate.Grups = {}
                for (let i = 0; i < this.Groups.length; i++) {
                    Object.assign(this.intermediate.Grups, { [this.Groups[i].id]: this.Groups[i].name.toUpperCase() })
                }
            }
            $("#Group").prop("disabled", false);
        });
        //Con el id del curso busco la cantidad de usuarios en el curso
        this.coursesService.getCountStudentsByOfer(model.id).subscribe(p => {
            this.Studends_by_Course = p;
            console.debug("el numero de estudiantes de ese curso es : " + JSON.stringify(this.Studends_by_Course["count"]));
        });
        //descargo la lista de estudiantes del curso (obtengo el id)
        this.coursesService.getStudentsByCourseOferByID(model.id).subscribe(p => {
            this.List_students_by_course = []
            for (var k = 0; k < p.length; k++) {

                if (p[k].UserCourses.length > 0) {

                    this.List_students_by_course.push(p[k])

                }


            }


            this.AreStudents = true;
            //console.debug("La lista de estudiantes son : " + JSON.stringify(this.List_students_by_course));
            $("#SplitStudents").prop("disabled", false);
        });
    }
    callAcademicAreas(model: Course) {
        this.AcademicArea_init = { id: 0 }
        if (this.isPersonal == true)
            this.enableSecondarystudentsasociation = false


        this.CourseGroupIDSelected = model.Group;
        this.array_to_asociate = []
        this.EnableAsociations = false
        this.settings.actions.edit = true
        this.enablestudentsasociation = false
        this.EnableTable = false
        if (model["Group"] == 0) {
            this.AcademicArea = [{ id: 0, name: "No Existen Grupos Académicos Para el Grupo Seleccionado" }]
            this.EnableTable = false;
        } else {
            this.EnableTable = false;
            //$("#AcademicAreaID").prop("disabled", true);
            console.debug(model)
            this.coursesService.getAllAcademicAreasBySchool(this.SchoolByID["id"]).subscribe(p => {
                if (p.length == 0) {
                    this.AcademicArea = [{ id: 0, name: "No se Puede Seleccionar Área Académica Porque No existen Grupos" }]
                }
                else {
                    this.AcademicArea = p;

                    //CREO UN OBJETO CON EL ID DEL AREA ACADEMICA PARA ASIGNARLE COLORES
                    this.KeyColorAcademicArea = []
                    for (let k = 0; k < this.AcademicArea.length; k++) {


                        this.KeyColorAcademicArea.push({

                            "areaid": this.AcademicArea[k].id,
                            "colorposition": k
                            // [this.AcademicArea[k].id]:k 

                        })

                    }

                    console.debug(this.KeyColorAcademicArea)
                    console.debug("LAS AREAS ACADEMICAS SON: " + JSON.stringify(p))
                    //$('#calendar').fullCalendar('removeEvents')
                    this.SubjectsModifyAddProfessor = []



                    this.callEvents({ CourseGroupID: model["Group"] })
                    this.ShowCalendar = true




                }
                $("#AcademicAreaID").prop("disabled", false);

            });
        }
    }


    callEvents(OtherInfo: Subject) {

        this.authService.swalLoading("Cargando eventos")
        //SE REOGE EL DIA DE HOY
        var date = new Date(this.serverTime)

        //SE RECOGE LA FECHA EN YYYY-MM-DD
        var m = moment("01-01-1800", 'YYYY-MM-DD');
        //SE RECOGE LA FECHA EN YYYY-MM-DD DE LA SIGUIENTE SEMANA
        var m2 = moment(date, 'YYYY-MM-DD').add(1, 'week');

        console.debug("estamos en la semana #   " + m.format('W'))
        var RangeDates1 = this.getDateRangeOfWeek(m.format('W'), m.format('YYYY'))
        var RangeDates2 = this.getDateRangeOfWeek(m2.format('W'), m2.format('YYYY'))

        //SE CREA EL RANGO DE LA SEMANA ACTUAL Y LA SEMANA SIGUIENTE
        // ESTO CON EL FIN DE PODER CREAR EL HORARIO DE LA SEMANA SIGUIENTE
        var RangeDates = [moment(RangeDates1[0], 'YYYY-MM-DD').startOf('day'), moment(RangeDates2[1], 'YYYY-MM-DD').endOf('day')]

        console.debug(RangeDates)
        console.debug(OtherInfo)

        //LLAMO LOS EVENTOS QUE EXISTEN  PARA EL ID DEL GRUPO
        this.coursesService.getEventsCalendarByGroupID(OtherInfo, RangeDates).subscribe(p => {



            console.debug(p)

            var events = []
            for (let i = 0; i < p.length; i++) {

                //SI EXISTEN EVENTOS CON CourseGroupID ACTUALIZAR EL CALENDARIO
                if (!isNullOrUndefined(p[i].SubjectGroups)) {
                    if (!isNullOrUndefined(p[i].SubjectGroups.CourseGroupID)) {
                        console.debug("SI HAY")

                        console.debug("el color es")
                        console.debug("el id del area es")
                        console.debug(p[i]["SubjectGroups"]["subjects"]["AcademicAreaID"])

                        var index = this.KeyColorAcademicArea.findIndex(x => x.areaid == p[i]["SubjectGroups"]["subjects"]["AcademicAreaID"]);
                        console.debug("se encontro en el indez de key color " + index)

                        let CompleteName = $.grep([p[i]["SubjectGroups"]["userapp"]["LastName1"], p[i]["SubjectGroups"]["userapp"]["LastName2"], p[i]["SubjectGroups"]["userapp"]["Name1"], p[i]["SubjectGroups"]["userapp"]["Name2"]], Boolean).join(" ");

                        var titulo = p[i]["SubjectGroups"]["subjects"]["NameSubject"].toUpperCase() + " <br> Dictada por: <i> " + CompleteName.toUpperCase() + "</i> <br> Para: <i>" + p[i]["SubjectGroups"]["coursesgroup"]["name"].toUpperCase() + "</i>"
                        //ACTUALIZAR CON EVENTOS EL CALENDARIO
                        events.push(
                            {
                                id: p[i].id,
                                title: " " + titulo,
                                start: p[i]["HourStart"],
                                end: p[i]["HourEnd"],
                                CourseGroupID: p[i]["SubjectGroups"]["CourseGroupID"],
                                SubjectGroupID: p[i]["SubjectGroupID"],
                                SubjectID: p[i]["SubjectGroups"]["SubjectID"],
                                color: this.ColorAcademicArea[this.KeyColorAcademicArea[index].colorposition]


                            })


                        console.debug("la variable del evento quedo")
                        console.debug(events)
                        //$('#Calendario').show()



                    }
                }



            }
            this.previousEvents = events
            //$('#calendar').fullCalendar('removeEvents')

            console.debug(events)
            //$('#calendar').fullCalendar('addEventSource', events, true);
 
            $("#Group").prop("disabled", false);
            $("#AcademicAreaID").prop("disabled", false);
            this.authService.swalEndLoad()


        }, e => {
            this.authService.swalError("Error cargando eventos", "No se pudo cargar los eventos " + e)
        })

    }

    //FUNCION PARA ENTREGAR LOS DIAS DE LA SEMANA EN BASE AL NUMERO DE LA SEMANA
    getDateOfISOWeek(w, y) {
        var simple = new Date(y, 0, 1 + (w - 1) * 7);
        var dow = simple.getDay();
        var ISOweekStart = simple;
        if (dow <= 4)
            ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
        else
            ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());


        // ISOweekStart.setHours(-5, 0, 0, 0);

        return ISOweekStart;
    }


    getDateRangeOfWeek(w, y) {

        var date = this.getDateOfISOWeek(w, y);
        var date2 = this.getDateOfISOWeek(w, y);
        // easily get ending date by adding 6 days more
        date.setDate(date.getDate());
        date.setHours(0, 0, 0, 0);
        date2.setDate(date2.getDate() + 6);
        date2.setHours(0, 0, 0, 0);
        //RETORNA EL DIA DE INICIO DE ESA SEMANA Y EL DIA QUE ACABA
        //LUNES A DOMINGO

        return ([date, date2]);
    }









    callSchedule(model: Course) {
        // 1.A traves del area academica, llamo las materias de esa(s) area(s)
        // 2. Con las materias consulto los 


        this.enablestudentsasociation = false
        this.authService.swalLoading('Cargando materias')
        this.isloading = true;
        this.array_to_asociate = []
        this.EnableAsociations = false
        this.EnableTable = false
        console.debug(model)
        this.ModelAcademicAreaID = model.AcademicAreaID;
        this.ModelCourseGroupID = model.Group;
        var indexCurso = this.course_by_school.findIndex(x => x.id == model.id);

        console.debug("el id del curso es" + this.course_by_school[indexCurso].CourseID)
        console.debug("el id del area academica es" + model.AcademicAreaID)



        //SI SE SELECCIONAN TODAS LAS AREAS ACADEMICAS

        if (model.AcademicAreaID == -1)
            this.AcademicAreaSelected = this.AcademicArea
        else {
            this.AcademicAreaSelected = [{ id: model.AcademicAreaID }]
        }
        console.debug("EL AREA ACADEMICA FUE:", this.AcademicAreaSelected)
        var cx = this
        this.SearchSubjects = []
        this.infop = []
        this.infoarray_to_asociate = []

        //#################################################################################
        //############     PRIMER PASO LLAMAR LAS MATERIAS DEL AREA ACADEMICA     #########
        //#################################################################################

        //PARA CADA UNA DE LAS AREAS ACADEMICAS VOY A CARGAR LAS MATERIAS
        async.forEach(this.AcademicAreaSelected, function (academics, callback) {
            cx.coursesService.getAllSubjectsByAreaByCourse(academics.id, cx.course_by_school[indexCurso].CourseID).subscribe(p => {
                //RECOJO TODAS LAS MATERIAS DE TODAS LAS AREAS

                for (let k = 0; k < p.length; k++) {
                    var position = cx.infop.findIndex(x => x.id === p[k].id)
                    if (position < 0) {
                        cx.infop.push(p[k])
                    }
                    cx.SearchSubjects.push({ "SubjectID": p[k].id, "HPM": p[k].HPM })
                }
                callback()
            }, e => {
                callback()
                this.authService.swalError("Error Cargando Materias", "Para el area seleccionada no existen materias")
            }
            );
        }, function (err) {


            //CUANDO DE COMPLETA TENGO EL ARREGLO CON TODAS LAS MATERIAS
            console.debug("el el search subjet essssss")
            console.debug(cx.SearchSubjects)
            console.debug("eL PINFO ES")
            console.debug(cx.infop)
            console.debug("el MODELCOURSEGROUPID ES")
            console.debug(cx.ModelCourseGroupID)


            // #####################
            // SEGUNDO ASYNC QUE ME INDICA SI EXISTEN REGISTROS EN SUBJECTGROUP (ES DECIR SI YA HAY PROFESORES ASOCIADOS A LAS MATERIAS)


            async.forEach(cx.SearchSubjects, function (subjects, callback) {

                cx.coursesService.getScheduleSubjectByCourseIDAndSubjectsID(cx.ModelCourseGroupID, cx.SearchSubjects).subscribe(response => {

                    //CREO EL FILTER PARA CONSULTAR TODAS LAS NOTAS
                    var filterarray = []
                    for (var index = 0; index < response.length; index++) {
                        filterarray.push({ "SubjectGroupID": response[index].id })
                    }
                    var filterdb = `{"where": { "or": ` + JSON.stringify(filterarray) + `}}`;

                    cx.coursesService.getUserSubjectsBySubjectGroupsIDs(filterdb).subscribe(list => {

                        for (var index = 0; index < response.length; index++) {

                            var position = cx.array_to_asociate.findIndex(i => (i.SubjectGroupID === response[index].id) && (i.CourseGroupID === response[index].CourseGroupID));

                            if (position < 0) {
                                cx.array_to_asociate.push({
                                    SubjectGroupID: response[index].id,
                                    CourseGroupID: response[index].CourseGroupID
                                })
                            }

                        }


                        cx.enablestudentsasociation = true  //DESHABILITO EL BOTON DE ASOCIAR ESTUDIANTES PORQUE YA EXISTEN LOS REGISTROS ASOCIADOS A SubjectGroup





                    })


                    //LIMPIAR LOS RESULTADOS DE RESPONSE QUE NO TENGAN EL COURSEGROUPID QUE ES
                    for (var index = 0; index < response.length; index++) {
                        //console.debug(response[index].CourseGroupID + " " + cx.ModelCourseGroupID)
                        if (response[index].CourseGroupID === cx.ModelCourseGroupID) { }  //SI EL ID DEL COURSEGOURP NO ES IGUAL AL DEL MODELO  BORRAR ESA POSICION
                        else {
                            delete response[index] //response.splice(index, 1);
                        };


                    }

                    //AL EJECUTAR DELETE HAY ESPACIOS QUE QUEDAN EN BLANCO, ELIMINAR ESTOS ESPACIOS
                    response = response.filter(function (x) {
                        return (x !== (undefined));
                    });

                    cx.infoarray_to_asociate = response


                    callback()


                })


            }, function (err) {
                console.debug("array_to_asociate", cx.array_to_asociate)


                //LIMPIAR LOS RESULTADOS array_to_asociate que no tengan CourseGroupID 
                for (var index = 0; index < cx.array_to_asociate.length; index++) {

                    if (cx.array_to_asociate[index].CourseGroupID === cx.ModelCourseGroupID) { }  //SI EL ID DEL COURSEGOURP NO ES IGUAL AL DEL MODELO  BORRAR ESA POSICION
                    else {
                        delete cx.array_to_asociate[index] //response.splice(index, 1);
                    };
                }
                //AL EJECUTAR DELETE HAY ESPACIOS QUE QUEDAN EN BLANCO, ELIMINAR ESTOS ESPACIOS
                cx.array_to_asociate = cx.array_to_asociate.filter(function (x) {
                    return (x !== (undefined));
                });


                console.debug("infoarray_to_asociate", cx.infoarray_to_asociate)

                //SI EN EL INFO ARRAY NO HAY NINGUNA MATERIA QUE TENGA PROFESOR ASOCIADO
                //CARGO EL INFO P EN EL array_to_asociate
                if (cx.infoarray_to_asociate.length == 0) {
                    console.debug("el infoarray_to_asociate esta vacio")



                    cx.infoarray_to_asociate = cx.infop
                } else {
                    //DE LO CONTRARIO ES PORQUE HAY AUNQUE SEA UNA ASOCIACION, POR LO TANTO TENGO QUE IGUALAR INFO P CON ARRAY TO ASOCIATE

                    console.debug("el infoarray_to_asociate no esta vacio")

                    //1. BUSCO EL  SUBJECTID DE INFO P  CON infoarray_to_asociate , SI EXISTE AGREGO LOS DATOS DE
                    // CodeBlackBoard
                    //CodeClassRoom
                    //CourseGroupID
                    //DateEnd
                    //DateStart
                    //MidTerms
                    //SubjectID
                    //UserID

                    //EN INFO P

                    for (var ind = 0; ind < cx.infoarray_to_asociate.length; ind++) {

                        var pos = cx.infop.findIndex(x => x.id == cx.infoarray_to_asociate[ind].SubjectID)

                        if (pos >= 0) {
                            cx.infop[pos]["CodeBlackBoard"] = cx.infoarray_to_asociate[ind]["CodeBlackBoard"]
                            cx.infop[pos]["CodeClassRoom"] = cx.infoarray_to_asociate[ind]["CodeClassRoom"]
                            cx.infop[pos]["CourseGroupID"] = cx.infoarray_to_asociate[ind]["CourseGroupID"]
                            cx.infop[pos]["DateEnd"] = cx.infoarray_to_asociate[ind]["DateEnd"]
                            cx.infop[pos]["DateStart"] = cx.infoarray_to_asociate[ind]["DateStart"]
                            cx.infop[pos]["MidTerms"] = cx.infoarray_to_asociate[ind]["MidTerms"]
                            cx.infop[pos]["SubjectID"] = cx.infoarray_to_asociate[ind]["SubjectID"]
                            cx.infop[pos]["UserID"] = cx.infoarray_to_asociate[ind]["UserID"]
                            cx.infop[pos]["id"] = cx.infoarray_to_asociate[ind]["id"]
                            cx.infop[pos]["IsDateChanged"] = cx.infoarray_to_asociate[ind]["IsDateChanged"]
                            cx.infop[pos]["IsPhysicalTest"] = cx.infoarray_to_asociate[ind]["IsPhysicalTest"]

                        }
                    }

                    //cx.infoarray_to_asociate.push(cx.infop[ind])

                    cx.infoarray_to_asociate = cx.infop
                }



                //AHORA BUSCO TODOS LOS infoarray_to_asociate que no tengan SubjectID, si no lo tienen es porque aun no tiene
                //un subjectgroup creado, por lo tanto el id es el id de la materia
                //Ahora bien, se va a hacer infoarray_to_asociate.SubjectID=infoarray_to_asociate.id
                for (var ind = 0; ind < cx.infoarray_to_asociate.length; ind++) {

                    if (isNullOrUndefined(cx.infoarray_to_asociate[ind].SubjectID)) {
                        cx.infoarray_to_asociate[ind].CourseGroupID = cx.ModelCourseGroupID
                        cx.infoarray_to_asociate[ind]["SubjectID2"] = cx.infoarray_to_asociate[ind].id
                        cx.infoarray_to_asociate[ind].SubjectID = cx.infoarray_to_asociate[ind].id
                        delete cx.infoarray_to_asociate[ind].id
                    }


                }



                console.debug("el nuevo infoarray_to_asociate quedó", cx.infoarray_to_asociate)





                //AHORA BUSCO LOS PROFESORES 
                //cx.ModelProfessorSmartTable = []
                //cx.ModelProfessorSmartTable2 = []
                cx.ModelProfessorSmartTable.push({
                    //value: CompleteName.toUpperCase(),
                    value: 2941,
                    title: "--ASIGNAR DESPUÉS",
                })
                Object.assign(cx.ModelProfessorSmartTable2, { 2941: "--ASIGNAR DESPUÉS" })





                async.forEach(cx.AcademicArea, function (academicarea, callback) {

                    cx.coursesService.getAllUserAppsByAcademicArea(academicarea.id).subscribe(p2 => {

                        cx.isloading = false;
                        cx.SubjectsModifyAddProfessor = cx.infoarray_to_asociate;
                        var date = new Date(cx.serverTime)
                        console.debug("LA INFORMACION DEL CURSO SE MANTIENE" + JSON.stringify(cx.CourseInfo))
                        console.debug(cx.infop)
                        cx.infop = cx.infop.sort(function (a, b) {
                            // Ascending: first age less than the previous
                            return a["id"] == b["id"] ? 0 : a["id"] < b["id"] ? -1 : 1;
                        });


                        cx.infoarray_to_asociate = cx.infoarray_to_asociate.sort(function (a, b) {
                            // Ascending: first age less than the previous
                            return a["SubjectID"] == b["SubjectID"] ? 0 : a["SubjectID"] < b["SubjectID"] ? -1 : 1;
                        });



                        console.debug("cx.SubjectsModifyAddProfessor", cx.SubjectsModifyAddProfessor)




                        for (let j = 0; j < cx.infoarray_to_asociate.length; j++) {

                            //SI NO TIENE ID ES PORQUE NO TIENE SUBJECTGROUP
                            if (isNullOrUndefined(cx.infoarray_to_asociate[j].id)) {

                                //LE CREO LOS MIDTERMS


                                console.debug("EN ALGUN LUGAR NO SE ENCONTRÓ")
                                console.debug("se van a guardar los midrerms")
                                console.debug(cx.infoarray_to_asociate[j].id)

                                var htpm_consulted = cx.HPMSubjects(cx.infoarray_to_asociate[j].SubjectID)

                                console.debug(htpm_consulted)
                                console.debug("SI NO ESTAN DEFINIDOS LOS MID TERMS", cx.infoarray_to_asociate[j])
                                //SI NO ESTAN DEFINIDOS LOS MID TERMS, DEFINIRLOS DE ACUERDO A LOS HPM
                                if (isNullOrUndefined(cx.infoarray_to_asociate[j]["MidTerms"]) == true) {
                                    cx.infoarray_to_asociate[j]["MidTerms"] = 0
                                    if (htpm_consulted == 0)
                                        cx.infoarray_to_asociate[j]["MidTerms"] = 1
                                    else if (htpm_consulted >= 1 && htpm_consulted <= 10)
                                        cx.infoarray_to_asociate[j]["MidTerms"] = 1
                                    else if (htpm_consulted >= 11 && htpm_consulted < 21)
                                        cx.infoarray_to_asociate[j]["MidTerms"] = 2
                                    else
                                        cx.infoarray_to_asociate[j]["MidTerms"] = 3

                                }
                                else {
                                    //SI YA ESTA DEFINIDO DEJARLO
                                    cx.infoarray_to_asociate[j]["MidTerms"] = cx.infoarray_to_asociate[j]["MidTerms"]

                                }

                                console.debug("para la materia", cx.infoarray_to_asociate[j].SubjectID, " tiene el userid", cx.infoarray_to_asociate[j]["UserID"])
                                cx.infoarray_to_asociate[j]["idRegistro"] = 0
                                cx.infoarray_to_asociate[j]["UserID"] = isNullOrUndefined(cx.infoarray_to_asociate[j]["UserID"]) == true ? 2941 : cx.infoarray_to_asociate[j]["UserID"]
                                cx.infoarray_to_asociate[j]["CourseGroupID"] = isNullOrUndefined(cx.infoarray_to_asociate[j]["CourseGroupID"]) == true ? model["Group"] : cx.infoarray_to_asociate[j]["CourseGroupID"]
                                cx.infoarray_to_asociate[j]["SubjectID"] = cx.infoarray_to_asociate[j]["id"]
                                cx.infoarray_to_asociate[j]["HPM"] = cx.HPMSubjects(cx.infoarray_to_asociate[j]["id"])
                                cx.infoarray_to_asociate[j]["DateStart"] = isNullOrUndefined(cx.infoarray_to_asociate[j]["DateStart"]) == true ? moment(cx.CourseInfo.Since).format('YYYY-MM-DD') : moment(cx.infoarray_to_asociate[j]["DateStart"]).format('YYYY-MM-DD')
                                cx.infoarray_to_asociate[j]["DateEnd"] = isNullOrUndefined(cx.infoarray_to_asociate[j]["DateEnd"]) == true ? moment(cx.CourseInfo.Until).format('YYYY-MM-DD') : moment(cx.infoarray_to_asociate[j]["DateEnd"]).format('YYYY-MM-DD')
                                cx.infoarray_to_asociate[j]["SubjectGroupID"] = isNullOrUndefined(cx.infoarray_to_asociate[j]["id"]) == true ? 0 : cx.infoarray_to_asociate[j]["id"]
                                cx.infoarray_to_asociate[j]["IsDateChanged"] = isNullOrUndefined(cx.infoarray_to_asociate[j]["IsDateChanged"]) == true ? false : cx.infoarray_to_asociate[j]["IsDateChanged"]
                                cx.infoarray_to_asociate[j]["IsPhysicalTest"] = isNullOrUndefined(cx.infoarray_to_asociate[j]["IsPhysicalTest"]) == true ? false : cx.infoarray_to_asociate[j]["IsPhysicalTest"]





                                //acaba


                            }

                            //cx.SubjectsModifyAddProfessor.push(cx.infoarray_to_asociate[j])



                            console.debug(cx.infoarray_to_asociate[j])

                        }



                        cx.ListProfessors = p2.sort(function (a, b) {
                            // Ascending: first age less than the previous
                            return a["LastName1"].localeCompare(b["LastName1"]);


                        });


                        for (let i = 0; i < p2.length; i++) {
                            if (p2[i]["UserJobs"].length > 0) {
                                let CompleteName = $.grep([cx.ListProfessors[i].LastName1, cx.ListProfessors[i].LastName2, cx.ListProfessors[i].Name1, cx.ListProfessors[i].Name2], Boolean).join(" ");
                                var position = cx.ModelProfessorSmartTable.findIndex(x => x.value === cx.ListProfessors[i].id)
                                if (position >= 0) { }
                                else {
                                    CompleteName = CompleteName + " (NO HABILITADO-ESCOGER OTRO DOCENTE)"
                                    cx.ModelProfessorSmartTable.push({
                                        //value: CompleteName.toUpperCase(),
                                        value: cx.ListProfessors[i].id,
                                        title: CompleteName.toUpperCase(),
                                    })
                                    Object.assign(cx.ModelProfessorSmartTable2, { [cx.ListProfessors[i].id]: CompleteName.toUpperCase() })
                                    cx.RenderProfessor()
                                }
                            }
                        }

                        callback()


                    });


                }, function (err) {
                    cx.authService.swalEndLoad()


                    console.debug("los profesores son:", cx.ModelProfessorSmartTable)
                    console.debug("los profesores2 son:", cx.ModelProfessorSmartTable2)

                    for (let j = 0; j < cx.infoarray_to_asociate.length; j++) {
                        if (isNullOrUndefined(cx.infoarray_to_asociate[j]["SubjectID2"]) == false) {
                            cx.infoarray_to_asociate[j]["SubjectID"] = cx.infoarray_to_asociate[j]["SubjectID2"]
                        }

                        cx.infoarray_to_asociate[j]["DateStart"] = moment(cx.infoarray_to_asociate[j]["DateStart"]).format('YYYY-MM-DD')
                        cx.infoarray_to_asociate[j]["DateEnd"] = moment(cx.infoarray_to_asociate[j]["DateEnd"]).format('YYYY-MM-DD')

                    }

                    cx.infoarray_to_asociate = cx.SubjectsModifyAddProfessor
                    cx.RenderProfessor()
                    //$('#calendar').fullCalendar('removeEvents')
                    cx.callEvents({ CourseGroupID: cx.CourseGroupIDSelected })
                    cx.source.empty()
                    cx.sourceDates.empty()
                    cx.settings.pager.perPage = 20;

                    //cambiar las fechas a COP
                    cx.SubjectsModifyAddProfessor.forEach(element => {
                        element["DateStart"] = moment(element["DateStart"]).utc().add(1, 'days').format("YYYY-MM-DD")
                        element["DateEnd"] = moment(element["DateEnd"]).utc().add(1, 'days').format("YYYY-MM-DD")

                    })

                    cx.source.load(cx.SubjectsModifyAddProfessor)

                    //SE MUESTRA UNICAMENTE LOS DATOS DE LOS ELEMENTOS QUE TENGAN ID
                    cx.SubjectsModifyAddProfessorWithSubjectGroupID = cx.SubjectsModifyAddProfessor
                    var temp = []
                    for (var indexD = 0; indexD < cx.SubjectsModifyAddProfessorWithSubjectGroupID.length; indexD++) {
                        var element = cx.SubjectsModifyAddProfessorWithSubjectGroupID[indexD];
                        console.log("para el splice", element)
                        if (!isNullOrUndefined(element.id)) {
                            temp.push(cx.SubjectsModifyAddProfessorWithSubjectGroupID[indexD])

                        }

                    }
                    cx.SubjectsModifyAddProfessorWithSubjectGroupID = temp
                    console.debug("quedo ", cx.SubjectsModifyAddProfessorWithSubjectGroupID)
                    cx.sourceDates.load(cx.SubjectsModifyAddProfessorWithSubjectGroupID)
                    cx.settings.pager.perPage = 20
                    cx.settingsDate.pager.perPage = 20
                    console.debug("LO QUE SE CARGO EN EL SMART TABLE FUE:")
                    console.debug(cx.SubjectsModifyAddProfessor)
                    cx.settings.columns.UserID.editor.config.list = cx.ModelProfessorSmartTable.sort(function (a, b) {
                        // Ascending: first age less than the previous
                        return a["title"] == b["title"] ? 0 : a["title"] < b["title"] ? -1 : 1;
                    });

                    cx.EnableTable = true;
                    cx.enableSecondarystudentsasociation = true

                })





            })




        });


    }

    callbackpruebas(error) {




    };



    AsociarDocentesMaterias() {





        this.source.getAll().then(respuesta => {

            console.debug(respuesta)


            this.midtermData = new Array(respuesta.length)
            //console.debug(respuesta)
            var UserCorrect = []
            let contador_total = respuesta.length - 1
            this.contador_calendario = 0
            console.debug("el ontador total es: " + contador_total)

            for (let i = 0; i < respuesta.length; i++) {
                if (respuesta[i].UserID == 0 || isNullOrUndefined(respuesta[i].UserID))
                    UserCorrect.push(true)
            }
            if (!UserCorrect.includes(true)) {

                this.EnableTable = false
                this.EnableAsociations = false


                this.authService.swalLoading("Asociando docentes a materias");
                this.isloading = true;
                var cx = this;

                async.forEach(respuesta, function (respuesta_u, callback) {



                    var Info = {
                        "CourseGroupID": respuesta_u.CourseGroupID,
                        "SubjectID": respuesta_u.SubjectID,
                        "MidTerms": respuesta_u.MidTerms,
                        "DateStart": moment(respuesta_u.DateStart).format('YYYY-MM-DD').toString(),
                        "DateEnd": moment(respuesta_u.DateEnd).format('YYYY-MM-DD').toString(),
                        "UserID": respuesta_u.UserID,
                        "HPM": respuesta_u.HPM
                    }

                    //console.debug("llego de pruebas")

                    //console.debug(respuesta_u)
                    //console.debug("------------------")

                    if (!isNullOrUndefined(respuesta_u.idRegistro) || respuesta_u.idRegistro != 0)
                        Info["id"] = respuesta_u.idRegistro
                    if (respuesta_u.idRegistro == 0)
                        delete Info["id"]



                    // #########################
                    //    1. CREO LOS SUBJECGROUPS
                    //##########################

                    cx.coursesService.createSubjectGroup(Info).subscribe(respuesta_nuevo => {



                        //console.debug(cx.contador_calendario)

                        //CREO EL NUEVO OBJETO UTILIZADO PARA EL CALENDARIO
                        cx.SubjectGtoupID_Verified.push({
                            SubjectGroupID: respuesta_nuevo.id,
                            SubjectID: respuesta_nuevo.SubjectID,
                            CourseGroupID: respuesta_nuevo.CourseGroupID,
                            UserID: respuesta_nuevo["UserID"],
                            HPM: respuesta_nuevo.HPM
                        })

                        //array para luego asociar a los estudiantes
                        cx.array_to_asociate.push({
                            SubjectGroupID: respuesta_nuevo.id,
                            CourseGroupID: respuesta_nuevo.CourseGroupID

                        })

                        //SI TODOS LOS REGISTROS SE GUARDARON ENTONCES MOSTRAR LA TABLA Y LLAMAR LOS EVENTOS
                        cx.AddSubjectGrupID()
                        //DESHABILITO LA OPCIONES PARA CAMBIAR DE CURSO Y DE GURPO
                        cx.EnableTable = true
                        cx.ShowCalendar = true
                        $("#CourseOfer").prop("disabled", true);
                        //$("#Group").prop("disabled", true);
                        //$("#AcademicAreaID").prop("disabled", true);
                        //$('#calendar').fullCalendar('removeEvents')
                        cx.callEvents({ CourseGroupID: Info.CourseGroupID })


                        //Con consulto los UserSubjects a partir del SubjectGroupID
                        cx.coursesService.getUserSubjectsBySubjectGroupID(cx.array_to_asociate[0].SubjectGroupID).subscribe(list => {

                            if (list.length > 0) {
                                cx.enablestudentsasociation = false  //DESHABILITO EL BOTON DE ASOCIAR ESTUDIANTES PORQUE YA EXISTEN LOS REGISTROS ASOCIADOS A SubjectGroup
                            }
                            else {
                                cx.enablestudentsasociation = true //SI NO TIENE, ENTONCES HABILITAR EL BOTON PARA ASOCIAR A LOS ESTUDIANTES

                            }

                            callback()
                        })



                    })






                }

                    , function (err) {




                        Swal({
                            title: 'Paso 1: Docentes asociados',
                            text: 'Falta asociar estudiantes a materias',
                            type: 'success',
                            showCancelButton: false,
                            allowEscapeKey: false,
                            allowOutsideClick: false,
                            confirmButtonText: 'Si, Asociar estudiantes a las materias del área académica',
                        }).then(function () {
                            cx.asociarestudiantes()
                        }, function (dismiss) {
                            if (dismiss == 'cancel') {
                                console.debug("se cancelo")
                            }
                        });




                        //cx.authService.swalSuccess("Docentes asociados", "Todos los Docentes le Han Sido Asignadas Las Materias Seleciconadas")


                    });

            } else {
                this.authService.swalError("Error asociando docentes", "Todas Las Materias Deben Tener Docentes Asignados ")
                this.isloading = false;

            }
        })
    }


    asociarestudiantes(orangeButton?: boolean) {

        this.authService.swalLoading("Asociando Estudiantes")
        this.isloading = true;

        console.debug("AcademicAreaSelected", this.AcademicAreaSelected)
        console.debug(this.SubjectsModifyAddProfessor)

        var count = 1
        var cx = this;
        async.forEachLimit(this.SubjectsModifyAddProfessor, 1, function (respuesta_u, callback) {
            if (respuesta_u.id) {
                cx.coursesService.assignUserSubject(
                    {

                        SubjectGroupID: respuesta_u.id,
                        CourseGroupID: cx.ModelCourseGroupID

                    }
                ).subscribe(ll => {
                    callback()
                })
            } else {
                // console.log("no tiene id",respuesta_u)
                callback()
            }
        }, function (err) {
            cx.authService.swalSuccess("Estudiantes asociados", "Todos los Estudiantes le Han Sido Asignadas Las Materias Seleccionadas")
            cx.enablestudentsasociation = false;
        });













    }

    saveusersubject(indexsubjectgroup: number) {








        //PRIMERO LLAMO A LOS ESTUDIANTES QUE PERTENEZCAN AL COURSEOFFERID
        this.coursesService.getUsersByCourseOfferIDandCourseGroupID(this.courseofferidseleted, this.midtermData[indexsubjectgroup].groupid).subscribe(UsersByCourse => {


            for (let i = 0; i < UsersByCourse.length; i++) {
                //POR CADA ESTUDIANTE CREO EN UserSubject UN REGISTRO CON LOS SIGUIENTES DATOS
                this.coursesService.createUserSubjects({
                    "SubjectGroupID": this.midtermData[indexsubjectgroup].idsubjectgroup,
                    "FinalRecord": 0,
                    "UserID": UsersByCourse[i]["UserID"],

                }).subscribe(usersubject => {

                    for (var j = 0; j < this.midtermData[indexsubjectgroup].subjectinfo.length; j++) {




                        this.coursesService.saveUserRecord(0, {
                            "UserSubjectID": usersubject.id,
                            "ParcialRecord": 0,
                            "DateRecordID": this.midtermData[indexsubjectgroup].subjectinfo[j].iddaterecord,
                            "MidTermID": this.midtermData[indexsubjectgroup].subjectinfo[j].idmidterm,
                            "IsAccording": true
                        }).subscribe(l => { var l2 = l })






                    }


                })

            }

        })


    }



    RenderProfessor() {
        //RENDERIZAR PROFESOR AL SMART TABLE
        this.intermediate.Professor = this.ModelProfessorSmartTable2
        this.Render.ngOnInit()
    }
    AddSubjectGrupID() {
        //FUNCION UTILIZADA PARA INSERTAR EL ID DE LA TABLA SUBJETGROUP EN EL OBJETO QUE CARGA EL SMART TABLE
        for (let j = 0; j < this.SubjectGtoupID_Verified.length; j++) {
            //CUANDO SE GUARDÓ LA INFORMACION EN SUBJECT GROUP LA VARIABLE  SubjectGtoupID_Verified
            //CONTIENE EL ID DE LO QUE SE GUARDO
            //AHORA SE PRETENDE GUARDAR ESE ID EN LA VARIABLE SubjectsModifyAddProfessor 
            //LA CUAL CARGA EL SMART TABLE
            console.debug(this.SubjectGtoupID_Verified[j].SubjectGroupID)

            //BUSCA EL INDEX DE UN OBJETO
            var index = this.SubjectsModifyAddProfessor.findIndex(i => i.SubjectID === this.SubjectGtoupID_Verified[j].SubjectID);
            //CON EL INDEX  CONCATENO EL ID DE SUBJECTGROUP
            this.SubjectsModifyAddProfessor[index]["SubjectGroupID"] = this.SubjectGtoupID_Verified[j].SubjectGroupID
            this.SubjectGtoupID_Verified = []
        }
        //veo como quedo el array cuando le inserto el SubjectGroupID
        console.debug(this.SubjectsModifyAddProfessor)
    }






    textSemestralize(id: number) {
        var num = this.course_by_school.find(x => x.id == id);
        if (num)
            return num.IsSemestralize;
        return false
    }



    infocoursebyID(id: number) {
        var num = this.course_by_school.find(x => x.id == id);
        if (num)
            return num;
        return false
    }



    HPMSubjects(SubjectID: number) {
        var num = this.SearchSubjects.find(x => x.SubjectID == SubjectID);
        if (num)
            return num.HPM;
        else
            return false
    }


    onEditConfirmDates(event): any {
        if (moment(event.newData.DateStart, 'YYYY-MM-DD').isValid() == true &&
            moment(event.newData.DateEnd, 'YYYY-MM-DD').isValid() == true


        ) {

            //SI EL EVENTO YA SE INDICA QUE LA FECHA  SE HA MODIFICADO 
            if (event.data.IsDateChanged == true) {
                this.authService.swalError("Error al guardar fechas", "La fecha para la materia ya ha sido modificada")
                return
            }
            console.debug(event.newData)
            var timestart = moment(event.newData.DateStart, 'YYYY-MM-DD')
            var timeEnd = moment(event.newData.DateEnd, 'YYYY-MM-DD')
            if (timestart.isSameOrAfter(timeEnd) == true) {
                this.authService.swalError("Error al guardar fechas", "La fecha final debe ser mayor a la inicial")
                return
            }

            var Info = {
                "id": event.data.id,
                "CourseGroupID": event.newData.CourseGroupID,
                "SubjectID": event.newData.SubjectID,
                "MidTerms": event.newData.MidTerms,
                "DateStart": moment(event.newData.DateStart).format('YYYY-MM-DD'),
                "DateEnd": moment(event.newData.DateEnd).format('YYYY-MM-DD'),
                "IsDateChanged": true

            }

            this.authService.swalLoading("Modificando Fecha")
            this.coursesService.patchSubjectGroup(Info, event.data.id).subscribe(newData => {

                console.debug("se actualizo")
                event.newData.IsDateChanged = true


                //actualizo el dato que exista tambien en la tabla de associar docentes

                var pos = this.SubjectsModifyAddProfessor.findIndex(x => x.id === newData.id)
                if (pos > 1) {
                    this.SubjectsModifyAddProfessor[pos]["DateStart"] == event.newData.DateStart
                    this.SubjectsModifyAddProfessor[pos]["DateEnd"] == event.newData.DateEnd
                    this.SubjectsModifyAddProfessor[pos]["IsDateChanged"] == event.newData.IsDateChanged
                }

                //ACTUALIZO TAMBIEN LOS  DateRecord
                this.coursesService.getAllDaterecordsWithNoExtensionBySubjectGroupID(newData.id).subscribe(daterecords => {

                    //LLAMO TODOS LOS DATEMIDTERMS
                    this.coursesService.getdatemidtermsBySchoolID2(this.SchoolIDSelected).subscribe(dateMid => {

                        this.coursesService.getAllMidTermsBySubjectGroupID(event.data.id).subscribe(midTerm => {


                            midTerm = midTerm.sort(function (a, b) {
                                // SE ORDENA DE FORMA ASCENDENTE
                                return a["MidTermNum"] == b["MidTermNum"] ? 0 : a["MidTermNum"] < b["MidTermNum"] ? -1 : 1;
                            });




                            console.debug("daterecords", daterecords)
                            console.debug("dateMid", dateMid)
                            console.debug("midterm", midTerm)


                            if (dateMid.length == 1)
                                var dateMidData = dateMid[0]
                            if (dateMid.length > 1)
                                var dateMidData = dateMid[dateMid.length - 1]

                            console.debug("dateMidData", dateMidData)
                            var cx = this
                            var errorPatch = false
                            var errorPatchMessage = ""
                            async.forEachLimit(midTerm, 4, function (respuesta_u, callback) {

                                var daterecord = daterecords.find(x => x.MidTermID === respuesta_u.id)

                                var sendData = {

                                    id: daterecord.id,
                                    DateStartRecord: timestart.subtract(dateMidData["NDays"], "days").format("YYYY-MM-DD"),
                                    DateEndRecord: timeEnd.add(3, "days").format("YYYY-MM-DD")

                                }
                                console.debug('se va a guardar en el daterecord: ', sendData)
                                cx.coursesService.patchDateRecord(
                                    sendData
                                ).subscribe(ll => {
                                    callback()
                                }, err => {
                                    errorPatch = true
                                    errorPatchMessage = err
                                    callback()
                                })
                            }, function (err) {

                                if (errorPatch == true) {
                                    cx.authService.swalError("Error al guardar", "Se produjo un error al guardar fecha, detalles " + errorPatchMessage)
                                    console.error("Se produjo un error al guardar fecha, detalles " + errorPatchMessage);

                                    //revertir fecha
                                    var Info = {
                                        "id": event.data.id,
                                        "IsDateChanged": false
                                    }
                                    cx.coursesService.patchSubjectGroup(Info, event.data.id).subscribe(newData => {
                                    })

                                }
                                else {
                                    event.confirm.resolve(event.newData);
                                    cx.authService.swalEndLoad()
                                }


                            });




                        })

                    })

                })

            })
        }

    }

    reloadPage() {
        this.ngOnInit()
    }
    onEditConfirm(event): any {

        console.debug('el evento que llego fue: ', event)
        //valido las fechas
        if (moment(event.newData.DateStart, 'YYYY-MM-DD').isValid() == true && moment(event.newData.DateEnd, 'YYYY-MM-DD').isValid() == true) {

            var Info = {
                "CourseGroupID": event.newData.CourseGroupID,
                "SubjectID": event.newData.SubjectID,
                "MidTerms": event.newData.MidTerms,
                "DateStart": moment(event.newData.DateStart).format('YYYY-MM-DD'),
                "DateEnd": moment(event.newData.DateEnd).format('YYYY-MM-DD'),
                "UserID": parseInt(event.newData["UserID"]),
                "HPM": event.newData.HPM,
                "IsPhysicalTest": event.newData.IsPhysicalTest
            }

            //SI EL DATO VIENE CON ID ES PORQUE YA TIENE SUBJECTGROUP, LO ADJUNTO A Info
            //
            if (isNullOrUndefined(event.newData.id) == false) {
                Info["id"] = event.newData.id
            }


            console.debug("Se le va a mandar al enpoiint", Info)



        }
        else {

            return this.authService.swalError("Error interno en fechas", "No se pudo encontrar fechas del curso")
        }



        // ########## VALIDO QUE EL DOCENTE QUE SE GUARDO SI ESTE HABILITADO
        var docentesNoHabilitados = []
        this.ModelProfessorSmartTable.forEach(docente => {
            //REVISO TODOS LOS DOCENTES QUE NO ESTEN HABILITADOS
            //PARA ELLO VOY A LLENAR LA VARIABLE docentesNoHabilitados

            if (docente.title.includes("NO HABILITADO-ESCOGER OTRO DOCENTE")) {
                var pos = -1
                pos = docentesNoHabilitados.findIndex(x => x.value === docente.value)
                if (pos >= 0) {
                }
                else {
                    console.debug(docente)
                    docentesNoHabilitados.push({
                        value: docente.value,
                        title: docente.title,
                    })
                }

            }

        })
        console.debug("DOCENTES NO HABILITADOS", docentesNoHabilitados)
        // REVISO QUE NINGUNO DE LOS UserID no esten dentro del array de los docentes no habilitados
        var error = 0

        var positionHabilitado = -1
        positionHabilitado = docentesNoHabilitados.findIndex(x => x.value === parseInt(event.newData.UserID))
        if (positionHabilitado >= 0) {
            error = 1
        }


        if (error == 1) {
            this.authService.swalError("Error al asociar docente", "No se puede guardar el usuario porque no esta habilitado, por favor seleccione otro")
            console.debug("No se puede guardar el usuario porque no esta habilitado, por favor seleccione otro.")
            return
        }




        // #########################
        //    1 CREO EL SUBJECGROUP
        //##########################

        this.coursesService.createSubjectGroup(Info).subscribe(respuesta_nuevo => {


            event.newData.id = respuesta_nuevo.id

            // this.callEvents({ CourseGroupID: respuesta_nuevo.CourseGroupID })


            // #########################
            //    2. ASOCIO A LOS ESTUDIANTES
            //##########################

            this.coursesService.assignUserSubject({

                SubjectGroupID: respuesta_nuevo.id,
                CourseGroupID: respuesta_nuevo.CourseGroupID

            }).subscribe(ll => {
                this.authService.swalSuccess("Docente asociado correctamente", "Se ha asociado la materia al docente correctamente")
                event.confirm.resolve(event.newData);

                //CARGO LOS DATOS PARA MODIFICAR LAS FECHAS
                this.SubjectsModifyAddProfessorWithSubjectGroupID.push(event.newData)
                this.sourceDates.empty()
                this.sourceDates.load(this.SubjectsModifyAddProfessorWithSubjectGroupID)

            }, e => {
                this.authService.swalError("Error asociando estudiantes", "No se pudo asociar los estudiantes, error: " + e)
            })



        }, e => {
            this.authService.swalError("Error asociando docente  ", "No se pudo asociar el docente, error: " + e)
        })



        /*
        
                    this.authService.swalLoading("Actualizando...")
                    this.isloading = true;
        
        
        
                    console.debug(event.newData)
        
                    var Info = {
                        "id": event.newData.idRegistro,
                        "CourseGroupID": event.newData.CourseGroupID,
                        "SubjectID": event.newData.SubjectID,
                        "MidTerms": event.newData.MidTerms,
                        "DateStart": moment(event.newData.DateStart).format('YYYY-MM-DD'),
                        "DateEnd": moment(event.newData.DateEnd).format('YYYY-MM-DD'),
                        "UserID": parseInt(event.newData["UserID"]),
                        "HPM": event.newData.HPM
                    }
        
        
        
        
                    this.coursesService.createSubjectGroup(Info).subscribe(respuesta_nuevo => {
        
                        $('#calendar').fullCalendar('removeEvents')
        
        
        
        
                        this.callEvents({ CourseGroupID: event.newData.CourseGroupID })
        
                        event.confirm.resolve(event.newData);
                        this.RenderProfessor()
                        this.authService.swalEndLoad()
                        this.isloading = false;
        
        
                    })
        
                } else {
                    this.authService.swalError("Error de Fechas", "El formato valido de fecha es YYYY-MM-DD")
                    this.isloading = false;
        
        
                }*/









        //llamo al servicio 
        // this.oapinfoService.updateUserJobByID(event.data.UserJobID, isAcepted).subscribe(p => {
        //   event.confirm.resolve(event.newData);
        // });





    }



}