import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { isNullOrUndefined } from 'util';
import { School } from "../../../../models/Coursesinfo/school";
import { Module } from "../../../../models/Coursesinfo/module";
import { UsersService } from '../../../../models/usersInfo/users.service';
import { CoursesService } from '../../../../models/Coursesinfo/courses.service';
import { UsersField } from '../../../../models/usersInfo/users-fields';
import { Subject } from '../../../../models/Coursesinfo/subject';
import { Course } from '../../../../models/Coursesinfo/course';
import { AuthService } from '../../../../models/Auth/auth.service';
import { ProfileNavComponent } from '../../../../components/profile-nav/profile-nav.component';
import { NewScheduleComponent } from '../new-schedule.component';

declare var jQuery: any;
declare var $: any;
var async = require("async");

export class Intermediate {

    public Disponible = 0;
    public Professor: object = {
        0: "Seleccionar Docente",
    }

    public Grups: object = {
        0: " NO GROUP",
    }



}




@Component({
    selector: 'calendar',
    templateUrl: './calendar.component.html',
    styleUrls: ['./calendar.component.css'],
    providers: [UsersService, CoursesService, Intermediate]
})
export class CalendarComponent implements OnInit {
    CourseGroupIDSelected: any;
    public queueEvents = []
    serverTime: Date
    //variables usadas en este componente

    public Groups: any;
    public AcademicArea: any;

    public EnableTable: boolean = false;
    public ListProfessors: UsersField[];
    public SubjectsModifyAddProfessor: Subject[];


    public ModelAcademicAreaID: number;

    user: string;
    student: UsersField;
    public CourseID;
    public Subject: Subject[];
    course_by_school: Course[];
    subjects_by_course: Course[];
    Studends_by_Course: any;
    public List_students_by_course: any[];
    public List_students_by_course2: any[];
    public Students: any[];
    public StudentsByGroup: any[];
    public Contador: number;
    public AreStudents: boolean;
    public AreGroups: boolean;
    public school: Course[];
    public NumberGroups = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26]
    public SchoolByID: School;
    public ModelProfessorSmartTable;
    public ModelProfessorSmartTable2;
    abecedario = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"]

    public Disponible: number = 0;


    public colores = ["#77BB3E", "#6916E4", "#C91A62", "#F19663", "#23D6EE", "#61265F", "#464208", "#7C6445", "#258C64", "#EAB5E7", "#8CEC87", "#565ACB", "#7C188A", "#A44015", "#21EBF3", "#891CBB", "#77BB3E", "#AAE951", "#F19663", "#52B743", "#A1924C", "#3CEA93", "#C3FBD4", "#E154CE", "#680345", "#E2337D", "#6CF132", "#A0B03D", "#144A20", "#393D78", "#176548"]

    public contador: number;

    modules: Module[];
    schools: School[];
    courses: Course[];
    subjects: Course[];
    userid: string;
    areaID: number = 0;
    courseID: number = 0;
    updateevent: any;


    //fin configuracion smart table


    constructor(private authService: AuthService,
        private route: ActivatedRoute,
        private router: Router,
        public servicios: UsersService,
        public coursesService: CoursesService,
        public profileNavComponent: ProfileNavComponent,

        public intermediate: Intermediate,
        public newScheduleComponent: NewScheduleComponent,


    ) {


    }




    //INDICO QUE LOS DATOS NO ESTAN DISPONIBLES AL CARGAR EL HTML PARA NO MOSTRAR ERRORES
    DatosDisponibles: boolean = false;





    ngOnInit() {

        //GUARDO LA HORA DEL SERVIDOR
        this.authService.getTime().then(server2 => this.serverTime = server2.time);

        $("#external-events").prop("disabled", true);
        $("#calendar").prop("disabled", true);
        $("#Group").prop("disabled", true);
        $("#AcademicAreaID").prop("disabled", true);


        //SI NO HAY USUARIOS EN LOCAL STORAGE, LLEVAR A LOGIN
        if (this.authService.getCurrentUser() == null) {
            this.router.navigate(['/register']);
        } else {
            //DE LO CONTRARO CARGAR EL ID DEL USUARIO
            this.user = this.authService.getCurrentUser(); //devuelve el id del usuario

            //SI EL ID DEL USUARIO ES UN NUMERO ENTERO
            if (Number.isInteger(parseInt(this.user))) {


                //CONSULTO EL PERFIL DEL USUARIO
                var validar_usuario = [false];
                var SchoolID = 0;
                for (let role of this.profileNavComponent.roles) {
                    if (role.name == "Inspector Escuela") {
                        validar_usuario = validar_usuario.concat([true])
                        SchoolID = role.SchoolID;
                        break
                    }


                }

                //SI ALGUN ROL PUEDE VERLO CARGAR LOS DATOS
                //DE LO CONTRARIO LLEVAR AL PEFIL
                if (validar_usuario.includes(true)) {
                    //INICIA CONTENIDO



                    this.coursesService.getSchoolByID(SchoolID).subscribe(p => {
                        this.SchoolByID = p;

                    });


                    this.coursesService.getAllCoursesBySchool(SchoolID).subscribe(p => {
                        this.course_by_school = p;

                    });

                    this.student = {
                        Document: '',
                        Name1: '',
                        LastName1: '',
                        CedulaMil: '',
                        IsMilitar: true,
                        SchoolID: 4,
                        CourseOferID: 0,
                        SubjectOferID: 0,

                    }

                    this.Groups = [{

                        id: 0,
                        name: "0"

                    }]

                    this.AcademicArea = [{
                        id: 0
                    }]




                    //FINALIZA CONTENIDO
                }
            }
        }












    }



    CargarCalendario() {

        /* initialize the external events
        -----------------------------------------------------------------*/

        $('#external-events .fc-event').each(function () {

            // store data so the calendar knows to render an event upon drop
            $(this).data('event', {
                title: $.trim($(this).text()), // use the element's text as the event title
                stick: true, // maintain when user navigates (see docs on the renderEvent method)
                color: $(this).data('color'),
                //className: $(this).attr("basura"),
                //url: "cualquier cosa",
                SubjectGroupID: $(this).attr("SubjectGroupID"), // ESTE ES EL ID DE LA TABLA SUBJECTGROUP
                CourseGroupID: $(this).attr("CourseGroupID"),
                SubjectID: $(this).attr("SubjectID"),

            },
            );

            // make the event draggable using jQuery UI
            $(this).draggable({
                zIndex: 999,
                revert: true,      // will cause the event to go back to its
                revertDuration: 0  //  original position after the drag
            });

        });





        /* initialize the calendar
        -----------------------------------------------------------------*/
        var cx = this;
        // $('#calendar').fullCalendar({
        //     dayNames: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
        //     dayNamesShort: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
        //     columnFormat: 'dddd D/M',
        //     locale: "es",
        //     //hiddenDays: [0],
        //     monthNamesShort: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
        //     firstDay: 1, // Monday as first day of week
        //     allDaySlot: false,
        //     slotDuration: '00:05:00',

        //     firstHour: 6,
        //     scrollTime: '06:00:00',
        //     //minTime : '06:00:00',
        //     //maxTime:'18:00:00',

        //     viewRender: function (view, element) {
        //         if (!(/Mobi/.test(navigator.userAgent)) && jQuery().jScrollPane) {
        //             $('.fc-scroller').jScrollPane({
        //                 autoReinitialise: true,
        //                 autoReinitialiseDelay: 100
        //             });
        //         }
        //     },

        //     eventResize: function (event, delta, revertFunc) {

        //         //SI EL EVENTO QUE SE HIZO RESIZE NO TIENE ID ES PORQUE ES NUEVO, NO IMPORTA DENTRO DE LA FUNCION GUARDAR SE IDENTIFICA SI ES NUEVO

        //         if (isNullOrUndefined(event.id)) { }
        //         else {
        //             //SI EL EVENTO TIENE ID ES PORQUE YA EXISTE EN LA BASE DE DATOS ENTONCES CREAR LA VARIABLE PARA MANDARLA A LA COLA DE ACTUALIZAR

        //             //CREAR OBJETO DEL EVENTO QUE SE HIZO RESIZE
        //             var ObtjectToSave = {
        //                 "id": event.id,
        //                 "Day": event.start._d.toISOString().split('T')[0],
        //                 "HourStart": event.start._d.toISOString(),
        //                 "HourEnd": event.end._d.toISOString(),
        //                 "SubjectGroupID": parseInt(event.SubjectGroupID),
        //                 "SubjectID": parseInt(event.SubjectID),
        //                 "CourseGroupID": parseInt(event.CourseGroupID),
        //             }



        //             //BUSCO SI EN LA COLA YA EXISTE EL EVENTO CON EL ID
        //             var positionQueue = cx.queueEvents.findIndex(x => x.id == event.id)
        //             // SI NO EXISTE EN LA COLA , CONCATENAR
        //             if (positionQueue < 0) {
        //                 cx.queueEvents.push(ObtjectToSave)
        //             }
        //             else {
        //                 //SI YA EXISTE EN LA COLA, ACTUALIZAR EL EVENTO DE LA COLA
        //                 cx.queueEvents[positionQueue] = ObtjectToSave
        //             }

        //             //MUESTRO COMO ESTA LA COLA
        //             console.log(cx.queueEvents)
        //         }

        //     },

        //     eventDrop: function (event, delta, revertFunc) {
        //         //SI EL EVENTO QUE SE MOVIO NO TIENE ID ES PORQUE ES NUEVO, NO IMPORTA DENTRO DE LA FUNCION GUARDAR SE IDENTIFICA SI ES NUEVO

        //         if (isNullOrUndefined(event.id)) { }
        //         else {
        //             //SI EL EVENTO TIENE ID ES PORQUE YA EXISTE EN LA BASE DE DATOS ENTONCES CREAR LA VARIABLE PARA MANDARLA A LA COLA DE ACTUALIZAR

        //             //CREAR OBJETO DEL EVENTO QUE SE MOVIO
        //             var ObtjectToSave = {
        //                 "id": event.id,
        //                 "Day": event.start._d.toISOString().split('T')[0],
        //                 "HourStart": event.start._d.toISOString(),
        //                 "HourEnd": event.end._d.toISOString(),
        //                 "SubjectGroupID": parseInt(event.SubjectGroupID),
        //                 "SubjectID": parseInt(event.SubjectID),
        //                 "CourseGroupID": parseInt(event.CourseGroupID),
        //             }



        //             //BUSCO SI EN LA COLA YA EXISTE EL EVENTO CON EL ID
        //             var positionQueue = cx.queueEvents.findIndex(x => x.id == event.id)
        //             // SI NO EXISTE EN LA COLA , CONCATENAR
        //             if (positionQueue < 0) {
        //                 cx.queueEvents.push(ObtjectToSave)
        //             }
        //             else {
        //                 //SI YA EXISTE EN LA COLA, ACTUALIZAR EL EVENTO DE LA COLA
        //                 cx.queueEvents[positionQueue] = ObtjectToSave
        //             }

        //             //MUESTRO COMO ESTA LA COLA
        //             console.log(cx.queueEvents)
        //         }
        //     },








        //     header: {
        //         left: 'prev,next today',
        //         // left: 'prev,next today',
        //         //left: false,
        //         center: 'title',
        //         right: 'agendaWeek'
        //     },

        //     /*
        //                             events: [
                                     
        //                                 {
        //                                     "title": "Ceramics",
        //                                     "id": "821",
        //                                     "start": "2017-16-13 09:00:00",
        //                                     "end": "2017-11-13 10:30:00"
        //                                   },
        //                                   {
        //                                     "title": "Zippy",
        //                                     "id": "822",
        //                                     "start": "2017-06-13 10:00:00",
        //                                     "end": "2017-11-13 11:30:00"
        //                                   }
                                        
        //                                 // my event data
        //                             ],
        //     */
        //     //eventColor: '#378006',

        //     textEscape: false,
        //     contentHeight: 900,
        //     defaultTimedEventDuration: '00:45:00',  //CLASES DE 45 MINUTOS
        //     forceEventDuration: true, //FORZAR TODOS LOS EVENTOS AL TIEMPO CONFIGURADO
        //     durationEditable: true,
        //     eventDurationEditable: true, //NO SE PUEDE EDITAR EL TIEMPO DE CADA EVENTO
        //     eventOverlap: false, //NO SE PUEDEN TENER DOS MATERIAS A LA MISMA HORA
        //     defaultView: 'agendaWeek',  //SE VE POR DEFECTO EN SEMANA

        //     eventRender: function (event, element, view) {

        //         var title = element.find('.fc-title, .fc-list-item-title, .fc-content');
        //         title.html(title.text());



        //         if (isNullOrUndefined($(this).attr("id"))) {
        //             if (view.name == 'listDay') {

        //                 element.find(".fc-list-item-time").append("<span class='closeon'><i class='lnr lnr-trash'</i></span> ");
        //             } else {
        //                 element.find(".fc-content").prepend("<span class='closeon'><i class='lnr lnr-trash'></i></span> ");
        //             }
        //             element.find(".closeon").on('click', function () {
        //                 $('#calendar').fullCalendar('removeEvents', event._id);
        //                 console.debug('delete');
        //             });

        //         }

        //         //SI EL EVENTO TIENE ID ES PORQUE ESTA GUARDADO
        //         if (!isNullOrUndefined($(this).attr("id"))) {
        //             if (view.name == 'listDay') {

        //                 element.find(".fc-list-item-time").append("<span class='closeon'><i class='lnr lnr-trash'</i></span> ");
        //             } else {
        //                 element.find(".fc-content").prepend("<span class='closeon'><i class='lnr lnr-trash'></i></span> ");
        //             }
        //             element.find(".closeon").on('click', function () {

        //                 cx.authService.swalLoading("Borrando Evento")
        //                 //busco en el array el evento que se va a borrar
        //                 var posicion = cx.newScheduleComponent.previousEvents.findIndex(x => x.id === event.id)
        //                 //cx.newScheduleComponent.previousEvents.splice(posicion, 1);
        //                 if (posicion >= 0)
        //                     cx.newScheduleComponent.previousEvents.splice(posicion, 1);
        //                 //delete cx.newScheduleComponent.previousEvents[posicion]

        //                 cx.coursesService.DeleteScheduleSubjectByID(event.id).subscribe(deleted => {
        //                     cx.authService.swalSuccess("Evento Borrado", "")
        //                     $('#calendar').fullCalendar('removeEvents', event.id);
        //                     //cx.newScheduleComponent.callEvents({CourseGroupID:event.CourseGroupID})

        //                 }, e => {
        //                     cx.authService.swalError("Error al borrar evento", "No se pudo borrar el evento: " + e);
        //                 })
        //             });

        //         }






        //         $(element).tooltip({ title: event.title, html: true });







        //     },




        //     editable: true, //SE PUEDE EDITAR EL EVENTO UNA VEZ PUESTO EN EL CALENDARIO
        //     droppable: true, // this allows things to be dropped onto the calendar
        //     drop: function () {
        //         // is the "remove after drop" checkbox checked?

        //         // if so, remove the element from the "Draggable Events" list
        //         // $(this).remove();

        //     }
        // });
    }





    ngAfterViewChecked() {

       // this.CargarCalendario()


    }


    exportcalendar() {
        //var events = $('#calendar').fullCalendar('clientEvents');
        var events = null
        var days = {
            0: "SU",
            1: "MO",
            2: "TU",
            3: "WE",
            4: "TH",
            5: "FR",
            6: "SA"



        }


        var CalendarExport = `BEGIN:VCALENDAR
VERSION:2.0
PRODID:-//NEEDZAIO CALENDAR GENERATOR//EN
CALSCALE:GREGORIAN
METHOD:PUBLISH
X-WR-CALNAME:MI CALENDARIO
X-WR-TIMEZONE:UTC
BEGIN:VTIMEZONE
TZID:America/Bogota
X-LIC-LOCATION:America/Bogota
BEGIN:STANDARD
TZOFFSETFROM:-0500
TZOFFSETTO:-0500
TZNAME:-05
DTSTART:19700101T000000
END:STANDARD
END:VTIMEZONE`



        for (let i = 0; i < events.length; i++) {

            var start = events[i].start._d.toISOString()
            start = String(start).split(/-|:/).join('');
            start = (start).split(/\./)[0];


            var stop = events[i].end._d.toISOString()
            stop = String(stop).split(/-|:/).join('');
            stop = (stop).split(/\./)[0];


            var title = events[i].title

            title = title.replace(/<(?:.|\n)*?>/gm, '');
            title = String(title).split(/-|:/).join('');

            var days_name = days[events[i].start._d.getDay()]

            var today = new Date(this.serverTime)

            var today_cal = String(today.toISOString()).split(/-|:/).join('');
            today_cal = (today_cal).split(/\./)[0];


            CalendarExport = CalendarExport + `
BEGIN:VEVENT
DTSTART;TZID=America/Bogota:`+ String(start) + `
DTEND;TZID=America/Bogota:`+ String(stop) + `
RRULE:FREQ=WEEKLY;UNTIL=20171201T045959Z;BYDAY=`+ String(days_name) + `
DTSTAMP:`+ String(today_cal) + `
UID:unic-id`+ String(i) + `@cedoc.edu.co
CREATED:`+ String(today_cal) + `
DESCRIPTION:`+ String(title) + `
LAST-MODIFIED:`+ String(today_cal) + `
LOCATION:
SEQUENCE:0
STATUS:CONFIRMED
SUMMARY:`+ String(title) + `
TRANSP:OPAQUE
END:VEVENT`;




        }
        CalendarExport = CalendarExport + `
END:VCALENDAR`;





        console.debug(CalendarExport)

        var element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(CalendarExport));
        element.setAttribute('download', 'Calendario.ical');

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);



    }


    UpdateCalendar() {


        //var events = $('#calendar').fullCalendar('clientEvents');
        var events = null;
        this.authService.swalLoading("Actualizando Eventos");
        // var data = {events:events};

        console.debug(events.length)
        this.newScheduleComponent.TotalEvents = events.length

        console.debug("total de eventos " + this.newScheduleComponent.TotalEvents)
        this.contador = 0

        this.updateevent = { CourseGroupID: this.newScheduleComponent.CourseGroupIDSelected }
        var cx = this;


        console.debug("LA LISTA DE EVENTOS QUEDO",this.queueEvents)
        //ejecuto en paralelo el actualizar los eventos y guardar los nuevos eventos 
        async.parallel([

            function (callback) {
                //REALIZO ASYNC PARA GUARDAR LOS EVENTOS QUE SE ACTUALIZARON
            
                async.forEachLimit(cx.queueEvents, 5, function (queue, callback) {
                    cx.coursesService.PatchScheduleSubjects(queue).subscribe(updateevent => {
                        cx.updateevent = updateevent;  //VARIABLE  USADA CUANDO SE TERMINEN DE GUARDAR TODOS LOS EVENTOS, SE CONSULTE LOS EVENTOS EN DB  
                        console.debug("el cx update quedo")
                        console.log(cx.updateevent)
                        console.debug("se ejecuto el patch y esto me devolvió")
                        console.debug(updateevent)
                        callback()


                    }, error => {
                        cx.authService.swalError("Error actualizando evento", "No se pudo actualizar el evento: " + error)
                    })

                }, callback)
            },



            //EJECUTO LA FUNCION QUE ME BUSCA LOS NUEVOS EVENTOS
            function (callback) {
                async.forEachLimit(events, 5, function (event, callback) {
                    console.log("se esta ejecutando el segundo asyc")
                    var ObtjectToSave = {}
                    /*
                    console.debug(event.title)
                    console.debug(event.start._d.toISOString())
                    console.debug(event.end._d.toISOString())
                    console.debug(event.SubjectGroupID)
                    console.debug(event.CourseGroupID)
                    console.debug(event.SubjectID)
                    console.debug(event.id)
                    console.debug(event)
                    */

                    ObtjectToSave = {
                        "Day": event.start._d.toISOString().split('T')[0],
                        "HourStart": event.start._d.toISOString(),
                        "HourEnd": event.end._d.toISOString(),
                        "SubjectGroupID": parseInt(event.SubjectGroupID),
                        "SubjectID": parseInt(event.SubjectID),
                        "CourseGroupID": parseInt(event.CourseGroupID),
                    }

                    //SI EL EVENTO NO TIENE ID ES PORQUE NO EXISTE, SE HUARDA EN DB
                    if (isNullOrUndefined(event.id)) {
                        console.log("NO EXISTE EL EVENTO CON ESAS CARACTERISTICAS, SE VA A CREAR UNO NUEVO")
                        console.debug(ObtjectToSave)
                        cx.coursesService.PatchScheduleSubjects(ObtjectToSave).subscribe(newevent => {

                            //CUANDO SE CREA EL NUEVO EVENTO SE MANDA A LA VARIABLE previousEvents POR SI DESPUES LA QUIEREN BORAAR CUANDO NO HAN 
                            //ACTUALIZADO LA PAGINA
                            var ObtjectNew = {
                                "id": newevent.id,
                                "HourStart": newevent.HourStart,
                                "HourEnd": newevent.HourEnd,
                                "SubjectGroupID": newevent.SubjectGroupID,
                                "SubjectID": newevent.SubjectID,
                                "CourseGroupID": newevent.CourseGroupID,
                            }
                            cx.updateevent = ObtjectNew;
                            cx.newScheduleComponent.previousEvents.push(ObtjectNew)
                            console.log(cx.newScheduleComponent.previousEvents)

                            callback()
                        }, error => {
                            cx.authService.swalError("Error creando evento", "No se pudo crear evento: " + error)
                        })

                    }


                    else {
                        callback()

                        /*
                        ObtjectToSave["id"] = event.id
                        console.log("ESTE EVENTO YA EXISTIA SE ACTUALIZA CON LA SIGUIENTE INFORMACION")
                        console.debug(ObtjectToSave)
                        cx.coursesService.PatchScheduleSubjects(ObtjectToSave).subscribe(updateevent => {
                            cx.updateevent = updateevent;  //VARIABLE  USADA CUANDO SE TERMINEN DE GUARDAR TODOS LOS EVENTOS, SE CONSULTE LOS EVENTOS EN DB  
                            console.debug("el cx update quedo")
                            console.log(cx.updateevent)
                            console.debug("se ejecuto el patch y esto me devolvio")
                            console.debug(updateevent)
                            callback()
        
        
                        }, error => {
                            cx.authService.swalError("Error actualizando evento", "No se pudo actualizar el evento: " + error)
                        })
                        */


                    }


                }, callback)
            },


        ], function (err) {
            cx.authService.swalSuccess("CALENDARIO ACTUALIZADO", "Los eventos han sido actualizados");
            cx.newScheduleComponent.callEvents(cx.updateevent)
            console.log('SE TERMINO DE GUARDAR LA ACTUALIZACION Y LA CREACION')
        })























        /*
                for (let i = 0; i < events.length; i++) {
                    var ObtjectToSave = {}
        
                    console.debug("DATOS DEL EVENTO " + i)
        
                    console.debug(events[i].title)
                    console.debug(events[i].start._d.toISOString())
                    console.debug(events[i].end._d.toISOString())
                    console.debug(events[i].SubjectGroupID)
                    console.debug(events[i].CourseGroupID)
                    console.debug(events[i].SubjectID)
                    console.debug(events[i].id)
                    console.debug(events[i])
        
        
        
        
                    ObtjectToSave = {
        
        
                        "Day": events[i].start._d.toISOString().split('T')[0],
                        "HourStart": events[i].start._d.toISOString(),
                        "HourEnd": events[i].end._d.toISOString(),
                        "SubjectGroupID": parseInt(events[i].SubjectGroupID),
                        "SubjectID": parseInt(events[i].SubjectID),
                        "CourseGroupID": parseInt(events[i].CourseGroupID),
        
        
        
        
        
                    }
        
                    if (isNullOrUndefined(events[i].id)) {
                        console.log("NO EXISTE EL EVENTO CON ESAS CARACTERISTICAS, SE VA A CREAR UNO NUEVO")
                        console.debug(ObtjectToSave)
                        this.coursesService.PatchScheduleSubjects(ObtjectToSave).subscribe(newevent => {
        
                            console.debug(newevent)
        
        
        
                            if (this.contador == (this.newScheduleComponent.TotalEvents - 1)) {
                                this.newScheduleComponent.callEvents(newevent)
                                console.debug("aca acaba el contador")
                                this.contador = 0
        
                            }
                            console.debug("el contador va en " + this.contador + "y se guardo un evento nuevo")
                            this.contador = this.contador + 1
        
        
        
                        })
        
                    }
        
        
                    else {
                        ObtjectToSave["id"] = events[i].id
        
        
        
        
                        console.log("ESTE EVENTO YA EXISTIA SE ACTUALIZA CON LA SIGUIENTE INFORMACION")
                        console.debug(ObtjectToSave)
                        this.coursesService.PatchScheduleSubjects(ObtjectToSave).subscribe(updateevent => {
        
                            console.debug("se ejecuto el patch y esto me devolvio")
                            console.debug(updateevent)
        
        
                            if (this.contador == (this.newScheduleComponent.TotalEvents - 1)) {
                                this.newScheduleComponent.callEvents(updateevent)
                                console.debug("aca acaba el contador")
                                this.contador = 0
        
        
                            }
        
                            console.debug("el contador va en " + this.contador + "y se actualizo un evento nuevo")
                            this.contador++
        
        
        
        
        
        
        
                        })
        
        
                    }
        
        
        
        
        
                    //console.log("SE VA A ENIAR A SAVECALENDAR2")
                    //console.log(ObtjectToSave)
                    //this.SaveCalendar2(ObtjectToSave)
        
        
                    //this.SaveCalendar(ObtjectToSave, events[i].SubjectGroupID, events[i])
        
                    // this.coursesService.UpdateSubjectGroup(ObtjectToSave,events[i].SubjectGroupID).subscribe(p => { var l = p })
        
                }
                */
    }


    SaveCalendar2(subject: Subject) {


        console.debug("se va a ejecutar el savecalendar2")
        console.debug(subject)

        if (subject.id == 0) {
            delete subject.id;
            console.debug("NO EXISTE EL EVENTO CON ESAS CARACTERISTICAS, SE VA A CREAR UNO NUEVO")
            console.debug(subject)
            this.coursesService.PatchScheduleSubjects(subject).subscribe(p => {
                var l = p
                console.debug(p)


                console.debug("el contador va en " + this.contador)
                if (this.contador == (this.newScheduleComponent.TotalEvents - 1)) {
                    this.newScheduleComponent.callEvents(p)
                    console.debug("aca acaba el contador")
                }

                this.contador++



            })

        }
        else {


            //PRIMERO CONSULTO SI EXISTE EL MISMO EVENTO DEL CALENDARIO
            this.coursesService.getScheduleSubjects(subject.SubjectGroupID).subscribe(p => {

                console.debug("se consulto  " + p.length)
                //SI ENCUENTRA RESULTADOS DE EVENTOS PARA DICHO ID
                if (p.length > 0) {

                    //SI DENTRO DE LOS RESULTADOS DE LA DB EN SUBJECGTOUP NO HAY DATOS QUE COINCIDAN CON EL  SUBJECTGROUP EVENTO  QUE TENGO EN EL CALENDARIO
                    //CREO EL EVENTO
                    console.debug("permanece el subject")

                    console.debug(subject)




                    //DE LO CONTRARIO RECORRO TODOS LOS VALORES DEL RESULTADO DE LA DB  PARA ENCONTRAR EL ID DEL EVENTO DEL CALENDARIO PARA ACTUALIZAR LA INFORMACION 
                    for (let i = 0; i < p.length; i++) {

                        if (subject.SubjectGroupID == p[i].SubjectGroupID) {
                            subject.id = p[i].id;

                            console.debug("ESTE EVENTO YA EXISTIA SE ACTUALIZA CON LA SIGUIENTE INFORMACION")
                            console.debug(subject)
                            this.coursesService.PatchScheduleSubjects(subject).subscribe(response => {
                                var l = response
                                console.debug("se ejecuto el patch y esto me devolvio")
                                console.debug(response)

                                console.debug("el contador va en " + this.contador)
                                if (this.contador == (this.newScheduleComponent.TotalEvents - 1)) {
                                    this.newScheduleComponent.callEvents(response)
                                    console.debug("aca acaba el contador")

                                }


                                this.contador++

                            })

                        }

                    }

                }


            });


        }
    }









    getStyle() {
        var letters = '0123456789ABCDEF'.split('');

        var combinaciones = []
        for (let i = 0; i < 30; i++) {
            var color = '#';
            for (var k = 0; k < 6; k++) {
                color += letters[Math.round(Math.random() * 15)];
            }
            combinaciones[i] = color
        }
        //this.colores= combinaciones;
        console.debug(combinaciones)
        //return color

    }



}

